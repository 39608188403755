<template>
  <div class="flex flex-row justify-end pb-6 divide-x">
    <div class="flex flex-row items-center">
      <base-button
        class="mr-4"
        icon="refresh"
        iconClass="w-5 h-5 stroke-primary stroke-2"
        label="Reload"
        :text="true"
        @click="reload"
      />
      <base-button
        v-if="!isEmbedMode"
        class="mr-4 hidden sm:flex"
        severity="primary"
        :text="true"
        :label="
          props.numberOfFilters === 0
            ? 'Filter'
            : `Filter (${props.numberOfFilters})`
        "
        icon="filter"
        :iconClass="`w-4 h-4 stroke-2 ${
          props.numberOfFilters === 0 ? 'stroke-primary' : 'fill-primary'
        }`"
        :stopClick="false"
        @click="showFilterss"
      />
    </div>

    <div class="ps-4 flex align-middle justify-center" v-if="!isEmbedMode">
      <base-button
        :text="true"
        icon="share_fill"
        iconClass="w-5 h-5 fill-primary"
        @click="openShareModal"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watchEffect } from "vue";
import { useRoute, useRouter } from "vue-router";
const props = defineProps({
  numberOfFilters: {
    type: Number,
    default: 0
  }
});
const emits = defineEmits(["reload", "showFilter", "share"]);
const route = useRoute();
const isEmbedMode = computed(() => route.query._embed === "true");
const isShareLink = computed(() => route.query._share === "true");
const reload = () => {
  emits("reload");
};
const showFilterss = () => {
  emits("showFilter");
};
const openShareModal = () => {
  emits("share");
};
</script>

<style lang="scss" scoped></style>
