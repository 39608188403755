<template>
  <div
    ref="kanbanRef"
    id="kanban"
    class="flex flex-col overflow-x-scroll overflow-y-hidden pt-10 pb-2 h-full transition-all"
    :style="
      sidebarVisible ? `width: calc(100% - ${difference}px)` : 'width:100%'
    "
  >
    <div class="flex w-full gap-6 h-full">
      <div
        v-for="column in kanbanConfig"
        :key="column.title"
        class="flex-1 w-0 min-w-[280px] h-full flex flex-col"
      >
        <div class="flex justify-between items-center pb-4">
          <div class="flex items-center">
            <p
              class="text-left text-black font-semibold font-sans tracking-wide text-xl mx-4"
            >
              {{ column.title }} ({{ column.tasks.length }})
            </p>
            <slot name="columnAction" :column="column.id" />
          </div>
          <span v-if="column.tooltip" v-tooltip.top="column.tooltip">
            <base-vite-icon name="info" classes="me-4 w-5 rotate-180"
          /></span>
        </div>
        <div
          class="bg-primary-light rounded-lg py-4 column-width flex flex-col justify-between items-center transition-all h-full overflow-y-hidden"
        >
          <!-- Draggable component comes from vuedraggable. It provides drag & drop functionality -->
          <draggable
            :id="column.id"
            :key="column.id"
            :move="props.onMove"
            @start="startMove"
            @end="endMove"
            :sort="false"
            :list="column.tasks"
            draggable=".draggable"
            :animation="200"
            ghost-class="ghost-card"
            class="h-full w-full overflow-y-auto flex flex-col gap-y-4 min-w-[272px]"
            :group="column.group || 'tasks'"
          >
            <div
              class="px-4"
              :class="[{ 'opacity-60': !!task.discarded }, task.class]"
              v-for="(task, index) in props.isLoading
                ? column.loadingCard
                : column.tasks"
              :key="`${index}_${column.id}`"
              :id="task.id"
            >
              <slot name="content" :card="task" :column="column.id" />
            </div> </draggable
          ><slot name="callToAction" :column="column"></slot>
        </div>
      </div>
    </div>
    <div
      v-if="!!visibility"
      class="bg-white h-[calc(125px+12vh)] fixed bottom-0 w-full left-0 lg:left-20 w-full lg:w-[calc(100%-80px)] p-8"
    >
      <div
        v-if="visibility === 'discard'"
        class="flex h-full w-full dash-border-red bg-alert-light"
      >
        <draggable
          :id="discarded.id"
          @add="discard"
          :list="discarded.tasks"
          :animation="200"
          draggable=".draggable"
          class="flex justify-start items-center w-full h-full relative"
          ghost-class="ghost-card"
          :group="discarded.group"
        >
          <div
            class="flex justify-center items-center w-full h-full text-alert text-lg font-bold absolute"
          >
            <base-vite-icon name="trash_fill" classes="h-5 w-5 me-2" />
            {{ discarded.title }}
          </div>
          <!-- Each element from here will be draggable and animated. Note :key is very important here to be unique both for draggable and animations to be smooth & consistent. -->
          <!-- <div
            class="hidden"
            v-for="task in discarded.tasks"
            :key="task.id"
            :id="task.id"
          >
            <slot :cardId="task.id.toString()" />
          </div> -->
        </draggable>
      </div>
      <div
        v-if="visibility === 'activate'"
        class="flex h-full w-full dash-border-green bg-success-light"
      >
        <draggable
          :id="reactivate.id"
          @add="activate"
          :list="reactivate.tasks"
          :animation="200"
          draggable=".draggable"
          class="flex justify-start items-center w-full h-full relative"
          ghost-class="ghost-card"
          :group="reactivate.group"
        >
          <div
            class="flex justify-center items-center w-full h-full text-success text-lg font-bold absolute"
          >
            <base-vite-icon name="check-circle_fill" classes="h-5 w-5 me-2" />
            {{ reactivate.title }}
          </div>
          <!-- <div
            class="hidden"
            v-for="task in reactivate.tasks"
            :key="task.id"
            :id="task.id"
          >
            <slot :cardId="task.id.toString()" />
          </div> -->
        </draggable>
      </div>
    </div>
  </div>
</template>

<script setup>
//documentation https://github.com/SortableJS/Sortable#options
//https://www.npmjs.com/package/vue-draggable-next
//NOTE UI TESTING should be without the inspector
import { VueDraggableNext as draggable } from "vue-draggable-next";
import { computed, onMounted, ref, watchEffect } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();
const emits = defineEmits(["discard", "activate"]);
const kanbanRef = ref("");
const props = defineProps({
  isLoading: {
    type: Boolean,
    default: true
  },
  kanbanConfig: {
    type: Array,
    default: () => [],
    required: true
  },
  onMove: {
    type: Function,
    default: () => {}
  },
  startMoving: {
    type: Function,
    default: () => {}
  },
  endMoving: {
    type: Function,
    default: () => {}
  },
  discardConfig: {
    type: Object,
    default: () => ({
      id: "discard",
      title: "Discard",
      group: "tasks"
    })
  },
  activeConfig: {
    type: Object,
    default: () => ({
      id: "activate",
      title: "Reactivate",
      group: "tasks"
    })
  }
});
const discard = (event) => {
  emits("discard", event.from.id, event.item.id);
};
const activate = (event) => {
  emits("activate", event.from.id, event.item.id);
};

const visibility = ref(null);
function startMove(event) {
  visibility.value = props.startMoving(event);
}
function endMove(event) {
  visibility.value = null;
  props.endMoving(event);
}

const discarded = ref(props.discardConfig);
const reactivate = ref(props.activeConfig);
function scrollTo(initial) {
  setTimeout(() => {
    const query = route.query?.a || route.query?.opportunity;
    const container = document.getElementById("kanban");
    const ele = document.getElementById(query);

    container?.scrollTo({
      top: 0,
      left: initial ? 0 : ele?.offsetLeft - 200 || 0,
      behavior: "smooth"
    });
  }, 300);
}

const difference = ref(0);
const sidebarVisible = computed(() => {
  return !!route.query?.a || !!route.query?.opportunity;
});
onMounted(() => {
  observeContainer();
});
watchEffect(() => {
  if (sidebarVisible.value) {
    getDifference();
  }
});
watchEffect(() => {
  if (sidebarVisible.value) {
    scrollTo();
  } else {
    scrollTo(true);
  }
});
function getDifference() {
  const main = document.querySelector("main");
  const diff =
    530 - (main?.clientWidth - kanbanRef.value?.clientWidth + 128) / 2;
  if (diff < 0) {
    difference.value = 0;
  } else {
    difference.value = diff;
  }
}
const observeContainer = () => {
  const resizeObserver = new ResizeObserver(() => {
    if (sidebarVisible.value) {
      getDifference();
    }
  });
  const main = document.querySelector("main");

  if (main) {
    resizeObserver.observe(main);
  }
};
</script>

<style scoped lang="scss">
.ghost-card {
  opacity: 0.6;
}
.dash-border-red {
  padding: 2px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='%23E44E4E' stroke-width='4' stroke-dasharray='10px%2c 10px' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
  border-radius: 16px;
}
.dash-border-green {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='%231BB11B' stroke-width='4' stroke-dasharray='10px%2c 10px' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
  border-radius: 16px;
}

.draggable {
  cursor: grab;
}
</style>
