<template>
  <div class="w-full flex align-middle justify-center" :class="chipClasses">
    <base-vite-icon :name="chipConfig?.icon" :classes="iconClasses" />
    <div>{{ chipConfig?.label }}</div>
    <base-vite-icon
      v-if="chipConfig?.dropdownIconClass"
      name="chevron-down"
      :classes="chipConfig?.dropdownIconClass"
    />
  </div>
</template>

<script setup>
import { toRefs, computed } from "vue";

const props = defineProps({
  type: {
    type: String,
    default: "chip"
  },
  chipConfig: {
    type: Object,
    default: () => ({
      chipClasses: "mtb-chip",
      icon: "",
      label: "",
      hasDropdown: false,
      iconClasses: "",
      dropdownIconClasses: ""
    }),
    validate: (value) => {
      // if (typeof value !== "object" || value === null) {
      //   console.error("chipConfig must be an object.");
      //   return false;
      // } else if (
      //   [
      //     "chipClasses",
      //     "icon",
      //     "label",
      //     "hasDropdown",
      //     "iconClasses",
      //     "dropdownIconClasses"
      //   ].includes(Object.key(value))
      // ) {
      //   return false;
      // }
      return true;
    }
  }
});
const { chipConfig, type } = toRefs(props);

const iconClasses = computed(() => {
  return chipConfig.value.iconClasses;
});
const chipClasses = computed(() => {
  const classBaseOnType =
    type.value === "chip"
      ? "mtb-chip font-medium "
      : "mtb-chip-button font-bold text-base ";

  const chipClasses =
    chipConfig.value.chipClasses || chipConfig.value.parentClasses;

  return classBaseOnType + chipClasses;
});
</script>

<style lang="scss" scoped></style>
