import { useNavigationGuard } from "@/global/hooks/use-navigation.js";
import { queryMemory } from "@/global/hooks/use-filter-url-bind.js";
const Module = () => import("@/global/Module.vue");

const StartupMeetings = () => import("./views/startup-meeting.vue");
const StartupRescheduleMeeting = () =>
  import("./views/startup-reschedule-meeting.vue");
const PipelineShow = () => import("./views/startup-pipeline-show.vue");
export const STARTUP_MEETINGS_ROUTE_NAME = "startup-meetings";
import { UPCOMING_MEETINGS } from "@corporates/router.js";
const StartupChallenges = () => import("./views/startup-challenges-index.vue");
export const STARTUP_CHALLENGES_ROUTE_NAME = "startup-challenges";
export const ALL_CHALLENGES_ROUTE_NAME = "all-challenges";
export const MY_APPLICATIONS_ROUTE_NAME = "my-applications";
export const STARTUP_PIPELINE_ROUTE_NAME = "startup-pipeline-view";
export const STARTUP_PIPELINE_TABLE_ROUTE_NAME = "startup-pipeline-table-view";

export const STARTUP_MODULE_ROUTES = [
  {
    path: "/startup",
    name: "startup-pages",
    component: Module,
    redirect: () => {
      return { name: "homepage" };
    },
    children: [
      {
        path: "pipeline",
        name: "startup-pipeline",
        component: PipelineShow,
        redirect: () => {
          return { name: STARTUP_PIPELINE_ROUTE_NAME };
        },
        children: [
          {
            name: STARTUP_PIPELINE_ROUTE_NAME,
            path: "kanban",
            component: () =>
              import("@startups/components/startup-pipeline-kanban.vue")
          },
          {
            name: STARTUP_PIPELINE_TABLE_ROUTE_NAME,
            path: "table",
            component: () =>
              import("@startups/components/startup-pipeline-table.vue")
          }
        ].map((r) => {
          return {
            ...r,
            meta: {
              title: "Pipeline",
              undo: false,
              //TODO: fallback
              undoLink: STARTUP_PIPELINE_ROUTE_NAME
            }
          };
        })
      },
      {
        path: "challenges-list/:section?",
        name: STARTUP_CHALLENGES_ROUTE_NAME,
        component: StartupChallenges,
        meta: {
          title: "Open Challenges",
          size: "text-3xl",
          undo: false,
          beforeEnter: (to, from, next) => {
            if (!to.params.section) {
              next({
                name: STARTUP_CHALLENGES_ROUTE_NAME,
                params: { section: ALL_CHALLENGES_ROUTE_NAME },
                query: queryMemory(STARTUP_CHALLENGES_ROUTE_NAME)
              });
            } else {
              next();
            }
          }
        }
      },
      {
        path: "meetings/:section?",
        name: STARTUP_MEETINGS_ROUTE_NAME,
        component: StartupMeetings,
        meta: {
          title: "Meetings",
          size: "text-3xl",
          undo: false,
          beforeEnter: (to, _from, next) => {
            if (!to.params.section) {
              next({
                name: STARTUP_MEETINGS_ROUTE_NAME,
                params: { section: UPCOMING_MEETINGS }
              });
            } else {
              next();
            }
          }
        }
      }
    ].map((route) => {
      return {
        ...route,
        meta: {
          ...route.meta,
          navbar: true,
          auth: true
        },
        async beforeEnter(from, to, next) {
          const { newNavigation, mtbTeleportTrigger } = useNavigationGuard([
            "startup"
          ]);

          mtbTeleportTrigger();
          const path = await newNavigation();
          if (!path && route.meta?.beforeEnter) {
            //I can go ahead with current route meta.beforeEnter
            route.meta.beforeEnter(from, to, next);
          } else {
            next(path);
          }
        }
      };
    })
  }
];
