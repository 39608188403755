const createHoverDirective = (el, bindingValue) => {
  el.addEventListener("mouseenter", () => {
    bindingValue(true, el.getAttribute("id"));
  });
  el.addEventListener("mouseleave", () => {
    bindingValue(false, el.getAttribute("id"));
  });
};
const destroyHoverDirective = (el) => {
  el?.removeEventListener("mouseenter", createHoverDirective);
  el?.removeEventListener("mouseleave", createHoverDirective);
};
const vHover = {
  mounted: (el, binding) => createHoverDirective(el, binding.value),
  unmounted: (el) => destroyHoverDirective(el)
};
export default vHover;
