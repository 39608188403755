import { reactive, toRefs } from "vue";
import { default as support } from "@share/commonSupport.js";
import {
  getAccountsListDetails,
  getEmployeesList
} from "@/global/services/accounts-service.js";
import { useAccountsStore } from "@global/stores/accounts-store.js";
import { userErrorHandling } from "@global/hooks/use-error-handling.js";

export const useAccountDetail = () => {
  const { errorManager } = userErrorHandling();
  const getAccountDetail = async (accountId, force) => {
    const state = reactive({
      accountDetails: {},
      accountContacts: [],
      accountMainContact: {},
      accountSponsorDetails: {},
      accountType: "institution"
    });
    try {
      if (!accountId) {
        throw new Error("AccountId is required");
      }
      const accountStore = useAccountsStore();

      if (
        accountStore.state.accountFullDetails[accountId]?.accountDetails &&
        accountStore.state.accountFullDetails[accountId]?.contacts &&
        !force
      ) {
        state.accountDetails =
          accountStore.state.accountFullDetails[accountId].accountDetails;
        state.accountContacts =
          accountStore.state.accountFullDetails[accountId].contacts;
      } else {
        //Get account details
        const [accounts, contacts] = await Promise.all([
          getAccountsListDetails({
            recordIds: [accountId],
            allDetails: true
          }),
          getEmployeesList(accountId)
        ]);

        state.accountDetails = accounts?.[accountId];

        //Get contact per account
        state.accountContacts = contacts;

        accountStore.actions.setAccountFullDetails({
          accountDetails: state.accountDetails,
          contacts: contacts
        });
      }

      state.accountType =
        support.accountType(state.accountDetails?.RecordTypeId) ||
        "institution";

      //TODO: get sponsorship for startup and corporate
      state.accountSponsorDetails = [
        ...(accountStore.state.sponsorshipsList?.corporate || []),
        ...(accountStore.state.sponsorshipsList?.startups || [])
      ].find((ele) => ele.companyId === accountId);

      state.accountMainContact =
        state.accountType === "institution"
          ? state.accountContacts?.find(
              (contact) => contact.Is_Main_Contact_Point__c == true
            )
          : null;
    } catch (error) {
      console.error("error===>", error);

      errorManager({
        error,
        functionPath: "useAccountDetail",
        userErrorMessage: "Unable to load account details"
      });
    }
    return { ...toRefs(state) };
  };
  return { getAccountDetail };
};
