import { defineStore } from "pinia";
import { reactive } from "vue";
import { useUserAuthStore } from "@/global/stores/user-auth-store.js";
import { useAccountsStore } from "@/global/stores/accounts-store.js";
import {
  getAnalyticsCorporatesExposure,
  getAnalyticsInstitutionExposure,
  getChallengeSuggestionEmail
} from "../services/institution-analytics-service.js";
import { getAnalyticsStartupsExposure } from "@/global/services/exposure-service.js";
import { useChallengesStore } from "@/global/stores/challenges-store.js";
import { userErrorHandling } from "@global/hooks/use-error-handling.js";
import {
  institutionProfileExposureMock,
  sponsoredCorporateExposureMock,
  sponsoredStartupExposureMock,
  challengeSuggestionEmailMock
} from "@/mock/big-query-mock";
import { useAppStateStore } from "@/global/stores/app-state-store.js";
import { encryptSessionStorage } from "@/plugins/pinia.js";

export const useInstitutionAnalyticsStore = defineStore(
  "institution-analytics",
  () => {
    const userAuthStore = useUserAuthStore();
    const accountsStore = useAccountsStore();
    const challengesStore = useChallengesStore();
    const appStateStore = useAppStateStore();
    const { errorManager } = userErrorHandling();
    //mock state and avoid too many call to bigquery
    const state = reactive({
      sponsoredCorporateExposure: null,
      sponsoredStartupExposure: null,
      institutionProfileExposure: null,
      challengeSuggestionEmail: null
    });
    const getters = {};
    const actions = reactive({
      setSponsoredCorporateExposure: async () => {
        try {
          appStateStore.actions.setStartProcess("sponsoredCorporateExposure");
          if (
            process.env.NODE_ENV === "development" ||
            userAuthStore.getters.isDemoAccount
          ) {
            state.sponsoredCorporateExposure = sponsoredCorporateExposureMock;
          } else {
            const corporateIds =
              accountsStore.getters.getSponsoredCorporatePerInstitution?.reduce(
                (acc, corporate) => {
                  if (corporate.active) {
                    acc.push(corporate.companyId);
                  }
                  return acc;
                },
                []
              ) || [];
            const challengesIds =
              challengesStore.getters.getChallengesForSponsoredCorporates?.reduce(
                (acc, challenge) => {
                  if (
                    corporateIds.includes(
                      challenge.Challenge_Corporate_Sponsor__c
                    )
                  ) {
                    acc.push(challenge.Id);
                  }
                  return acc;
                },
                []
              ) || [];
            state.sponsoredCorporateExposure =
              [...corporateIds, ...challengesIds].length !== 0
                ? await getAnalyticsCorporatesExposure({
                    corporateIds,
                    challengesIds
                  })
                : [];
          }
        } catch (error) {
          appStateStore.actions.setError("sponsoredCorporateExposure", true);
          errorManager({
            error: error,
            functionPath: "setSponsoredCorporateExposure",
            userErrorMessage:
              "Unable to retrieve data on exposure for your partnered corporate entities"
          });
        } finally {
          appStateStore.actions.setLoading("sponsoredCorporateExposure", false);
        }
      },
      setSponsoredStartupsExposure: async () => {
        try {
          appStateStore.actions.setStartProcess("sponsoredStartupExposure");
          if (
            process.env.NODE_ENV === "development" ||
            userAuthStore.getters.isDemoAccount
          ) {
            state.sponsoredStartupExposure = sponsoredStartupExposureMock;
          } else {
            const startupIds =
              accountsStore.getters.getSponsoredStartupPerInstitution?.map(
                (startup) => {
                  return startup.companyId;
                }
              );
            state.sponsoredStartupExposure =
              startupIds?.length !== 0
                ? await getAnalyticsStartupsExposure({
                    startupIds
                  })
                : [];
          }
        } catch (error) {
          appStateStore.actions.setError("sponsoredStartupExposure", true);
          errorManager({
            error: error,
            functionPath: "setSponsoredStartupsExposure",
            userErrorMessage:
              "Unable to retrieve data on exposure for your sponsored startups"
          });
        } finally {
          appStateStore.actions.setLoading("sponsoredStartupExposure", false);
        }
      },
      setInstitutionProfileExposure: async () => {
        try {
          appStateStore.actions.setStartProcess("institutionProfileExposure");
          if (
            process.env.NODE_ENV === "development" ||
            userAuthStore.getters.isDemoAccount
          ) {
            state.institutionProfileExposure = institutionProfileExposureMock;
          } else {
            state.institutionProfileExposure =
              await getAnalyticsInstitutionExposure({
                institutionId: userAuthStore.state.accountInfo.id
              });
          }
        } catch (error) {
          appStateStore.actions.setError("institutionProfileExposure", true);
          errorManager({
            error: error,
            functionPath: "setInstitutionProfileExposure",
            userErrorMessage:
              "Unable to retrieve data on exposure for your profile"
          });
        } finally {
          appStateStore.actions.setLoading("institutionProfileExposure", false);
        }
      },
      setChallengeSuggestionEmail: async () => {
        try {
          appStateStore.actions.setStartProcess("challengeSuggestionEmail");
          if (
            process.env.NODE_ENV === "development" ||
            userAuthStore.getters.isDemoAccount
          ) {
            state.challengeSuggestionEmail = challengeSuggestionEmailMock;
          } else {
            const startupIds =
              accountsStore.getters.getSponsoredStartupPerInstitution?.map(
                (startup) => {
                  return startup.companyId;
                }
              );
            state.challengeSuggestionEmail =
              startupIds?.length !== 0
                ? await getChallengeSuggestionEmail({
                    startupIds
                  })
                : [];
          }
        } catch (error) {
          appStateStore.actions.setError("challengeSuggestionEmail", true);
          errorManager({
            error: error,
            functionPath: "setChallengeSuggestionEmail",
            userErrorMessage:
              "Unable to retrieve the number of email sent to your sponsored startups "
          });
        } finally {
          appStateStore.actions.setLoading("challengeSuggestionEmail", false);
        }
      },
      reset: () => {
        state.ecosystemUsersInfo = null;
      }
    });

    return {
      state,
      getters,
      actions
    };
  },
  {
    persist: {
      storage: encryptSessionStorage
    }
  }
);
