<template>
  <div class="w-full rounded-lg bg-primary-light py-3 px-4">
    <!-- === Header ==== -->
    <section class="flex flex-col justify-end w-full h-12">
      <div class="flex flex-row items-start justify-between w-full">
        <!-- Name + Avatar -->
        <div class="flex flex-row items-center justify-start gap-3 w-full">
          <!-- logo -->

          <Skeleton class="w-8 h-8 mb-0"></Skeleton>

          <!-- Name + comment type -->
          <div class="flex flex-col justify-center items-start w-full">
            <div
              class="flex flex-row items-center justify-between gap-1.5 w-full"
            >
              <Skeleton class="w-32 h-4 mb-0"></Skeleton>
              <div>
                <Skeleton class="w-40 h-4 my-2"></Skeleton>
              </div>
            </div>
            <span
              class="text-grey2 text-sm font-normal text-left truncate w-full"
            >
              <Skeleton class="w-24 h-4 mb-0"></Skeleton
            ></span>
          </div>
        </div>
      </div>
    </section>
    <section
      class="flex flex-col mt-2 gap-1.5 items-start justify-between w-full"
    >
      <Skeleton
        :class="[' h-4 mb-0', { 'w-2/3': n == 2 }, { 'w-full': n !== 2 }]"
        v-for="n in 2"
        :key="n"
      ></Skeleton>
    </section>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped></style>
