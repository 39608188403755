import { getAccountsList } from "@/global/services/accounts-service.js";
import {
  getCorporateSponsored,
  getStartupSponsored
} from "@/global/services/sponsorships-service.js";
import { defineStore } from "pinia";
import { computed, reactive } from "vue";
import { functionsEU } from "@plugins/firebase.js";
import { httpsCallable } from "firebase/functions";
import { useUserAuthStore } from "@/global/stores/user-auth-store.js";
import { getAccountsListDetails } from "@global/services/accounts-service.js";
import { useAppStateStore } from "./app-state-store.js";
import {
  demoInstitutionSponsoredStartups,
  demoInstitutionSponsoredCorporates
} from "@/mock/demo-institution-mock";
import { userErrorHandling } from "@global/hooks/use-error-handling.js";
import { encryptLocalStorage, encryptSessionStorage } from "@/plugins/pinia.js";

export const useAccountsStore = defineStore(
  "accounts",
  () => {
    const appStateStore = useAppStateStore();
    const userAuthStore = useUserAuthStore();
    const { errorManager } = userErrorHandling();

    const state = reactive({
      accountFullDetails: {},
      corporatesList: [],

      institutionsList: [],
      startupsList: [],
      sponsorshipsList: {},
      salesforceAccountInfo: {},
      //TODO: remove when we rework on the matching calendar page
      itemCollections: []
    });
    const getters = reactive({
      getAllAccounts: computed(() => {
        return [
          ...state.corporatesList,
          ...state.startupsList,
          ...state.institutionsList
        ];
      }),
      //TODO: ask for a rule between state and getters (when to use? and how?)
      getCorporateList: computed(() => {
        if (!state.corporatesList) {
          return [];
        }
        return state.corporatesList.map((corporate) => {
          return {
            ...corporate,
            sponsorship: state.sponsorshipsList.corporate
              ? state.sponsorshipsList.corporate.find((sponsorship) => {
                  return sponsorship.companyId === corporate.id;
                })
              : null
          };
        });
      }),
      sortedByPartnerCorporateList: computed(() => {
        if (
          !state.sponsorshipsList.corporate ||
          state.sponsorshipsList.corporate.length == 0
        ) {
          return [];
        }
        const allSponsoredCorporate = state.sponsorshipsList.corporate.map(
          (e) => e.companyId
        );
        const tmpCorporate = state.corporatesList;
        return tmpCorporate.sort((a, b) => {
          const aIsSponsored = allSponsoredCorporate.includes(a.Id);

          const bIsSponsored = allSponsoredCorporate.includes(b.Id);
          if (aIsSponsored && !bIsSponsored) {
            return -1;
          }
          if (!aIsSponsored && bIsSponsored) {
            return 1;
          }
          return 0;
        });
      }),
      getStartupsList: computed(() => {
        if (!state.startupsList) {
          return [];
        }
        return state.startupsList.map((startup) => {
          return {
            ...startup,
            sponsorship: state.sponsorshipsList.startups
              ? state.sponsorshipsList.startups.find((sponsorship) => {
                  return sponsorship.companyId === startup.id;
                })
              : null
          };
        });
      }),
      getInstituionList: computed(() => {
        if (!state.institutionsList) {
          return [];
        }
        return state.institutionsList.map((institution) => {
          return {
            ...institution,
            activeStartups:
              [
                ...(state.sponsorshipsList.corporate || []),
                ...(state.sponsorshipsList.startups || [])
              ]?.filter((sponsorship) => {
                return (
                  sponsorship.sponsorId === institution.id && sponsorship.active
                );
              }) || [],
            sponsorships: {
              startups:
                state.sponsorshipsList.startups?.filter((sponsorship) => {
                  return sponsorship.sponsorId === institution.id;
                }) || [],
              corporates:
                state.sponsorshipsList.corporate?.filter((sponsorship) => {
                  return sponsorship.sponsorId === institution.id;
                }) || []
            }
          };
        });
      }),
      //TODO: Remove it, not applying any filter or modification
      getItemCollections: computed(() => {
        if (!state.itemCollections) {
          return [];
        }
        return state.itemCollections;
      }),
      getSponsoredCorporatePerInstitution: computed(() => {
        if (
          !state.sponsorshipsList?.corporate ||
          !userAuthStore.state.accountInfo?.id
        ) {
          return [];
        }
        return state.sponsorshipsList.corporate.filter(
          (corporate) =>
            corporate.sponsorId === userAuthStore.state.accountInfo.id
        );
      }),
      getSponsoredStartupPerInstitution: computed(() => {
        if (
          !state.sponsorshipsList?.startups ||
          !userAuthStore.state.accountInfo?.id
        ) {
          return [];
        }
        return state.sponsorshipsList.startups.filter(
          (startup) => startup.sponsorId === userAuthStore.state.accountInfo.id
        );
      })
    });
    const actions = reactive({
      setAccountFullDetails: (data) => {
        state.accountFullDetails[data.accountDetails.Id] = {
          ...(state.accountFullDetails[data.accountDetails.Id] || {}),
          ...data.accountDetails
        };
      },
      setCorporatesList: async () => {
        try {
          appStateStore.actions.setStartProcess("basicCorporateInfo");
          const corporates = await getAccountsList("corporates");
          //DEBT: needing to unify the id and Id
          state.corporatesList = corporates.map((ele) => ({
            ...ele,
            Id: ele.id
          }));
          // if (!corporates || corporates.length == 0) {
          //   return;
          // }
          // actions.setAdditionalCorporateInfo(corporates, allDetails);
        } catch (error) {
          errorManager({
            error,
            functionPath: "setCorporatesList",
            userErrorMessage: "Unable to load corporates information"
          });
          appStateStore.actions.setError("basicCorporateInfo", true);
        } finally {
          appStateStore.actions.setLoading("basicCorporateInfo", false);
        }
      },
      async setAdditionalCorporateInfo(corporates, allDetails) {
        try {
          appStateStore.actions.setStartProcess("additionalCorporateInfo");
          const recordIdsChunks = chunkArray(corporates, 50);
          let delay = 0;
          const delayIncrement = 200;

          const promises = recordIdsChunks.map(
            (chunk) =>
              new Promise((resolve) => {
                setTimeout(async () => {
                  const result = await getAccountsListDetails({
                    recordIds: chunk.map((company) => company.id),
                    allDetails: allDetails
                  });
                  const updatedChunk = chunk.map((corporate) => ({
                    ...corporate,
                    ...result[corporate.id]
                  }));
                  resolve(updatedChunk);
                }, delay);
                delay += delayIncrement;
              })
          );

          const updatedData = (await Promise.all(promises)).flat();
          updatedData.map((data) => {
            state.accountFullDetails[data.Id] = data;
          });
        } catch (error) {
          appStateStore.actions.setError("additionalCorporateInfo", true);
          errorManager({
            error,
            functionPath: "setAdditionalCorporateInfo",
            userErrorMessage: "Unable to load corporates information"
          });
        } finally {
          appStateStore.actions.setLoading("additionalCorporateInfo", false);
        }
      },

      setStartupsList: async () => {
        try {
          appStateStore.actions.setStartProcess("basicStartupInfo");
          const startups = await getAccountsList("startups");
          state.startupsList = startups;
          // if (!startups || startups.length == 0) {
          //   return;
          // }
          // actions.setAdditionalStartupsInfo(startups, allDetails);
        } catch (error) {
          appStateStore.actions.setError("basicStartupInfo", true);

          errorManager({
            error,
            functionPath: "setStartupsList",
            userErrorMessage: "Unable to load startup information"
          });
        } finally {
          appStateStore.actions.setLoading("basicStartupInfo", false);
        }
      },
      async setAdditionalStartupsInfo(startups, allDetails) {
        //TODO: double-check the field  'Name' of startup

        try {
          appStateStore.actions.setStartProcess("additionalStartupInfo");
          const recordIdsChunks = chunkArray(startups, 50);
          let delay = 0;
          const delayIncrement = 200;

          const promises = recordIdsChunks.map(
            (chunk) =>
              new Promise((resolve) => {
                setTimeout(async () => {
                  const result = await getAccountsListDetails({
                    recordIds: chunk.map((company) => company.id),
                    allDetails: allDetails
                  });
                  const updatedChunk = chunk.map((startup) => ({
                    ...startup,
                    ...result[startup.id]
                  }));
                  resolve(updatedChunk);
                }, delay);
                delay += delayIncrement;
              })
          );

          const updatedData = (await Promise.all(promises)).flat();

          updatedData.map((data) => {
            state.accountFullDetails[data.Id] = data;
          });
        } catch (error) {
          appStateStore.actions.setError("additionalStartupInfo", true);
          errorManager({
            error,
            functionPath: "setAdditionalStartupsInfo",
            userErrorMessage: "Unable to load startup information"
          });
        } finally {
          appStateStore.actions.setLoading("additionalStartupInfo", false);
        }
      },

      setInstitutionsList: async () => {
        try {
          appStateStore.actions.setStartProcess("basicInstitutionInfo");
          const institutions = await getAccountsList("institutions");
          state.institutionsList = institutions;
          // if (!institutions || institutions.length == 0) {
          //   return;
          // }
          // actions.setAdditionalInstitutionsInfo(institutions, allDetails);
        } catch (error) {
          appStateStore.actions.setError("basicInstitutionInfo", true);

          errorManager({
            error,
            functionPath: "setInstitutionsList",
            userErrorMessage: "Unable to load institution information"
          });
          // throw new Error("Unable to load institutions information");
        } finally {
          appStateStore.actions.setLoading("basicInstitutionInfo", false);
        }
      },
      async setAdditionalInstitutionsInfo(institutions, allDetails) {
        try {
          appStateStore.actions.setStartProcess("additionalInstitutionsInfo");
          const recordIdsChunks = chunkArray(institutions, 50);
          let delay = 0;
          const delayIncrement = 200;

          const promises = recordIdsChunks.map(
            (chunk) =>
              new Promise((resolve) => {
                setTimeout(async () => {
                  const result = await getAccountsListDetails({
                    recordIds: chunk.map((company) => company.id),
                    allDetails: allDetails
                  });
                  const updatedChunk = chunk.map((institution) => ({
                    ...institution,
                    ...result[institution.id]
                  }));
                  resolve(updatedChunk);
                }, delay);
                delay += delayIncrement;
              })
          );

          const updatedData = (await Promise.all(promises)).flat();

          updatedData.map((data) => {
            state.accountFullDetails[data.Id] = data;
          });
        } catch (error) {
          appStateStore.actions.setError("additionalInstitutionsInfo", true);
          errorManager({
            error,
            functionPath: "setAdditionalInstitutionsInfo",
            userErrorMessage: "Unable to load institution information"
          });
        } finally {
          appStateStore.actions.setLoading("additionalInstitutionsInfo", false);
        }
      },

      setSponsorshipsList: async () => {
        try {
          appStateStore.actions.setStartProcess("sponsorshipsList");
          await Promise.all([
            actions.setSponsoredCorporate(),
            actions.setSponsoredStartup()
          ]);
        } catch (error) {
          appStateStore.actions.setError("sponsorshipsList", true);

          errorManager({
            error,
            functionPath: "setSponsorshipsList",
            userErrorMessage: "Unable to load sponsorship information"
          });
        } finally {
          appStateStore.actions.setLoading("sponsorshipsList", false);
        }
      },
      setSponsoredCorporate: async () => {
        try {
          if (userAuthStore.getters.isDemoAccount) {
            const sponsoredCorporates =
              (await getCorporateSponsored(
                userAuthStore.state.accountInfo?.id
              )) || [];
            const sponsorshipsMockCorporates =
              JSON.parse(localStorage.getItem("sponsorCorporates")) ||
              demoInstitutionSponsoredCorporates;
            state.sponsorshipsList.corporate = [
              ...sponsoredCorporates,
              ...sponsorshipsMockCorporates
            ];
            localStorage.setItem(
              "sponsorCorporates",
              JSON.stringify(sponsorshipsMockCorporates)
            );
          } else {
            state.sponsorshipsList.corporate =
              (await getCorporateSponsored(
                userAuthStore.state.accountInfo?.id
              )) || [];
          }
          // console.log("###==>", state.sponsorshipsList.corporate);
          // actions.setAdditionalCorporateInfo(
          //   state.sponsorshipsList.corporate.map(({ companyId }) => {
          //     return { id: companyId };
          //   })
          // );
        } catch (error) {
          errorManager({
            error,
            functionPath: "setSponsoredCorporate",
            userErrorMessage: "Unable to load sponsorships"
          });
          // throw new Error("Unable to load sponsorships");
        }
      },
      setSponsoredStartup: async () => {
        try {
          if (userAuthStore.getters.isDemoAccount) {
            const sponsoredStartups =
              (await getStartupSponsored(
                userAuthStore.state.accountInfo?.id
              )) || [];
            const sponsorshipsMockStartups =
              JSON.parse(localStorage.getItem("sponsorStartups")) ||
              demoInstitutionSponsoredStartups;
            state.sponsorshipsList.startups = [
              ...sponsoredStartups,
              ...sponsorshipsMockStartups
            ];
            localStorage.setItem(
              "sponsorStartups",
              JSON.stringify(sponsorshipsMockStartups)
            );
          } else {
            state.sponsorshipsList.startups =
              (await getStartupSponsored(
                userAuthStore.state.accountInfo?.id
              )) || [];
          }
          // actions.setAdditionalCorporateInfo(
          //   state.sponsorshipsList.startups.map(({ companyId }) => {
          //     return { id: companyId };
          //   })
          // );
        } catch (error) {
          errorManager({
            error,
            functionPath: "setSponsoredStartup",
            userErrorMessage: "Unable to load sponsorships"
          });
          // throw new Error("Unable to load sponsorships");
        }
      },
      setItemCollections: async (data) => {
        try {
          const res = await httpsCallable(
            functionsEU,
            "LoadStartupsLists-myPortfolio_init"
          )({
            CorporateId: data.CorporateId,
            CampaignId: data.CampaignId
          });
          state.itemCollections = res.data;
        } catch (error) {
          console.error(error);

          errorManager({
            error,
            functionPath: "setItemCollections",
            userErrorMessage: "Unable to set item collections"
          });
          // throw new Error("Unable to set item collections");
        }
      },
      reset: () => {
        state.corporatesList = [];
        state.institutionsList = [];
        state.startupsList = [];
        state.sponsorshipsList = {};
        //TODO: remove when we rework on the matching calendar page
        state.itemCollections = [];
      }
    });

    return {
      state,
      getters,
      actions
    };
  },
  {
    persist: {
      storage: encryptLocalStorage
    }
  }
);
const chunkArray = (array, chunkSize) => {
  const chunks = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }
  return chunks;
};
// Function to merge objects with duplicate keys
function mergeObjects(obj1, obj2) {
  var mergedObject = {};

  // Merge objects from obj1 into mergedObject
  Object.keys(obj1).forEach(function (key) {
    mergedObject[key] = obj1[key];
  });

  // Merge objects from obj2 into mergedObject, overriding obj1 if necessary
  Object.keys(obj2).forEach(function (key) {
    if (
      mergedObject[key] &&
      typeof mergedObject[key] === "object" &&
      typeof obj2[key] === "object"
    ) {
      Object.assign(mergedObject[key], obj2[key]);
    } else {
      mergedObject[key] = obj2[key];
    }
  });

  return mergedObject;
}
