const vClickOutside = {
  mounted: (el, binding) => {
    el.clickOutsideEvent = function (event) {
      // Check if the clicked element is neither the element
      // to which the directive is applied nor its child
      const dialogElements = document.querySelectorAll('[role="dialog"]');
      if (
        !(el === event.target || el.contains(event.target)) &&
        (!dialogElements || dialogElements.length == 0)
      ) {
        // Invoke the provided method
        binding.value(event);
      }
    };
    document.addEventListener("click", el.clickOutsideEvent);
  },
  unmounted: (el) => document.removeEventListener("click", el.clickOutsideEvent)
};
export default vClickOutside;
