<template>
  <div
    ref="svgContainer"
    class="svg-icon d-flex align-items-center"
    :style="svgStyle"
  ></div>
</template>

<script setup>
import variables from "@/global/scss/base/variables/colors/_color.module.scss";
import { computed, ref, watch } from "vue";
const props = defineProps({
  iconName: {
    type: String,
    required: true
  },
  fill: {
    type: String,
    default: "#000"
  },
  stroke: {
    type: String,
    default: "#000"
  },
  strokeWidth: {
    type: [Number, String],
    default: 1
  },
  scale: {
    type: Number,
    default: 10
  }
});
const svgContainer = ref(null);
const svgStyle = computed(() => {
  return {
    "--svg-fill": variables[props.fill],
    "--svg-stroke": variables[props.stroke],
    "--zoom": props.scale / 10,
    "--strokeWidth": props.strokeWidth
  };
});
const currentSvg = ref(null);
const path = computed(() => {
  return ""; //require(`@assets/mtbIcons/${props.iconName}.svg`);
});
const loadSvg = async (path) => {
  const response = await fetch(path);
  const text = await response.text();
  const container = svgContainer.value;
  // Parse the SVG text into an SVG element
  const parser = new DOMParser();
  const svg = parser
    .parseFromString(text, "image/svg+xml")
    .querySelector("svg");

  if (currentSvg.value) {
    container?.removeChild(currentSvg.value);
  }
  // Add the SVG element to the container
  container?.appendChild(svg);
  currentSvg.value = svg;
};

watch(
  () => path.value,
  (path) => {
    if (!path) {
      return;
    }
    loadSvg(path);
  },
  { immediate: true }
);
</script>
<style lang="scss" scoped>
.svg-icon {
  :deep(svg) {
    fill: var(--svg-fill);
    stroke: var(--svg-stroke);
    zoom: var(--zoom);
    stroke-width: var(--strokeWidth);
  }
}
</style>
