<template>
  <div
    v-if="!!sponsorship && id"
    @mouseenter="toggleOverlay($event)"
    @mouseleave="toggleOverlay($event)"
  >
    <OverlayPanel ref="overlay" class="z-10" :dismissable="true">
      <!-- Content based on the selected row's data -->
      Sponsored by {{ sponsorship.sponsorName }}
      <!-- <base-logo :companyId="sponsorship.sponsorId" classes="w-4 h-4" />
      <p class="max-w-[rem] text-base text-grey1">
        {{ sponsorship.sponsorName }}
      </p> -->
    </OverlayPanel>

    <base-vite-icon
      v-if="sponsorship"
      name="award"
      classes="fill-secondary stroke-white w-4 h-4"
    />
  </div>
</template>
<script setup>
import { computed, ref } from "vue";
import { default as envConstants } from "@share/envConstant.js";
import { useAccountsStore } from "@global/stores/accounts-store.js";
const accountsStore = useAccountsStore();
const props = defineProps({
  id: {
    type: String
  }
});
const overlay = ref();

const sponsorship = computed(() => {
  return [
    ...(accountsStore.state.sponsorshipsList?.startups || []),
    ...(accountsStore.state.sponsorshipsList?.corporate || [])
  ].find(
    (ele) =>
      ele.companyId === props.id &&
      !envConstants.INSTITUTION_TEST_DEMO_ACCOUNTS.includes(ele?.sponsorId)
  );
});
const toggleOverlay = (event) => {
  overlay.value?.toggle(event);
};
</script>
