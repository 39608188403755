<template>
  <div class="flex flex-column items-start gap-2">
    <!-- TODO: check the option if select other => remove the current one -->
    <div class="flex flex-row justify-between items-center w-full">
      <span
        :class="[isInvalid ? 'text-alert' : 'text-black']"
        class="text-sm font-medium capitalize"
        >{{ label }}</span
      >
      <slot />
    </div>

    <MultiSelect
      :id="`${name}_${contextUuid}`"
      :modelValue="inputValue"
      :options="multiselectOptions"
      optionLabel="label"
      autoFilterFocus
      :filter="multiselectOptions?.length > 0"
      :showToggleAll="multiselectOptions?.length !== 0"
      :placeholder="placeholder"
      :virtualScrollerOptions="{ itemSize: 24 }"
      :dataKey="dataKey"
      :display="display"
      :class="isInvalid ? 'border-alert' : 'border-grey3'"
      @blur="setTouched(true)"
      @update:modelValue="onChange"
    >
      <template #dropdownicon>
        <base-vite-icon name="chevron-down" classes="h-4 w-4 stroke-grey1" />
      </template>
      <template #headercheckboxicon="{ checked }">
        <div
          :class="`flex items-center justify-center w-[24px] h-[24px] border-2 rounded-md ${checked ? ' border-primary' : 'border-grey3'}`"
        >
          <base-vite-icon
            v-if="checked"
            name="checkbox-selected-noborder"
            classes="w-[24px] h-[24px] stroke-primary stroke-2"
          />
        </div>
      </template>
      <template #option="{ option }">
        <div class="w-full flex justify-between items-center">
          <span> {{ option.label }}</span>
          <slot name="labelBadge" :content="option[badgeKey]" />
        </div>
      </template>
      <template #chip="{ value }">
        <!--To apply different style use "category" key-->
        <div :class="['badge', chipConfig?.[value.category]?.parentClasses]">
          {{ value.label }}
        </div>
      </template>
      <template #itemcheckboxicon="{ checked }">
        <div
          :class="`flex items-center justify-center w-[24px] h-[24px] border-2 rounded-md ${checked ? ' border-primary' : 'border-grey3'}`"
        >
          <base-vite-icon
            v-if="checked"
            name="checkbox-selected-noborder"
            classes="w-full h-full stroke-primary stroke-2"
          />
        </div>
      </template>
      <template #footer>
        <div
          v-if="options?.length > 0"
          class="flex shadow-mtb-scrolling px-4 py-3 justify-between items-center"
        >
          <base-button
            severity="primary"
            :text="true"
            label="Clear"
            @click="clearAll"
          />
          <div class="text-base">{{ inputValue?.length }} selected</div>
        </div>
      </template>
    </MultiSelect>

    <small
      v-if="isInvalid"
      class="text-alert inline-flex items-center gap-1"
      id="text-error"
    >
      <base-vite-icon name="close" classes="w-3.5 h-3.5 stroke-2" />
      {{ errorMessage }}</small
    >
  </div>
</template>

<script setup>
import MultiSelect from "primevue/multiselect";
import { ref, watch, watchEffect, toRefs, computed } from "vue";
import { useField } from "vee-validate";
import { v4 as uuidv4 } from "uuid";
const contextUuid = uuidv4();
const props = defineProps({
  options: {
    type: Array,
    default: () => []
  },
  placeholder: {
    type: String,
    default: () => "select an option"
  },
  badgeKey: {
    type: String,
    default: null
  },
  maxSelectedLabels: {
    type: Number,
    default: () => 1
  },
  defaultSelectedOption: {
    type: Array,
    default: () => []
  },
  name: {
    type: String,
    default: ""
  },
  chipConfig: {
    type: Object,
    default: () => null
  },
  display: {
    type: String,
    default: "comma"
  },
  isDraftValidated: {
    type: Boolean,
    default: false
  },
  dataKey: {
    type: String,
    default: "value"
  },
  label: {
    type: String,
    default: ""
  },
  emptyMessage: {
    type: String,
    default: "No options available"
  }
});

const emit = defineEmits(["update:modelValue"]);

const multiselectOptions = ref(props.options);
watch(
  () => props.options,
  (newOptions) => {
    multiselectOptions.value = newOptions;
  },
  { immediate: true }
);
const { defaultSelectedOption, name, isDraftValidated } = toRefs(props);

const {
  value: inputValue,
  errorMessage,
  meta,
  setTouched,
  handleChange
} = useField(name, undefined, {
  initialValue: defaultSelectedOption,
  validateOnMount: true
});
const onChange = (value) => {
  handleChange(value);
  emit("update:modelValue", value);
};

//ONLY SHOW THE ERROR MESSAGE IF
//1. field is dirty and has a error message
//2. A Save draft
const isInvalid = computed(() => {
  return errorMessage.value && (meta.touched || isDraftValidated.value);
});

const clearAll = () => {
  inputValue.value = [];
};

// watchEffect(() => {
//   const valueEmit =
//     props.maxSelectedLabels === 1 ? inputValue.value[0] : inputValue;
//   emit("update:modelValue", valueEmit);
// });
</script>

<style lang="scss" scoped></style>
