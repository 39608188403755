<template>
  <div class="vue-world-map">
    <svg-map
      ref="svgMap"
      style="max-height: 50vh"
      @hoverCountry="methods.onHoverCountry"
      @hoverLeaveCountry="methods.onHoverLeaveCountry"
    />
    <transition name="fade">
      <div
        v-show="state.legend.name && state.notShow"
        class="vue-map-legend"
        :style="
          'left:' +
          (state.position.left + props.positionLeftTooltip) +
          'px; top: ' +
          (state.position.top + props.positionTopTooltip) +
          'px;' +
          `box-shadow:${props.legendBoxShadowCss}; border: ${props.legendBorderCss};`
        "
      >
        <div
          v-if="state.notShow"
          class="p-1 chart-tooltip d-flex justify-content-center align-items-center"
        >
          <div
            :style="`background:${
              state.mapColor[state.legend.code] || props.defaultCountryFillColor
            }; height:12px; width:12px;`"
            class="rounded-circle me-1"
          ></div>
          {{ state.legend.name }}:
          <b class="ms-1"> {{ props.countryData[state.legend.code] || 0 }} </b>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup>
import colors from "@/plugins/color.js";
import SvgMap from "./map.vue";
import {
  getDynamicMapCss,
  getBaseCss,
  getCombinedCssString
} from "./dynamic-map-css";
import { reactive, computed, watch, onMounted } from "vue";

const emits = defineEmits(["hoverCountry", "hoverLeaveCountry"]);
const props = defineProps({
  availableColor: {
    type: Object,
    default: null
  },
  countryData: {
    type: Object,
    required: true
  },
  defaultCountryFillColor: {
    type: String,
    default: "#dadada"
  },
  countryStrokeColor: {
    type: String,
    default: "#e9e9e9"
  },
  showEmptyValue: {
    type: Boolean,
    default: true
  },
  legendHeaderBackgroundColor: {
    type: String,
    default: ""
  },
  legendContentBackgroundColor: {
    type: String,
    default: ""
  },
  legendBorderCss: {
    type: String,
    default: `border: 1px solid ${colors.grey4}`
  },
  positionLeftTooltip: {
    type: Number,
    default: -50
  },
  positionTopTooltip: {
    type: Number,
    default: -20
  },
  legendBoxShadowCss: {
    type: String,
    default: "0px 0px 3px #999999"
  },
  legendFontColorHeader: {
    type: String,
    default: ""
  },
  legendFontColorContent: {
    type: String,
    default: ""
  }
});
const state = reactive({
  legend: {
    data: null,
    code: null,
    name: null
  },
  position: {
    left: 0,
    top: 0
  },
  node: document.createElement("style"),
  mapColor: {},
  keysLegend: [],
  notShow: true
});
const methods = reactive({
  onHoverCountry(country) {
    state.legend = country;
    methods.notShowLegendEmpty();
    state.position = country.position;
    emits("hoverCountry", country);
  },
  onHoverLeaveCountry(country) {
    state.legend = {
      data: null,
      code: null,
      name: null
    };
    emits("hoverLeaveCountry", country);
  },

  notShowLegendEmpty() {
    if (!props.countryData[state.legend.code] && !props.showEmptyValue) {
      state.notShow = false;
    } else {
      state.notShow = true;
    }
  },
  renderMapCSS(countryData) {
    const baseCss = getBaseCss(props);
    const { css, mapColor } = getDynamicMapCss(
      countryData,
      props.availableColor,
      props.defaultCountryFillColor
    );
    state.node.innerHTML = "";
    state.node.innerHTML = getCombinedCssString(baseCss, css);
    state.mapColor = mapColor;
  }
});
const getKeyLegend = computed(() => {
  Object.keys(props.countryData).forEach((key) => {
    if (key === "unknown") return;
    state.keysLegend.push(key);
  });
  return state.keysLegend;
});
watch(
  () => JSON.stringify(props.countryData),
  (value) => {
    methods.renderMapCSS(JSON.parse(value));
  },
  { immediate: true }
);

onMounted(() => {
  state.node.setAttribute("id", "map-css-style");
  const style = document.getElementById("map-css-style");
  if (style) {
    document.body.removeChild(style);
  }
  document.body.appendChild(state.node);
  methods.notShowLegendEmpty();
});
</script>

<style scoped lang="scss">
.vue-world-map,
#map-svg {
  height: 100%;
}
.vue-world-map * {
  transition: all 0.2s ease;
}
.vue-world-map {
  position: relative;
}

.vue-map-legend {
  background: #ffffff;
  border: 0px solid;
  position: absolute;
  z-index: 200;
  border-radius: 4px;
  // color: $grey-600;
}

.fade-left-enter-active,
.fade-left-leave-active {
  transition: all 0.3s;
  opacity: 1;
  transform: translateY(0px);
}
.fade-left-enter,
.fade-left-leave-to {
  opacity: 0;
  transition: all 0.3s;
  transform: translateX(50%);
}
</style>
