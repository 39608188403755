import { useUserAuthStore } from "@global/stores/user-auth-store.js";
import { checkDateStatus } from "@global/helpers/date.js";

export function formatMeetingObject(
  meeting,
  allMatchingCalendars,
  type = "meeting"
) {
  try {
    const userAuthStore = useUserAuthStore();

    let calendar = {};

    if (allMatchingCalendars) {
      //NOTE: to determine that startup can schedule a meeting or not, we are currently use the CalendarScope
      calendar = allMatchingCalendars.find((calendar) => {
        return (
          calendar.CorpId === meeting.Account?.Id &&
          (calendar.CalendarScope?.Startups?.includes(
            userAuthStore.state.accountInfo?.id
          ) ||
            calendar.CalendarScope?.ChallIds?.includes(
              meeting.Related_Challenge__c
            ))
        );
      });
    }

    return type === "meeting"
      ? {
          ...meeting,
          AccountId: meeting.Account?.Id,
          AccountName: meeting.Account?.Name,
          Short_Description__c: meeting.Account?.Short_Description__c,
          TimeScope: checkDateStatus(meeting.Ecosystem_Meeting_Date__c),
          matchingCalendarStatus: calendar?.status || null,
          Related_Challenge: {
            name: meeting.Related_Challenge__r?.Name || "No Challenge",
            index: meeting.Related_Challenge__c
          },
          AccountDetail: {
            name: meeting.Account?.Name,
            index: meeting.Account?.Id
          }
        }
      : {
          ...meeting,
          companyId: meeting.Account?.Id,
          companyName: meeting.Account?.Name,
          challengeId: meeting.Related_Challenge__c,
          challengeName: meeting.Related_Challenge__r?.Name,
          TimeScope: checkDateStatus(meeting.Ecosystem_Meeting_Date__c),
          matchingCalendarStatus: calendar?.status || null
        };
  } catch (error) {
    console.log("error=>", error);
    throw new Error(error);
  }
}
