import { defineAsyncComponent } from "vue";
import { useDialog } from "primevue/usedialog";
import { objectiveMapping } from "@/global/configs/challenge-mapping.js";
import { useRoute, useRouter } from "vue-router";

export const useEditStartupProfileModal = () => {
  const EditStartupProfileModal = defineAsyncComponent(
    () => import("@startups/components/startup-modal-edit-profile.vue")
  );
  const dialog = useDialog();
  const route = useRoute();
  const router = useRouter();
  const showEditStartupProfileModal = ({
    accountDetails,
    updateRoute = false
  }) => {
    return new Promise(() => {
      dialog?.open(EditStartupProfileModal, {
        props: {
          class: "w-[90vw] md:w-[670px] relative",
          header: `Edit ${accountDetails.Name} Profile`,
          modal: true,
          draggable: false
        },
        data: {
          details: {
            ...accountDetails,
            Keywords__c:
              accountDetails.Keywords__c?.replaceAll(",", ";")
                ?.replaceAll("\n", ";")
                ?.split(";")
                ?.filter((e) => e) || [],
            Main_Investors__c:
              accountDetails.Main_Investors__c?.replaceAll("\n", ";")
                ?.split(";")
                ?.filter((e) => e) || [],
            Relevant_Clients__c:
              accountDetails.Relevant_Clients__c?.replaceAll("\n", ";")
                ?.replaceAll("\n", ";")
                ?.split(";")
                ?.filter((e) => e) || [],
            Last_Funding_Stage__c: {
              name: accountDetails.Last_Funding_Stage__c,
              id: accountDetails.Last_Funding_Stage__c
            },
            Open_to_Challenge_Objectives__c:
              accountDetails.Open_to_Challenge_Objectives__c?.split(";")
                ?.map((el) => {
                  return { label: objectiveMapping[el]?.label, id: el };
                })
                ?.filter(({ label }) => label) || []
          }
        },
        onClose: () => {
          if (updateRoute == true) {
            const params = { ...route.params };
            delete params["editable"];
            router.replace({ ...route, params });
          }
        }
      });
    });
  };
  return { showEditStartupProfileModal };
};
