<template>
  <div
    v-click-outside="
      () => {
        visibleRight = false;
      }
    "
  >
    <Sidebar v-model:visible="visibleRight" position="right" @click.stop>
      <template #header>
        <div v-if="challenge" class="flex items-center gap-3 mr-auto">
          <base-vite-icon
            v-if="
              !isCorporateChallengeVisible(
                {
                  Challenge_Visibility__c: challenge.Challenge_Visibility__c,
                  Challenge_Corporate_Sponsor__c:
                    challenge.Challenge_Corporate_Sponsor__c
                },
                opportunity
              )
            "
            :name="visibilityMapping[challenge.Challenge_Visibility__c].icon"
            :classes="`${
              visibilityMapping[challenge.Challenge_Visibility__c].iconClasses
            } h-7 w-7`"
          />
          <base-logo
            v-else
            classes="w-8 h-8"
            :companyId="challenge.Challenge_Corporate_Sponsor__c"
          />
          <p class="font-bold text-xl truncate w-[300px]">
            {{ challenge.Name }}
          </p>
        </div>
      </template>
      <template #closeicon>
        <base-vite-icon name="close" classes="stroke-black stroke-2 w-6 h-6" />
      </template>
      <div v-if="!challenge && !application && !opportunity">
        <fallback-error :reload-visible="false" :isRow="false" />
      </div>
      <mtb-tab
        v-else
        :frontSpace="false"
        :tabs-config="tabsConfig"
        classes="mt-4"
        :defaultIndex="defaultTabIndex"
      >
        <template #filterButton>
          <div class="mr-4 flex" v-if="isApplyVisible">
            <!-- Filter button-->
            <base-button
              severity="primary"
              :text="true"
              label="
              Apply
            "
              icon="add"
              :iconClass="`w-4 h-4 stroke-2 stroke-primary
            `"
              :stopClick="false"
              @click="applyToChallenge(challenge)"
            />
          </div>
        </template>
        <template #challenge-info>
          <div class="flex flex-col gap-4 mt-8" v-if="challenge">
            <div
              v-if="
                isCorporateChallengeVisible({
                  Challenge_Visibility__c: challenge.Challenge_Visibility__c,
                  Challenge_Corporate_Sponsor__c:
                    challenge.Challenge_Corporate_Sponsor__c
                })
              "
              class="flex flex-col gap-1.5"
            >
              <p class="font-bold text-lg text-black">Challenge Owner</p>
              <p class="text-grey1 text-base flex items-center">
                <base-logo
                  classes="h-8 w-8 me-2"
                  :companyId="challenge.Challenge_Corporate_Sponsor__c"
                />
                {{ challenge.Challenge_Corporate_Sponsor__r.Name }}
              </p>
              <div class="flex gap-1.5 items-center">
                <base-vite-icon
                  name="globe"
                  classes="fill-primary stroke-0 w-4 h-4"
                />
                <a
                  class="text-start truncate w-[15rem] underline text-grey1 text-base"
                  :href="challenge.Challenge_Corporate_Sponsor__r?.Website"
                  target="_blank"
                  >{{ challenge.Challenge_Corporate_Sponsor__r?.Website }}</a
                >
              </div>
            </div>

            <div class="flex flex-col gap-1.5">
              <p class="font-bold text-lg text-black">Status</p>
              <div>
                <div
                  class="mtb-chip font-bold text-sm"
                  :class="
                    statusMapping[challenge?.Challenge_State__c].parentClasses
                  "
                >
                  <span class="text-base font-normal text-left">{{
                    challenge["Challenge_State__c"]
                  }}</span>
                </div>
              </div>
            </div>

            <div
              v-for="field in filteredFields"
              :key="field"
              class="flex flex-col gap-1.5"
            >
              <p class="font-bold text-lg text-black">
                {{ field.label }}
              </p>

              <div v-if="field.id == 'Challenge_Objective__c'">
                <div
                  :class="`mtb-chip text-sm ${
                    objectiveMapping[challenge[field.id]]?.parentClasses ||
                    objectiveMapping.default.parentClasses
                  }`"
                >
                  <base-vite-icon
                    :name="
                      objectiveMapping[challenge[field.id]]?.icon ||
                      objectiveMapping.default.icon
                    "
                    :classes="`${
                      objectiveMapping[challenge[field.id]]?.iconClasses ||
                      objectiveMapping.default.iconClasses
                    } h-4 w-4`"
                  />{{
                    objectiveMapping[challenge[field.id]]?.label ||
                    challenge[field.id]
                  }}
                </div>
              </div>
              <p class="text-grey1 text-base" v-else>
                {{ formatValue(field, challenge[field.id]) }}
              </p>
            </div>
          </div>
        </template>
        <template #startup-application>
          <div class="mt-8 flex flex-col items-start justify-center gap-4">
            <div class="flex flex-col gap-1.5">
              <div class="flex gap-2 items-center">
                <p class="font-bold text-lg text-black">
                  Why are you applying?
                </p>
                <base-vite-icon
                  v-if="isApplicationEditable"
                  role="button"
                  @click="applyToChallenge(challenge, application)"
                  name="edit"
                  classes="stroke-grey1 stroke-2 w-4 h-4"
                />
              </div>
              <p v-html="application.whyFit" class="text-grey1 text-base"></p>
            </div>
            <div class="flex flex-col gap-1.5" v-if="application.pitchdeckURL">
              <div class="flex gap-2 items-center">
                <p class="font-bold text-lg text-black">Pitch Deck</p>
                <!-- TODO: add conditoon here to show -->
                <base-vite-icon
                  v-if="isApplicationEditable"
                  role="button"
                  @click="applyToChallenge(challenge, application)"
                  name="edit"
                  classes="stroke-grey1 stroke-2 w-4 h-4"
                />
              </div>

              <div class="inline-flex gap-2">
                <base-vite-icon
                  name="document"
                  classes="stroke-primary stroke-2 w-4 h-4"
                />

                <a
                  @click="getPitchdeck(application.pitchdeckURL)"
                  role="button"
                  class="text-grey1 text-base underline"
                  target="_blank"
                  >Pitch-deck.pdf</a
                >
              </div>
            </div>

            <base-comments-index
              :readOnly="true"
              :startupId="application.accountId"
              :isSidebar="true"
              :selectedChallenge="challenge?.Id"
            />
          </div>
        </template>
        <template #startup-opportunity>
          <div class="flex gap-6 flex-col mt-6">
            <div class="flex gap-2 flex-col">
              <p class="font-bold text-lg text-black">Status</p>

              <base-chip
                :chipConfig="
                  stageMapping.stageMappingConfig.stageName[
                    opportunity.StageName
                  ]
                "
              />
            </div>
            <div
              class="flex gap-4"
              v-if="
                (opportunity.matchingCalendarStatus === 'ONLINE' &&
                  opportunity.stageName === 'Selected') ||
                opportunity.Ecosystem_Meeting_Date__c
              "
            >
              <p class="font-bold text-lg text-black">Meeting Details</p>
              <base-button
                v-if="
                  opportunity.matchingCalendarStatus === 'ONLINE' &&
                  opportunity.stageName === 'Selected'
                "
                :text="true"
                icon="add"
                iconClass="w-4 h-4 stroke-primary stroke-2"
                severity="primary"
                label="Schedule Meeting"
                @click="goToReschedule(opportunity.Id, 'book')"
              />
            </div>

            <div
              v-if="opportunity.Ecosystem_Meeting_Date__c"
              class="flex gap-2 flex-col"
            >
              <p class="font-bold text-md text-black">Meeting Date</p>
              <p class="text-grey1 text-base">
                {{
                  format(
                    new Date(opportunity.Ecosystem_Meeting_Date__c),
                    "dd MMM yyyy"
                  )
                }}
              </p>
            </div>
            <div
              v-if="
                opportunity.Ecosystem_Meeting_Link__c &&
                opportunity.TimeScope !== 'In the past'
              "
              class="flex gap-4 flex-row items-center"
            >
              <p class="font-bold text-md text-black">Meeting Link</p>
              <!-- TODO: condition to render it -->
              <base-button
                :text="true"
                severity="primary"
                label="Join Meeting"
                @click="joinMeeting(opportunity.Ecosystem_Meeting_Link__c)"
              />
            </div>
            <!-- TODO: bring the logic of schedule meeting -->
          </div>

          <base-comments-index
            :opportunityId="opportunity.Id"
            :isSidebar="true"
            :startupId="opportunity.AccountId"
            :selectedChallenge="challenge.Id"
            :read-only="true"
          />
        </template>
      </mtb-tab>
    </Sidebar>
  </div>
</template>

<script setup>
import Sidebar from "primevue/sidebar";
import {
  ref,
  onMounted,
  onBeforeUnmount,
  watchEffect,
  computed,
  watch
} from "vue";
import {
  visibilityMapping,
  statusMapping
} from "@global/configs/challenge-mapping.js";
import { mixpanelMtbTracking } from "@/plugins/mixpanel.js";
import { bus } from "@/main.js";
import { format } from "date-fns";
import { default as stageMapping } from "@share/stageMappingConfig.js";
import { checkDateStatus } from "@global/helpers/date.js";
import { useChallengesStore } from "@/global/stores/challenges-store.js";
import { useOpportunitiesStore } from "@/global/stores/opportunities-store.js";
import { useChallengeDetails } from "../hooks/use-challenge-details.js";
import { objectiveMapping } from "../configs/challenge-mapping.js";
import { useUserAuthStore } from "@global/stores/user-auth-store.js";
import { getOpportunityPitchdeck } from "@global/services/pitchdeck-service.js";
import { auth } from "@/plugins/firebase.js";
import { useStartupApplicationModal } from "@startups/hooks/use-startup-application-modal.js";
const { showApplicationModal } = useStartupApplicationModal();
import { isCorporateChallengeVisible } from "@global/helpers/util.js";
import { formatMeetingObject } from "@global/helpers/formatObject.js";

const visibleRight = ref(false);
const challenge = ref(null);
const application = ref(null);
const opportunity = ref(null);
const challengeStore = useChallengesStore();
const opportunityStore = useOpportunitiesStore();
const userAuthStore = useUserAuthStore();
import { useRoute, useRouter } from "vue-router";
const route = useRoute();
const router = useRouter();

const tabsConfig = computed(() => {
  return [
    {
      label: "Challenge Info",
      id: "challenge-info"
    },
    ...(application.value
      ? [
          {
            label: isMyProfile.value ? "My Application" : "Startup Application",
            id: "startup-application"
          }
        ]
      : []),
    ...(opportunity.value
      ? [
          {
            label: "My Business Opportunity",
            id: "startup-opportunity"
          }
        ]
      : [])
  ];
});
const isMyProfile = computed(() => {
  return (
    auth &&
    application.value?.accountId === userAuthStore.state?.accountInfo?.id
  );
});
const isApplyVisible = computed(() => {
  return (
    !application.value &&
    userAuthStore.state?.accountInfo?.type === "startup" &&
    !opportunity.value
  );
});
const isApplicationEditable = computed(() => {
  return (
    application.value &&
    challenge.value?.Challenge_State__c === "Open" &&
    ["MTB Selected", "Applied", "Bookmarked"].includes(
      application.value.stageName
    )
  );
});

const { challengesField, formatValue, isMyCorporate } = useChallengeDetails();

const filteredFields = computed(() => {
  if (challenge.value) {
    return challengesField(challenge.value, true);
  }
  return [];
});

const defaultTabIndex = computed(() => {
  if (application.value) {
    return tabsConfig.value.findIndex(
      (ele) => ele.id === "startup-application"
    );
  } else if (opportunity.value) {
    return tabsConfig.value.findIndex(
      (ele) => ele.id === "startup-opportunity"
    );
  } else {
    return tabsConfig.value.findIndex((ele) => ele.id === "challenge-info");
  }
});
onMounted(() => {
  setTimeout(() => {
    if (route.query?.opportunity) {
      // visibleRight.value = true;
      // accountId.value = route.query?.a;
      getInfo(route.query?.opportunity);
    }
  }, 100);
  bus.on("open-application-detail-sidebar", async (data) => {
    getInfo(data);
    router.replace({
      ...route,
      query: {
        ...route.query,
        //NOTE: application will be opportunity
        opportunity: data
      }
    });
  });
});
const getInfo = (data) => {
  const challengeDetails = [
    ...(challengeStore.state?.ecosystemPublishedChallengesList || [])
  ].find((challenge) => challenge.Id === data);
  const applicationDetails = opportunityStore.state.challengeApplications.find(
    (application) => application.id === data
  );
  const opportunityDetails =
    opportunityStore.state.startupAllOpportunities.find(
      (opportunity) => opportunity.Id === data
    );
  if (data.includes("_") && applicationDetails) {
    application.value = applicationDetails;
    challenge.value =
      challengeStore.state?.ecosystemPublishedChallengesList?.find(
        (challenge) => challenge.Id === data.split("_")[0]
      );
    opportunity.value = null;
  } else if (opportunityDetails) {
    application.value = null;
    opportunity.value = formatMeetingObject(
      {
        ...opportunityDetails,
        Account: opportunityDetails.InterestedCompAcc__r
      },
      opportunityStore.state.allMatchingCalendars,
      "sidebar"
    );

    challenge.value = [
      ...(challengeStore.state?.ecosystemPublishedChallengesList || [])
    ].find(
      (challenge) => challenge.Id === opportunity.value?.Related_Challenge__c
    );
    if (!challenge.value || !opportunity.value) {
      //FALLBACK
      challenge.value = null;
      opportunity.value = null;
    }
  } else if (challengeDetails) {
    challenge.value = challengeDetails;
    application.value = null;
    opportunity.value = null;
  }

  visibleRight.value = true;
};
//TODO: needing to refresh the data
//put all to null
onBeforeUnmount(() => {
  bus.off("open-application-detail-sidebar");
});

const getPitchdeck = async (url) => {
  try {
    const DEMO_PITCH_DECK =
      "gs://mtb_pitch-decks_opps/0014U000036I4rQQAS/SunSpark_2023-07-20";
    const PITCH_DECK_OPP_STORAGE_NAME = "gs://mtb_pitch-decks_opps/";

    const pitchDeckURL = userAuthStore.getters.isDemoAccount.value
      ? DEMO_PITCH_DECK
      : PITCH_DECK_OPP_STORAGE_NAME + url;

    await getOpportunityPitchdeck(pitchDeckURL, false);
  } catch (error) {
    throw error;
  }
};
const joinMeeting = (url) => {
  if (!url) return;
  window.open(encodeURI(url), "_blank");
};
function applyToChallenge(challenge, application) {
  showApplicationModal({
    currentApplication: application ? application : null,
    currentChallenge: { id: challenge.Id, name: challenge.Name }
  });
}

const goToReschedule = (opportunityId, action) => {
  router.push({
    name: "schedule Meeting",
    params: {
      action: action
    },
    query: {
      OPPID: opportunityId
      //TODO: we don't need it, can we remove it?
      // sfid: data.AccountDetail.index,
      // companyname: data.AccountDetail.name
    }
  });
};
watchEffect(() => {
  // NOTE: need to be separated, only whit && condition the watch observe only the condition result not the single variable
  const isVisible = visibleRight.value;
  const queryAccount = route.query?.opportunity;
  if (isVisible && queryAccount) {
    return;
  }
  setTimeout(() => {
    //re-check, should be different
    if (visibleRight.value && route.query?.opportunity) {
      return;
    }
    visibleRight.value = false;
    const updatedQuery = { ...route.query };

    delete updatedQuery?.opportunity;

    router.replace({
      ...route,
      query: updatedQuery
    });
  }, 500);
});
watch(
  () => challenge?.value?.Id,
  (val, oldVal) => {
    if (val && val !== oldVal) {
      mixpanelTrack();
    }
  },
  { immediate: true }
);
function isSuggestion() {
  //it's suggested if it came from the search or if we have some suggestions
  return !!(
    route.name === "search" ||
    challengeStore.state.startupSuggestedChallenges?.find(
      (item) => item.Id === challenge?.value?.Id
    )
  );
}
function mixpanelTrack() {
  const forMixpanel = {
    visitor_id: userAuthStore.state?.accountInfo?.id,
    visitor_name: userAuthStore.state?.accountInfo?.name,
    visitor_type: userAuthStore.state?.accountInfo?.type,
    visited_id: challenge.value?.Challenge_Corporate_Sponsor__c,
    visited_name: challenge.value?.Challenge_Corporate_Sponsor__r?.Name,
    challenge_id: challenge?.value?.Id,
    suggested: isSuggestion()
  };
  mixpanelMtbTracking({
    eventName: "Challenge Exposure",
    properties: forMixpanel
  });
}
</script>

<style lang="scss" scoped></style>
