<template>
  <div class="flex flex-column items-start gap-2">
    <div class="flex flex-row justify-between items-center w-full">
      <span
        :class="[isInvalid || injectedError ? 'text-alert' : 'text-black']"
        class="text-sm font-medium capitalize"
        >{{ props.label }}</span
      >
      <slot />
    </div>
    <Textarea
      :id="`${name}_${contextUuid}`"
      v-model="inputValue"
      autoResize
      rows="5"
      cols="30"
      :placeholder="placeholder"
      class="w-full"
      :class="[isInvalid ? 'border-alert' : 'border-grey3']"
      @blur="setTouched(true)"
    />
    <span
      v-if="props.maxLength > 0"
      class="text-grey2 font-normal text-xs self-end"
      >{{ inputValue?.length || 0 }}/{{ props.maxLength }}</span
    >
    <small
      v-if="isInvalid || injectedError"
      class="text-alert inline-flex items-center gap-1"
      id="text-error"
    >
      <base-vite-icon name="close" classes="w-3.5 h-3.5 stroke-2" />
      {{ errorMessage || injectedErrorMessage }}</small
    >
  </div>
</template>

<script setup>
import Textarea from "primevue/textarea";
import { useField } from "vee-validate";
import { ref, toRefs, watchEffect, computed } from "vue";
import { v4 as uuidv4 } from "uuid";
const contextUuid = uuidv4();
const props = defineProps({
  label: {
    type: String,
    default: ""
  },
  name: {
    type: String,
    default: ""
  },
  value: {
    type: String,
    default: ""
  },
  formCount: {
    type: Number,
    default: 0
  },
  disabled: {
    type: Boolean,
    default: false
  },
  isDraftValidated: {
    type: Boolean,
    default: false
  },
  maxLength: {
    type: Number,
    default: 0
  },
  injectedError: {
    type: Boolean,
    default: false
  },
  injectedErrorMessage: {
    type: String,
    default: null
  },
  placeholder: {
    type: String,
    default: ""
  }
});

const emit = defineEmits(["update:modelValue"]);

const { name, value, isDraftValidated } = toRefs(props);

const {
  value: inputValue,
  errorMessage,
  meta,
  setTouched
} = useField(name, undefined, {
  initialValue: value,
  validateOnMount: true
});

//ONLY SHOW THE ERROR MESSAGE IF
//1. field is dirty and has a error message
//2. A Save draft
const isInvalid = computed(() => {
  return errorMessage.value && (meta.touched || isDraftValidated.value);
});

watchEffect(() => {
  emit("update:modelValue", inputValue || "");
});
</script>

<style lang="scss" scoped></style>
