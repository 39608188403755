<template>
  <div :id="id" class="flex flex-col items-center justify-center w-full">
    <apexchart
      class="pt-3 w-full"
      type="bar"
      height="350"
      :options="chartOptions"
      :series="series"
      @dataPointSelection="showDropdownMenu"
    ></apexchart>
  </div>
</template>

<script setup>
import {
  grid,
  singleStateChart,
  toolbar,
  xaxis,
  yaxis
} from "@/global/configs/analytics.js";
import colors from "@/plugins/color.js";
import { computed, reactive, ref } from "vue";

const props = defineProps({
  id: {
    type: String,
    required: true
  },
  chartProps: {
    type: Object,
    required: true
  }
});

const series = computed(() => {
  return props.chartProps.chartData.map((series) => {
    return {
      name: "",
      data: series.data
    };
  });
});

const chartOptions = computed(() => {
  return {
    chart: {
      type: "bar",
      height: 350,
      stacked: true,
      toolbar: toolbar()
    },
    states: singleStateChart,
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          position: "top"
        }
      }
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [0],
      offsetY: -25,
      style: {
        colors: ["#6c757d"],
        fontSize: "16px",
        fontFamily: "Helvetica, Arial, sans-serif",
        fontWeight: "400"
      }
    },
    tooltip: {
      custom: function ({ series, dataPointIndex }) {
        return `
            <div class="p-1 chart-tooltip">
            ${props.chartProps.categories[dataPointIndex]}:
            <b>
            ${series[0][dataPointIndex]} </b>

            </div>`;
      }
    },
    yaxis: yaxis,
    grid: grid,
    xaxis: xaxis(props.chartProps.categories),
    legend: {
      show: false
    },
    // colors: colors.indigo[700],
    // fill: {
    //   type: "solid",
    //   opacity: 1 // Set opacity to 1
    // }
    fill: {
      opacity: 1,
      type: "gradient",
      gradient: {
        type: "vertical",
        //removed 700, 300(0,3) 100(0.1)
        colorStops: [
          [
            { offset: 0, color: colors.chart[900] },
            { offset: 100, color: colors.chart[900] }
          ],
          [
            { offset: 0, color: colors.chart[100], opacity: 0.3 },
            { offset: 100, color: colors.chart[100], opacity: 0.3 }
          ]
        ]
      }
    }
  };
});

const emits = defineEmits(["dataPointSelection"]);
function showDropdownMenu(a, _b, c) {
  const mousePosition = {
    offsetX: a.offsetX,
    offsetY: a.offsetY
  };
  const title = props.chartProps.categories[c.dataPointIndex];
  emits("dataPointSelection", { mousePosition, title });
}
</script>
<style lang="scss" scoped>
.chart-menu {
  --position-x: 0;
  --position-y: 0;
  background-color: #fff;
  top: var(--position-y);
  left: var(--position-x);
}
.chart-menu {
  --position-x: 0;
  --position-y: 0;
  background-color: #fff;
  top: var(--position-y);
  left: var(--position-x);
}
</style>
