<template>
  <MultiSelect
    v-model="internalFilterModel"
    @update:modelValue="updateFilter"
    :options="internalOptions"
    :autoFilterFocus="options?.length !== 0"
    :filter="options?.length !== 0"
    :overlayVisible="true"
    :virtualScrollerOptions="{ itemSize: 24 }"
    dataKey="index"
    :option-label="optionProps.label"
    :showToggleAll="options?.length !== 0"
    class="w-auto bg-transparent border-0 p-0"
    :pt="passthrough"
  >
    <template #header v-if="props.sortable">
      <SelectButton
        class="pt-3 px-3 w-full grid justify-between grid-cols-2"
        v-model="internalSortingModel"
        @update:modelValue="updateSort"
        :options="sortOptions"
        aria-labelledby="sorting"
        :pt="{
          button: ({ context }) => {
            return {
              class: [
                'inline-flex cursor-pointer select-none items-center align-bottom text-center',
                'overflow-hidden relative px-3 py-2 transition duration-200 border rounded-[8px] focus:outline-none',
                context.active
                  ? ' bg-primary-light border-primary'
                  : 'border-grey3'
              ]
            };
          }
        }"
      >
        <template #option="slotProps">
          <div
            v-if="slotProps.option.sortDirection === 1"
            class="flex items-center"
          >
            <base-vite-icon
              name="arrow-up"
              :classes="`${
                activeAcending ? 'stroke-primary' : 'stroke-black'
              }  h-4 w-4 stroke-2`"
            />
            <span
              class="text-sm text-black ms-2 font-medium"
              :class="{
                'text-primary': activeAcending
              }"
              >Ascending</span
            >
          </div>
          <div
            v-if="slotProps.option?.sortDirection === -1"
            class="flex items-center"
          >
            <base-vite-icon
              name="arrow-down"
              :classes="`${
                activeDescending ? 'stroke-primary' : 'stroke-black'
              }  h-4 w-4 stroke-2 
              `"
            />
            <span
              class="text-sm text-black ms-2 font-medium"
              :class="{
                'text-primary': activeDescending
              }"
              >Descending</span
            >
          </div>
        </template>
      </SelectButton>
    </template>
    <template #dropdownicon>
      <base-vite-icon name="chevron-down" classes="h-4 w-4 stroke-grey1" />
    </template>
    <template #headercheckboxicon="{ checked }">
      <div
        :class="`flex items-center justify-center w-[24px] h-[24px] border-2 rounded-md ${checked ? ' border-primary' : 'border-grey3'}`"
      >
        <base-vite-icon
          v-if="checked"
          name="checkbox-selected-noborder"
          classes="w-[24px] h-[24px] stroke-primary stroke-2"
        />
      </div>
    </template>
    <template #itemcheckboxicon="{ checked }">
      <div
        :class="`flex items-center justify-center w-[24px] h-[24px] border-2 rounded-md ${checked ? ' border-primary' : 'border-grey3'}`"
      >
        <base-vite-icon
          v-if="checked"
          name="checkbox-selected-noborder"
          classes="w-full h-full stroke-primary stroke-2"
        />
      </div>
    </template>

    <template #value>
      <span
        class="capitalize"
        :class="
          (internalSortingModel?.sortField === props.field ||
            (Array.isArray(internalSortingModel?.sortField) &&
              internalSortingModel?.sortField.some(
                (item) => item.field == props.field
              ))) &&
          'font-bold'
        "
        >{{ props.staticLabel }}</span
      >
    </template>
    <template #empty>
      <span></span>
    </template>
    <template #footer>
      <div
        v-if="options.length > 0"
        class="flex shadow-mtb-scrolling px-4 py-3 justify-between items-center"
      >
        <base-button
          severity="primary"
          :text="true"
          label="Clear"
          @click="clearAll"
        />
        <div class="text-base">
          {{ internalFilterModel ? internalFilterModel.length : 0 }}
          selected
        </div>
      </div>
    </template>
  </MultiSelect>
</template>
<script setup>
import MultiSelect from "primevue/multiselect";
import SelectButton from "primevue/selectbutton";
import { computed, ref, watchEffect, watch } from "vue";
import _ from "lodash";
const emit = defineEmits(["updateFilter", "updateSort"]);
const props = defineProps({
  field: {
    type: String,
    default: ""
  },
  filterModel: {
    type: Array,
    default: () => null
  },
  sortingModel: {
    type: Object,
    default: () => null
  },
  staticLabel: {
    type: String,
    default: ""
  },
  options: {
    type: Array,
    default: () => []
  },
  sortable: {
    type: Boolean,
    default: true
  }
});
const internalOptions = ref(props.options);
watch(
  () => props.options,
  (newOptions) => {
    internalOptions.value = newOptions;
  },
  { immediate: true }
);
//NOTE: this is to make multiselect works for both array of strings and array of object
const optionProps = computed(() => {
  const isObjectArray =
    props.options.length > 0 && typeof props.options[0] === "object";
  return {
    label: isObjectArray ? "name" : null,
    value: isObjectArray ? "index" : null
  };
});
const internalFilterModel = ref(props.filterModel);
const internalSortingModel = ref(props.sortingModel);
const sortOptions = ref([
  { sortField: props.field, sortDirection: 1 },
  { sortField: props.field, sortDirection: -1 }
]);

const activeAcending = computed(() => {
  return (
    (internalSortingModel.value?.sortDirection === 1 &&
      internalSortingModel.value?.sortField === props.field) ||
    (Array.isArray(internalSortingModel.value?.sortField) &&
      internalSortingModel.value?.sortField.some(
        (item) => item.field == props.field && item.order == 1
      ))
  );
});
const activeDescending = computed(() => {
  return (
    (internalSortingModel.value?.sortDirection === -1 &&
      internalSortingModel.value?.sortField === props.field) ||
    (Array.isArray(internalSortingModel.value?.sortField) &&
      internalSortingModel.value?.sortField.some(
        (item) => item.field == props.field && item.order == -1
      ))
  );
});
function updateFilter(value) {
  emit("updateFilter", value);
}
function updateSort(value) {
  emit("updateSort", value);
}
function clearAll() {
  emit("updateFilter", null);
  emit("updateSort", null);
}

watchEffect(() => {
  if (!_.isEqual(internalFilterModel.value, props.filterModel)) {
    internalFilterModel.value = props.filterModel;
  }
});
watchEffect(() => {
  if (!_.isEqual(internalSortingModel.value, props.sortingModel)) {
    internalSortingModel.value = props.sortingModel;
  }
});
const passthrough = _pt;
</script>
<script>
const _pt = {
  root: "w-auto md-w-auto flex cursor-pointer",
  label: "p-0 text-grey1",
  panel: "border-1 border-grey3 rounded-[8px] w-[300px] bg-white",
  header: "flex flex-column items-start",
  headerCheckbox: ({ context }) => {
    return {
      root: "flex order-2 border-b-[1px] border-grey3 border-solid w-full p-3 mtb-select-all relative items-center cursor-pointer",
      input: {
        class: [
          "flex items-center justify-center",
          "absolute opacity-0 cursor-pointer w-6 h-6 text-black rounded-lg transition-colors duration-200",
          {
            "border-grey3 bg-white": !context.checked,
            "border-primary bg-primary": context.checked
          }
        ]
      },
      box: "",
      icon: "w-4 h-4 transition-all duration-200 text-white text-base"
    };
  },
  filtercontainer:
    "flex order-1 relative w-full p-3  border-b-[1px] border-grey3 border-solid ",
  filtericon: "-mt-2 absolute top-1/2 ms-2",
  closebutton: "hidden",
  emptymessage: () => ({
    class: ["px-3"]
  }),
  wrapper: {
    class: ["max-h-[200px] overflow-auto", "bg-white border-0 rounded-md"]
  },
  list: ({ context, props }) => {
    return {
      class: [
        "list-none m-0",
        {
          "py-3": props.options.length > 0,
          "pt-3": props.options.length === 0
        }
      ]
    };
  },
  item: ({ context }) => {
    return {
      class: [
        "cursor-pointer font-normal overflow-hidden relative whitespace-nowrap items-center flex",
        "m-0 px-3 my-3 border-0 transition-shadow duration-200 rounded-none text-black text-base",
        "flex flex-row gap-2"
      ]
    };
  },
  checkboxicon: ({ context, props, state }) => {
    return {
      class: ["transition-all duration-200 text-base stroke-2"]
    };
  }
  // checkbox: ({ context }) => ({
  //   class: [
  //     "flex items-center justify-center",
  //     "border-2 w-6 h-6 rounded-lg transition-colors duration-200",
  //     "hover:border-primary focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)]",
  //     {
  //       "border-grey2": !context?.selected,
  //       "border-primary text-primary bg-white": context?.selected
  //     }
  //   ]
  // })
};
</script>
