<template>
  <div class="flex flex-col gap-y-4">
    <div class="flex gap-x-6">
      <div class="text-xl text-black font-bold">Contacts</div>
      <base-button
        label="Add new contact"
        :text="true"
        icon="add"
        iconClass="w-4 h-4 stroke-2 stroke-primary"
        severity="primary"
        @click="addContact"
      />
    </div>
    <div class="flex w-full overflow-hidden h-[55vh] pb-4">
      <div class="overflow-y-auto h-full w-full flex flex-col gap-y-4">
        <edit-contact
          v-for="contact in contactsRef"
          :key="contact.Id"
          :contact="contact"
          @resetNewContact="removeContact"
          @saveChanges="storeContact"
          @mainContactPoint="setMainContactPoint"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import { v4 as uuidv4 } from "uuid";
// Is_Main_Contact_Point__c
const emits = defineEmits(["onContactChange"]);
const props = defineProps({
  contacts: {
    type: Array,
    default: () => []
  }
});
const contactsRef = ref([]);
const emptyContact = {
  tmp: true,
  LinkedIn_URL__c: null,
  Email: null,
  FirstName: null,
  LastName: null,
  Id: `temporary_${uuidv4()}`,
  Is_Main_Contact_Point__c: false,
  Title: null
};
watch(
  props.contacts,
  (newVal, oldVal) => {
    if (newVal && oldVal && JSON.stringify(newVal) == JSON.stringify(oldVal)) {
      return;
    }
    contactsRef.value = [...props.contacts];
  },
  { immediate: true }
);

function addContact() {
  contactsRef.value.unshift(emptyContact);
}
function removeContact(id) {
  contactsRef.value = contactsRef.value.filter((contact) => {
    return contact.Id !== id;
  });
}
function storeContact({ id, form }) {
  contactsRef.value = contactsRef.value.map((contact) => {
    if (contact.Id == id) {
      return { ...form, tmp: false };
    } else {
      return { ...contact };
    }
  });
  emits("onContactChange", contactsRef.value);
}
function setMainContactPoint(id) {
  contactsRef.value = contactsRef.value.map((contact) => {
    if (contact.Id == id) {
      return {
        ...contact,
        Is_Main_Contact_Point__c: !contact.Is_Main_Contact_Point__c
      };
    } else {
      return { ...contact, Is_Main_Contact_Point__c: false };
    }
  });
  emits("onContactChange", contactsRef.value);
}
</script>
