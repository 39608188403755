<template>
  <div class="w-full flex flex-col gap-y-4 items-start">
    <Skeleton class="w-32 h-4" v-if="isLoading || isLoadingAccount"></Skeleton>
    <span class="text-lg font-bold" v-else>Similar Startups</span>
    <div
      v-if="isLoading || isLoadingAccount"
      class="w-full relative flex gap-x-4"
    >
      <base-card-small-skeleton v-for="n in 2" :key="n" />
    </div>
    <div v-else-if="!similarStartups || similarStartups.length === 0">
      <fallback-empty-state
        :isRow="false"
        message="
      It seems there are no similar startups to the one selected.
      "
      />
    </div>
    <div class="w-full relative" v-else>
      <button
        @click="scrollLeft"
        v-if="scrollLeftVisible"
        class="rounded-full bg-white border-2 border-primary p-2 absolute left-[-24px] top-1/2 transform -translate-y-1/2"
      >
        <base-vite-icon
          name="arrow-left"
          classes="stroke-primary stroke-2 w-4 h-4"
        />
      </button>
      <div
        class="w-full overflow-x-auto hide-scrollbar flex gap-x-4"
        id="rialto-carousel"
        ref="rialtoCarousel"
      >
        <base-card-small
          class="h-fit"
          v-for="startup in similarStartups"
          :key="startup.id"
          :companyId="startup.id"
          :sub-title="startup.HQ_Country__c || ''"
          account-type="startup"
          :title="startup.Name"
          :isNew="false"
          :ctaButtonConfig="null"
          :chip-config="{
            label: 'AI Suggested',
            iconClasses: 'fill-data2 w-4 h-4',
            chipClasses: 'bg-data2-light text-data2 font-bold',
            icon: 'ai-sparkle'
          }"
        >
          <template #icon-title>
            <base-sponsorship-badge :id="startup.id" /> </template
        ></base-card-small>
      </div>
      <button
        @click="scrollRight"
        v-if="scrollRightVisible"
        class="rounded-full bg-white border-2 border-primary p-2 absolute right-[-24px] top-1/2 transform -translate-y-1/2"
      >
        <base-vite-icon
          name="arrow-right"
          classes="stroke-primary stroke-2 w-4 h-4"
        />
      </button>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, watchEffect } from "vue";

import { getSimilarStartups } from "../services/rialto-service.js";

const props = defineProps({
  startupId: {
    type: String,
    default: null
  },
  isLoadingAccount: {
    type: Boolean,
    default: false
  }
});
const similarStartups = ref([]);
const isLoading = ref(true);
const rialtoCarousel = ref(null);
watchEffect(async () => {
  try {
    isLoading.value = true;
    if (props.startupId) {
      similarStartups.value = (
        await getSimilarStartups({
          AccountId: props.startupId
        })
      ).suggestions;
    }
  } catch (error) {
    console.error(error);
  } finally {
    isLoading.value = false;
  }
});

function scrollRight() {
  rialtoCarousel.value?.scrollTo({
    left: rialtoCarousel.value.scrollLeft + 200,
    behavior: "smooth"
  });
  setTimeout(() => {
    updateScrollButton();
  }, 500);
}
function scrollLeft() {
  rialtoCarousel.value?.scrollTo({
    left: rialtoCarousel.value.scrollLeft - 200,
    behavior: "smooth"
  });
  setTimeout(() => {
    updateScrollButton();
  }, 500);
}
const scrollLeftVisible = ref(false);
const scrollRightVisible = ref(false);
function updateScrollButton() {
  if (rialtoCarousel.value.scrollWidth <= rialtoCarousel.value.clientWidth) {
    scrollLeftVisible.value = false;
    scrollRightVisible.value = false;
  } else if (rialtoCarousel.value.scrollLeft <= 0) {
    scrollLeftVisible.value = false;
    scrollRightVisible.value = true;
  } else if (
    rialtoCarousel.value.scrollLeft >=
    rialtoCarousel.value.clientWidth - 1
  ) {
    scrollLeftVisible.value = true;
    scrollRightVisible.value = false;
  } else {
    scrollLeftVisible.value = true;
    scrollRightVisible.value = true;
  }
}

watchEffect(() => {
  if (isLoading.value) {
    rialtoCarousel.value?.removeEventListener("wheel", null);
  } else {
    rialtoCarousel.value &&
      setTimeout(() => {
        updateScrollButton();
        //timeout needed for the right initial value
      }, 100);
    rialtoCarousel.value?.addEventListener(
      "wheel",
      (evt) => {
        evt.preventDefault();
        rialtoCarousel.value.scrollLeft += evt.deltaY;
        updateScrollButton();
      },
      { passive: false }
    );
  }
});
onMounted(() => {});
</script>
