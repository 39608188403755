import Button from "primevue/button";
import Dialog from "primevue/dialog";
import DialogService from "primevue/dialogservice";
import ConfirmationService from "primevue/confirmationservice";
import ToastService from "primevue/toastservice";
import DynamicDialog from "primevue/dynamicdialog";
import ConfirmDialog from "primevue/confirmdialog";
import Toast from "primevue/toast";
import Sidebar from "primevue/sidebar";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Paginator from "primevue/paginator";
import ScrollPanel from "primevue/scrollpanel";
import Skeleton from "primevue/skeleton";
import Menu from "primevue/menu";
import OverlayPanel from "primevue/overlaypanel";
import Tooltip from "primevue/tooltip";
import AutoComplete from "primevue/autocomplete";
import FileUpload from "primevue/fileupload";

export function registerPrimeVueComponent(app) {
  app.component("MTB-Button", Button);
  app.component("MTB-Dialog", Dialog);
  app.component("DynamicDialog", DynamicDialog);
  app.component("ConfirmDialog", ConfirmDialog);
  app.component("Toast", Toast);
  app.component("Sidebar", Sidebar);
  app.component("DataTable", DataTable);
  app.component("Column", Column);
  app.component("ScrollPanel", ScrollPanel);
  app.component("Paginator", Paginator);
  app.component("Skeleton", Skeleton);
  app.component("MTB-Menu", Menu);
  app.component("OverlayPanel", OverlayPanel);
  app.component("AutoComplete", AutoComplete);
  app.component("FileUpload", FileUpload);
  app.directive("tooltip", Tooltip);
  app.use(DialogService);
  app.use(ConfirmationService);
  app.use(ToastService);
}
