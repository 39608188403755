<template>
  <div class="mtb-card" :class="props.size">
    <!-- === Logo ==== -->
    <Skeleton class="w-20 h-20 mb-0"></Skeleton>
    <!-- === Right container ==== -->
    <div
      class="flex flex-col justify-between items-center h-20"
      style="width: calc(100% - 80px - 16px)"
    >
      <!-- === Title + subtitle === -->
      <div class="flex flex-col items-start gap-0.5 self-stretch">
        <!-- === Title + sponsorship === -->
        <div>
          <Skeleton class="w-48 h-4"></Skeleton>
          <Skeleton class="w-32 h-4"></Skeleton>
        </div>
      </div>
      <!-- === Footer === -->
      <Skeleton class="w-full h-5 mb-0"></Skeleton>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  size: {
    type: String,
    default: "card-md"
  }
});
</script>

<style lang="scss" scoped></style>
