import { usePassThrough } from "primevue/passthrough";
import Tailwind from "primevue/passthrough/tailwind";

export const TRANSITIONS = {
  overlay: {
    enterFromClass: "opacity-0 scale-75",
    enterActiveClass:
      "transition-transform transition-opacity duration-150 ease-in",
    leaveActiveClass: "transition-opacity duration-150 ease-linear",
    leaveToClass: "opacity-0"
  }
};

const generateButtonClasses = (props) => {
  //text
  if (props.text && props.severity) {
    if (props.severity === "alert") {
      return `bg-transparent border-none hover:border-none p-0 text-alert`;
    } else if (props.severity === "primary") {
      return `bg-transparent border-none hover:border-none p-0 text-primary`;
    } else if (props.severity === "warning") {
      return `bg-transparent border-none hover:border-none p-0 text-warning`;
    } else if (props.severity === "grey2") {
      return `bg-transparent border-none hover:border-none p-0 text-grey2`;
    } else if (props.severity === "success") {
      return `bg-transparent border-none hover:border-none p-0 text-success`;
    } else if (props.severity === "mtb") {
      return `bg-transparent border-none hover:border-none p-0 text-mtb`;
    } else if (props.severity === "grey3") {
      return `bg-transparent border-none hover:border-none p-0 text-grey3`;
    }
  } else if (props.outlined && props.severity) {
    //outline
    if (props.severity === "alert") {
      return `bg-transparent text-alert border border-alert`;
    } else if (props.severity === "primary") {
      return `bg-transparent text-primary border border-primary`;
    } else if (props.severity === "warning") {
      return `bg-transparent text-warning border border-warning`;
    } else if (props.severity === "grey2") {
      return `bg-transparent text-grey2 border border-grey2`;
    } else if (props.severity === "success") {
      return `bg-transparent text-success border border-success`;
    } else if (props.severity === "mtb") {
      return `bg-transparent text-mtb border border-mtb`;
    } else if (props.severity === "grey3") {
      return `bg-transparent text-grey1 border border-grey3`;
    }
  } else if (props.severity) {
    //full
    if (props.severity === "alert") {
      return `text-white bg-alert border-alert border`;
    } else if (props.severity === "primary") {
      return `text-white bg-primary border-primary border`;
    } else if (props.severity === "warning") {
      return `text-white bg-warning border-warning border`;
    } else if (props.severity === "grey2") {
      return `text-white bg-grey2 border-grey2 border`;
    } else if (props.severity === "success") {
      return `text-white bg-success border-success border`;
    } else if (props.severity === "mtb") {
      return `text-white bg-mtb border-mtb border`;
    }
  }
};

const tailwindPassthroughConfig = {
  checkbox: {
    root: {
      class: [
        "relative cursor-pointer inline-flex relative select-none align-bottom",
        "w-6 h-6"
      ]
    },
    input: ({ props, context }) => ({
      class: [
        "flex items-center cursor-pointer justify-center accent-primary absolute opacity-0 w-full h-full top-0 left-0",
        "w-6 h-6 text-black rounded-lg transition-colors duration-200",
        {
          "focus:outline-none focus:outline-offset-0": !props.disabled,
          "cursor-default opacity-60": props.disabled
        }
      ]
    }),
    icon: "w-4 h-4 transition-all duration-200 text-white text-base",
    box: "cursor-pointer"
  },
  sidebar: {
    root: ({ props }) => ({
      class: [
        "w-[100vw] sm:w-[500px] flex flex-col pointer-events-auto relative transform translate-x-0 translate-y-0 translate-z-0 relative transition-transform duration-300",
        "bg-white text-gray-700 border-0 rounded-l-4xl shadow-lg p-6",
        {
          "!transition-none !transform-none !w-screen !h-screen !max-h-full !top-0 !left-0":
            props.position == "full",
          "h-full w-80": props.position == "left" || props.position == "right",
          "h-40 w-full": props.position == "top" || props.position == "bottom"
        }
      ]
    }),
    header: {
      class: [
        "flex flex-row items-top justify-between pb-4 border-1 border-transparent border-b-grey3"
      ]
    },

    closeButton: {
      class: [
        "flex items-center justify-center overflow-hidden relative h-full",
        "w-8 h-8 text-gray-500 border-0 bg-transparent",
        "hover:text-gray-700 hover:border-transparent hover:bg-transparent",
        "focus:outline-none focus:outline-offset-0 focus:shadow-none" // focus
      ]
    },
    closeButtonIcon: "w-4 h-4 inline-block",
    content: {
      class: ["h-full w-full", "grow overflow-y-auto p-2"]
    },
    mask: ({ props }) => ({
      class: [
        "flex pointer-events-auto",
        "bg-transparent",
        { "force-right-sidebar": props.position == "right" }
      ]
    }),
    transition: ({ props }) => {
      return props.position === "top"
        ? {
            enterFromClass: "translate-x-0 -translate-y-full translate-z-0",
            leaveToClass: "translate-x-0 -translate-y-full translate-z-0"
          }
        : props.position === "bottom"
          ? {
              enterFromClass: "translate-x-0 translate-y-full translate-z-0",
              leaveToClass: "translate-x-0 translate-y-full translate-z-0"
            }
          : props.position === "left"
            ? {
                enterFromClass: "-translate-x-full translate-y-0 translate-z-0",
                leaveToClass: "-translate-x-full translate-y-0 translate-z-0"
              }
            : props.position === "right"
              ? {
                  enterFromClass:
                    "translate-x-full translate-y-0 translate-z-0",
                  leaveToClass: "translate-x-full translate-y-0 translate-z-0"
                }
              : {
                  enterFromClass: "opacity-0",
                  enterActiveClass: "transition-opacity duration-200 ease-in",
                  leaveActiveClass: "transition-opacity duration-200 ease-in",
                  leaveToClass: "opacity-0"
                };
    }
  },
  button: {
    root: ({ props, context }) => {
      return {
        class: [
          // { "px-[26px] py-2": props.size === "small" },
          {
            "mtb-button-sm": props.size === "small",
            "mtb-button": props.size === "medium"
          },
          "cursor-pointer",
          "transition duration-200 ease-in-out",
          generateButtonClasses(props),
          { "shadow-lg": props.raised },
          { "rounded-md": !props.rounded, "rounded-full": props.rounded },
          { "shadow-lg": props.raised && props.text },
          { "opacity-60 pointer-events-none cursor-default": context.disabled }
        ]
      };
    },
    label: ({ props }) => {
      //TODO: find a way to make label style more flexible
      return {
        class: props.label
          ? [
              "text-base font-bold whitespace-nowrap",
              { "text-sm ": props.size === "small" }
            ]
          : "hidden"
      };
    },
    icon: ({ props }) => ({
      class: [
        "mx-0",
        {
          "mr-2": props.iconPos == "left" && props.label != null,
          "ml-2": props.iconPos == "right" && props.label != null,
          "mb-2": props.iconPos == "top" && props.label != null,
          "mt-2": props.iconPos == "bottom" && props.label != null
        }
      ]
    }),
    badge: ({ props }) => ({
      class: [
        {
          "ml-2 w-4 h-4 leading-none flex items-center justify-center":
            props.badge
        }
      ]
    }),
    loadingicon: ({ props }) => {
      return {
        class: { "custom-spinner": props.loading }
      };
    }
  },
  chips: {
    root: ({ props }) => {
      return {
        class: [
          "flex",
          {
            "select-none pointer-events-none cursor-default": props.disabled
          }
        ]
      };
    },
    container: {
      class: [
        "m-0 py-1 px-1 list-none cursor-text overflow-hidden flex items-center flex-wrap gap-2",
        "w-full",
        "font-sans text-base  transition-colors duration-200 appearance-none rounded-lg bg-white",
        "focus:outline-none focus:outline-offset-0 ]"
      ]
    },
    inputtoken: ({ props }) => {
      return {
        class: [
          {
            hidden: !props.inputProps.inlineEditable
          },
          {
            "flex flex-1 inline-flex ": props.inputProps.inlineEditable
          },
          "py-1.5 px-0",
          "bg-white"
        ]
      };
    },
    input: ({ props }) => ({
      class: [
        "font-sans text-base  text-primary p-0 m-0",
        "border-0 outline-none shadow-none rounded-none w-full",
        {
          [props.inputClass]: props.inputClass
        },
        {
          "select-none pointer-events-none cursor-default": !props.inputProps
        }
      ]
    }),
    removeTokenIcon: ({ props }) => ({
      class: [{ hidden: props.disabled || !props.inputProps.editable }]
    }),
    token: {
      class: [
        "py-1 px-2  bg-primary-light rounded-[8px] token",
        "cursor-default inline-flex items-center"
      ]
    },
    label: {
      class: ["text-primary text-base font-bold"]
    }
  },
  multiselect: {
    root: ({ props }) => ({
      class: [
        //max-h-[50px]
        "flex cursor-pointer select-none",
        "bg-white border transition-colors duration-200 ease-in-out rounded-lg",
        "w-full",
        {
          "opacity-60 select-none pointer-events-none cursor-default":
            props?.disabled
        }
      ]
    }),
    emptymessage: () => ({
      class: ["px-3"]
    }),
    labelContainer: "overflow-hidden flex flex-auto cursor-pointer",
    label: ({ props }) => ({
      class: [
        "block cursor-pointer",
        "text-grey2 text-base",
        "p-3 transition duration-200",
        {
          "!p-3":
            props.display !== "chip" &&
            (props?.modelValue == null || props?.modelValue == undefined),
          "!py-1.5 px-3": props.display == "chip" && props?.modelValue !== null
          //   "!p-3": props.display == "chip" && props?.modelValue == null
        }
      ]
    }),
    token: {
      class: [
        "py-1 px-2 mr-2 bg-primary-light text-primary rounded-sm",
        "cursor-default inline-flex items-center"
      ]
    },
    removeTokenIcon: "ml-2",
    trigger: {
      class: [
        "flex items-center justify-center shrink-0",
        "bg-transparent text-gray-600 w-12 rounded-tr-lg rounded-br-lg"
      ]
    },
    panel: "border-1 border-grey3 rounded-[8px] w-[300px] bg-white",
    header: "flex flex-column items-start",
    headerCheckbox: ({ props, context }) => {
      return {
        root: "flex order-2 border-b-[1px] border-grey3 border-solid w-full p-3 mtb-select-all relative items-center cursor-pointer",
        input: {
          class: [
            "flex items-center cursor-pointer justify-center accent-primary relative opacity-0 z-20",
            "w-6 h-6 text-black rounded-lg transition-colors duration-200",
            {
              "focus:outline-none focus:outline-offset-0": !props.disabled,
              "cursor-default opacity-60": props.disabled
            }
            // "flex items-center justify-center accent-primary",
            // "border-2 w-6 h-6 text-black rounded-lg transition-colors duration-200",
            // {
            //   "border-grey3 bg-white": !context.checked,
            //   "border-primary bg-primary": context.checked
            // }
          ]
        },
        box: "absolute border-0 z-10",
        icon: "w-4 h-4 transition-all duration-200 text-white text-base"
      };
    },
    filtercontainer:
      "flex order-1 relative w-full p-3 border-b-[1px] border-grey3 border-solid ",
    filtericon: "-mt-2 absolute top-1/2 ms-2",
    filterinput: {
      class: [
        "pr-7 -mr-7",
        "w-full",
        "font-sans text-base text-black bg-white ps-7 pe-3 py-3 border border-grey3 transition duration-200 rounded-lg appearance-none",
        "hover:border-primary focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)]"
      ]
    },
    wrapper: {
      class: ["max-h-[200px] overflow-auto", "bg-white border-0 rounded-md"]
    },
    list: ({ context, props }) => {
      return {
        class: [
          "list-none m-0",
          {
            "py-3": props.options?.length > 0,
            "pt-3": props.options?.length === 0
          }
        ]
      };
    },
    item: ({ context }) => ({
      class: [
        "cursor-pointer font-normal overflow-hidden relative whitespace-nowrap ",
        "m-0 px-3 my-2 border-0 transition-shadow duration-200 rounded-none text-black text-base",
        "flex flex-row items-center gap-2"
      ]
    }),
    checkboxContainer: {
      class: [
        "inline-flex cursor-pointer select-none align-bottom relative",
        "mr-2",
        "w-6 h-6"
      ]
    },
    checkbox: ({ context }) => ({
      class: [
        "flex items-center justify-center",
        "border-2 w-6 h-6 rounded-lg transition-colors duration-200",
        "hover:border-primary focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)]",
        {
          "border-grey2": !context?.selected,
          "border-primary text-primary bg-white": context?.selected
        }
      ]
    }),
    checkboxicon: ({ context, props, state }) => {
      return {
        class: ["transition-all duration-200 text-base stroke-2"]
      };
    },
    itemgroup: {
      class: ["m-0 p-3 text-gray-800 bg-white font-bold", "cursor-auto"]
    },
    option: "text-base inline-block",
    clearicon: "text-gray-500 right-12 -mt-2 absolute top-1/2",
    closebutton: "hidden",
    transition: TRANSITIONS.overlay
  },
  dropdown: {
    root: ({ props }) => {
      return {
        class: [
          "cursor-pointer inline-flex relative select-none",
          "bg-white transition-colors duration-200 ease-in-out rounded-md border",
          {
            "opacity-60 select-none pointer-events-none cursor-default":
              props.disabled
          }
        ]
      };
    },
    emptymessage: () => ({
      class: ["px-3"]
    }),
    panel: "border-1 border-grey3 rounded-[8px] w-[300px] bg-white",
    input: ({ props }) => ({
      class: [
        "cursor-pointer block flex flex-auto relative overflow-hidden overflow-ellipsis whitespace-nowrap",
        "bg-transparent border-0 text-grey2",
        "p-3 transition duration-200 bg-transparent rounded appearance-none font-sans text-base",
        "focus:outline-none focus:shadow-none",
        { "pr-7": props.showClear }
      ]
    }),
    trigger: {
      class: [
        "flex items-center justify-center shrink-0",
        "bg-transparent text-black w-12 rounded-tr-lg rounded-br-lg"
      ]
    },
    wrapper: {
      class: [
        "max-h-[200px] overflow-auto",
        "bg-white text-grey2  shadow-lg rounded-bl-lg rounded-br-lg"
      ]
    },
    list: "py-3 list-none m-0",
    item: ({ context }) => ({
      class: [
        "cursor-pointer font-normal overflow-hidden relative whitespace-nowrap ",
        "m-0 p-3 border-0 transition-shadow duration-200 rounded-none text-base",
        {
          "text-black": !context.focused && !context.selected,
          "bg-primary-light text-primary": context.focused || context.selected
        }
      ]
    }),
    itemgroup: {
      class: ["m-0 p-3 text-gray-800 bg-white font-bold", "cursor-auto"]
    },

    header: {
      class: [
        "p-3 border-b border-gray-300 text-gray-700 mt-0 rounded-tl-lg rounded-tr-lg"
      ]
    },
    filtercontainer: "relative",
    filterinput: {
      class: [
        "pr-7 -mr-7",
        "w-full",
        "font-sans text-base text-gray-700 bg-white py-3 pr-3 pl-6 border border-gray-300 transition duration-200 rounded-lg appearance-none",
        "hover:border-blue-500 focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)]"
      ]
    },
    filtericon: "-mt-2 absolute top-1/2 ml-2",
    clearicon: "text-gray-500 right-12 -mt-2 absolute top-1/2",
    transition: TRANSITIONS.overlay
  },
  calendar: {
    root: ({ props }) => ({
      class: [
        "inline-flex max-w-full relative text-sm",
        {
          "opacity-60 select-none pointer-events-none cursor-default":
            props.disabled
        }
      ]
    }),
    input: {
      class: [
        "font-sans text-base text-gray-600 transition-colors duration-200 appearance-none rounded-lg",
        // "hover:border-primary", //Hover,
        "focus:outline-none focus:shadow-none",
        "border-solid p-3 border-1 border-grey3 rounded-md w-full p-2"
      ]
    },
    panel: ({ props }) => {
      return {
        class: [
          "bg-white text-sm mtb-card z-[1200]",
          "min-w-[250px] w-auto",
          {
            "shadow-md border-0 absolute": !props.inline,
            "inline-block overflow-x-auto border border-grey3 p-2 rounded-lg":
              props.inline
          }
        ]
      };
    },
    header: {
      class: [
        "flex items-center justify-between",
        "p-2 text-black font-semibold m-0 border-b border-grey3 rounded-t-lg"
      ]
    },
    previousbutton: {
      class: [
        "flex items-center justify-center cursor-pointer overflow-hidden relative",
        "w-8 h-8 text-gray-600 border-0 bg-transparent rounded-full transition-colors duration-200 ease-in-out",
        "hover:text-black hover:border-transparent hover:bg-gray-200"
      ]
    },
    title: "leading-8 mx-auto",
    monthTitle: {
      class: [
        "text-black transition duration-200 font-semibold p-2 py-0",
        "mr-2",
        "hover:text-primary"
      ]
    },
    yearTitle: {
      class: [
        "text-black transition duration-200 font-semibold p-2 py-0",
        "hover:text-primary"
      ]
    },
    nextbutton: {
      class: [
        "flex items-center justify-center cursor-pointer overflow-hidden relative",
        "w-8 h-8 text-gray-600 border-0 bg-transparent rounded-full transition-colors duration-200 ease-in-out",
        "hover:text-black hover:border-transparent hover:bg-gray-200"
      ]
    },
    table: {
      class: ["border-collapse w-full", "my-2"]
    },
    tableheadercell: "p-0 text-xs text-center",
    weekday: "text-gray-600",
    day: ({ context }) => ({
      class: ["p-0"]
    }),
    daylabel: ({ context, instance, props }) => {
      let additionalClasses = [];
      if (props.selectionMode == "range") {
        if (
          instance?.modelValue?.[0] &&
          instance?.modelValue?.[0]?.getDate() == context.date.day &&
          instance?.modelValue?.[0]?.getMonth() == context.date.month &&
          instance?.modelValue?.[0]?.getFullYear() == context.date.year &&
          context.selected
        ) {
          additionalClasses.push("rounded-full", "text-white", "bg-primary");
        } else if (
          instance?.modelValue?.[1] &&
          instance?.modelValue?.[1]?.getDate() == context.date.day &&
          instance?.modelValue?.[1]?.getMonth() == context.date.month &&
          instance?.modelValue?.[1]?.getFullYear() == context.date.year &&
          context.selected
        ) {
          additionalClasses.push("rounded-full", "text-white", "bg-primary");
        } else if (
          instance?.modelValue?.[0] &&
          instance?.modelValue?.[1] &&
          (instance?.modelValue?.[0]?.getDate() != context.date.day ||
            instance?.modelValue?.[0]?.getMonth() != context.date.month ||
            instance?.modelValue?.[0]?.getFullYear() != context.date.year ||
            instance?.modelValue?.[1]?.getDate() != context.date.day ||
            instance?.modelValue?.[1]?.getMonth() != context.date.month ||
            instance?.modelValue?.[1]?.getFullYear() != context.date.year) &&
          context.selected
        ) {
          additionalClasses.push(
            "rounded-full",
            "text-primary",
            "bg-primary-light"
          );
        }
      } else if (props.selectionMode == "single") {
        additionalClasses.push("rounded-full");
        if (context.selected) {
          additionalClasses.push("text-white", "bg-primary");
        }
      }
      // console.log("additionalClasses", additionalClasses);

      return {
        class: [
          ...additionalClasses, // Add the conditional classes here
          "w-9 h-9 text-sm transition-shadow duration-200 border-transparent border",
          "flex items-center justify-center mx-auto overflow-hidden relative",
          "focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)]",
          {
            "text-grey2 cursor-default": context.disabled,
            "cursor-pointer": !context.disabled
          },
          {
            "text-black rounded-full bg-grey4":
              context.date.today && !context.selected && !context.disabled
          },
          {
            "text-gray-600 bg-transprent hover:bg-gray-200 rounded-full":
              !context.selected && !context.disabled && !context.date.today
          }
        ]
      };
    },
    monthpicker: "my-2 flex flex-wrap",
    month: ({ context }) => ({
      class: [
        "w-1/3 inline-flex items-center justify-center cursor-pointer overflow-hidden relative",
        "p-2 transition-shadow duration-200 rounded-lg",
        "focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)]",
        {
          "text-gray-600 bg-transprent hover:bg-gray-200":
            !context.selected && !context.disabled,
          "text-blue-700 bg-primary-light hover:bg-blue-200":
            context.selected && !context.disabled
        }
      ]
    }),
    yearpicker: {
      class: ["my-2 flex flex-wrap"]
    },
    year: ({ context }) => {
      return {
        class: [
          "w-1/2 inline-flex items-center justify-center hover:cursor-pointer overflow-hidden relative",
          "p-2 transition-shadow duration-200 rounded-lg",
          "focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)]",
          {
            "text-gray-600 bg-transprent hover:bg-gray-200":
              !context.selected && !context.disabled,
            "text-blue-700 bg-primary-light hover:bg-blue-200":
              context.selected && !context.disabled,
            "cursor-default pointer-events-none opacity-50": context.disabled
          }
        ]
      };
    },
    timepicker: {
      class: [
        "flex justify-center items-center",
        "border-t-1 border-solid border-grey3 p-2"
      ]
    },
    separatorcontainer: "flex items-center flex-col px-2",
    separator: "text-xl",
    hourpicker: "flex items-center flex-col px-2",
    minutepicker: "flex items-center flex-col px-2",
    ampmpicker: "flex items-center flex-col px-2",
    incrementbutton: {
      class: [
        "flex items-center justify-center cursor-pointer overflow-hidden relative",
        "w-8 h-8 text-gray-600 border-0 bg-transparent rounded-full transition-colors duration-200 ease-in-out",
        "hover:text-black hover:border-transparent hover:bg-gray-200"
      ]
    },
    decrementbutton: {
      class: [
        "flex items-center justify-center cursor-pointer overflow-hidden relative",
        "w-8 h-8 text-gray-600 border-0 bg-transparent rounded-full transition-colors duration-200 ease-in-out",
        "hover:text-black hover:border-transparent hover:bg-gray-200"
      ]
    },
    groupcontainer: "flex",
    group: {
      class: ["flex-1", "pr-0.5 pl-0.5 pt-0 pb-0"]
    },
    transition: TRANSITIONS.overlay
  },
  tabmenu: {
    root: "w-auto min-h-[24px]",
    menu: {
      class: [
        "flex m-0 p-0 list-none flex-nowrap gap-6 align-items-center",
        "flex flex-1 list-none m-0 p-0 ",
        "bg-transparent border border-gray-300 border-0 border-b-2",
        "outline-none no-underline text-base list-none"
      ]
    },
    inkbar: {
      class: ["hidden"]
    },
    menuitem: ({ props }) => {
      const element =
        props.model && props.model.length ? props.model.length : 1;
      return {
        class: ["mr-0", `${element < 4 ? "max-w-auto" : "max-w-[7rem]"}`]
      };
    },
    action: ({ context, state }) => {
      return {
        class: [
          "items-center cursor-pointer flex overflow-hidden relative select-none text-decoration-none select-none",
          "py-1 px-0 rounded-t-md transition-shadow duration-200 m-0 ",
          "focus:outline-none focus:outline-offset-0 focus:shadow-0 ",
          "font-bold",
          {
            "text-grey2 border-b-0 hover:border-gray-300":
              state.d_activeIndex !== context.index, // Condition-based hover styles.
            "border-b-2 border-primary text-primary":
              state.d_activeIndex === context.index, // Condition-based active styles.
            "opacity-50 !cursor-default": context.item?.disabled
          }
        ],
        style: "top:2px"
      };
    },
    label: "inline-block text-ellipsis whitespace-nowrap overflow-hidden"
  },
  autocomplete: {
    root: "relative inline-flex w-full",
    input: {
      class:
        "w-full transition-colors duration-200 appearance-none rounded-lg font-sans text-base text-grey1  bg-white  p-3 border border-grey3 focus:outline-offset-0 focus:outline-none"
    },
    loadingIcon: "position-absolute right-2 top-0 bottom-0 mx-0 my-auto",
    emptyMessage: "px-2",
    panel:
      "max-w-[300px] overflow-y-auto bg-white border-t-0 rounded-b-2xl border-grey3 border-1 border-solid",
    item: "cursor-pointer font-normal overflow-hidden relative whitespace-wrap m-0 p-3 border-0  transition-shadow duration-200 rounded-none bg-white text-grey1  hover:text-grey1 hover:bg-grey4"
  },
  dialog: {
    root: {
      class: "rounded-lg shadow-lg border-0 bg-white transform scale-100 m-0"
    },
    header: {
      class:
        "flex items-center justify-between flex-shrink-0 bg-white text-gray-800 border-t-0 rounded-tl-2xl rounded-tr-2xl p-6"
    },
    title: "font-bold text-xxl text-black",
    headerTitle: {
      class: "font-bold text-xl"
    },
    headerIcons: {
      class: "flex items-center"
    },
    closeButton: {
      class: [
        "flex items-center justify-center overflow-hidden relative",
        "w-5 h-5 text-gray-500 border-0 bg-transparent rounded-full transition duration-200 ease-in-out mr-2 last:mr-0",
        "hover:text-gray-700 hover:border-transparent hover:bg-gray-200"
      ]
    },
    closeButtonIcon: {
      class: "text-black w-5 h-5 inline-block"
    },
    content: {
      class:
        "overflow-y-auto bg-white text-gray-700 px-6 pb-6 pt-0 rounded-bl-2xl rounded-br-2xl h-full "
    },
    mask: {
      class:
        "fixed top-0 left-0 w-full h-full flex items-center justify-center pointer-events-auto bg-black bg-opacity-40 transition duration-200 z-1002"
    }
  },

  paginator: {
    root: () => ({
      class:
        "flex items-center justify-center flex-wrap bg-transparent text-black text-base border-none px-4 py-2 "
      // style: {
      //   boxShadow: "0 1px 8px 2px rgba(243, 243, 243)"
      // }
    }),
    previousPageButton: ({ context }) => ({
      class: ["me-4", context.disabled ? "text-grey2" : undefined]
    }),
    nextPageButton: ({ context }) => ({
      class: ["ms-4", context.disabled ? "text-grey2" : undefined]
    })
  },
  //NOTE: z-index number is important!!!!! (loading overlay(3) - header(2) - rowgroup header(2) - body cell(1) )
  //NOTE: set a fix height for table header - use it for the position of row group
  datatable: {
    root: ({ props }) => {
      return {
        class: [
          "relative w-full",
          {
            "flex flex-col justify-between h-full whitespace-nowrap font-bold text-ellipsis gap-10":
              props.scrollable && props.scrollHeight === "flex"
          }
        ]
      };
    },
    // w-full box-border
    table: ({ props }) => {
      return {
        class: [
          "border-separate border-spacing-y-2.5 w-full",
          {
            "pr-2": !props.groupRowsBy
          }
        ]
      };
    },
    header: "sticky top-0 box-border bg-primary z-2",
    thead: "z-2 bg-grey4 top-0 sm:table-header-group hidden",
    rowGroupHeader: {
      class: ["sticky z-2 top-[5vh] bg-grey4 w-full"]
    },
    rowGroupHeaderCell: "bg-grey4 px-4 sticky z-2 top-4.5 left-0 w-[20px]",

    loadingoverlay: {
      class: [
        "fixed w-full h-full t-0 l-0 bg-gray-100/40",
        "transition duration-200",
        "absolute flex items-center justify-center z-3"
      ]
    },
    loadingicon: "w-8 h-8",
    wrapper: ({ props }) => {
      return {
        class: [
          {
            relative: props.scrollable,
            "overflow-visible": !props.scrollable,
            "flex flex-col grow":
              props.scrollable && props.scrollHeight === "flex"
          },
          "h-full"
        ]
      };
    },
    emptymessagecell: "mtb-card h-[85px] text-start px-4",
    bodyrow: ({ props }) => {
      //DEBT poor solution
      const disableClick = props.rowData?.Challenge_State__c?.index === "Draft";
      return {
        class: [
          "mtb-card border-none flex flex-col sm:table-row mb-2 sm:mb-0",
          { "cursor-pointer": !disableClick }
        ]
      };
    },
    column: {
      columnFilter: "inline-flex items-center",
      filterMenuButton: "cursor-pointer px-4",
      bodycell: ({ props, context, instance }) => {
        const totalColumns =
          instance?.$params?.parentInstance?.columns?.length || 0;

        const index = props.class === "rowgroup-table" ? 1 : 0;

        return {
          class: [
            "text-left border-none bg-white px-4 sm:h-[85px] h-auto",
            context?.index === index ? "py-3 ps-4 pe-0" : "sm:py-6 py-3 px-4",
            {
              "sm:rounded-bl-2xl rounded-tl-2xl rounded-bl-none rounded-tr-2xl sm:rounded-tr-none":
                context?.index === index
            },
            {
              "sm:rounded-bl-none rounded-bl-2xl sm:rounded-tr-2xl rounded-tr-none rounded-br-xl":
                context?.index === totalColumns - 1
            },
            //TODO: fix the frozen cell
            {
              "sticky z-[1]": props.frozen || props.frozen === "" // Frozen Columns
            }
          ]
        };
      },

      headercell: ({ props }) => {
        return {
          class: [
            { hidden: props.preview },
            "text-left text-base font-medium border-none bg-grey4 text-grey1 border-none px-4 pb-2 h-[5vh]",
            {
              "p-frozen-column sticky z-1": props.frozen || props.frozen === "" // Frozen Columns
            }
          ]
        };
      },
      //TODO: ask this Remove for the date
      filteroverlay: {
        class: ["bg-white  border-1 border-grey3 rounded-[8px] min-w-[12.5rem]"]
      },
      filtermatchmodedropdown: {
        root: "hidden"
      },
      filterconstraint: "p-5 border-b border-solid border-gray-300"
    }
  },
  overlaypanel: {
    root: {
      class: [
        "bg-white rounded-md shadow-mtb-card max-w-lg p-1",
        "z-[1500] transform origin-center",
        "absolute mt-4",
        "before:hidden"
      ]
    },
    content: {
      class: "px-3 py-1 items-center text-start flex text-base text-grey1"
    },
    transition: TRANSITIONS.overlay
  },
  inputswitch: {
    root: ({ props }) => ({
      class: [
        "inline-block relative",
        "w-12 h-7",
        {
          "opacity-60 select-none pointer-events-none cursor-default":
            props.disabled
        }
      ]
    }),
    input: "hidden",
    slider: ({ props }) => ({
      class: [
        "absolute cursor-pointer top-0 left-0 right-0 bottom-0 border border-transparent",
        "transition-colors duration-200 rounded-2xl",
        "focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] dark:focus:shadow-[0_0_0_0.2rem_rgba(147,197,253,0.5)]",
        "before:absolute before:content-'' before:top-1/2 before:bg-white before:dark:bg-gray-900 before:w-5 before:h-5 before:left-1 before:-mt-2.5 before:rounded-full before:transition-duration-200",
        {
          "bg-grey3": !props.modelValue,
          "bg-primary before:transform before:translate-x-5": props.modelValue
        }
      ]
    })
  },
  inputgroup: {
    root: "flex items-stretch w-full"
  },
  radiobutton: {
    input: ({ props }) => {
      return {
        class: [
          "flex justify-center accent-primary items-center border-2 w-6 h-6 text-grey2 rounded-full transition duration-200 ease-in-out",
          {
            "border-primary": props.modelValue == props.value,
            "border-grey2": props.modelValue != props.value
          }
        ]
      };
    },
    icon: ({ props }) => {
      return {
        class: [
          "w-2 h-2 rounded-full ",
          { "bg-primary border-primary": props.modelValue == props.value }
        ]
      };
    },
    box: "hidden"
  },
  menu: {
    root: "py-1 bg-white text-gray-700 border border-gray-300 rounded-md w-auto",
    menu: {
      class: ["m-0 p-0 list-none", "outline-none"]
    },
    content: ({ context }) => ({
      class: [
        "text-gray-700 transition-shadow duration-200 rounded-none",
        "hover:text-gray-700 dark:hover:text-white/80"
      ]
    }),
    action: {
      class: [
        "text-gray-700 py-2 px-3 select-none",
        "cursor-pointer flex items-center no-underline overflow-hidden relative"
      ]
    },
    icon: "text-gray-600 mr-2",
    submenuheader: {
      class: [
        "m-0 p-3 text-gray-700 bg-white font-bold rounded-tl-none rounded-tr-none"
      ]
    },
    separator:
      "w-[calc(100%-24px)] mx-auto my-2  border-1 border-solid border-grey3",
    transition: TRANSITIONS.overlay
  },
  skeleton: {
    root: ({ props }) => ({
      class: [
        "overflow-hidden",
        "!mb-2",
        "bg-grey3",
        "rounded-md after:absolute after:top-0 after:left-0 after:right-0 after:bottom-0 after:content after:w-full after:h-full after:bg-primary after:left-full after:transform after:translate-x-full after:z-10 after:bg-gradient-to-r after:from-transparent after:via-white after:to-transparent animate-pulse",
        {
          "rounded-[16px]": props.shape == "card",
          "rounded-full": props.shape == "circle"
        }
      ]
    })
  },
  directives: {
    tooltip: {
      root: ({ context }) => ({
        class: [
          "absolute shadow-md max-w-[250px] rounded-md bg-white shadow-mtb-card",
          {
            "mt-[-4px]": context?.top,
            "mt-[4px]": context?.bottom
          }
        ]
      }),
      text: {
        class: "py-1 px-3 text-grey1 whitespace-pre-line break-words text-base"
      },
      transition: TRANSITIONS.overlay
    }
  },
  toast: {
    root: {
      class: ["sm:w-96 w-[90%]", "opacity-90"]
    },
    container: ({ props }) => ({
      class: [
        "my-4 w-full bg-white rounded-2xl shadow-mtb-card text-black pt- pl-4 pr-3 pb-4",
        {
          "bg-primary-light border-solid border-0 border-l-16 border-primary":
            props.message.severity == "info",
          "bg-success-light border-solid border-0 border-l-16 border-success":
            props.message.severity == "success",
          "bg-warning-light border-solid border-0 border-l-16 border-warning":
            props.message.severity == "warn",
          "bg-alert-light border-solid border-0 border-l-16 border-alert":
            props.message.severity == "error"
        }
      ]
    }),
    content: "flex items-center py-5 pr-3 text-left",
    icon: {
      class: ["w-6 h-6", "text-lg mr-2 hidden"]
    },
    text: "text-base font-medium flex flex-col flex-1 grow shrink",
    summary: "font-bold block",
    detail: "mt-1 block",
    closebutton: {
      class: [
        "w-4 h-4 text-grey2 stroke-grey2 stroke-1 rounded-full bg-transparent transition duration-200 ease-in-out",
        "ml-auto overflow-hidden relative",
        "flex items-center justify-center",
        "hover:bg-white/30"
      ]
    },
    transition: {
      enterFromClass: "opacity-0 translate-x-0 translate-y-2/4 translate-z-0",
      enterActiveClass: "transition-transform transition-opacity duration-300",
      leaveFromClass: "max-h-40",
      leaveActiveClass: "transition-all duration-500 ease-in",
      leaveToClass: "max-h-0 opacity-0 mb-0 overflow-hidden"
    }
  },
  textarea: {
    root: {
      class: [
        "m-0 text-base text-black bg-white p-3 border transition-colors duration-200 appearance-none rounded-lg w-full focus:outline-none focus:border-primary resize-none overflow-scroll max-h-[200px]"
      ]
    }
  },
  inputnumber: {
    root: ({ props, context }) => ({
      class: [
        "w-full",
        "text-black border-0 rounded-lg transition-colors duration-200 appearance-none rounded-lg"
      ]
    }),
    input: ({ props, context }) => ({
      root: {
        class: [
          "w-full ",
          "text-black border-1 transition-colors duration-200 appearance-none",
          "m-0 font-sans text-gray-600 bg-white  border border-gray-300 transition-colors duration-200 appearance-none rounded-lg p-3 text-base"
        ]
      }
    })
  }
};

const CustomTailwind = usePassThrough(Tailwind, tailwindPassthroughConfig, {
  mergeSections: true,
  mergeProps: false
});

export default CustomTailwind;
