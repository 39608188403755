import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
const firebaseConfig = {
  apiKey: "AIzaSyBubSwq7uJymp7vq1glzyrkVs-Mudy7iFE",
  authDomain: "mtb-platform.firebaseapp.com",
  databaseURL: "https://mtb-platform.firebaseio.com",
  projectId: "mtb-platform",
  storageBucket: "mtb-platform.appspot.com",
  messagingSenderId: "125559858868",
  appId: "1:125559858868:web:05c4079218b879937e99e1"
};

export const firebaseApp = initializeApp(firebaseConfig);
export const functionsEU = getFunctions(firebaseApp, "europe-west2");
export const functionsUS = getFunctions(firebaseApp);
export const database = getFirestore(firebaseApp);
export const auth = getAuth(firebaseApp);

/******------  EMULATOR MODE   -------*******/
if (process.env.NODE_ENV === "development") {
  !!firebaseApp && console.info("🔥 Firebase application initialize");
  try {
    connectFunctionsEmulator(functionsEU, "localhost", 5000);
    console.info("🐴 Firestore Emulator Europe connected");
    connectFunctionsEmulator(functionsUS, "localhost", 5000);
    console.info("🐴 Firestore Emulator US connected");
  } catch (error) {
    console.error("Error connecting to Firestore Emulator:", error);
  }
}
/******---------------------------------*******/
