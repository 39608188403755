<template>
  <div
    v-if="isLoading"
    class="h-full overflow-y-auto py-4 flex flex-col items-start justify-start gap-y-4"
  >
    <div
      class="w-full rounded-lg bg-primary-light py-8 px-6 flex justify-between items-center"
      v-for="n in 3"
      :key="n"
    >
      <Skeleton class="w-full h-4 mb-0"></Skeleton>
    </div>
  </div>
  <div
    v-else-if="interactions.length !== 0"
    class="h-full overflow-y-auto py-4 flex flex-col items-start justify-start gap-y-4"
  >
    <div
      v-for="(interaction, id) in interactions"
      :key="id"
      class="w-full rounded-lg bg-primary-light py-4 px-6 flex justify-between items-start"
    >
      <div
        v-if="interaction.Type == 'opportunity'"
        class="flex flex-col items-start"
      >
        <span class="text-start font-bold"
          >Moved to '{{
            stageMapping.stageMappingConfig.stageName[interaction.StageName]
              .label || "Selected"
          }}'</span
        >
        <span class="text-start text-grey2">{{ interaction.Chellenge }}</span>
      </div>
      <div
        v-else-if="interaction.Type == 'favourite'"
        class="flex flex-col items-start"
      >
        <div
          v-if="interaction.Field === 'created'"
          class="flex flex-col items-start"
        >
          <span class="text-start font-bold">Saved in Favorite list</span>
          <span class="text-start text-grey2">{{ interaction.Favourite }}</span>
        </div>

        <div
          v-else-if="interaction.IsDeleted"
          class="flex flex-col items-start"
        >
          <span class="text-start font-bold">Removed from Favorite list</span>
          <span class="text-start text-grey2">{{ interaction.Favourite }}</span>
        </div>
      </div>

      <span class="text-grey2">{{ parseDateTime(interaction.Time) }}</span>
    </div>
  </div>
  <div v-else class="py-4">
    <fallback-empty-state
      :isRow="false"
      message="
        There are no previous interaction between you and this startup!
      "
    />
  </div>
</template>
<script setup>
import { default as stageMapping } from "@share/stageMappingConfig.js";
import { getInteractions } from "@/modules/corporates/services/corporate-service.js";
import { watchEffect, ref } from "vue";
import { parseDateTime } from "../helpers/date.js";
const props = defineProps({
  startupId: {
    type: String,
    default: null
  },
  corporateId: {
    type: String,
    default: null
  }
});
const interactions = ref([]);
const isLoading = ref(true);
/**
 *
 * id opp
 */
watchEffect(async () => {
  if (props.startupId && props.corporateId) {
    try {
      isLoading.value = true;
      const result = await getInteractions({
        startupId: props.startupId,
        corporateId: props.corporateId
      });
      interactions.value = result;
    } catch (e) {
      console.error(e);
    } finally {
      isLoading.value = false;
    }
  }
});
</script>
