<template>
  <div class="w-full rounded-lg bg-primary-light py-3 px-4">
    <!-- === Header ==== -->
    <section class="flex flex-col justify-end w-full h-12">
      <div class="flex flex-row items-start justify-between w-full">
        <!-- Name + Avatar -->
        <div class="flex flex-row items-center justify-start gap-3 w-full">
          <!-- logo -->
          <div class="w-[45px] h-[45px]">
            <div v-if="isMtber" class="rounded-circle bg-primary">
              <base-vite-icon
                name="logo"
                style="scale: 0.7"
                classes="w-12 h-12  translate-y-[7px]"
              />
            </div>
            <base-avatar :name="props.user" v-else />
          </div>
          <!-- Name + comment type -->
          <div class="flex flex-col justify-center items-start w-full">
            <div
              class="flex flex-row items-center justify-between gap-1.5 w-full"
            >
              <div class="text-base font-bold text-black truncate capitalize">
                {{ isMtber ? "MTB Advisor" : props.user }}
              </div>
              <div class="text-sm text-grey2">
                {{ formattedTime }}
              </div>
            </div>
            <span
              class="text-grey2 text-sm font-normal text-left truncate w-full"
              >{{ getCommentMap(props.commentType) }}</span
            >
          </div>
        </div>
      </div>
    </section>
    <!-- ==== Footer ==== -->
    <section
      class="flex flex-row items-start justify-between w-full h-auto mt-2"
    >
      <div
        v-if="!pitchdeckURL"
        class="text-grey1 text-base text-start"
        v-html="props.message"
      ></div>
      <a
        v-else
        @click="getPitchdeck(props.pitchdeckURL)"
        role="button"
        class="text-grey1 text-base underline"
        target="_blank"
        >Pitch-deck.pdf</a
      >
    </section>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { parseDateTime } from "../helpers/date.js";
import { getOpportunityPitchdeck } from "@global/services/pitchdeck-service.js";
import { useUserAuthStore } from "@global/stores/user-auth-store.js";
const userAuthStore = useUserAuthStore();

const props = defineProps({
  isMtber: {
    type: Boolean,
    default: false
  },
  user: {
    type: String,
    default: ""
  },
  time: {
    type: String,
    default: "--"
  },
  commentType: {
    type: String,
    default: ""
  },
  message: {
    type: String,
    default: ""
  },
  pitchdeckURL: {
    type: String,
    default: ""
  }
});
const formattedTime = computed(() => {
  try {
    return parseDateTime(props.time);
  } catch (error) {
    return "--";
  }
});
const getPitchdeck = async (url) => {
  try {
    const DEMO_PITCH_DECK =
      "gs://mtb_pitch-decks_opps/0014U000036I4rQQAS/SunSpark_2023-07-20";
    const PITCH_DECK_OPP_STORAGE_NAME = "gs://mtb_pitch-decks_opps/";

    const pitchDeckURL = userAuthStore.getters.isDemoAccount.value
      ? DEMO_PITCH_DECK
      : PITCH_DECK_OPP_STORAGE_NAME + url;

    await getOpportunityPitchdeck(pitchDeckURL, false);
  } catch (error) {
    throw error;
  }
};
function getCommentMap(commentType) {
  switch (commentType) {
    case "Application Notes (Why Fit)":
      return "Startup Application";
    case "Internal Corporate Comment":
      return "Corporate Comment (Internal)";
    case "Internal Rejection to Startup":
      return "Rejection Reason (Internal)";
    case "Rialto Rejection":
      return "Rejection Reason (AI Suggestion)";
    case "Message to Startup":
      return "Message to Startup (Public)";
    case "MTB Internal Notes":
      return "MTB Internal Notes (MTB only)";
    case "MTB Notes":
      return "MTB Note";
    case "Public Rejection to Startup":
      return "Rejection Reason (Public)";
    case "Rejection To Startup Application":
      return "Rejection to Startup Application";
    default:
      return commentType;
  }

  //  =
  // "Internal Rejection to Startup" = Rejection Reason (Internal)
  // "Rialto Rejection" = Rejection Reason (AI Suggestion)
  // "Message to Startup" = Message to Startup => when is this recorded? at the rejection moment?
  // "MTB Internal Notes" => Is this visible to users or only MTB advisors?
  // "MTB Notes" = MTB Advisor
  // "Public Rejection to Startup",
  // "Rejection To Startup Application"
}
</script>

<style lang="scss" scoped></style>
