<template>
  <div
    ref="tabsRef"
    class="flex flex-row flex-wrap md:flex-nowrap items-center justify-between py-1 hide-scrollbar"
    :class="[
      props.classes,
      { 'ml-4': frontSpace },
      { 'overflow-x-auto': isScrollable }
    ]"
  >
    <div :class="['flex flex-row w-full', { 'pointer-events-none': disabled }]">
      <TabMenu
        @tab-change="onTabChange"
        v-model:activeIndex="activeIndex"
        :model="props.tabsConfig"
      />
      <slot name="additionalBadge"></slot>
    </div>
    <base-button
      v-if="showReload"
      class="mr-4"
      icon="refresh"
      iconClass="w-5 h-5 stroke-primary stroke-2"
      label="Reload"
      :text="true"
      @click="reload"
    />
    <div v-if="!isEmbedMode"><slot name="filterButton"></slot></div>
  </div>

  <slot
    :activeTab="props.tabsConfig[activeIndex].id"
    :name="props.tabsConfig[activeIndex].id"
  />
</template>

<script setup>
import { computed, onMounted, ref, watchEffect } from "vue";
import { useRoute, useRouter } from "vue-router";
import TabMenu from "primevue/tabmenu";
import { bus } from "@/main.js";
import { encryptSessionStorage } from "@/plugins/pinia.js";

/** how to use this component:
####CASE 1: if real router link -> pass object like example below, with different route names.
tabsConfig: [
    {
      label: "My Startups",
      id: "my-startups",
      route: {
        name: "institution-ecosystem-startups"
      }
    },
    {
      label: "My Corporates",
      id: "my-corporates",
      route: {
        name: "institution-ecosystem-corporates"
      }
    }
  ]

when using the mtb-tab component:
<mtb-tab :tabs-config="state.tabsConfig">
  <router-view />
</mtb-tab>

####CASE 2: if 'fake' router link -> pass object like example below, with same route name and params.
tabsConfig: [
    {
      label: "Active",
      id: "active",
      route: {
        name: "active-corporate-challenges",
        params: { section: "active" }
      }
    },
    {
      label: "Closed",
      id: "closed",
      route: {
        name: "active-corporate-challenges",
        params: { section: "closed" }
      }
    }
  ]

when using the mtb-tab component:
<mtb-tab :tabs-config="state.tabsConfig">
    <template v-slot="{ activeTab }">
      {{ activeTab }} put logic to display components based on activeTab
    </template>
  </mtb-tab>
*/
const tabsRef = ref(null);
const props = defineProps({
  tabsConfig: {
    type: Array,
    required: true
  },
  classes: {
    type: String,
    default: "h-full"
  },
  isPersisted: {
    type: Boolean,
    default: false
  },
  frontSpace: {
    type: Boolean,
    default: true
  },
  defaultIndex: {
    type: Number,
    default: 0
  },
  isScrollable: {
    type: Boolean,
    default: false
  },
  badge: {
    type: Boolean,
    default: false
  },
  showReload: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  }
});

const route = useRoute();
const router = useRouter();
const activeIndex = ref(props.defaultIndex);

const isEmbedMode = computed(() => route.query._embed === "true");

watchEffect(() => {
  activeIndex.value = props.defaultIndex;
});

const emit = defineEmits(["onTabChange", "currentActiveIndex"]);
const onTabChange = (e) => {
  // Navigate to the route
  if (props.tabsConfig[e.index].route) {
    //NOTE: isPersisted is used to persist the query in the route
    // TODO: to be improved, only persist selected key
    if (props.isPersisted) {
      const queryString = encryptSessionStorage.getItem(
        `query_${props.tabsConfig[e.index].route.name}`
      );

      const newQuery = queryString ? JSON.parse(queryString) : {};
      const newRoute = {
        ...props.tabsConfig[e.index].route,
        query: newQuery
      };
      router.push(newRoute);
    } else {
      router.push(props.tabsConfig[e.index].route);
    }
  } else {
    activeIndex.value = e.index;
  }
  emit("onTabChange", e.index);
  bus.emit("onTabChange");
};
watchEffect(() => {
  const index = !route.params?.section
    ? props.tabsConfig?.findIndex((x) => x.route?.name == route?.name)
    : props.tabsConfig?.findIndex(
        (x) => x.route?.params?.section == route?.params?.section
      );
  activeIndex.value = index < 0 ? props.defaultIndex : index;
});

watchEffect(() => {
  tabsRef.value?.addEventListener(
    "wheel",
    (evt) => {
      evt.preventDefault();
      tabsRef.value.scrollLeft += evt.deltaY;
    },
    { passive: false }
  );
});
watchEffect(() => {
  emit("currentActiveIndex", activeIndex.value);
});
const reload = () => {
  console.log("reloaded!!!!");
  bus.emit("reload-data");
};
</script>

<style lang="scss" scoped></style>
