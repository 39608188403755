const STARTUP_TYPE = "startup";
const CORPORATE_TYPE = "corporate";
const PARTNER_TYPE = "institution";
//
const STARTUP_TIER = "startup";
const CORPORATE_TIER_BASIC = "basic";
const CORPORATE_TIER_STAR = "star";
const PARTNER_TIER = "partner";
///
const SF_RECORD_ID_CORPORATE = "0122E000000QmFTQA0";
const SF_RECORD_ID_STARTUP = "0122E000000QmFWQA0";
const SF_RECORD_ID_PARNTER = "0122E000000QmFUQA0";
const SF_RECORD_ID_INVESTOR = "0122E000000QmFVQA0";
const STARTUP_TEST_ACCOUNTS = ["0012E000024zLsVQAU", "0012E00002jQBDqQAO"];
const INSTITUTION_TEST_ACCOUNTS = ["0012E00002hwK2vQAE"];
const CORPORATE_TEST_ACCOUNTS = [
  "0012E00002TH3ebQAD", //MTB_Test_Corp
  "0012E00002W4vNZQAZ", //Ecosystem_BASIC
  "0012E00002W4vEaQAJ", //Ecosystem_STAR
  "0012E00002W4y94QAB" //Ecosystem_ADMIN
];

//TODO create a map recordId<>type
//TODO replace this with the old constants
const CORPORATE_DEMO_MODE_ACCOUNT_LIST = [
  //the basic one (LeadingEdgeCo),
  "0014U00002omxHqQAI",
  //the star (LunaticLabCo)
  "0014U00002ooVLDQA2"
];
//SunSpark
const STARTUP_DEMO_ACCOUNT_LIST = ["0014U000036I4rQQAS"];
const CORPORATE_TEST_DEMO_ACCOUNTS = [
  "0012E00002TH3ebQAD",
  "0012E00002W4vEaQAJ",
  "0012E00002W4vNZQAZ",
  "0012E00002W4y94QAB",
  ...CORPORATE_DEMO_MODE_ACCOUNT_LIST
];
const STARTUP_TEST_DEMO_ACCOUNTS = [
  ...STARTUP_DEMO_ACCOUNT_LIST,
  "0012E000024zLsVQAU",
  "0012E00002jQBDqQAO"
];

const INSTITUTION_TEST_DEMO_ACCOUNTS = [
  ...INSTITUTION_TEST_ACCOUNTS,
  "0014U000035na4QQAQ"
];

const STARTUP_LOGO_URL = "https://storage.googleapis.com/mtb_logos/original/";

const ECOSYSTEM_TEST_ACCOUNTS = [
  ...STARTUP_TEST_ACCOUNTS,
  ...INSTITUTION_TEST_ACCOUNTS,
  ...CORPORATE_TEST_ACCOUNTS
];

const ECOSYSTEM_DEMO_ACCOUNTS = [
  ...STARTUP_DEMO_ACCOUNT_LIST,
  ...CORPORATE_DEMO_MODE_ACCOUNT_LIST,
  "0014U000035na4QQAQ"
];

const ECOSYSTEM_DEMO_TEST_ACCOUNTS = [
  ...ECOSYSTEM_TEST_ACCOUNTS,
  ...ECOSYSTEM_DEMO_ACCOUNTS
];

module.exports = {
  ACCOUNT_TYPE: {
    STARTUP_TYPE,
    CORPORATE_TYPE,
    PARTNER_TYPE
  },
  ACCOUNT_TIER: {
    STARTUP_TIER,
    CORPORATE_TIER_BASIC,
    CORPORATE_TIER_STAR,
    PARTNER_TIER
  },
  SF_RECORD_TYPE_ID: {
    SF_RECORD_ID_CORPORATE,
    SF_RECORD_ID_STARTUP,
    SF_RECORD_ID_PARNTER,
    SF_RECORD_ID_INVESTOR
  },
  CORPORATE_DEMO_MODE_ACCOUNT_LIST,
  STARTUP_DEMO_ACCOUNT_LIST,
  STARTUP_TEST_ACCOUNTS,
  CORPORATE_TEST_ACCOUNTS,
  INSTITUTION_TEST_ACCOUNTS,
  CORPORATE_TEST_DEMO_ACCOUNTS,
  STARTUP_TEST_DEMO_ACCOUNTS,
  INSTITUTION_TEST_DEMO_ACCOUNTS,
  STARTUP_LOGO_URL,
  ECOSYSTEM_TEST_ACCOUNTS,
  ECOSYSTEM_DEMO_ACCOUNTS,
  ECOSYSTEM_DEMO_TEST_ACCOUNTS
};
