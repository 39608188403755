<template>
  <div class="flex flex-col">
    <div
      class="flex gap-x-4 gap-y-2 w-full flex-wrap justify-between"
      :class="{ 'pointer-events-none cursor-default': isNegativeFromStartup }"
    >
      <nav aria-label="Progress" class="flex flex-1">
        <ol role="list" class="rounded-md border border-white flex w-full">
          <li
            v-for="(step, stepIdx) in steps"
            :key="step.value"
            class="relative flex w-full border-white border-[3px]"
            :class="stepIdx == 0 ? 'border-l-0' : ''"
          >
            <div
              class="group flex w-full items-center"
              :class="[
                stepIdx == 0 ? 'rounded-l-md' : '',
                {
                  'bg-success-solid':
                    step.isActive &&
                    !step.isNegative &&
                    !isNegativeFromCorporate &&
                    !isNegativeFromStartup,
                  'bg-grey3':
                    step.isNegative ||
                    isNegativeFromCorporate ||
                    isNegativeFromStartup,
                  'bg-grey4': !step.isActive
                },
                {
                  'pointer-events-none cursor-default': !step.clickable
                }
              ]"
              role="button"
              @click="changeStage(step.value)"
            >
              <span
                class="flex items-center justify-center w-full ps-4 pe-2 py-2 text-sm font-medium"
              >
                <span
                  class="text-sm font-medium"
                  :class="[
                    stepIdx == 0 ? 'rounded-l-md' : '',
                    {
                      'text-success':
                        step.isActive &&
                        !step.isNegative &&
                        !isNegativeFromCorporate &&
                        !isNegativeFromStartup,
                      'text-grey1':
                        step.isNegative ||
                        isNegativeFromCorporate ||
                        isNegativeFromStartup,
                      'text-primary': !step.isActive
                    }
                  ]"
                  >{{ step.label }}</span
                >
              </span>
            </div>

            <template v-if="stepIdx !== steps.length + 1">
              <!-- Arrow separator for lg screens and up -->
              <div
                class="absolute top-0 z-10 hidden h-full w-5 md:block"
                style="right: -18px"
                aria-hidden="true"
              >
                <svg
                  width="100%"
                  height="100%"
                  viewBox="0 0 21 39"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  xml:space="preserve"
                  xmlns:serif="http://www.serif.com/"
                  :class="[
                    {
                      'fill-success-solid':
                        step.isActive &&
                        !step.isNegative &&
                        !isNegativeFromCorporate &&
                        !isNegativeFromStartup,
                      'fill-grey3':
                        step.isNegative ||
                        isNegativeFromCorporate ||
                        isNegativeFromStartup,
                      'fill-grey4': !step.isActive
                    }
                  ]"
                  style="fill-rule: evenodd; clip-rule: evenodd"
                >
                  <g transform="matrix(1,0,0,1.05094,-111.777,-3.09636)">
                    <path
                      id="Union"
                      d="M111.819,41.5C113.478,41.5 115.039,40.827 116.297,39.67L127.723,29.17C129.838,27.227 130.806,24.295 130.806,21.5C130.806,18.705 129.838,15.773 127.723,13.83L116.297,3.33C115.039,2.173 113.478,1.5 111.819,1.5"
                      style="
                        fill-rule: nonzero;
                        stroke: white;
                        stroke-width: 3px;
                      "
                    />
                  </g>
                </svg>
              </div>
            </template>
          </li>
        </ol>
      </nav>
      <base-button
        v-if="isNegativeFromCorporate"
        :pt="{
          label: 'text-sm font-bold'
        }"
        label="Activate"
        :text="true"
        :outlined="false"
        icon="check-circle_fill"
        iconClass="h-4 w-4"
        severity="success"
        :class="{
          'pointer-events-none cursor-default':
            currentChallenge.Challenge_State__c !== 'Open'
        }"
        @click="click"
      />
      <base-button
        v-else
        :pt="{
          label: 'text-sm font-bold'
        }"
        :class="{
          'pointer-events-none cursor-default':
            currentChallenge.Challenge_State__c !== 'Open'
        }"
        label="Discard"
        :text="true"
        :outlined="false"
        icon="trash_fill"
        iconClass="h-4 w-4"
        severity="alert"
        @click="click"
      />
    </div>
    <div class="flex flex-col items-start">
      <div class="flex text-lg font-bold mt-6 mb-4">Details</div>
      <div class="flex items-center gap-4">
        <div
          v-if="currentChallenge.Challenge_State__c == 'Closed'"
          :class="`mtb-chip text-sm font-bold ${
            statusMapping[currentChallenge.Challenge_State__c].parentClasses
          }`"
        >
          Challenge
          {{ statusMapping[currentChallenge.Challenge_State__c].label }}
        </div>
        <div
          v-if="
            stageMapping.stageMappingConfig.leadSource[
              selectedOpportunity.LeadSource
            ]
          "
          class="mtb-chip"
          :class="[
            stageMapping.stageMappingConfig.leadSource[
              selectedOpportunity.LeadSource
            ].chipClasses
          ]"
        >
          <base-vite-icon
            :name="
              stageMapping.stageMappingConfig.leadSource[
                selectedOpportunity.LeadSource
              ].icon
            "
            :classes="
              stageMapping.stageMappingConfig.leadSource[
                selectedOpportunity.LeadSource
              ].iconClasses
            "
          />
          <span class="truncate w-full text-left">{{
            stageMapping.stageMappingConfig.leadSource[
              selectedOpportunity.LeadSource
            ]?.label
          }}</span>
        </div>
        <div
          v-if="
            stageMapping.stageMappingConfig.stageName[
              selectedOpportunity.StageName
            ]
          "
          class="mtb-chip"
          :class="[
            stageMapping.stageMappingConfig.stageName[
              selectedOpportunity.StageName
            ].chipClasses
          ]"
        >
          <base-vite-icon
            :name="
              stageMapping.stageMappingConfig.stageName[
                selectedOpportunity.StageName
              ].icon
            "
            :classes="
              stageMapping.stageMappingConfig.stageName[
                selectedOpportunity.StageName
              ].iconClasses
            "
          />
          <span class="truncate w-full text-left">{{
            stageMapping.stageMappingConfig.stageName[
              selectedOpportunity.StageName
            ]?.label
          }}</span>
        </div>
        <div
          v-if="
            isNegativeFromStartup &&
            stageMapping.stageMappingConfig.Startup_Opportunity_Status__c[
              selectedOpportunity.Startup_Opportunity_Status__c
            ]
          "
          class="mtb-chip"
          :class="[
            stageMapping.stageMappingConfig.Startup_Opportunity_Status__c[
              selectedOpportunity.Startup_Opportunity_Status__c
            ].chipClasses
          ]"
        >
          <base-vite-icon
            :name="
              stageMapping.stageMappingConfig.Startup_Opportunity_Status__c[
                selectedOpportunity.Startup_Opportunity_Status__c
              ].icon
            "
            :classes="
              stageMapping.stageMappingConfig.Startup_Opportunity_Status__c[
                selectedOpportunity.Startup_Opportunity_Status__c
              ].iconClasses
            "
          />
          <span class="truncate w-full text-left">{{
            stageMapping.stageMappingConfig.Startup_Opportunity_Status__c[
              selectedOpportunity.Startup_Opportunity_Status__c
            ]?.label
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, watchEffect } from "vue";
import { default as stageMapping } from "@share/stageMappingConfig.js";
import { useOpportunitiesStore } from "../stores/opportunities-store.js";
import { useChallengesStore } from "../stores/challenges-store.js";
import { useOpportunityModals } from "@/modules/corporates/hooks/use-opportunity-modals.js";
import { statusMapping } from "@global/configs/challenge-mapping.js";
const { openRejectModal, openMeetingModal, openChangeStageModal } =
  useOpportunityModals();
const emits = defineEmits(["selected-opportunity"]);
const props = defineProps({
  challengeId: {
    type: String,
    required: true
  },
  startupId: {
    type: String,
    required: true
  }
});
const challengesStore = useChallengesStore();
const opportunitiesStore = useOpportunitiesStore();
const selectedOpportunity = computed(() => {
  return [
    ...(opportunitiesStore.state.challengeOpportunities.opportunities[
      props.challengeId
    ] || []),
    ...(opportunitiesStore.state.challengeOpportunities.suggestions[
      props.challengeId
    ] || [])
  ].find((opp) => {
    return opp.AccountId === props.startupId;
  });
});
const isNegativeFromCorporate = computed(() => {
  return stageMapping.stageMappingConfig.stageName[
    selectedOpportunity.value.StageName
  ].isNegative;
});
const isNegativeFromStartup = computed(() => {
  return (
    selectedOpportunity.value.Startup_Opportunity_Status__c === "Not Interested"
  );
});
const globalClickable = computed(() => {
  return !isNegativeFromCorporate.value && !isNegativeFromStartup.value;
});
const steps = computed(() => {
  const oppStageIndex = Object.values(stageMapping.stageMap).find((stage) =>
    stage.includedStage.includes(selectedOpportunity.value.StageName)
  )?.index;
  return [
    {
      label: stageMapping.stageMap.suggestion.label,
      value: "suggestion",
      clickable:
        oppStageIndex < stageMapping.stageMap.suggestion.index &&
        globalClickable.value &&
        currentChallenge.value.Challenge_State__c == "Open",
      isActive:
        oppStageIndex >= 0 &&
        oppStageIndex >= stageMapping.stageMap.suggestion.index,
      isNegative:
        stageMapping.stageMap.suggestion.includedStage.includes(
          selectedOpportunity.value.StageName
        ) && isNegativeFromCorporate.value
    },
    {
      label: stageMapping.stageMap.selection.label,
      value: "selection",
      clickable:
        oppStageIndex < stageMapping.stageMap.selection.index &&
        globalClickable.value &&
        currentChallenge.value.Challenge_State__c == "Open",
      isActive:
        oppStageIndex >= 0 &&
        oppStageIndex >= stageMapping.stageMap.selection.index,
      isNegative:
        stageMapping.stageMap.selection.includedStage.includes(
          selectedOpportunity.value.StageName
        ) && isNegativeFromCorporate.value
    },
    {
      label: stageMapping.stageMap.meeting.label,
      value: "meeting",
      clickable:
        oppStageIndex < stageMapping.stageMap.meeting.index &&
        globalClickable.value &&
        currentChallenge.value.Challenge_State__c == "Open",
      isActive:
        oppStageIndex >= 0 &&
        oppStageIndex >= stageMapping.stageMap.meeting.index,
      isNegative:
        stageMapping.stageMap.meeting.includedStage.includes(
          selectedOpportunity.value.StageName
        ) && isNegativeFromCorporate.value
    },
    {
      label: stageMapping.stageMap.evaluation.label,
      value: "evaluation",
      clickable: globalClickable.value,
      isActive:
        oppStageIndex >= 0 &&
        oppStageIndex >= stageMapping.stageMap.evaluation.index,
      isNegative:
        stageMapping.stageMap.evaluation.includedStage.includes(
          selectedOpportunity.value.StageName
        ) && isNegativeFromCorporate.value
    }
  ];
});
const currentChallenge = computed(() => {
  const myChallenges = challengesStore.state.accountChallenges;
  return myChallenges.find(({ Id }) => Id == props.challengeId);
});
function openMeetingModalGlobal(startupIds) {
  openMeetingModal({
    selectedStartupIds: startupIds,
    selectedChallenge: currentChallenge.value,
    startupTagEditable: false,
    challengeEditable: false,
    forceSuggestions: null,
    SFSearch: false,
    resetOnChallengeChange: false
  });
}

function changeStage(index) {
  const rejectClick =
    !steps.value.find((step) => step.value == index).clickable ||
    isNegativeFromStartup.value;
  if (rejectClick) {
    return;
  }
  if (index === "meeting") {
    return openMeetingModalGlobal([props.startupId]);
  } else {
    return openChangeStageModal({
      startupId: props.startupId,
      prefilledStage: null,
      section: index,
      sameSection: stageMapping.stageMap.evaluation.includedStage.includes(
        selectedOpportunity.value.StageName
      ),
      challengeId: props.challengeId
    });
  }
}
function click() {
  const fromColumn =
    stageMapping.stageMappingConfig.stageName[
      selectedOpportunity.value.StageName
    ].section;

  if (isNegativeFromCorporate.value) {
    if (fromColumn === "meeting") {
      openMeetingModalGlobal([props.startupId]);
    } else {
      openChangeStageModal({
        startupId: props.startupId,
        prefilledStage: null,
        section: fromColumn,
        sameSection: false,
        challengeId: props.challengeId
      });
    }
  } else {
    const negativeStage = Object.values(
      stageMapping.stageMappingConfig.stageName
    ).find(
      (element) => element.section == fromColumn && element.isNegative
    )?.value;
    const opportunityData = selectedOpportunity.value;
    const myChallenges = challengesStore.state.accountChallenges;
    const myChallenge = myChallenges.find(({ Id }) => Id == props.challengeId);
    openRejectModal({
      startupId: props.startupId,
      potentialNewStage: negativeStage || "Not Selected",
      opportunityData,
      currentChallenge: myChallenge
    });
  }
}
watchEffect(() => {
  if (!selectedOpportunity.value) {
    return;
  }
  emits("selected-opportunity", selectedOpportunity.value);
});
</script>

<style lang="scss" scoped></style>
