<template>
  <div v-if="route.query._embed !== 'true'" class="sm:hidden">
    <Sidebar
      position="full"
      :pt="{
        root: ({ props }) => ({
          class: [
            'w-[100vw] sm:w-[500px] flex flex-col pointer-events-auto relative transform translate-x-0 translate-y-0 translate-z-0 relative transition-transform duration-300',
            'bg-primary text-gray-700 border-0 shadow-lg p-6',
            '!transition-none !transform-none !w-screen !h-screen !max-h-full !top-0 !left-0'
          ]
        }),
        header: {
          class: ['flex flex-row items-top justify-end pb-4']
        },

        closeButton: {
          class: [
            'overflow-hidden w-4 h-4 text-white border-0 bg-transparent hover:text-white hover:border-transparent hover:bg-transparent focus:outline-none focus:outline-offset-0 focus:shadow-none' // focus
          ]
        },
        closeButtonIcon: 'w-4 h-4 inline-block',
        content: {
          class: ['h-full w-full', 'grow overflow-y-auto p-2']
        },
        mask: ({ props }) => ({
          class: ['flex pointer-events-auto', 'bg-transparent']
        }),
        transition: ({ props }) => {
          return {
            enterFromClass: 'opacity-0',
            enterActiveClass: 'transition-opacity duration-200 ease-in',
            leaveActiveClass: 'transition-opacity duration-200 ease-in',
            leaveToClass: 'opacity-0'
          };
        }
      }"
      v-model:visible="open"
    >
      <div class="flex">
        <div class="w-full flex flex-col items-center py-4 px-6 gap-y-20">
          <div class="flex h-20 shrink-0 items-center">
            <router-link
              :to="{
                name: GLOBAL_HOME
              }"
              :disabled="!userAuthStore.getters.getFullProfileInformation?.type"
            >
              <base-vite-icon name="logo" classes="w-12" />
            </router-link>
          </div>

          <ul class="flex flex-col gap-y-10 items-center justify-center">
            <li v-for="item in navigation" :key="item.routeName">
              <router-link
                :to="{ name: item.routeName, params: item.params }"
                :id="item.routeName"
                class="text-white hover:text-white group flex flex-col items-center rounded-md text-xs leading-6"
                v-slot="{ isActive }"
                v-hover="hover"
              >
                <component
                  :is="item.icon"
                  :active="isActive || !!isHover[item.routeName]"
                  class="w-6 h-6"
                />
                <div class="text-sm mt-2">
                  {{ item.label }}
                </div>
              </router-link>
            </li>
            <mtb-support />
          </ul>
        </div>
      </div>
    </Sidebar>
  </div>
  <div v-if="route.query._embed !== 'true'" class="hidden lg:hidden sm:flex">
    <Sidebar
      v-model:visible="openMdSidebar"
      position="left"
      :pt="{
        root: ({ props }) => ({
          class: [
            'w-[250px] flex h-full flex-col pointer-events-auto relative transform translate-x-0 translate-y-0 translate-z-0 relative transition-transform duration-300 rounded-r-4xl',
            'bg-primary border-0 shadow-lg p-6'
          ]
        }),
        header: {
          class: ['flex flex-row items-top justify-end pb-4']
        },

        closeButton: {
          class: [
            'overflow-hidden w-4 h-4 text-white border-0 bg-transparent hover:text-white hover:border-transparent hover:bg-transparent focus:outline-none focus:outline-offset-0 focus:shadow-none' // focus
          ]
        },
        closeButtonIcon: 'w-4 h-4 inline-block',
        content: {
          class: ['h-full w-full overflow-y-auto px-4 pt-24']
        },
        mask: ({ props }) => ({
          class: [
            'flex pointer-events-auto',
            'bg-transparent',
            { 'force-right-sidebar': props.position == 'right' }
          ]
        }),
        transition: ({ props }) => {
          return props.position === 'top'
            ? {
                enterFromClass: 'translate-x-0 -translate-y-full translate-z-0',
                leaveToClass: 'translate-x-0 -translate-y-full translate-z-0'
              }
            : props.position === 'bottom'
              ? {
                  enterFromClass:
                    'translate-x-0 translate-y-full translate-z-0',
                  leaveToClass: 'translate-x-0 translate-y-full translate-z-0'
                }
              : props.position === 'left'
                ? {
                    enterFromClass:
                      '-translate-x-full translate-y-0 translate-z-0',
                    leaveToClass:
                      '-translate-x-full translate-y-0 translate-z-0'
                  }
                : props.position === 'right'
                  ? {
                      enterFromClass:
                        'translate-x-full translate-y-0 translate-z-0',
                      leaveToClass:
                        'translate-x-full translate-y-0 translate-z-0'
                    }
                  : {
                      enterFromClass: 'opacity-0',
                      enterActiveClass:
                        'transition-opacity duration-200 ease-in',
                      leaveActiveClass:
                        'transition-opacity duration-200 ease-in',
                      leaveToClass: 'opacity-0'
                    };
        }
      }"
    >
      <div class="flex h-20 shrink-0 items-center justify-center pb-28">
        <router-link
          :to="{
            name: GLOBAL_HOME
          }"
          :disabled="!userAuthStore.getters.getFullProfileInformation?.type"
        >
          <base-vite-icon name="logo" classes="w-12" />
        </router-link>
      </div>
      <!-- Your company logo -->
      <nav class="px-4">
        <ul role="list" class="flex flex-1 flex-col gap-y-10 mb-20">
          <li v-for="item in navigation" :key="item.routeName">
            <router-link
              :to="{ name: item.routeName, params: item.params }"
              :id="item.routeName"
              class="text-white hover:text-white group flex flex-row justify-start items-center rounded-md text-xs leading-6 gap-4"
              v-slot="{ isActive }"
              v-hover="hover"
            >
              <component
                :is="item.icon"
                :active="isActive || !!isHover[item.routeName]"
                class="w-6 h-6"
              />
              <div class="text-base">
                {{ item.label }}
              </div>
            </router-link>
          </li>
        </ul>
        <mtb-support />
      </nav>
    </Sidebar>
  </div>
  <div
    v-if="route.query._embed !== 'true'"
    class="hidden sm:z-50 sm:absolute sm:top-0 sm:left-0 sm:w-56 sm:flex-col bg-primary h-full"
  >
    <div class="flex items-start grow flex-col overflow-hidden gap-y-20 px-4">
      <!-- Your company logo -->

      <div class="flex h-20 shrink-0 items-center justify-end w-full">
        <base-vite-icon
          name="close"
          classes="w-6 h-6 stroke-white"
          role="button"
          @click="openMdSidebar = !openMdSidebar"
        />
        <!-- <button
          type="button"
          class="-m-2.5 p-2.5 text-gray-700 lg:hidden col-span-1"
          @click="open = !open"
        >
          <span class="sr-only">Open sidebar</span>
          <svg
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="white"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
            ></path>
          </svg>
        </button> -->
      </div>

      <nav class="flex flex-1 flex-col px-4">
        <ul role="list" class="flex flex-1 flex-col gap-y-10">
          <li v-for="item in navigation" :key="item.routeName">
            <router-link
              :to="{ name: item.routeName, params: item.params }"
              :id="item.routeName"
              class="text-white hover:text-white group flex flex-row justify-start items-center rounded-md text-xs leading-6 gap-4"
              v-slot="{ isActive }"
              v-hover="hover"
            >
              <component
                :is="item.icon"
                :active="isActive || !!isHover[item.routeName]"
                class="w-6 h-6"
              />
              <div class="text-base">
                {{ item.label }}
              </div>
            </router-link>
          </li>
        </ul>
      </nav>
    </div>
  </div>
  <!-- Static sidebar for desktop -->
  <div
    v-if="route.query._embed !== 'true'"
    class="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-20 lg:flex-col"
  >
    <div class="flex items-center grow flex-col overflow-hidden gap-y-20 px-4">
      <!-- Your company logo -->

      <div class="flex h-20 shrink-0 items-center">
        <router-link
          :to="{
            name: GLOBAL_HOME
          }"
          :disabled="!userAuthStore.getters.getFullProfileInformation?.type"
        >
          <base-vite-icon name="logo" classes="w-12" />
        </router-link>
      </div>
      <nav class="flex flex-1 flex-col">
        <ul role="list" class="flex flex-1 flex-col gap-y-10">
          <li v-for="item in navigation" :key="item.routeName">
            <router-link
              :to="{ name: item.routeName, params: item.params }"
              :id="item.routeName"
              class="text-white hover:text-white group flex flex-col items-center rounded-md text-xs leading-6"
              v-slot="{ isActive }"
              v-hover="hover"
            >
              <component
                :is="item.icon"
                :active="isActive || !!isHover[item.routeName]"
                class="w-6 h-6"
              />
              <div class="text-xs mt-2">
                {{ item.label }}
              </div>
            </router-link>
          </li>
        </ul>
      </nav>
      <mtb-support />
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch, onUnmounted } from "vue";
import { useUserAuthStore } from "../stores/user-auth-store.js";
import { bus } from "@/main.js";
import { useNavigationLinks } from "@/global/hooks/use-navigation.js";
import vHover from "@/global/directives/v-hover.js";
import { GLOBAL_HOME } from "@global/router.js";
import { useRoute } from "vue-router";

const route = useRoute();
const open = ref(false);
const openMdSidebar = ref(false);
const userAuthStore = useUserAuthStore();
const isHover = ref({});
const navigation = computed(() => {
  const navigationConfig = useNavigationLinks();
  const currentType = userAuthStore.getters.getFullProfileInformation?.type;
  const currentAccountId = userAuthStore.getters.getFullProfileInformation?.id;
  if (!currentType || !currentAccountId) {
    return navigationConfig.unauth;
  }
  const currentNavigation = navigationConfig[currentType];
  return currentNavigation(currentAccountId);
});

onMounted(() => {
  bus.on("open-menu", () => {
    if (window.innerWidth < 641) {
      open.value = true;
    } else {
      openMdSidebar.value = true;
    }
  });
});

onUnmounted(() => {
  bus.off("open-menu");
});

watch(
  () => route.name,
  (newVal) => {
    openMdSidebar.value = false;
    open.value = false;
  }
);

const hover = (isActive, key) => {
  isHover.value[key] = isActive;
};
</script>
