import { useHead } from "@vueuse/head";
import { computed } from "vue";
import { loadMetaLogo } from "@global/helpers/image.js";
import { toPascalCase } from "@global/helpers/text.js";
const defaultLogo =
  "https://firebasestorage.googleapis.com/v0/b/mtb-platform.appspot.com/o/logo%2Fog-image.png?alt=media&token=f9bb6042-62b7-460b-bc2e-bf7a5137a4dd";
const defaultTitle =
  " MTB Ecosystem - Connecting and Boosting Innovation Ecosystems";
export function useMetaTags({ data, pageName, sharingLink, imageType }) {
  const imageUrl = computed(
    () => loadMetaLogo(imageType?.value || imageType) || defaultLogo
  );

  const name = computed(() => toPascalCase(data?.value?.Name?.toLowerCase()));

  // Dynamically generate meta tag values
  const title = computed(() => {
    if (!name.value && !pageName) {
      return defaultTitle;
    } else {
      const parts = [name.value || "", pageName || "", "MTB Ecosystem"].filter(
        Boolean
      ); // This filters out any empty or falsy values

      return parts.join(" - "); // Join the parts with ' - ' if they exist
    }
  });

  const description = computed(() => {
    if (pageName === "Ecosystem Partner Profile") {
      return `Explore the detailed profile of ${name.value}, our partner on the MTB Ecosystem platform. Discover their expertise, collaborations, and contributions to our ecosystem.`;
    } else if (pageName === "Featured List") {
      return `Explore top innovative companies driving change across technology, healthcare, finance, and more.`;
    } else if (pageName === "Corporate Challenge Details") {
      return "Explore the details of the latest innovation challenges on MTB Ecosystem. Discover opportunities to collaborate, innovate, and contribute solutions to real-world problems. Join the challenge and help drive innovation forward.";
    } else {
      return "An AI-powered open innovation platform built to enhance the international growth of innovation ecosystems and empower global scouting";
    }
  });

  useHead({
    title: "MTB Ecosystem",
    meta: [
      { name: "description", content: description.value },
      {
        name: "keywords",
        content:
          "MTB Ecosystem, Partner Profile, Innovation, Collaboration, Technology, Business Ecosystem"
      },
      { name: "author", content: "MTB Ecosystem" },
      {
        property: "og:title",
        content: () => title.value,
        prefix: "og: http://ogp.me/ns#"
      },
      {
        property: "og:description",
        content: () => description.value,
        prefix: "og: http://ogp.me/ns#"
      },
      {
        property: "og:image",
        content: () => imageUrl.value,
        prefix: "og: http://ogp.me/ns#"
      },
      {
        property: "og:url",
        content: () => sharingLink.value,
        prefix: "og: http://ogp.me/ns#"
      },
      {
        property: "og:type",
        content: "website",
        prefix: "og: http://ogp.me/ns#"
      },
      { property: "og:site_name", content: "MTB Ecosystem" },
      { property: "og:locale", content: "en_US" },
      { name: "twitter:card", content: "summary_large_image" },
      { name: "twitter:title", content: () => title.value },
      { name: "twitter:description", content: () => description.value },
      { name: "twitter:image", content: () => imageUrl.value },
      { name: "twitter:url", content: () => sharingLink.value },
      { name: "robots", content: "index, follow" },
      { name: "theme-color", content: "#ffffff" }
    ],
    link: [{ rel: "canonical", href: () => sharingLink.value }]
  });
}
