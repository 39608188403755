/**-- STATE MANAGEMENT --**/
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import localForage from "localforage";
/**----------------------**/
import { EncryptStorage } from "encrypt-storage";

//if we are in test env disable localforage as indexdb
if (process.env.NODE_ENV !== "test") {
  localForage.config({
    driver: localForage.INDEXEDDB // This force IndexedDB as the driver
  });
}

async function indexDbPlugin({ store }) {
  if (!["opportunities", "challenges"].includes(store.$id)) {
    return;
  }

  const stored = await localForage.getItem(store.$id);
  if (stored) {
    store.$patch(stored);
  }
  store.$subscribe(async () => {
    const stateCopy = JSON.parse(JSON.stringify(store.$state.state));
    await localForage.setItem(store.$id, { state: stateCopy });
  });
}
// Example of secret_key variable in an .env file
// const encryptStorage = new EncryptStorage(process.env.SECRET_KEY, options);
export const encryptSessionStorage = new EncryptStorage("sessionStorage", {
  storageType: "sessionStorage",
  stateManagementUse: true
});
export const encryptLocalStorage = new EncryptStorage("localStorage", {
  stateManagementUse: true
});
export function initPiniaStore(app) {
  const pinia = createPinia();
  pinia.use(indexDbPlugin);
  pinia.use(piniaPluginPersistedstate);
  app.use(pinia);
  if (process.env.NODE_ENV === "development") {
    console.info("🍍 Pinia is ready");
  }
}
