import { ref, onMounted, onUnmounted } from "vue";

export function useScreenHeight(cardHeight = 85, subtractHeight = 0) {
  const numberOfCards = ref(1);

  // Update the screenHeight when the window is resized
  const updateScreenHeight = () => {
    //default substract height: tab: 24, paginator: 35, header: 35, gap: 12, padding: 24
    //subtractHeight: Any other element needs to subtract
    const totalSubtractHeight = subtractHeight + 100 + 24;
    const tabHeight =
      document.querySelector(".main-container").clientHeight -
      totalSubtractHeight;
    if (typeof cardHeight === "number" && typeof subtractHeight === "number") {
      // Calculate the number of cards that can fit in the available vertical space
      numberOfCards.value =
        Math.floor(tabHeight / cardHeight) > 0
          ? Math.floor(tabHeight / cardHeight)
          : 1;
    }
  };

  // Add event listener on component mount
  onMounted(() => {
    updateScreenHeight();
    window.addEventListener("resize", updateScreenHeight);
  });

  // Remove event listener on component unmount
  onUnmounted(() => {
    window.removeEventListener("resize", updateScreenHeight);
  });

  // Return the reactive ref for the screen height
  return numberOfCards;
}
