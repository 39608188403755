<template>
  <div
    class="flex items-center text-sm whitespace-nowrap"
    role="group"
    :class="state.isLoading && 'pe-none opacity-50'"
  >
    <!--TODO style is missing there!!-->
    <div class="">
      <button
        @click.stop="toggle"
        class="border-1 rounded-s-[8px] py-2 px-3"
        :class="[
          !isCustom
            ? 'bg-primary-light border-primary text-primary'
            : 'border-grey3 bg-white text-black',
          { 'rounded-e-[8px]': props.isOnlyCustom }
        ]"
      >
        {{ isCustom ? "Custom" : methods.format(state.dateRange) }}
      </button>
      <!-- Datepicker -->
      <base-calendar-input
        class="absolute z-[999]"
        v-show="isOpen"
        :inline="true"
        :minDate="new Date(props.creationDate)"
        :maxDate="new Date()"
        :value="state.dateRange"
        selectionMode="range"
        :showTime="false"
        @update:model-value="methods.handleCustomRange"
      ></base-calendar-input>
    </div>
    <!-- Button list for preset ranges -->
    <template v-if="!props.isOnlyCustom">
      <button
        v-tooltip.top="methods.format(presetRange.range)"
        v-for="(presetRange, index) in presetRanges"
        :id="presetRange.label.toLowerCase()?.replaceAll(' ', '-')"
        :key="index"
        type="button"
        class="border-1 py-2 px-3"
        :class="[
          !isAllDate &&
          methods.format(presetRange.range) == methods.format(state.dateRange)
            ? 'bg-primary-light border-primary text-primary'
            : 'border-grey3 bg-white text-black'
        ]"
        @click="methods.handlePresetRange(presetRange.range)"
      >
        {{ presetRange.label }}
      </button>

      <button
        id="all-time"
        type="button"
        class="border-1 rounded-e-[8px] py-2 px-3"
        :class="
          isAllDate
            ? 'bg-primary-light border-primary text-primary'
            : 'border-grey3 bg-white text-black'
        "
        @click="methods.handleAllTimeRange"
      >
        All time
      </button>
    </template>
  </div>
</template>

<script setup>
import {
  reactive,
  watch,
  computed,
  onMounted,
  onUnmounted,
  watchEffect,
  ref
} from "vue";
import { getTodayDay } from "@/helpers/function/util.js";
import { useRoute, useRouter } from "vue-router";
import {
  endOfYear,
  startOfDay,
  endOfDay,
  startOfYear,
  subMonths,
  subYears,
  format,
  parseISO,
  isSameDay,
  isBefore,
  isValid,
  formatISO
} from "date-fns";
import { bus } from "@/main.js";
import { default as constants } from "@share/constants.js";
import _ from "lodash";
import { parse } from "date-fns";
import { isAfter } from "date-fns";
import { isSameMonth } from "date-fns";
import { isSameYear } from "date-fns";
import { encryptSessionStorage } from "@/plugins/pinia.js";

const isOpen = ref(false);
const toggle = () => {
  isOpen.value = !isOpen.value;
};
const route = useRoute();
const router = useRouter();
const props = defineProps({
  creationDate: {
    type: String,
    default: constants.FALLBACK_CREATION_ACCOUNT_DATE
  },
  isOnlyCustom: {
    type: Boolean,
    default: false
  }
});
const emit = defineEmits(["click-applied"]);

const state = reactive({
  dateRange: null,
  isLoading: true
});

//DEBT: maybe can put in js file to export it (needing also for testing)
const presetRanges = [
  {
    label: "Last 90 days",
    range: [startOfDay(subMonths(getTodayDay(), 3)), endOfDay(getTodayDay())]
  },
  {
    label: "Last year",
    range: [
      startOfYear(subYears(getTodayDay(), 1)),
      endOfYear(subYears(getTodayDay(), 1))
    ]
  },
  {
    label: "Current year",
    range: [startOfYear(getTodayDay()), endOfYear(getTodayDay())]
  }
];

const methods = reactive({
  handleCustomRange: (newRange) => {
    if (newRange[0] && newRange[1]) {
      state.dateRange = newRange;
    }
  },
  handlePresetRange: (range) => {
    state.dateRange = range;
  },
  handleAllTimeRange: () => {
    state.dateRange = [
      new Date(props.creationDate || constants.FALLBACK_CREATION_ACCOUNT_DATE),
      getTodayDay()
    ];
  },
  updateMonth: (event, updateMonthYear, year) => {
    updateMonthYear(+event.target.value, year);
  },
  updateYear: (event, updateMonthYear, month) => {
    updateMonthYear(month, +event.target.value);
  },
  format(dateRange) {
    if (!dateRange) {
      return;
    }
    if (
      dateRange &&
      (!isValid(new Date(dateRange[0])) || !isValid(new Date(dateRange[1])))
    ) {
      return "";
    } else {
      const start = format(dateRange[0], "dd MMM yyyy");
      const end = format(dateRange[1], "dd MMM yyyy");
      return `${start} - ${end}`;
    }
  },
  isFirstDateGreaterOrEqual: (date1, date2) => {
    const parsedDate1 = startOfDay(new Date(date1));
    const parsedDate2 = startOfDay(new Date(date2));
    return (
      isSameDay(parsedDate1, parsedDate2) || isAfter(parsedDate1, parsedDate2)
    );
  },

  isFirstDateLessOrEqual: (date1, date2) => {
    const parsedDate1 = startOfDay(new Date(date1));
    const parsedDate2 = startOfDay(new Date(date2));
    return (
      isSameDay(parsedDate1, parsedDate2) || isBefore(parsedDate1, parsedDate2)
    );
  },
  isDateEqual: (date1, date2) => {
    const parsedDate1 = startOfDay(new Date(date1));
    const parsedDate2 = startOfDay(new Date(date2));
    return (
      isSameDay(parsedDate1, parsedDate2) &&
      isSameMonth(parsedDate1, parsedDate2) &&
      isSameYear(parsedDate1, parsedDate2)
    );
  }
});
const isAllDate = computed(() => {
  if (!state.dateRange) {
    return true;
  }

  const startDate = new Date(state.dateRange[0]);
  const creationDate =
    props.creationDate || constants.FALLBACK_CREATION_ACCOUNT_DATE;
  const endDate = new Date(state.dateRange[1]);

  if (
    !isValid(startDate) ||
    !isValid(endDate) ||
    (methods.isDateEqual(startDate, creationDate) &&
      methods.isDateEqual(endDate, getTodayDay()))
  ) {
    return true;
  } else {
    return false;
  }
});
function checkDateEqual(array1, array2) {
  const formattedArray1 = array1?.map((date) =>
    format(new Date(date), "dd/MM/yyyy")
  );
  const formattedArray2 = array2?.map((date) =>
    format(new Date(date), "dd/MM/yyyy")
  );

  // Confronta le stringhe formattate
  return formattedArray1.every(
    (date, index) => date === formattedArray2?.[index]
  );
}
watch(
  () => state.dateRange,
  async (newDaterange, oldDateRange) => {
    if (!newDaterange && !oldDateRange) {
      return;
    }
    if (newDaterange && !checkDateEqual(newDaterange, oldDateRange)) {
      router.push({
        ...route,
        query: {
          ...route.query,
          startDate: format(new Date(newDaterange[0]), "dd/MM/yyyy"),
          endDate: format(new Date(newDaterange[1]), "dd/MM/yyyy")
        },
        ...(route.query.startDate && route.query.endDate
          ? { replace: true }
          : undefined)
      });

      // bus.emit("handle-date-change", newDaterange);
    }
  },
  { immediate: true }
);

watch(
  () => route.matched,
  (newRoute, oldRoute) => {
    //DEBT
    if (
      newRoute[1]?.name === oldRoute[1]?.name &&
      (!route.query.startDate || !route.query.endDate)
    ) {
      router.push({
        ...route,
        query: {
          ...route.query,
          startDate: format(new Date(state.dateRange[0]), "dd/MM/yyyy"),
          endDate: format(new Date(state.dateRange[1]), "dd/MM/yyyy")
        },
        ...(route.query.startDate && route.query.endDate
          ? { replace: true }
          : undefined)
      });
    }
  }
);

const isCustom = computed(() => {
  return (
    !props.isOnlyCustom &&
    (isAllDate.value ||
      presetRanges.some((element) => {
        return methods.format(element.range) == methods.format(state.dateRange);
      }))
  );
});

onMounted(() => {
  window.addEventListener("click", () => {
    isOpen.value = false;
  });
  const encryptQuery = encryptSessionStorage.getItem(`query_${route.name}`);
  const startDate = route.query.startDate
    ? parse(route.query.startDate, "dd/MM/yyyy", new Date())
    : encryptQuery && JSON.parse(encryptQuery).startDate
      ? parse(JSON.parse(encryptQuery).startDate, "yyyy-MM-dd", new Date())
      : parse(props.creationDate, "yyyy-MM-dd", new Date());
  const endDate = route.query.endDate
    ? parse(route.query.endDate, "dd/MM/yyyy", new Date())
    : encryptQuery && JSON.parse(encryptQuery).endDate
      ? parse(JSON.parse(encryptQuery).endDate, "yyyy-MM-dd", new Date())
      : getTodayDay();

  state.dateRange = [startDate, endDate];
  bus.on("clear-filters", () => {
    methods.handleAllTimeRange();
  });
  bus.on("data loading", () => {
    state.isLoading = true;
  });
  bus.on("data loaded", () => {
    state.isLoading = false;
  });
  bus.on("tab-changed", () => {
    // emit("click-applied", state.dateRange);
    // bus.emit("handle-date-change", state.dateRange);
  });
});

onUnmounted(() => {
  bus.off("clear-filters");
  bus.off("data loading");
  bus.off("data loaded");
  bus.off("tab-changed");
});
</script>

<style lang="scss" scoped></style>
