export function makeUniqueArray(arr, isEqual = (a, b) => a === b) {
  if (!Array.isArray(arr)) {
    throw new Error("Input must be an array.");
  }

  if (typeof isEqual !== "function") {
    throw new Error("isEqual parameter must be a function.");
  }

  if (arr.length === 0) {
    return [];
  }
  const filteredArr = arr.filter((item) => item);

  return filteredArr.reduce((uniqueArr, current) => {
    const isDuplicate = uniqueArr.some((existing) =>
      isEqual(existing, current)
    );

    if (!isDuplicate) {
      uniqueArr.push(current);
    }

    return uniqueArr;
  }, []);
}
