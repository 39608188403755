const validate = require("validate.js");
const { Api400Error } = require("./errorClasses");
const { format } = require("date-fns");

function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}
function getValueByPath(obj, path) {
  const pathKeys = path.split(".");
  let value = obj;
  pathKeys.forEach((key) => {
    value = value?.[key];
  });
  return value;
}

function isDataFalsyOrEmpty(data) {
  if (data === "" || data === null || data === undefined) {
    return true;
  } else if (Array.isArray(data)) {
    if (data.length === 0) {
      return true;
    }

    for (let i = 0; i < data.length; i++) {
      if (!isDataFalsyOrEmpty(data[i])) {
        return false;
      }
    }
    return true;
  }
  if (data instanceof Date) {
    return false;
  } else if (typeof data === "object") {
    if (Object.keys(data).length === 0) {
      return true;
    }
    for (let key in data) {
      if (!isDataFalsyOrEmpty(data[key])) {
        return false;
      }
    }
    return true;
  }
}
function checkDataType(data) {
  if (Array.isArray(data)) {
    return "array";
  } else if (typeof data === "object" && data !== null) {
    return "object";
  } else if (data === null || data === undefined) {
    return data;
  }
  return typeof data;
}
//TODO: there are different naming for opportunities in SF => Unify it
//CorporateName_StartupName_MonthYear
//CorporateName_StartupName_Month_Year?
function generateOpportunityName(CorpName, AccountName) {
  try {
    const validationRules = {
      CorpName: {
        presence: { allowEmpty: false },
        type: "string"
      },
      AccountName: {
        presence: { allowEmpty: false },
        type: "string"
      }
    };
    const validationErrors = validate(
      { CorpName, AccountName },
      validationRules
    );
    if (validationErrors) {
      throw new Api400Error(JSON.stringify(validationErrors));
    }
    const formattedDate = format(new Date(), "MMMMyyyy");
    const corporateName = CorpName.trim();
    const startupName = AccountName.trim();
    const opportunityName = `${corporateName}_${startupName}_${formattedDate}`;

    return opportunityName;
  } catch (error) {
    throw error;
  }
}
function accountType(RecordTypeId) {
  if (RecordTypeId == "0122E000000QmFTQA0") return "corporate";
  else if (RecordTypeId == "0122E000000QmFWQA0") return "startup";
  else if (RecordTypeId == "0122E000000QmFUQA0") return "institution";
  else if (RecordTypeId == "0122E000000QmFVQA0") return "investor";
  else return null;
}
function recordTypeId(account) {
  if (account == "corporate") {
    return "0122E000000QmFTQA0";
  } else if (account == "startup") {
    return "0122E000000QmFWQA0";
  } else if (account == "institution") {
    return "0122E000000QmFUQA0";
  } else if (account == "investor") {
    return "0122E000000QmFVQA0";
  } else return null;
}

module.exports = {
  isJsonString,
  getValueByPath,
  isDataFalsyOrEmpty,
  checkDataType,
  generateOpportunityName,
  accountType,
  recordTypeId
};
