export const IS_LOADING = "IS_LOADING";
export const ERROR = "ERROR";

export const PITCHDECK_LINK =
  "https://europe-west2-mtb-platform.cloudfunctions.net/pitchdecks-serve_latest?sfid=";
export const SALESFORCE_LINK_ACCOUNT =
  "https://mindthebridge.lightning.force.com/lightning/r/Account/";
export const SALESFORCE_LINK_OPPORTUNITY =
  "https://mindthebridge.lightning.force.com/lightning/r/Opportunity/";
export const SALESFORCE_LINK_CHALLENGE =
  "https://mindthebridge.lightning.force.com/lightning/r/Challenge__c/";
export const noImageUrl =
  "https://firebasestorage.googleapis.com/v0/b/mtb-platform.appspot.com/o/logo%2Fdefault.png?alt=media&token=4b2a4994-bb36-4717-8672-ef72fab08782";

export const DEMO_MODE_ACCOUNT_LIST = [
  "0014U00002omxHqQAI", //corporate
  "0014U00002ooVLDQA2", //corporate
  "0014U000036I4rQQAS", //startup
  "0014U000035na4QQAQ" //institution demo
];
