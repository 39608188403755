<template>
  <div class="mtb-card flex w-full justify-between p-6 relative">
    <base-form-validation
      class="w-full"
      :schema="schema"
      form-id="contactForm"
      @submitForm="saveChanges"
    >
      <template #form-body>
        <div class="w-full">
          <div class="flex gap-x-2">
            <base-input-text
              v-if="editMode || tmpContact"
              type="input-text"
              v-model="formData.FirstName"
              name="FirstName"
              placeholder="First Name"
              :value="contact.FirstName"
            />
            <div v-else class="py-3 font-bold text-black">
              {{ formData.FirstName }}
            </div>

            <base-input-text
              v-if="editMode || tmpContact"
              type="input-text"
              v-model="formData.LastName"
              name="LastName"
              placeholder="Last Name"
              :value="contact.LastName"
            />
            <div v-else class="py-3 font-bold text-black">
              {{ formData.LastName }}
            </div>
          </div>
          <div class="flex items-center text-grey2">
            <label class="w-1/5 text-grey1">Role:</label>
            <div class="w-4/5">
              <base-input-text
                v-if="editMode || tmpContact"
                type="input-text"
                v-model="formData.Title"
                placeholder="Role"
                name="Title"
                :value="contact.Title"
              />

              <div v-else class="py-3">{{ formData.Title || "--" }}</div>
            </div>
          </div>
          <div class="flex items-center text-grey2">
            <label class="w-1/5 text-grey1">Email:</label>
            <div class="w-4/5">
              <base-input-text
                v-if="editMode || tmpContact"
                type="input-text"
                v-model="formData.Email"
                placeholder="Email Address"
                name="Email"
                :value="contact.Email"
              />

              <div v-else class="py-3">{{ formData.Email }}</div>
            </div>
          </div>
          <div class="flex items-center text-grey2">
            <label class="w-1/5 text-grey1">LinkedIn:</label>
            <div class="w-4/5">
              <base-input-text
                v-if="editMode || tmpContact"
                type="input-text"
                placeholder="LinkedIn URL"
                v-model="formData.LinkedIn_URL__c"
                name="LinkedIn_URL__c"
                :value="contact.LinkedIn_URL__c"
              />
              <div v-else class="py-3">
                {{ formData.LinkedIn_URL__c || "--" }}
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #form-action>
        <div
          class="absolute top-6 right-6 flex gap-x-4"
          v-if="editMode || tmpContact"
        >
          <base-vite-icon
            @click="cancelEdit"
            name="close"
            classes="fill-none stroke-alert stroke-2 w-5 h-5"
            role="button"
          />
          <base-button
            label=""
            :text="true"
            icon="checkbox-selected-noborder"
            iconClass="fill-none stroke-2 stroke-success w-5 h-5"
            severity="success"
            type="submit"
          />

          <!-- <base-vite-icon
            name="checkbox-selected-noborder"
            classes="fill-none stroke-2 stroke-success w-5 h-5"
            role="button"
            type="submit"
          /> -->
        </div>
        <div class="absolute top-6 right-6 flex gap-x-4" v-else>
          <span v-tooltip.top="'Set as Primary Contact'">
            <base-vite-icon
              @click="emits('mainContactPoint', formData.Id)"
              name="star"
              :classes="`${formData.Is_Main_Contact_Point__c ? 'fill-data3' : 'fill-none'} stroke-data3 stroke-2 w-5 h-`"
              role="button"
            />
          </span>

          <base-vite-icon
            @click="editMode = true"
            name="edit"
            classes="fill-none stroke-2 stroke-grey1 w-5 h-5"
            role="button"
          />
        </div> </template
    ></base-form-validation>
  </div>
</template>

<script setup>
import { ref, watchEffect } from "vue";
import { bus } from "@/main.js";
const emits = defineEmits([
  "resetNewContact",
  "saveChanges",
  "mainContactPoint"
]);
const props = defineProps({
  contact: {
    type: Object,
    required: true
  }
});
const editMode = ref(false);
const tmpContact = ref(false);
const formData = ref({});
watchEffect(() => {
  formData.value = { ...props.contact };
  tmpContact.value = props.contact.tmp;
});
watchEffect(() => {
  if (editMode.value || tmpContact.value) {
    bus.emit("editing-contacts", true);
  } else {
    bus.emit("editing-contacts", false);
  }
});

const schema = {
  FirstName: {
    required: true
  },
  LastName: {
    required: true
  },
  Email: {
    required: true,
    email: true
  },
  LinkedIn_URL__c: {
    linkedin: true
  }
};

const saveChanges = () => {
  emits("saveChanges", { id: props.contact.Id, form: formData.value });
  editMode.value = false;
};

const cancelEdit = () => {
  editMode.value = false;
  if (tmpContact.value) {
    emits("resetNewContact", props.contact.Id);
    bus.emit("editing-contacts", false);
  }

  formData.value = props.contact;
};
</script>

<style scoped></style>
