<template>
  <div :id="id" class="flex flex-col items-center justify-center w-full">
    <apexchart
      id="multi-chart"
      height="350"
      width="1200"
      :options="chartOptions"
      :series="props.chartProps?.series"
    ></apexchart>
    <OverlayPanel ref="overlay" class="z-10" :dismissable="true">
      <!-- Content based on the selected row's data -->
      {{ tooltip }}
    </OverlayPanel>
  </div>
</template>
<script setup>
import { computed, watchEffect, ref } from "vue";
import color from "@/plugins/color.js";
import { toPng } from "html-to-image";
import { default as support } from "@share/commonSupport.js";
import { mkConfig, generateCsv, download } from "export-to-csv";
import { format } from "date-fns";

const csvConfig = mkConfig({
  useKeysAsHeaders: true,
  filename: "Analytics Recap",
  title: "Analytics Recap"
});
const tooltip = ref(null);
const overlay = ref();
const toggleOverlay = (event) => {
  overlay.value?.toggle(event);
};
const props = defineProps({
  id: {
    type: String,
    required: true
  },
  chartProps: {
    type: Object,
    required: true
  }
});

const chartOptions = computed(() => {
  if (!props.chartProps) {
    return {};
  }
  return {
    responsive: [
      {
        breakpoint: 640,
        options: {
          chart: {
            width: 300
          }
        }
      },
      {
        breakpoint: 768,
        options: {
          chart: {
            width: 500
          }
        }
      },
      {
        breakpoint: 1024,
        options: {
          chart: {
            width: 700
          }
        }
      },
      {
        breakpoint: 1280,
        options: {
          chart: {
            width: 900
          }
        }
      },
      {
        breakpoint: 1536,
        options: {
          chart: {
            width: 1100
          }
        }
      }
    ],
    chart: {
      height: 350,
      width: 1200,
      type: "line",
      stacked: true,
      zoom: {
        enabled: false
      },
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 2,
        borderRadiusApplication: "top",
        borderRadiusWhenStacked: "all",
        stacked: true
      }
    },
    stroke: {
      curve: "smooth"
    },
    grid: {
      strokeDashArray: 8
    },
    labels: props.chartProps.dateList,
    xaxis: {
      type: "category",
      tickAmount: 5,
      labels: {
        show: true,
        rotate: 0,
        rotateAlways: false
      },
      tooltip: {
        enabled: false
      },
      crosshairs: {
        show: false
      }
    },
    yaxis: props.chartProps.optionSeries.map((serie) => {
      return {
        title: {
          text: serie.text
        },
        show: serie.show,
        max: serie.max,
        opposite: serie.opposite,
        labels: serie.labels
      };
    }),
    tooltip: {
      shared: true,
      intersect: false,
      x: {
        formatter: function (x) {
          function getLabel(index) {
            return index >= props.chartProps.dateList.length
              ? format(new Date(), "dd MMMM yyyy")
              : props.chartProps.dateList[index];
          }

          return `${getLabel(x - 1)} - ${getLabel(x)}`;
        }
      }
    },
    fill: {
      opacity: 1
    },
    colors: props.chartProps.optionSeries.map((s) => s.color),
    markers: {
      size: 4,
      colors: color.violet[700],
      strokeColors: "#fff"
    }
  };
});
const methods = {
  downloadCSV: () => {
    var excelData = [];

    // Ciclo attraverso le date
    for (var i = 0; i < props.chartProps.dateList.length; i++) {
      const date = props.chartProps.dateList[i];
      var dateObject = { Date: date };

      // Ciclo attraverso i dati del grafico
      props.chartProps?.series.forEach(function (entry) {
        var name = entry.name;
        var value = entry.data[i];
        dateObject[name] = value;
      });

      // Aggiungi l'oggetto alla lista
      excelData.push(dateObject);
    }
    const csv = generateCsv(csvConfig)(excelData);
    download(csvConfig)(csv);
  },
  downloadPNG: () => {
    const node = document.getElementById("multi-chart");
    toPng(node)
      .then(function (dataUrl) {
        const link = document.createElement("a");
        link.download = "analytics-recap.png";
        link.href = dataUrl;
        link.click();
      })
      .catch((error) => {
        console.log(error);
      });
  }
};
watchEffect(() => {
  if (chartOptions.value) {
    //DEBT: too many setTimeout
    setTimeout(() => {
      const legendItems = document.getElementsByClassName(
        "apexcharts-legend-series"
      );
      Array.from(legendItems).forEach((item) => {
        item.removeEventListener("mouseenter", () => {
          return;
        });
        item.removeEventListener("mouseleave", () => {
          return;
        });
      });
      setTimeout(() => {
        Array.from(legendItems).forEach((item) => {
          item.addEventListener("mouseenter", (e) => {
            const ref = Number(item.getAttribute("rel"));
            if (ref && !isNaN(ref) && props.chartProps) {
              tooltip.value = props.chartProps?.series[ref - 1]?.tooltip;
              toggleOverlay(e);
            }
          });
          item.addEventListener("mouseleave", (e) => {
            toggleOverlay(e);
            tooltip.value = null;
          });
        });
      }, 500);
    }, 100);
  }
});
</script>

<style scoped lang="scss">
.mtb-line-chart {
  padding: 24px 24px 0;
  .image-wrapper {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 1.25rem;

    display: flex;
    justify-content: center;
    align-items: center;
    &:not(.mtb-skeleton) {
      background: #e7eafb;
    }
  }
  .detail-title {
    color: #808191;
    font-size: 14px;
    &.mtb-skeleton {
      height: 14px;
      width: 4rem;
    }
  }
  .detail-total {
    color: #2c3e50;
    font-size: 26px;
    &.mtb-skeleton {
      height: 26px;
    }
  }
}
</style>
