export const toolbar = (offset = -24) => {
  return {
    show: false,
    offsetY: offset,
    tools: {
      download: false
    }
  };
};

//no hover/ active effect
export const singleStateChart = {
  normal: {
    filter: {
      type: "none",
      value: 0
    }
  },
  hover: {
    filter: {
      type: "none",
      value: 0
    }
  },
  active: {
    filter: {
      type: "none",
      value: 0
    }
  }
};
export const yaxis = {
  show: true,
  labels: {
    show: true,
    align: "right",
    style: {
      colors: "#6C757D",
      fontSize: "14px",
      fontFamily: "Helvetica, Arial, sans-serif",
      fontWeight: 400
    }
  },
  axisBorder: {
    show: false
  },
  axisTicks: {
    show: false
  }
};
export const grid = {
  show: false
};
export const xaxis = (categories = [], type) => {
  return {
    axisBorder: {
      show: false
    },
    axisTicks: {
      show: false
    },
    categories: categories,
    labels: {
      show: true,
      formatter: function (value) {
        if (type == "years" && value != "N.A") {
          return value + "y";
        } else if (typeof value == "number" && !Number.isInteger(value)) {
          return value.toFixed(0);
        } else {
          return value;
        }
      },
      style: {
        colors: "#6C757D",
        fontSize: "14px",
        fontFamily: "Helvetica, Arial, sans-serif",
        fontWeight: 700
      }
    }
  };
};
