<template>
  <form @submit="onSubmit" :id="formId">
    <slot name="form-body" :formValues="values"></slot>
    <slot name="form-action"></slot>
  </form>
</template>

<script setup>
import { watchEffect, toRefs, ref, onMounted, watch, provide } from "vue";
import { useForm } from "vee-validate";
const emit = defineEmits(["update:modelValue", "submitForm", "invalidForm"]);
const props = defineProps({
  schema: { type: Object, default: () => ({}) },
  formId: { type: String, default: "form" },
  initialValues: { type: Object, default: () => ({}) }
});
const { schema, initialValues } = toRefs(props);

const { handleSubmit, values, errors, resetForm, controlledValues } = useForm({
  validationSchema: schema,
  initialValues: initialValues
});
function onSuccess(values) {
  if (process.env.NODE_ENV === "development") {
    console.log("SUBMIT FORM:", values);
  }
  emit("submitForm", values, controlledValues.value);
}
function onInvalidSubmit({ values, errors, results }) {
  emit("invalidForm", errors);
  if (process.env.NODE_ENV === "development") {
    console.error("###Current values ERROR: ", errors, values);
  }
}
// This handles both valid and invalid submissions
const onSubmit = handleSubmit(onSuccess, onInvalidSubmit);

watch(
  values,
  (newValue) => {
    if (process.env.NODE_ENV == "development") {
      console.info("UPDATE FORM VALUE", newValue);
    }
    if (!newValue) {
      emit("update:modelValue", {});
    } else {
      emit("update:modelValue", newValue);
    }
  },
  { immediate: true }
);

watch(
  () => initialValues.value,
  (newVal, oldVal) => {
    if (JSON.stringify(oldVal) !== JSON.stringify(newVal)) {
      console.log("SHOULD SET FORM VALUE", newVal);
      resetForm({ values: newVal, initialValues: newVal });
    }
  }
);
</script>

<style lang="scss" scoped></style>
