import DOMPurify from "dompurify";
export function truncate(text, max) {
  if (text.length > max) {
    return text.substring(0, max) + "...";
  }
  return text;
}

export function formatRichTextToPlainText(richText) {
  const clean = DOMPurify.sanitize(richText, {
    ALLOWED_TAGS: [],
    ALLOWED_ATTR: []
  });

  return clean;
}
export function richTextSingleValidation(value, limit) {
  if (!value || !value.length) {
    return false;
  }
  if (formatRichTextToPlainText(value).length > limit) {
    return `This field must be less than ${limit} characters`;
  }
  return false;
}
export function toPascalCase(str) {
  return str
    ?.replace(/(?:^\w|[A-Z]|\b\w)/g, (match) => match.toUpperCase())
    ?.replace(/[^a-zA-Z0-9\s]/g, "");
}
