class FirebaseCollection extends Error {
  constructor() {
    super();
    this.name = "FirebaseCollection";
  }
}

class Unauthenticated extends Error {
  constructor() {
    super();
    this.name = "Unauthenticated";
  }
}

class Api401Error extends Error {
  constructor(message) {
    super(message);
    this.name = "Unauthorized";
    this.statusCode = 401;
  }
}

class Api400Error extends Error {
  constructor(message) {
    super(message);
    this.name = "BAD_REQUEST";
    this.statusCode = 400;
  }
}

module.exports = {
  FirebaseCollection,
  Unauthenticated,
  Api401Error,
  Api400Error
};
