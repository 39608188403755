<template>
  <div class="mtb-card w-full py-6 px-6 relative">
    <!-- Chart title -->
    <base-chart-header
      :chartTitle="props.chartTitle"
      :tooltip="props.tooltip"
      :dropdownOptionsChart="config.length > 1 ? config : null"
      :actionIsVisible="
        !props.isError &&
        !props.isLoading &&
        !support.isDataFalsyOrEmpty(chartData)
      "
      @download-csv="methods.downloadCSV"
      @download-png="methods.downloadPNG"
      @change-chart="methods.changeChart"
    >
      <template #custom-tooltip> <slot name="custom-tooltip" /></template>
    </base-chart-header>
    <!-- SKELETON -->
    <div
      v-if="isLoading"
      class="px-10 py-5 flex gap-x-8"
      :class="skeleton.containerClasses"
    >
      <Skeleton
        v-for="n in skeleton.number"
        :key="n"
        class="w-full h-[350px]"
        :class="skeleton.skeletonClasses"
      ></Skeleton>
    </div>
    <!-- ERROR FALLBACK MESSAGE -->
    <div
      v-else-if="isError"
      class="pt-6 mt-6 d-flex justify-content-center align-items-center vite-error-block fallback-message"
      style="height: 350px"
    >
      <fallback-error @reload="reload(state.selectedChart.id)" :isRow="false" />
    </div>
    <div
      v-else-if="support.isDataFalsyOrEmpty(chartData) || isEmpty"
      class="p-6 m-6"
    >
      <fallback-empty-state
        :isRow="false"
        message="
      No results found matching the selected criteria.
      "
      />
    </div>

    <div
      v-else
      aria-haspopup="true"
      ref="containerChart"
      class="flex items-center justify-center w-full relative"
    >
      <!-- MAIN CHART -->
      <component
        :is="state.selectedChart.component"
        :id="state.selectedChart.id"
        :chartProps="{
          ...commonProps,
          ...(state.selectedChart.chartProps || {})
        }"
        @dataPointSelection="methods.showDropdownMenu"
      />
      <base-chart-dropdown
        @click.stop
        @mousedown.stop
        @touchend.stop
        v-if="
          state.showDropDown &&
          props.commonProps.enableDropdownOnSelection &&
          chartDropdownOption
        "
        :menu-position="state.menuPosition"
        :chartDropdownOption="chartDropdownOption"
        @select="methods.select"
      />
    </div>
  </div>
</template>

<script setup>
import { bus } from "@/main.js";
import useDetectOutsideClick from "@/global/hooks/useClickOutSideElement.js";
import { default as support } from "@share/commonSupport.js";
import { computed, reactive, ref, onMounted } from "vue";
import { generateCsv, download } from "export-to-csv";
import { toPng } from "html-to-image";
import { useUserAuthStore } from "../stores/user-auth-store.js";

const emits = defineEmits(["reload", "show-items"]);
const props = defineProps({
  chartDropdownOption: {
    type: Array,
    default: () => null
  },
  isEmpty: {
    type: Boolean,
    default: false
  },
  filterKey: {
    type: String,
    default: null
  },
  chartTitle: {
    type: String,
    default: ""
  },
  tooltip: {
    type: Boolean,
    default: false
  },
  isLoading: {
    type: Boolean,
    default: true
  },
  isError: {
    type: Boolean,
    default: false
  },
  commonProps: {
    type: Object,
    required: true
  },
  config: {
    type: Object,
    required: true
  },
  skeleton: {
    type: Object,
    //you can customize the skeleton style there
    default: () => {
      return {
        number: 1,
        containerClasses: "",
        skeletonClasses: ""
      };
    }
  }
});
const userAuthStore = useUserAuthStore();
const state = reactive({
  showDropDown: false,
  menuPosition: { x: 0, y: 0 },
  areaSelected: "",
  selectedChart: props.config[0]
});

//SHOULD BE DYNAMIC
const containerChart = ref([]);

useDetectOutsideClick(
  containerChart,
  () => {
    methods.hideDropdownMenu();
  },
  {
    isClickBlocked: true,
    isApexChartElement: true
  }
);
const chartData = computed(() => {
  return { ...props.commonProps, ...state.selectedChart.chartProps }.chartData;
});

const methods = reactive({
  select(event) {
    bus.emit(event, {
      key: props.filterKey,
      value: state.areaSelected
    });
    methods.hideDropdownMenu();
  },
  showDropdownMenu({ mousePosition, title }) {
    if (state.showDropDown && state.areaSelected === title) {
      //toggle dropdown if you click on same element
      state.showDropDown = false;
      return;
    }
    bus.emit("chart-selection", title);
    state.showDropDown = true;
    state.areaSelected = title;
    state.menuPosition.x = `${mousePosition.offsetX}px`;
    state.menuPosition.y = `${mousePosition.offsetY}px`;
  },
  hideDropdownMenu() {
    state.showDropDown = false;
  },
  downloadCSV: () => {
    const resultArray = state.selectedChart.csvParser(chartData.value);
    const localization = Intl.DateTimeFormat().resolvedOptions();
    const contextConfig = {
      ...state.selectedChart.csvConfig,
      filename: `${userAuthStore.state.accountInfo.name}_${
        state.selectedChart.csvConfig.filename
      }_MTBEcosystem_${new Date().toLocaleDateString(localization.locale, {
        day: "numeric",
        month: "numeric",
        year: "numeric"
      })}`
    };
    const csv = generateCsv(contextConfig)(resultArray);
    download(contextConfig)(csv);
  },
  downloadPNG: () => {
    //change id base on SelectedChart and name
    const node = document.getElementById(state.selectedChart.id);
    toPng(node)
      .then(function (dataUrl) {
        const link = document.createElement("a");
        const localization = Intl.DateTimeFormat().resolvedOptions();
        link.download = `${userAuthStore.state.accountInfo.name}_${
          state.selectedChart.downloadName
        }_MTBEcosystem_${new Date().toLocaleDateString(localization.locale, {
          day: "numeric",
          month: "numeric",
          year: "numeric"
        })}.png`;
        link.href = dataUrl;
        link.click();
      })
      .catch((error) => {
        console.log(error);
      });
  },
  changeChart(data) {
    state.selectedChart = props.config.find(({ id }) => id === data.id);
  }
});

function reload(componentId) {
  emits("reload", componentId);
}
</script>

<style lang="scss" scoped></style>
