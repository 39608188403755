<template>
  <div class="mtb-card w-full h-[85px] flex justify-between items-center p-6">
    <span class="text-start me-2"> {{ props.message }}</span>
    <base-button
      label="Contact us"
      severity="primary"
      @click="click"
      class="whitespace-nowrap"
    />
  </div>
</template>
<script setup>
import { useUserAuthStore } from "../stores/user-auth-store.js";

const userAuthStore = useUserAuthStore();
const props = defineProps({
  message: {
    type: String,
    required: true
  }
});
const click = () => {
  //TODO ask if it's ok and if we want improve it
  location.href = `mailto:${userAuthStore.state.accountInfo.advisorEmail}`;
};
</script>
