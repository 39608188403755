//NAMING RULE FOR CONSTANTS: WORD_WORD
const EMAIL_ID_LIST = {
  WEEKLY_CORPORATE_OPPORTUNITY_SUMMARY: "newOpp_corpNotification_recap",
  WEEKLY_CORPORATE_SUGGESTIONS_SUMMARY: "newSuggestions_corpNotification_recap",
  WEEKLY_CORPORATE_SUGGESTIONS_SUMMARY_A:
    "newSuggestions_corpNotification_recap_5_suggestions",
  WEEKLY_CORPORATE_SUGGESTIONS_SUMMARY_B:
    "newSuggestions_corpNotification_recap_1_suggestion",
  CORPORATE_NEW_OPPORTUNITY_EMAIL_NOTIFICATION: "newOpp_corpNotification",
  DAILY_CORPORATE_CHALLENGE_EXPIRATION: "challExpiration_corpNotification",
  DAILY_CORPORATE_ALL_CHALLENGE_CLOSED:
    "corporateDailyWatchAllChallengesClosed",
  //TODO: separate it for corp, startup and advisor
  CORPORATE_STARTUP_MEETING_REQUESTED_NOTIFICATION: "meetingReq_Notification",
  ADVISOR_MEETNG_REQUESTED_NOTIFICATION: "advisorMeetingRequestedNotification",
  ADVISOR_NEW_CHALLENGE_APPLICATION: "advisorNewChallengeApplication",
  ADVISOR_NEW_CHALLENGE_CREATION: "new_challenge_creating_advisor",
  WEEKLY_ADVISOR_PENDING_CHALLENGE_APPLICATION_SUMMARY:
    "advisorPendingChallengeApplicationSummary",
  DAILY_ADVISOR_PENDING_MEETING_REQUESTED: "advisorPendingMeetingRequested",
  WEEKLY_STARTUP_OUTDATED_PITCH_DECK: "outdatedPitchdeck_startupNotification",
  WEEKLY_STARTUP_RIALTO_CHALLENGE_SUGGESTION:
    "suggestedChall_startupNotification",
  STARTUP_APPLICATION_REJECTION_NOTIFICATION: "sendStartupApplicationRejection",
  STARTUP_APPLICATION_AUTOMATICALLY_REJECTION:
    "sendStartupAutomaticallyApplicationRejection",
  STARTUP_OUTDATED_PITCHDECK_NOTIFICATION:
    "outdatedPitchdeck_startupNotification",
  STARTUP_NEW_APPLICATION_NOTIFICATION: "notifyStartupChallengeApplication",
  USER_WELCOME_EMAIL: "userWelcomeEmail",
  SIGN_UP_USER: "signup_user"
};

const CORPORATE_SELECTION = "Ecosystem - Corporate Selection";
const MTB_SELECTION = ["Ecosystem - MTB Selection", ""];
const STARTUP_APPLICATION = "Ecosystem - Startup Application";
const RIALTO_SUGGESTION = "Ecosystem - Rialto Suggestion";
const ecosystemLeadSources = [
  CORPORATE_SELECTION,
  ...MTB_SELECTION,
  STARTUP_APPLICATION,
  RIALTO_SUGGESTION
];

const FALLBACK_RIALTO_SIGGESTIONS_DATE = "2021-01-01";
const FALLBACK_CREATION_ACCOUNT_DATE = "2018-01-01";
const FALLBACK_CREATION_BIGQUERY = "2023-03-01";
const PORTFOLIO_TAB = {
  TO_BE_EVALUATED: "toBeEvaluated",
  MY_SELECTION: "mySelection",
  MY_SELECTION_NON: "mySelectionNon",
  ALL_MY_STARTUPS: "allMyStartups"
};
const COMPANY_TYPE = {
  CORPORATE: "corporate",
  STARTUP: "startup"
};

const EMAIL_PREFERENCES = [
  //TODO: ask for copy
  //only basic
  {
    id: EMAIL_ID_LIST.DAILY_CORPORATE_ALL_CHALLENGE_CLOSED,
    visible: true,
    disabled: false,
    user_type: ["corporate"],
    companyTier: ["basic"],
    name: "Challenge Renewal Reminder",
    description:
      "Receive email notifications when all of your challenges have been closed or expired for over 2 weeks.",
    category: "corporate challenge management",
    show: false
  },
  {
    id: EMAIL_ID_LIST.WEEKLY_CORPORATE_SUGGESTIONS_SUMMARY,
    visible: true,
    disabled: false,
    user_type: ["corporate"],
    companyTier: ["basic"],
    name: "Suggested startups Summary",
    description:
      "Receive every week a list of startups suggested specifically for your challenges by our AI engine.",
    category: "startups suggestion",
    show: false
  },
  {
    id: EMAIL_ID_LIST.DAILY_CORPORATE_CHALLENGE_EXPIRATION,
    visible: true,
    disabled: false,
    user_type: ["corporate"],
    companyTier: ["basic", "star"],
    name: "Expiring Challenge Reminder",
    description:
      "Receiving an email notification when one of your Challenges is about to expire.",
    category: "corporate challenge management",
    show: false
  },
  {
    id: EMAIL_ID_LIST.CORPORATE_NEW_OPPORTUNITY_EMAIL_NOTIFICATION,
    visible: true,
    disabled: false,
    user_type: ["corporate"],
    companyTier: ["basic", "star"],
    name: "New Business Opportunity",
    description:
      "Receiving an email notification when you receive a new application by a startup, or MTB advisor's suggestion, to one of your Challenges.",
    category: "opportunity",
    show: false
  },
  {
    id: EMAIL_ID_LIST.WEEKLY_CORPORATE_OPPORTUNITY_SUMMARY,
    visible: true,
    disabled: false,
    user_type: ["corporate"],
    companyTier: ["star"],
    name: "Weekly Opportunity Summary",
    description:
      "Receive a weekly summary with a list of all the new business opportunities generated for you on the platform.",
    category: "opportunity",
    show: false
  },
  {
    id: EMAIL_ID_LIST.CORPORATE_STARTUP_MEETING_REQUESTED_NOTIFICATION,
    visible: true,
    disabled: true,
    user_type: ["corporate", "startup"],
    companyTier: ["basic", "star"],
    name: {
      corporate: "New Meeting Requested Notification",
      startup: "New Meeting Requested from Corporate Notification"
    },
    description: {
      corporate:
        "Receive an introduction email to the startup when you ask for a meeting.",
      startup:
        "Receive an email notification when a corporate wants to meet you."
    },
    category: "opportunity",
    show: false
  },
  {
    id: EMAIL_ID_LIST.WEEKLY_STARTUP_OUTDATED_PITCH_DECK,
    visible: true,
    disabled: false,
    user_type: ["startup"],
    companyTier: ["startup"],
    name: "Outdated Pitchdeck Alert",
    description:
      "Receive an email notification when your pitch deck becomes outdated.",
    category: "startup profile",
    show: false
  },
  {
    id: EMAIL_ID_LIST.WEEKLY_STARTUP_RIALTO_CHALLENGE_SUGGESTION,
    visible: true,
    disabled: false,
    user_type: ["startup"],
    companyTier: ["startup"],
    name: "Suggested Challenge Summary",
    description:
      "Receive every two weeks a list of corporate challenges suggested specifically for you by our AI engine.",
    category: "challenges suggestion",
    show: false
  }
];
module.exports = {
  ECOSYSTEM_LEAD_SOURCES: ecosystemLeadSources,
  CORPORATE_SELECTION,
  MTB_SELECTION,
  STARTUP_APPLICATION,
  RIALTO_SUGGESTION,
  FALLBACK_RIALTO_SIGGESTIONS_DATE,
  FALLBACK_CREATION_ACCOUNT_DATE,
  FALLBACK_CREATION_BIGQUERY,
  PORTFOLIO_TAB,
  COMPANY_TYPE,
  EMAIL_PREFERENCES,
  EMAIL_ID_LIST
};
