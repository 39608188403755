<template>
  <div class="card border-0 flex justify-content-center">
    <Dropdown
      :id="`${name}_${contextUuid}`"
      v-model="inputValue"
      :disabled="disabled"
      :options="options"
      autoFilterFocus
      :optionLabel="optionLabel"
      :loading="isLoading"
      :filter="filter"
      :placeholder="placeholder"
      :class="isInvalid ? 'border-alert' : 'border-grey3'"
      :emptyMessage="props.emptyMessage"
      @blur="setTouched(true)"
      @change="onChange"
    >
      <template #value="slotProps">
        <div
          v-if="slotProps.value || slotProps.value?.name"
          class="flex align-items-center"
        >
          <div>{{ slotProps.value[optionLabel] }}</div>
        </div>
        <span v-else>
          {{ slotProps.placeholder }}
        </span>
      </template>
      <template #option="slotProps">
        <slot name="option" v-bind="slotProps"></slot>
      </template>
      <template #emptyfilter>
        <div class="ml-4">No option matches your criteria</div>
      </template>
    </Dropdown>
    <small
      v-if="isInvalid"
      class="text-alert inline-flex items-center gap-1"
      id="text-error"
    >
      <base-vite-icon name="close" classes="w-3.5 h-3.5 stroke-2" />
      {{ errorMessage }}</small
    >
  </div>
</template>

<script setup>
import Dropdown from "primevue/dropdown";
import {
  onUnmounted,
  watch,
  toRefs,
  onMounted,
  watchEffect,
  computed
} from "vue";
import { bus } from "@/main.js";
import { useField } from "vee-validate";
import { v4 as uuidv4 } from "uuid";
const contextUuid = uuidv4();
const props = defineProps({
  name: {
    type: String,
    default: ""
  },
  disabled: {
    type: Boolean,
    default: false
  },
  options: {
    //{name:"", id: ""}
    type: Array,
    default: () => []
  },
  placeholder: {
    type: String,
    default: () => "select an option"
  },
  defaultSelectedOption: {
    type: Object,
    default: () => null
  },
  optionLabel: {
    type: String,
    default: "label"
  },
  isLoading: {
    type: Boolean,
    default: false
  },
  filter: {
    type: Boolean,
    default: false
  },
  isDraftValidated: {
    type: Boolean,
    default: false
  },
  forceEmit: {
    type: Boolean,
    default: false
  },
  emptyMessage: {
    type: String,
    default: "No option available."
  }
});
function onChange(e) {
  if (props.forceEmit) {
    emit("update:modelValue", e.value, true);
  }
}
const emit = defineEmits(["update:modelValue"]);
// const selectedOption = ref(props.defaultSelectedOption);
const { options } = toRefs(props);

const { name, defaultSelectedOption, isDraftValidated } = toRefs(props);
const {
  value: inputValue,
  errorMessage,
  meta,
  setTouched
} = useField(name, undefined, {
  initialValue: defaultSelectedOption,
  validateOnMount: true
});

//ONLY SHOW THE ERROR MESSAGE IF
//1. field is dirty and has a error message
//2. A Save draft
const isInvalid = computed(() => {
  return errorMessage.value && (meta.touched || isDraftValidated.value);
});

watch(
  () => inputValue.value,
  (newValue, oldValue) => {
    if (JSON.stringify(newValue) === JSON.stringify(oldValue)) {
      return;
    }
    emit("update:modelValue", newValue);
  },
  { immediate: true }
);
watchEffect(() => {
  if (
    !props.defaultSelectedOption ||
    props.defaultSelectedOption?.id == inputValue.value?.id
  ) {
    return;
  }

  inputValue.value = props.defaultSelectedOption;
});

onMounted(() => {
  bus.on("reset-option", (value) => {
    inputValue.value = value;
  });
});
onUnmounted(() => {
  bus.off("reset-option");
});
</script>
<style lang="scss" scoped></style>
