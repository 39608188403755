import { useDialog } from "primevue/usedialog";
import { defineAsyncComponent } from "vue";
import { default as stageMapping } from "@share/stageMappingConfig.js";
import { useUserAuthStore } from "@/global/stores/user-auth-store.js";
import { useAccountsStore } from "@/global/stores/accounts-store.js";
export const useOpportunityModals = () => {
  const userAuthStore = useUserAuthStore();
  const accountsStore = useAccountsStore();
  const dialog = useDialog();
  const ModalAddStartups = defineAsyncComponent(
    () =>
      import("@/modules/corporates/components/corporate-modal-add-startups.vue")
  );
  function openAddModal({
    column,
    opportunityStage,
    currentChallengeId,
    internalSuggestModal
  }) {
    if (internalSuggestModal) {
      dialog?.open(ModalInternalSuggestToCorporate, {
        data: {
          challengeId: currentChallengeId
        },
        props: {
          header: "Suggest to Corporate",
          style: {
            width: "560px"
          },
          breakpoints: {
            "640px": "90vw"
          },
          modal: true,
          draggable: false
        }
      });
    } else {
      const specialMeetingLayout = column.id === "meeting";
      dialog?.open(ModalAddStartups, {
        data: {
          challengeId: currentChallengeId,
          newStage: opportunityStage,
          specialMeetingLayout: specialMeetingLayout
        },
        props: {
          header: specialMeetingLayout
            ? "Set up a new meeting"
            : `Add startups to ${column.title}`,
          style: {
            width: "500px",
            maxHeight: "90vh"
          },
          breakpoints: {
            "640px": "90vw"
          },
          modal: true,
          draggable: false
        }
      });
    }
  }
  const ModalRejectionStartup = defineAsyncComponent(
    () =>
      import("@/modules/corporates/components/corporate-modal-rejection.vue")
  );
  function openRejectModal({
    startupId,
    potentialNewStage,
    opportunityData,
    currentChallenge
  }) {
    dialog?.open(ModalRejectionStartup, {
      data: {
        challengeId: currentChallenge?.Id,
        challengeName: currentChallenge?.Name,
        startupId,
        potentialNewStage,
        isRialtoSuggestion:
          !opportunityData?.Id &&
          opportunityData?.LeadSource === "Ecosystem - Rialto Suggestion",
        isStartupApplication:
          opportunityData?.LeadSource === "Ecosystem - Startup Application"
      },
      props: {
        header: `Discard ${opportunityData?.AccountName}`,
        style: {
          width: "560px"
        },
        breakpoints: {
          "640px": "90vw"
        },
        modal: true,
        draggable: false
      }
    });
  }
  const ModalMeetings = defineAsyncComponent(
    () => import("@/modules/corporates/components/corporate-modal-meeting.vue")
  );
  //selectedStartupIds
  //selectedChallenge
  //startupTagEditable
  //challengeEditable
  //forceSuggestions
  //SFSearch
  //resetOnChallengeChange
  function openMeetingModal({
    selectedStartupIds = null,
    selectedChallenge = null,
    startupTagEditable = true,
    challengeEditable = true,
    forceSuggestions = null,
    SFSearch = true,
    resetOnChallengeChange = false
  }) {
    dialog?.open(ModalMeetings, {
      data: {
        selectedStartupIds,
        selectedChallenge,
        startupTagEditable,
        challengeEditable,
        forceSuggestions,
        SFSearch,
        resetOnChallengeChange
      },
      props: {
        header: "Request a new meeting",
        style: {
          width: "500px"
        },
        breakpoints: {
          "640px": "90vw"
        },
        modal: true,
        draggable: false
      }
    });
  }
  const ModalChangeStage = defineAsyncComponent(
    () =>
      import("@/modules/corporates/components/corporate-modal-change-stage.vue")
  );

  function openChangeStageModal({
    startupId,
    prefilledStage,
    section,
    sameSection,
    challengeId
  }) {
    dialog?.open(ModalChangeStage, {
      data: {
        section,
        startupId,
        prefilledStage,
        challengeId
      },
      props: {
        header: sameSection
          ? `Change '${stageMapping.stageMap[section].label}' stage`
          : `Move to '${stageMapping.stageMap[section].label}'`,
        style: {
          width: "560px"
        },
        breakpoints: {
          "640px": "90vw"
        },
        modal: true,
        draggable: false
      }
    });
  }
  const ModalAddToChallenge = defineAsyncComponent(
    () =>
      import(
        "@/modules/corporates/components/corporate-modal-add-to-challenge.vue"
      )
  );
  const ModalInternalSuggestToCorporate = defineAsyncComponent(
    () =>
      import(
        "@/modules/corporates/components/corporate-modal-internal-suggest.vue"
      )
  );

  function openAddToChallengeModal({ startupId, startupName }) {
    if (
      userAuthStore.getters.isMtbUser &&
      !userAuthStore.state.isDemoModeActive
    ) {
      dialog?.open(ModalInternalSuggestToCorporate, {
        data: {
          startupId,
          startupName
        },
        props: {
          header: "Suggest to Corporate",
          style: {
            width: "560px"
          },
          breakpoints: {
            "640px": "90vw"
          },
          modal: true,
          draggable: false
        }
      });
    } else {
      dialog?.open(ModalAddToChallenge, {
        data: {
          startupId,
          startupName
        },
        props: {
          header: "Add to Challenge",
          style: {
            width: "560px"
          },
          breakpoints: {
            "640px": "90vw"
          },
          modal: true,
          draggable: false
        }
      });
    }
  }
  const updateFavouriteStartupModal = defineAsyncComponent(
    () =>
      import(
        "@/modules/corporates/components/update-favourite-startup-modal.vue"
      )
  );
  function openUpdateFavourite(startupId) {
    dialog.open(updateFavouriteStartupModal, {
      props: {
        style: {
          width: "400px"
        },
        pt: {
          mask: "fixed top-0 left-0 w-full h-full flex items-center justify-center pointer-events-auto bg-black bg-opacity-40 transition duration-200 ",
          content:
            "overflow-y-auto bg-white text-gray-700 px-6 pb-6 pt-0 rounded-2xl h-full"
        },
        header: "test",
        showHeader: false,
        modal: true,
        draggable: false
      },
      data: {
        startupId: startupId
      }
    });
  }
  return {
    openAddModal,
    openRejectModal,
    openMeetingModal,
    openChangeStageModal,
    openAddToChallengeModal,
    openUpdateFavourite
  };
};
