import { defineStore } from "pinia";
import { computed, reactive } from "vue";
import { useAccountsStore } from "@/global/stores/accounts-store.js";
import { useOpportunitiesStore } from "@/global/stores/opportunities-store.js";
import { useChallengesStore } from "@/global/stores/challenges-store.js";
import { useAppStateStore } from "@/global/stores/app-state-store.js";
import {
  getStartupIssues,
  getMatches,
  getSponsorshipUser,
  getAllSuggestedChallenges
} from "../services/institution-myecosystem-service.js";
import { userErrorHandling } from "@global/hooks/use-error-handling.js";
import { challengeToStartupSuggestionsWithoutOpps } from "@/global/services/rialto-service.js";
import { getChallengesOpportunities } from "@/global/services/opportunities-service.js";
import { encryptSessionStorage } from "@/plugins/pinia.js";
import { useLoading } from "@/global/hooks/use-loading.js";

export const useInstitutionMyEcosystemStore = defineStore(
  "institution-myecosystem",
  () => {
    const accountsStore = useAccountsStore();
    const opportunityStore = useOpportunitiesStore();
    const challengesStore = useChallengesStore();
    const appStateStore = useAppStateStore();
    const {
      isGlobalUserInfoLoading,
      isAllChallengesListLoading,
      isChallengeApplicationsLoading
    } = useLoading();
    const { errorManager } = userErrorHandling();
    const state = reactive({
      startupsAdditionalInfo: {},
      corporatesAdditionalInfo: {}
    });

    const actions = reactive({
      setAdditionalInfoStartups: async () => {
        try {
          appStateStore.actions.setStartProcess("startupsAdditionalInfo");

          if (
            isGlobalUserInfoLoading.value ||
            isChallengeApplicationsLoading.value
          ) {
            setTimeout(() => {
              actions.setAdditionalInfoStartups();
            }, 100);
            return;
          }
          const initialInfo =
            accountsStore.getters.getSponsoredStartupPerInstitution;
          if (!initialInfo || initialInfo.length === 0) {
            console.log("No startups to process.");
            return;
          }

          const infoPromises = initialInfo.map(async (startup) => {
            const startupName = accountsStore.state.startupsList.find(
              (st) => st.id == startup.companyId
            )?.name;

            const [matches, issues, users, challenges] = await Promise.all([
              getMatches([startup.companyId]),
              getStartupIssues(startup.companyId),
              getSponsorshipUser({
                companyId: startup.companyId,
                collection: "startups"
              }),
              getAllSuggestedChallenges(startup.companyId)
            ]);

            const applicationCount =
              opportunityStore.state.challengeApplications.filter(
                (app) => app.accountId === startup.companyId
              ).length;

            // Map companyId to additional info
            state.startupsAdditionalInfo[startup.companyId] = {
              startupName: startupName,
              startupMatches: matches.length,
              startupIssues: issues,
              sponsorshipUsers: users,
              suggestedChallenges: challenges,
              startupApplications: applicationCount
            };
          });

          await Promise.all(infoPromises);
        } catch (error) {
          appStateStore.actions.setError("startupsAdditionalInfo", true);
          errorManager({
            error: error,
            functionPath: "setAdditionalInfoStartups",
            userErrorMessage:
              "Unable to retrieve additional data for your sponsored startups"
          });
        } finally {
          appStateStore.actions.setLoading("startupsAdditionalInfo", false);
        }
      },
      setAdditionalInfoCorporates: async () => {
        try {
          appStateStore.actions.setStartProcess("corporatesAdditionalInfo");

          if (
            isGlobalUserInfoLoading.value ||
            isAllChallengesListLoading.value
          ) {
            setTimeout(() => {
              actions.setAdditionalInfoCorporates();
            }, 100);
            return;
          }
          const initialInfo =
            accountsStore.getters.getSponsoredCorporatePerInstitution;
          if (!initialInfo || initialInfo.length === 0) {
            console.log("No corporates to process.");
            return;
          }

          // Get sponsorship users for all corporates in parallel
          const sponsorshipUsersPromises = initialInfo.map(
            async (corporate) => {
              return await getSponsorshipUser({
                companyId: corporate.companyId,
                collection: "corporates"
              });
            }
          );
          // Get challenges for all corporates in parallel
          const challenges = initialInfo.map((corporate) =>
            challengesStore.getters.getChallengesForSponsoredCorporates.filter(
              (ch) =>
                ch.Challenge_Corporate_Sponsor__c === corporate.companyId &&
                ch.Challenge_State__c !== "Draft"
            )
          );

          // Get applications for all corporates in parallel
          const applicationsPromises = initialInfo.map(
            async (corporate) =>
              await getChallengesOpportunities({
                corporateId: corporate.companyId,
                challengesIds: challenges.flat().map((c) => c.Id),
                filters: {
                  LeadSource: "Ecosystem - Startup Application"
                }
              })
          );

          // Get suggestions count for all challenges of all corporates in parallel
          const suggestionsPromises = challenges
            .flat()
            .map(async (challenge) => {
              return {
                suggestions: await challengeToStartupSuggestionsWithoutOpps({
                  ChallengeId: challenge.Id
                }),
                corporateId: challenge.Challenge_Corporate_Sponsor__c
              };
            });

          const [
            sponsorshipUsersResponse,
            applicationsResultsResponse,
            suggestionsCountsResponse
          ] = await Promise.allSettled([
            Promise.allSettled(sponsorshipUsersPromises),
            Promise.allSettled(applicationsPromises),
            Promise.allSettled(suggestionsPromises)
          ]);
          const sponsorshipUsers = sponsorshipUsersResponse?.value.map(
            ({ value }) => value || 0
          );
          const applicationsResults = applicationsResultsResponse?.value.map(
            ({ value }) => value || { 0: [] }
          );
          const suggestionsCounts = suggestionsCountsResponse?.value.map(
            ({ value }) =>
              value || {
                suggestions: {
                  suggestionsAboveThresholdCount: 0,
                  suggestionsWithinScopeCount: 0
                }
              }
          );

          initialInfo.forEach((corporate, index) => {
            const corporateEntry = accountsStore.state.corporatesList.find(
              (corp) => corp.id == corporate.companyId
            );
            const corporateName = corporateEntry
              ? corporateEntry.name
              : "Unknown";

            state.corporatesAdditionalInfo[corporate.companyId] = {
              corporateName,
              sponsorshipUsers: sponsorshipUsers?.[index] || 0,
              applicationsReceived:
                applicationsResults?.map(
                  (apps) => Object.values(apps).flat()?.length || 0
                )?.[index] || 0,
              corporateChallenges: challenges?.[index]?.length || 0,
              allSuggestions:
                suggestionsCounts?.reduce((acc, curr) => {
                  if (curr.corporateId === corporate.companyId) {
                    acc += curr?.suggestions?.suggestionsWithinScopeCount || 0;
                  }
                  return acc;
                }, 0) || 0
            };
          });
        } catch (error) {
          appStateStore.actions.setError("corporatesAdditionalInfo", true);
          errorManager({
            error: error,
            functionPath: "setAdditionalInfoCorporates",
            userErrorMessage:
              "Unable to retrieve additional data for your sponsored corporates"
          });
        } finally {
          appStateStore.actions.setLoading("corporatesAdditionalInfo", false);
        }
      },

      reset: () => {
        state.startupsAdditionalInfo = {};
        state.corporatesAdditionalInfo = {};
      }
    });
    const getters = reactive({
      getSponsoredStartups: computed(() => {
        return (
          accountsStore.getters.getSponsoredStartupPerInstitution?.map(
            (startup) => {
              const additionalInfo =
                state.startupsAdditionalInfo[startup.companyId] || {};
              return {
                ...startup,
                ...additionalInfo
              };
            }
          ) || []
        );
      }),
      getSponsoredCorporates:
        computed(() => {
          return accountsStore.getters.getSponsoredCorporatePerInstitution?.map(
            (corporate) => {
              const additionalInfo =
                state.corporatesAdditionalInfo[corporate.companyId] || {};
              return {
                ...corporate,
                ...additionalInfo
              };
            }
          );
        }) || []
    });

    return {
      state,
      actions,
      getters
    };
  },
  {
    persist: {
      storage: encryptSessionStorage
    }
  }
);
