<template>
  <div>
    <label v-if="props.label" class="text-small text-start">
      {{ props.label }}
    </label>
    <div
      ref="searchInputContainer"
      class="mtb-search-input-container form-control"
      :class="containerClasses"
    >
      <mtb-svg
        v-if="props.hasSearchIcon"
        icon-name="search"
        :scale="6"
        :stroke-width="1"
        stroke="mtb-text"
        fill="mtb-text"
      />

      <Vue3TagsInput
        :tags="props.tags"
        :class="props.classes"
        :validate="props.validate"
        :placeholder="props.placeholder"
        class="mtb-vue-tag-input"
        @on-focus="addFocusClass"
        @on-blur="removeFocusClass"
        @on-tags-changed="onTagsChanged"
      />
      <mtb-icon
        v-if="props.hasCloseAllIcon"
        icon="close"
        :stroke-width="2"
        variant="mtb-text"
        role="button"
        scale="0.9"
        @click-applied="onClearAll"
      />
      <slot name="invalid-feedback"></slot>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import Vue3TagsInput from "vue3-tags-input";

const props = defineProps({
  placeholder: { type: String, default: "" },
  classes: { type: [String, Object], default: "" },
  containerClasses: { type: [String, Object], default: "" },
  label: { type: String, default: "" },
  tags: { type: Array, default: () => [] },
  hasCloseAllIcon: { type: Boolean, default: true },
  hasSearchIcon: { type: Boolean, default: false },
  validate: { type: [String, Function, Object], default: () => {} }
});

const emit = defineEmits(["onTagsChanged", "onClearAll"]);

const searchInputContainer = ref(null);
const addFocusClass = () => {
  searchInputContainer?.value.classList.add("focus");
};
const removeFocusClass = () => {
  searchInputContainer?.value.classList.remove("focus");
};
const onTagsChanged = (tags) => {
  emit("onTagsChanged", tags);
};
const onClearAll = () => {
  emit("onClearAll");
};
</script>

<style lang="scss" scoped></style>
