<template>
  <div class="flex flex-row items-start w-full h-full gap-6">
    <Listbox
      :model-value="selectedCategory"
      :options="props.filterCategories"
      optionLabel="name"
      class="w-1/3 custom-listbox"
      :pt="leftPt"
      @update:modelValue="handleListBoxChange"
    >
      <template #option="slotProps">
        <div>
          {{ slotProps.option?.modalLabel }}

          <span>{{
            selectedFilterOptions[slotProps.option.name]?.length > 0
              ? `(${selectedFilterOptions[slotProps.option.name]?.length})`
              : ""
          }}</span>
        </div>
      </template>
    </Listbox>

    <Listbox
      v-if="selectedCategory?.filterType === 'multiselect'"
      :modelValue="selectedFilterOptions[selectedCategory?.name]"
      @update:modelValue="(value) => updateSelectedItem(value)"
      :options="currentFilterOptions"
      filter
      multiple
      optionLabel="name"
      class="w-2/3 bg-transparent p-0"
      :pt="pt"
      listStyle="height:36vh"
    >
      <template #header>
        <div
          class="inline-flex gap-3 items-center justify-start h-full w-full order-2 border-b-[1px] border-grey3 border-solid p-4"
        >
          <base-modal-checkbox
            name="Select All"
            :isChecked="checkedSelectAll[selectedCategory?.name]"
            @onToggleCheck="selectAll"
          />
          <div class="text-black text-base">Select All</div>
        </div>
      </template>
      <template #option="slotProps">
        <div class="inline-flex gap-3">
          <base-modal-checkbox
            :isChecked="checkboxState[slotProps.option?.index]"
            :name="slotProps.option?.index"
          />
          {{ slotProps.option?.name }}
        </div>
      </template>

      <template #footer>
        <div
          class="flex shadow-mtb-scrolling px-4 py-3 justify-between items-center w-full order-4"
        >
          <base-button
            severity="primary"
            :text="true"
            label="Clear"
            @click="clearAll"
          />
          <div>
            {{ selectedFilterOptions[selectedCategory?.name]?.length || 0 }}
            selected
          </div>
        </div>
      </template>
    </Listbox>

    <div
      v-else-if="selectedCategory?.filterType === 'datePicker'"
      class="w-2/3 h-[61vh] rounded-lg border border-grey3 flex-col justify-start items-start inline-flex"
    >
      <!-- TODO: add the input search here THis is for the search box -->

      <div
        class="self-stretch grow shrink basis-0 p-4 flex overflow-y-auto h-[32vh] justify-center items-center"
      >
        <!-- TODO: add more cases here -->
        <!-- IF it is a date picker -->
        <base-table-filter-input-calendar
          v-model="selectedFilterOptions[selectedCategory?.name]"
          :value="selectedFilterOptions[selectedCategory?.name]"
        />
      </div>
      <!-- Footer -->
      <div
        class="flex shadow-mtb-scrolling px-4 py-3 justify-between items-center w-full order-4"
      >
        <base-button
          severity="primary"
          :text="true"
          label="Clear"
          @click="clearAll"
        />
        <div v-if="currentFilterOptions">
          {{ selectedFilterOptions[selectedCategory?.name]?.length || 0 }}
          selected
        </div>
      </div>
    </div>
  </div>
  <!-- Modal Footer -->
  <div class="flex flex-row items-center justify-end gap-10 w-full mt-10">
    <base-button
      class="w-auto h-full"
      label="Clear All"
      :text="true"
      severity="primary"
      @click="clearAllFilter"
    />
    <base-button
      class="w-auto"
      label="Save"
      severity="primary"
      @click="saveFilter"
    />
  </div>
</template>

<script setup>
import Listbox from "primevue/listbox";
import { ref, computed, inject, onMounted, reactive } from "vue";
import { bus } from "@/main.js";
const dialogRef = inject("dialogRef");

const props = reactive({
  //Filter category = filter fields in the left side
  filterCategories: []
});
const selectedCategory = ref();
const selectedFilterOptions = ref({});
const checkedSelectAll = ref({});

const currentFilterOptions = computed(() => selectedCategory.value?.options);

//All boolean of checkboxes
const checkboxState = computed(() => {
  return currentFilterOptions.value.reduce((acc, curr) => {
    const isSelected = Object.values(selectedFilterOptions.value)
      ?.flat()
      ?.some((ele) => {
        return ele?.index === curr?.index;
      });
    acc[curr?.index] = isSelected;

    return acc;
  }, {});
});

function clearAll() {
  selectedFilterOptions.value[selectedCategory.value?.name] = null;
  checkedSelectAll.value[selectedCategory.value?.name] = false;

  bus.emit("update-filter-from-modal", selectedFilterOptions.value);
}
function clearAllFilter() {
  selectedFilterOptions.value = {};

  checkedSelectAll.value = {};
  bus.emit("update-filter-from-modal", selectedFilterOptions.value);
}
function saveFilter() {
  bus.emit("update-filter-from-modal", selectedFilterOptions.value);
  dialogRef.value.close();
}

const selectAll = () => {
  //get index of current filter field
  const index = selectedCategory.value?.name;
  //Manually change checkbox model value
  checkedSelectAll.value[index] = !checkedSelectAll.value[index];

  //Manually assigned all value to the Listbox
  //Handle both checked and unchecked
  selectedFilterOptions.value[index] = checkedSelectAll.value[index]
    ? [...currentFilterOptions.value]
    : [];
};
const updateSelectedItem = (value) => {
  const index = selectedCategory.value?.name;
  selectedFilterOptions.value[index] = value;

  // //get index of current filter field
  // const index = selectedCategory.value?.name;

  // //get current selected option of Listbox
  // const selectedOptions = selectedFilterOptions.value[index] || [];

  // //Check if current selected value is already selected => to define if it is check or uncheck action
  // const existingIndex = selectedOptions.findIndex(
  //   (ele) => ele.index === value?.[0]?.index
  // );

  // //uncheck -> remove, check -> add
  // if (existingIndex !== -1) {
  //   selectedOptions.splice(existingIndex, 1);
  // } else {
  //   selectedFilterOptions.value[index] = [...selectedOptions, value[0]];
  // }
};
onMounted(() => {
  //Take data passed from the dynamic dialog
  const params = dialogRef.value.data;
  console.log("debug param from dynamic modal =>", params);
  //Filter category = filter fields in the left side
  props.filterCategories = params.filterCategories;

  //Assign to the first field if no preselected filter
  selectedCategory.value = props.filterCategories[0];

  //take out the currentFilterValue inside params and assign it to selectedFilterOptions
  props.filterCategories.forEach((category) => {
    const currentFilterValue = category?.currentFilterValue;

    const isSelectedAll =
      category?.options?.length === currentFilterValue?.length ? true : false;

    checkedSelectAll.value[category.name] = isSelectedAll;

    if (currentFilterValue) {
      selectedFilterOptions.value[category.name] = currentFilterValue;
      //If there is a preselected filter => assign the selected filter category to this one
      selectedCategory.value = category;
    }
  });
});

const handleListBoxChange = (value) => {
  if (value) {
    selectedCategory.value = value;
  } else {
    //NOTE: stop the deselect behavior
    return;
  }
};

const pt = _listbox;
const leftPt = _leftListBox;
</script>
<script>
const _leftListBox = {
  root: {
    class: [
      "bg-white border border-1 border-grey3 transition-colors duration-200 ease-in-out rounded-md",
      "flex flex-col items-center justify-center cursor-pointer"
    ]
  },
  wrapper: {
    class: "overflow-auto w-full order-3"
  },
  list: {
    class: "list-none m-0 divide-y divide-grey3 divide-solid gap-4 w-full"
  },
  item: ({ context, props }) => {
    return {
      class: [
        "cursor-pointer overflow-hidden relative whitespace-nowrap block p-4 items-center flex",
        "m-0 border-0  transition-shadow duration-200 rounded-none",
        {
          "text-black font-medium bg-grey4": context.selected
        }
      ]
    };
  }
};
const _listbox = {
  root: {
    class: [
      "bg-white border border-1 border-grey3 transition-colors duration-200 ease-in-out rounded-md",
      "flex flex-col items-center justify-center cursor-pointer"
    ]
  },
  wrapper: {
    class: "overflow-auto w-full p-4 order-3 "
  },
  list: {
    class: "py-3 list-none m-0 flex flex-col items-start justify-center gap-4"
  },
  item: ({ context }) => ({
    class: [
      "cursor-pointer font-normal overflow-hidden relative whitespace-nowrap items-center flex",
      "m-0 border-b-1 border-b-grey3  transition-shadow duration-200 rounded-none",
      {
        "text-primary": context.selected
      }
    ]
  }),
  header: {
    class: ["w-full order-1"]
  },
  filtercontainer: {
    class:
      "relative flex relative w-full p-4 border-b-[1px] border-grey3 border-solid order-1"
  },
  filterinput: {
    class: [
      "w-full",
      "font-sans text-base text-grey2 bg-white py-3 px-9 border border-grey3 transition duration-200 rounded-lg appearance-none",
      "hover:border-blue-500 focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] "
    ]
  },
  filtericon: {
    class: "-mt-2 absolute top-1/2 ms-2"
  }
};
</script>
<style lang="scss" scoped>
.custom-listbox {
  .p-listbox-item {
    border-bottom: 1px solid #ccc; /* Adjust the color and style as needed */
    padding: 8px; /* Adjust the padding as needed */
  }

  .p-listbox-item:last-child {
    border-bottom: none; /* Remove the border from the last item */
  }
}
</style>
