<template>
  <div class="flex align-items-center">
    <Checkbox
      :inputId="props.name?.toString()"
      :model-value="props.isChecked"
      :binary="true"
      :disabled="disabled"
      :value="props.name"
      @click="onChange"
      ><template #icon="{ checked }">
        <div
          :class="`flex items-center justify-center w-[24px] h-[24px] border-2 rounded-md ${checked ? ' border-primary' : 'border-grey3'}`"
        >
          <base-vite-icon
            v-if="checked"
            name="checkbox-selected-noborder"
            classes="w-full h-full stroke-primary stroke-2"
          />
        </div> </template
    ></Checkbox>
    <!-- <label :for="props.name" class="ml-2"> {{ props.name }} </label> -->
  </div>
</template>

<script setup>
import Checkbox from "primevue/checkbox";
const emit = defineEmits(["onToggleCheck", "update:modelValue"]);
const props = defineProps({
  isChecked: {
    type: Boolean,
    default: false
  },
  name: {
    type: [String, Boolean, Array, Number],
    default: ""
  },
  disabled: {
    type: Boolean,
    default: false
  }
});

const onChange = () => {
  emit("onToggleCheck");
};
</script>
