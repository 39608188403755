<template>
  <div v-if="isLoadingOpportunities">
    <div class="flex-flex-col gap-y-4 py-4">
      <Skeleton class="w-full h-4 mb-4"></Skeleton>
      <Skeleton class="w-full h-6 mb-4"></Skeleton>
      <Skeleton class="w-2/5 h-4"></Skeleton>
      <Skeleton class="w-1/5 h-4" v-for="n in 2" :key="n"></Skeleton>
    </div>
  </div>
  <div
    :class="props.isSidebar ? 'h-auto' : 'h-full'"
    class="overflow-y-auto py-4 hide-scrollbar flex flex-col"
    v-else-if="challengesTab && challengesTab.length !== 0"
  >
    <!--for sure we should manage the preselected tab if open in sidebar we should check the route, in the profile?-->
    <mtb-tab
      :tabs-config="challengesTab"
      classes="gap-4 mb-4 min-h-[5vh]"
      :frontSpace="false"
      :defaultIndex="preselectedChallenge.index"
      :isScrollable="true"
      @onTabChange="onTabChange"
    ></mtb-tab>

    <base-opportunity-stepper
      :challengeId="selectedChallenge"
      :startupId="startupId"
      @selected-opportunity="setSelectedOpportunity"
    />

    <base-comments-index
      :opportunityId="selectedOpportunity"
      :isSidebar="isSidebar"
      :startupId="startupId"
      :selectedChallenge="selectedChallenge"
    />
  </div>
  <div v-else class="py-4">
    <fallback-empty-state
      :isRow="false"
      classes="p-0"
      message="
        It looks like this startup hasn't been added to any challenge yet.
      "
    />
  </div>
</template>
<script setup>
import { computed, ref, watchEffect, watch } from "vue";
import { useOpportunitiesStore } from "../stores/opportunities-store.js";
import { useChallengesStore } from "../stores/challenges-store.js";
import { useLoading } from "@/global/hooks/use-loading.js";
const {
  isGlobalUserInfoLoading,
  isCorporateOpportunitiesLoading,
  isCorporateSuggestionsLoading
} = useLoading();
const emits = defineEmits(["selected-opportunity", "selected-challenge"]);
const props = defineProps({
  startupId: {
    type: String,
    required: true
  },
  fromChallenge: {
    type: String,
    default: null
  },
  isSidebar: {
    type: Boolean,
    default: false
  }
});
const selectedOpportunity = ref(null);
const opportunitiesStore = useOpportunitiesStore();
const challengesStore = useChallengesStore();
const isLoadingOpportunities = computed(() => {
  return (
    isGlobalUserInfoLoading.value ||
    isCorporateOpportunitiesLoading.value ||
    isCorporateSuggestionsLoading.value
  );
});
const myChallenges = computed(() => {
  return challengesStore.state.accountChallenges;
});
const challengesTab = computed(() => {
  const startupId = props.startupId;
  const opportunities =
    opportunitiesStore.state.challengeOpportunities.opportunities;
  const challengeOppIds =
    Object.keys(opportunities).reduce((acc, curr) => {
      const currChallengeOpportunities = opportunities[curr];
      const startupIsPresent = currChallengeOpportunities.some(
        ({ AccountId }) => AccountId === startupId
      );
      if (startupIsPresent) {
        acc.push(curr);
      }
      return acc;
    }, []) || [];
  const suggestions =
    opportunitiesStore.state.challengeOpportunities.suggestions;
  const challengeSuggIds =
    Object.keys(suggestions).reduce((acc, curr) => {
      const currChallengeSuggestions = suggestions[curr];
      const startupIsPresent = currChallengeSuggestions.some(
        ({ AccountId }) => AccountId === startupId
      );
      if (startupIsPresent) {
        acc.push(curr);
      }
      return acc;
    }, []) || [];
  const uniqueChallengesId = [
    ...new Set([...challengeOppIds, ...challengeSuggIds])
  ];

  const relatedChallenges = uniqueChallengesId
    .map((challengeId) => {
      const myChallenge = myChallenges.value?.find(
        ({ Id }) => Id == challengeId
      );
      if (!myChallenge) {
        return null;
      }
      return { label: myChallenge.Name, id: myChallenge.Id };
    })
    .filter((c) => !!c);
  return relatedChallenges || [];
});
const preselectedChallenge = computed(() => {
  return props.fromChallenge &&
    challengesTab.value.find((c) => {
      return c.id === props.fromChallenge;
    })
    ? {
        id: challengesTab.value.find((c) => {
          return c.id === props.fromChallenge;
        }).id,
        index: challengesTab.value.findIndex((c) => {
          return c.id === props.fromChallenge;
        })
      }
    : {
        id: challengesTab.value[0]?.id,
        index: 0
      };
});
const selectedChallenge = ref(preselectedChallenge.value.id);
watch(
  preselectedChallenge,
  (newValue, oldValue) => {
    if (newValue && newValue.id !== oldValue?.id) {
      selectedChallenge.value = newValue.id;
    }
  },
  { immediate: true }
);
function onTabChange(index) {
  selectedChallenge.value = challengesTab.value[index]?.id;
}
watchEffect(() => {
  const currentChallenge = myChallenges.value?.find(
    ({ Id }) => Id == selectedChallenge.value
  );
  if (!currentChallenge) return;
  emits("selected-challenge", currentChallenge);
});
function setSelectedOpportunity(data) {
  selectedOpportunity.value = data.Id;
  emits("selected-opportunity", data);
}
</script>
