<template>
  <div
    v-tooltip.bottom="
      isFavouriteListLoading
        ? 'Favorites'
        : isStartupFavourite
          ? 'Remove from Favorites'
          : 'Add to Favorites'
    "
  >
    <base-vite-icon
      @click.stop="openUpdateFavourite(props.startupId)"
      role="button"
      class="w-1/5"
      name="star"
      :classes="`${isFavouriteListLoading ? 'opacity-50 pointer-events-none' : ''}${
        isStartupFavourite ? 'fill-data3' : 'fill-none'
      } h-7 w-7 stroke-data3 stroke-2`"
    >
    </base-vite-icon>
  </div>
</template>
<script setup>
import { computed } from "vue";
import { useCorporateFavouritesStore } from "@/modules/corporates/stores/corporate-favourites-store.js";
import { useOpportunityModals } from "../hooks/use-opportunity-modals.js";
import { useLoading } from "@/global/hooks/use-loading.js";

const { openUpdateFavourite } = useOpportunityModals();
const { isFavouriteListLoading } = useLoading();
const corporateFavouritesStore = useCorporateFavouritesStore();

const props = defineProps({
  startupId: {
    type: String,
    default: ""
  }
});

//boolean that return if the props.startupId is present in at least one favourite list
const isStartupFavourite = computed(() => {
  return corporateFavouritesStore.getters.getFavouriteLists?.some(
    (favouriteList) =>
      favouriteList.startupList?.some(
        (startup) => startup.Account__c === props.startupId
      )
  );
});
</script>
