import { defineStore } from "pinia";
import { reactive } from "vue";
import { useAppStateStore } from "./app-state-store.js";
import {
  getChallengeVerticalPicklist,
  getCountryPicklist,
  getTechnologyPicklist,
  getVerticalPicklist
} from "../services/picklists-service.js";
import { encryptLocalStorage } from "@/plugins/pinia.js";

export const usePicklistStore = defineStore(
  "picklist",
  () => {
    const appStateStore = useAppStateStore();
    const state = reactive({
      verticalPicklist: null,
      challengeVerticalPicklist: null,
      countryPicklist: null,
      technologyPicklist: null
    });
    const getters = reactive({});
    const actions = reactive({
      setVerticalPicklist: async () => {
        try {
          appStateStore.actions.setStartProcess("verticalPicklist");
          const verticalPicklist = await getVerticalPicklist();
          state.verticalPicklist = verticalPicklist[
            "Industry_Verticals__c"
          ].map((el) => {
            return {
              label: el.label,
              value: el.value
            };
          });
        } catch (error) {
          appStateStore.actions.setError("verticalPicklist", true);
          console.log("####ERROR", error);
          throw new Error("Unable to load verticals picklist");
        } finally {
          appStateStore.actions.setLoading("verticalPicklist", false);
        }
      },
      setChallengeVerticalPicklist: async () => {
        try {
          appStateStore.actions.setStartProcess("challengeVerticalPicklist");
          const challengeVerticalPicklist =
            await getChallengeVerticalPicklist();
          state.challengeVerticalPicklist = challengeVerticalPicklist.reduce(
            (acc, curr) => {
              acc.push({ value: curr.value, label: curr.label });
              return acc;
            },
            []
          );
        } catch (error) {
          appStateStore.actions.setError("challengeVerticalPicklist", true);
        } finally {
          appStateStore.actions.setLoading("challengeVerticalPicklist", false);
        }
      },
      setCountryPicklist: async () => {
        try {
          appStateStore.actions.setStartProcess("countryPicklist");

          const countryPicklist = await getCountryPicklist();
          state.countryPicklist = countryPicklist["HQ_Country__c"].map((el) => {
            return {
              label: el.label,
              value: el.value
            };
          });
        } catch (error) {
          appStateStore.actions.setError("countryPicklist", true);
          console.log("####ERROR", error);
          throw new Error("Unable to load countries picklist");
        } finally {
          appStateStore.actions.setLoading("countryPicklist", false);
        }
      },
      setTechnologyPicklist: async () => {
        try {
          appStateStore.actions.setStartProcess("technologyPicklist");
          const technologyPicklist = await getTechnologyPicklist();
          state.technologyPicklist = technologyPicklist["Technologies__c"].map(
            (el) => {
              return {
                label: el.label,
                value: el.value
              };
            }
          );
        } catch (error) {
          appStateStore.actions.setError("technologyPicklist", true);
          console.log("####ERROR", error);
          throw new Error("Unable to load technologies picklist");
        } finally {
          appStateStore.actions.setLoading("technologyPicklist", false);
        }
      },

      reset: () => {
        state.verticalPicklist = null;
        state.challengeVerticalPicklist = null;
        state.countryPicklist = null;
        state.technologyPicklist = null;
      }
    });

    return {
      state,
      getters,
      actions
    };
  },
  {
    persist: {
      storage: encryptLocalStorage
    }
  }
);
