import { encryptSessionStorage } from "@/plugins/pinia.js";
import { defineStore } from "pinia";
import { reactive } from "vue";
export const useAppStateStore = defineStore(
  "app-state",
  () => {
    const state = reactive({
      isLoading: {},
      isError: {}
    });
    const getters = reactive({});

    const actions = reactive({
      setLoading: (stateName, isLoading) => {
        state.isLoading[stateName] = isLoading;
      },
      setError: (stateName, isError) => {
        state.isError[stateName] = isError;
      },
      setStartProcess: (stateName) => {
        state.isError[stateName] = false;
        state.isLoading[stateName] = true;
      },
      reset: () => {
        state.isLoading = {};
        state.isError = {};
      }
    });

    return {
      state,
      getters,
      actions
    };
  },
  {
    persist: {
      storage: encryptSessionStorage
    }
  }
);
