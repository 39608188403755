import { functionsEU } from "@plugins/firebase.js";
import { httpsCallable } from "firebase/functions";
import { default as classes } from "@share/errorClasses.js";
import validate from "validate.js";
export async function getInteractions(data) {
  try {
    const validationRules = {
      corporateId: {
        presence: { allowEmpty: false },
        type: "string"
      },
      startupId: {
        presence: { allowEmpty: false },
        type: "string"
      }
    };
    const validationErrors = validate(data, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }
    const result = await httpsCallable(functionsEU, "getInteractions")(data);
    return result.data;
  } catch (error) {
    throw error;
  }
}

export async function getTopOpportunities(data) {
  try {
    const validationRules = {
      corporateId: {
        presence: { allowEmpty: false },
        type: "string"
      }
    };
    const validationErrors = validate(data, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }
    const result = await httpsCallable(
      functionsEU,
      "handleOpportunities-getTopOpportunities"
    )(data);
    return result.data;
  } catch (error) {
    throw error;
  }
}
