<template>
  <div @click.stop>
    <Calendar
      id="calendar-24h"
      v-model="datetime24h"
      :selectionMode="props.selectionMode"
      hourFormat="24"
      dateFormat="dd/mm/yy"
      :inline="inline"
      placeholder="--/--/-- hh:mm"
      :minDate="props.minDate"
      :maxDate="props.maxDate"
      :showTime="props.showTime"
      :showButtonBar="props.showButtonBar"
    />
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import Calendar from "primevue/calendar";
const props = defineProps({
  inline: {
    type: Boolean,
    default: false
  },
  maxDate: {
    type: Object,
    default: () => null
  },
  minDate: {
    type: Object,
    default: new Date()
  },
  value: {
    type: [Array, String],
    default: () => null
  },
  reset: {
    type: Boolean,
    default: false
  },
  showTime: {
    type: Boolean,
    default: true
  },
  selectionMode: {
    type: String,
    default: "single"
  },
  showButtonBar: {
    type: Boolean,
    default: false
  }
});
const emit = defineEmits(["update:modelValue"]);
const datetime24h = ref(props.value);

watch(
  () => props.reset,
  (newVal) => {
    if (newVal === true) {
      datetime24h.value = "";
    }
  }
);
watch(
  () => props.value,
  (newVal) => {
    datetime24h.value = newVal;
  }
);
watch(datetime24h, (newVal) => {
  emit("update:modelValue", newVal);
});
</script>

<style lang="scss" scoped></style>
