import * as Yup from "yup";
import { usePicklistStore } from "@/global/stores/picklist-store.js";

// import { noCorpName } from "../validators/index";
import { computed } from "vue";
export const challengeObjectives = [
  {
    label: "Procurement",
    value: "Procurement",
    icon: "icon-challenge-procurement",
    explanation: "I want to buy the product / service."
    // explanation:
    //   "We are looking to establish commercial partnerships with startups offering technological solutions to our business needs."
  },
  {
    label: "Investment",
    value: "Investment",
    icon: "iconChallengeInvestment",
    explanation: "I want to invest money"
    // explanation:
    //   "We want to invest in a startup to obtain financial and strategic benefits."
  },
  {
    label: "Market Landscaping",
    value: "Landscaping",
    icon: "iconChallengeLandscaping",
    explanation: "I am just exploring"
    // explanation:
    //   "We want to explore the startup landscape to learn more about a technological solution and make strategic innovation decisions."
  }
];
export const geographicalArea = [
  { value: "Europe", label: "Europe" },
  { value: "North America", label: "North America" },
  { value: "South America", label: "South America" },
  { value: "Asia", label: "Asia" },
  { value: "Middle East", label: "Middle East" },
  { value: "Israel", label: "Israel" },
  { value: "Oceania", label: "Oceania" },
  { value: "Africa", label: "Africa" }
];

export const sectionHeaders = [
  { name: "Objective", subTitle: "Objective" },
  {
    name: "Basic Info",
    subTitle: "Fill out the basic informations for your new challenge"
  },
  { name: "Need & Details", subTitle: "Add key information to your challenge" },
  {
    name: "Additional Info",
    subTitle: "Add more informations to your challenge"
  }
];
//TODO: add computed here
export const challengeFieldModalConfig = computed(() => {
  const picklistStore = usePicklistStore();
  return [
    {
      label: "Objective",
      //startupLabel: "Objective",
      //startupOrder: 0,
      id: "Challenge_Objective__c",
      type: "button",
      //Right explanation
      question: "What is the primary objective of your challenge?",
      questionDescription:
        "Based on your choice we will ask you different specific questions, to support your challenge design.",
      //startup view
      visibleToStartups: true,
      //canEdit: true,
      step: 0,
      options: challengeObjectives,
      //To show base on the objective
      visibility: true,
      // breakPoint: true,
      draftValidation: Yup.string()
        .required()
        .transform((value) => (value ? value : "")),
      //TODO: double check the validation using this field
      isNotSelfValidate: true,
      fullValidation: Yup.string()
        .required()
        .transform((value) => (value ? value : "")),
      fieldMeta: {
        required: true
      }

      //strength_score: 1
    },
    {
      label: "Title",
      //startupOrder: 1,
      //startupLabel: "Title",
      id: "Name",
      type: "text",
      isNotSelfValidate: true,
      draftValidation: Yup.string()
        .required("The field is a required field")
        .min(10)
        .max(80)
        .matches(/^(?:\b\w+\b[/\W|_/g]*){2,5}$/),
      question: "What is the title of your new innovation challenge?",
      questionDescription:
        "Choose a title that will make your challenge quickly understood by your team and the startups in MTB Ecosystem evaluating to apply.",
      //TODO: double check if we need it
      help: "- Predictive maintenance and monitoring solutions for electrical storage system <br><br>- Digitalization of water cycle operations <br><br>- AR/VR solutions for training and clinical rehabilitation",
      visibleToStartups: true,
      //canEdit: true,
      step: 1,
      visibility: true,
      fullValidation: Yup.string()
        .min(10)
        .max(80)
        .test(
          "min-words",
          "This field must be at least 2 words",
          (value) => value?.split(" ").length > 1
        )
        .test(
          "max-words",
          "This field must be less than 5 words",
          (value) => value?.split(" ").length <= 5
        )
        //   .test(
        //     "no-corpName",
        //     "The field should not contain the name of your company, to ensure the challenge remains anonymous.\nThe challenge can still be saved ad draft, but cannot be opened.",
        //     (value) => noCorpName(value)
        //   )
        .required(),
      fieldMeta: {
        required: true,
        maxCharacter: 80
      }
      //strength_score: 0
    },
    {
      label: "Industry Verticals",
      //startupLabel: "Industry Verticals",
      //startupOrder: 3,
      question:
        "Which industry verticals should be considered for your search?",
      questionDescription:
        "Refine the scope of your search by considering innovative startups only from the industry verticals selected.",
      id: "Challenge_Verticals__c",
      visibleToStartups: true,
      type: "tags",
      //canEdit: true,
      step: 1,
      options: picklistStore.state.challengeVerticalPicklist?.map((ele) => ({
        label: ele.label,
        value: ele.value
      })),
      visibility: true,
      fullValidation: Yup.array()
        .min(1, "The field is a required field")
        .transform((value) => (value ? value : [])),
      fieldMeta: {
        required: true
      }

      //strength_score: 0.5
    },

    {
      label: "Geographical Scope",
      //startupLabel: "Geographical Scope",
      //startupOrder: 4,
      question: "Which is the geographical scope of your search?",
      questionDescription:
        "Refine the scope of your search by considering innovative startups only from the geographical regions selected.",
      id: "Geography__c",
      visibleToStartups: true,
      Split: ";",
      type: "tags",
      step: 1,
      // Keep the consistency with SF
      prefilledValue: geographicalArea.filter((ele) =>
        [
          "Europe",
          "North America",
          "South America",
          "Asia",
          "Middle East",
          "Israel",
          "Oceania",
          "Africa"
        ].includes(ele.value)
      ),
      options: geographicalArea,
      visibility: true,
      fullValidation: Yup.array()
        .min(1, "The field is a required field")
        .transform((value) => (value ? value : [])),
      fieldMeta: {
        required: true
      }
      //strength_score: 0.5
    },
    {
      label: "Internal Sponsor",
      //startupLabel: "Internal Sponsor",
      //startupOrder: 5,
      question:
        "Which internal team (e.g. Department, Business Unit) is responsible for the challenge?",
      questionDescription:
        "Provide the name of the internal team responsible for the challenge and shortly describe its structure and operations.",
      help: "Our PU Tubes Business Line offers customizable polyurethane tubing solutions tailored to meet your specific needs and requirements.",
      id: "Internal_Sponsor__c",
      type: "textarea",
      //canEdit: true,
      //onlyIfAvailable: true,
      step: 1,
      visibility: {
        field: "Challenge_Objective__c",
        value: ["Investment", "Procurement"]
      },
      draftValidation: Yup.string()
        .nullable()
        .notRequired()
        .when("Internal_Sponsor__c", {
          is: (value) => value?.length,
          then: (rule) => rule.max(400)
        }),
      fullValidation: Yup.string()
        .min(50)
        .max(400)
        //   .test(
        //     "no-corpName",
        //     "The field should not contain the name of your company, to ensure the challenge remains anonymous.\nThe challenge can still be saved ad draft, but cannot be opened.",
        //     (value) => noCorpName(value)
        //   )
        .required()
        .transform((value) => (value ? value : "")),
      fieldMeta: {
        required: true,
        maxCharacter: 400
      }
      //strength_score: 1
    },
    {
      label: "Current Situation",
      //startupLabel: "Current Situation",
      //startupOrder: 6,
      question:
        "What is underperforming in the current situation or not satisfactory with the implemented solution?",
      questionDescription:
        "Describe the problem you are facing in the current situation and what generated the need to search for a solution. If any, which solutions or technologies have you tried and failed to meet your expectations? Why did they fail?",
      id: "Status_Quo__c",
      type: "textarea",
      //canEdit: true,
      //onlyIfAvailable: true,

      step: 2,
      visibility: {
        field: "Challenge_Objective__c",
        value: ["Procurement"]
      },
      draftValidation: Yup.string()
        .nullable()
        .notRequired()
        .when("Status_Quo__c", {
          is: (value) => value?.length,
          then: (rule) => rule.max(400)
        }),

      fullValidation: Yup.string()
        .nullable()
        .notRequired()
        .min(30)
        .max(400)
        //   .test(
        //     "no-corpName",
        //     "The field should not contain the name of your company, to ensure the challenge remains anonymous.\nThe challenge can still be saved ad draft, but cannot be opened.",
        //     (value) => noCorpName(value)
        //   )
        .transform((value) => (value ? value : null)),
      fieldMeta: {
        required: false,
        maxCharacter: 400
      }
      //strength_score: 1
    },
    {
      label: "Suggested Solution",
      //startupLabel: "Suggested Solution",
      //startupOrder: 7,
      question:
        "Is there a suggested solution or preferred technology that should be considered?",
      questionDescription:
        "Describe an existing technological solution that you found applicable to your business need, including any specific company currently offering it. Please provide a link for each reference.",
      id: "Suggested_Solution__c",
      type: "textarea",
      //canEdit: true,
      //onlyIfAvailable: true,
      step: 2,
      visibility: {
        field: "Challenge_Objective__c",
        value: ["Procurement"]
      },
      draftValidation: Yup.string()
        .nullable()
        .notRequired()
        .when("Suggested_Solution__c", {
          is: (value) => value?.length,
          then: (rule) => rule.max(400)
        }),
      fullValidation: Yup.string().nullable().notRequired().min(0).max(400),
      fieldMeta: {
        required: false,
        maxCharacter: 400
      }
      //strength_score: 1
    },
    {
      label: "Expected Outcome",
      //startupLabel: "Expected Outcome",
      //startupOrder: 8,
      id: "Expected_Outcome__c",
      question: "What are the expected outcomes of the desired solution?",
      questionDescription:
        "Describe what an ideal implementation would look like, including the specific function the identified solution will need to perform and its most important features.",
      help: "Implementing the solution would reduce stress level of our employees and virtually eliminate sick-leaves related to it. The accumulation of ice on the windmill blades is reduced below the critical level.",
      type: "textarea",
      //canEdit: true,
      //onlyIfAvailable: true,

      step: 2,
      visibility: {
        field: "Challenge_Objective__c",
        value: ["Procurement"]
      },
      draftValidation: Yup.string()
        .nullable()
        .notRequired()
        .when("Expected_Outcome__c", {
          is: (value) => value?.length,
          then: (rule) => rule.max(400)
        }),

      fullValidation: Yup.string()
        .nullable()
        .notRequired()
        .min(3)
        .max(400)
        //   .test(
        //     "no-corpName",
        //     "The field should not contain the name of your company, to ensure the challenge remains anonymous.\nThe challenge can still be saved ad draft, but cannot be opened.",
        //     (value) => noCorpName(value)
        //   )
        .transform((value) => (value ? value : null)),
      fieldMeta: {
        required: false,
        maxCharacter: 400
      }
    },
    {
      label: "Technology Readiness Level",
      //startupLabel: "Technology Readiness Level",
      //startupOrder: 9,
      id: "min_trl__c",
      question:
        "Is there a desired minimum Technology Readiness Level of the solution?",
      questionDescription:
        "Learn more about <a class='text-info' href='https://en.wikipedia.org/wiki/Technology_readiness_level' target='_blank'>Technology Readiness Levels</a>.",
      visibleToStartups: true,
      type: "single",
      prefilledValue: {
        value: "6",
        label:
          "6 - Technology demonstrated in relevant environment (industrially relevant environment in the case of key enabling technologies)."
      },
      options: [
        { value: "1", label: "1 - Basic principles observed." },
        { value: "2", label: "2 - Technology concept formulated." },
        { value: "3", label: "3 - Experimental proof of concept." },
        { value: "4", label: "4 - Technology validated in lab." },
        {
          value: "5",
          label: "5 - Technology validated in relevant environment."
        },
        {
          value: "6",
          label:
            "6 - Technology demonstrated in relevant environment (industrially relevant environment in the case of key enabling technologies)."
        },
        {
          value: "7",
          label:
            "7 - System prototype demonstration in operational environment."
        },
        { value: "8", label: "8 - System complete and qualified." },
        {
          value: "9",
          label: "9 -  Actual system proven in operational environment."
        }
      ],
      //canEdit: true,
      //onlyIfAvailable: true,
      step: 2,
      visibility: {
        field: "Challenge_Objective__c",
        value: ["Procurement"]
      },

      fullValidation: Yup.object().nullable().notRequired(),
      fieldMeta: {
        required: false
      }
      //strength_score: 1
    },
    {
      label: "Funding Stage",
      //startupLabel: "Funding Stage",
      //startupOrder: 10,
      id: "Funding_Stage__c",
      question: "Which is the ideal funding stage of the company?",
      type: "single",
      options: [
        { value: "Bootstrap", label: "Bootstrap" },
        { value: "Seed", label: "Seed" },
        { value: "Series A", label: "Series A" },
        { value: "Series B", label: "Series B" },
        { value: "Series C", label: "Series C" },
        { value: "Series D +", label: "Series D +" },
        { value: "Acquired/Merged", label: "Acquired/Merged" },
        { value: "Post IPO", label: "Post IPO" }
      ],
      //canEdit: true,
      //onlyIfAvailable: true,

      step: 2,
      visibility: {
        field: "Challenge_Objective__c",
        value: ["Investment"]
      },

      fullValidation: Yup.object().nullable().notRequired(),
      fieldMeta: {
        required: false
      }
      //strength_score: 1
    },
    {
      label: "Investment Ticket Size",
      //startupLabel: "Investment Ticket Size",
      //startupOrder: 11,
      id: "Investment_Amount__c",
      question:
        "What is the investment ticket you are considering (approximately)?",
      questionDescription:
        "Provide the amount you are looking to invest in the company. We are not looking for the exact amount but rather a ballpark figure that can help us further refine our scouting process.",
      visibleToStartups: false,
      Split: ";",
      type: "tags",
      //canEdit: true,

      step: 2,
      options: [
        { value: "<250k", label: "<250K" },
        { value: "250k-1M", label: "250K-1M" },
        { value: "1M-5M", label: "1M-5M" },
        { value: "5M-20M", label: "5M-20M" },
        { value: "+20M", label: "+20M" }
      ],
      visibility: {
        field: "Challenge_Objective__c",
        value: ["Investment"]
      },
      fullValidation: Yup.array().nullable().notRequired(),
      fieldMeta: {
        required: false
      }
      //strength_score: 0.5
    },
    {
      label: "Investment Role",
      //startupLabel: "Investment Role",
      //startupOrder: 12,
      id: "Investment_Role__c",
      question:
        "Are you considering to lead the investment round or just participate?",
      questionDescription:
        "Describe the role you want to have in this investment.",
      visibleToStartups: false,
      Split: ";",
      type: "single",
      //canEdit: true,

      step: 2,
      options: [
        { value: "Available to lead", label: "Available to lead" },
        { value: "Just participating", label: "Just participating" },
        {
          value: "To be defined based on the target company",
          label: "To be defined based on the target company"
        }
      ],
      visibility: {
        field: "Challenge_Objective__c",
        value: ["Investment"]
      },

      fullValidation: Yup.object().nullable().notRequired(),
      fieldMeta: {
        required: false
      }
      //strength_score: 0.5
    },
    {
      label: "Requirement & Constraints",
      //startupLabel: "Requirement & Constraints",
      //startupOrder: 13,
      id: "Constraints__c",
      question:
        "Are there any requirements or constraints that would limit the applicability of a solution?",
      questionDescription:
        "Describe any specific requirement or constraint that should be considered during the scouting process.",
      help: "Operating temperature of the equipment is -20ºC, User language must include Swedish, Being able to wear it for more than 3 hours a day, Maximum cost per user 10€/month",
      visibleToStartups: true,
      type: "textarea",
      //canEdit: true,
      //onlyIfAvailable: true,

      step: 2,
      visibility: {
        field: "Challenge_Objective__c",
        value: ["Procurement"]
      },
      draftValidation: Yup.string()
        .nullable()
        .notRequired()
        .when("Constraints__c", {
          is: (value) => value?.length,
          then: (rule) => rule.max(400)
        }),

      fullValidation: Yup.string()
        .nullable()
        .notRequired()
        .min(30)
        .max(400)
        .transform((value) => (value ? value : null)),
      fieldMeta: {
        required: false,
        maxCharacter: 400
      }
      //strength_score: 1
    },
    {
      label: "Allocated Budget",
      //startupLabel: "Allocated Budget",
      //startupOrder: 14,
      question: "Have you allocated a budget for this challenge?",
      type: "radio",
      Checkboxes: [
        { value: true, label: "Yes" },
        { value: false, label: "No" }
      ],
      id: "Budget__c",
      step: 2,
      prefilledValue: false,
      //canEdit: true,
      //onlyIfAvailable: true,
      visibility: {
        field: "Challenge_Objective__c",
        value: ["Procurement"]
      },

      fullValidation: Yup.string()
        .required()
        .transform((value) => (value ? value : "")),
      fieldMeta: {
        required: true
      }
      //strength_score: 0.5
    },
    {
      label: "We want to invest in companies that offer...",
      //startupLabel: "Description",
      //startupOrder: 2,
      question:
        "What innovative solutions are you interested in for your investment?",
      questionDescription:
        "Describe the type of innovative solutions that the company you are looking to invest in should offer.",
      help: "- Solutions to create reliable digital twins for components, systems and various driving environments which will in turn enable accurate simulations, prescriptive and predictive maintenance. The solutions can be applicable for electrical energy storage systems, motor drive systems and charging solutions.<br>- Software or platform that allow for gathering and mapping of employee profiles, knowledge and competences to improve engagement, training, knowledge sharing and internal mobility.",
      visibleToStartups: true,
      id: "Challenge_Description__c",
      type: "textarea",
      //canEdit: true,

      step: 2,
      visibility: {
        field: "Challenge_Objective__c",
        value: ["Investment"]
      },
      draftValidation: Yup.string()
        .nullable()
        .notRequired()
        .when("Challenge_Description__c", {
          is: (value) => value?.length,
          then: (rule) => rule.max(450)
        }),

      fullValidation: Yup.string()
        .min(170)
        .max(450)
        .required()
        .transform((value) => (value ? value : "")),
      fieldMeta: {
        required: true,
        maxCharacter: 450
      }
      //strength_score: 1
    },
    {
      label: "We are scouting for companies that offer...",
      //startupLabel: "Description",
      //startupOrder: 2,
      question:
        "Which innovative solutions are you looking for to address your business need?",
      questionDescription:
        "Describe the business needs you are currently facing and the type of innovative solutions you are looking for to address it.",
      help: "- Solutions to create reliable digital twins for components, systems and various driving environments which will in turn enable accurate simulations, prescriptive and predictive maintenance. The solutions can be applicable for electrical energy storage systems, motor drive systems and charging solutions.<br>- Software or platform that allow for gathering and mapping of employee profiles, knowledge and competences to improve engagement, training, knowledge sharing and internal mobility.",
      visibleToStartups: true,
      id: "Challenge_Description__c",
      type: "textarea",
      //canEdit: true,

      step: 2,
      visibility: {
        field: "Challenge_Objective__c",
        value: ["Procurement"]
      },
      draftValidation: Yup.string()
        .nullable()
        .notRequired()
        .when("Challenge_Description__c", {
          is: (value) => value?.length,
          then: (rule) => rule.max(450)
        }),

      fullValidation: Yup.string()
        .min(170)
        .max(450)
        .required()
        .transform((value) => (value ? value : "")),
      fieldMeta: {
        required: true,
        maxCharacter: 450
      }
      //strength_score: 1
    },
    {
      label: "We are interested in learning more about...",
      //startupLabel: "Description",
      //startupOrder: 2,
      question:
        "What are the innovation trends, solutions and technologies you are interested in?",
      questionDescription:
        "Describe the innovation trends, solutions, and technologies you are interested to learn more about. The market landscaping challenges are created to better understand your business needs, map the current innovation landscape and support your colleagues narrowing down the scope of the search for specific solutions.",
      help: "- Metaverse technologies for remote team collaboration<br>- Robotics for field worker safety<br>- AI tools for the finance and accounting department of large corporates<br>- Satellite connection for IOT devices in remote areas<br>- BIM solutions for HVAC installments in commercial buildings ",
      visibleToStartups: true,
      id: "Challenge_Description__c",
      type: "textarea",
      //canEdit: true,

      step: 2,
      visibility: {
        field: "Challenge_Objective__c",
        value: ["Landscaping"]
      },
      draftValidation: Yup.string()
        .nullable()
        .notRequired()
        .when("Challenge_Description__c", {
          is: (value) => value?.length,
          then: (rule) => rule.max(450)
        }),

      fullValidation: Yup.string()
        .min(170)
        .max(450)
        .required()
        .transform((value) => (value ? value : "")),
      fieldMeta: {
        required: true,
        maxCharacter: 450
      }
      //strength_score: 1
    },
    {
      label: "Other information",
      //startupLabel: "Other information",
      //startupOrder: 15,
      question: "Any other information regarding the challenge?",
      questionDescription:
        "Please add any relevant additional information that can support our scouting process in finding the most suitable solution to your business need.",
      id: "Additional_Information__c",
      type: "textarea",
      //canEdit: true,
      //onlyIfAvailable: true,
      step: 3,
      visibility: true,
      draftValidation: Yup.string()
        .nullable()
        .notRequired()
        .when("Additional_Information__c", {
          is: (value) => value?.length,
          then: (rule) => rule.max(450)
        }),

      fullValidation: Yup.string().nullable().notRequired().min(0).max(450),
      fieldMeta: {
        required: false,
        maxCharacter: 450
      }
      //strength_score: 0.5
    },
    {
      label: "Why work with us?",
      //startupLabel: "Why work with us?",
      //startupOrder: 16,
      id: "Your_Added_Value__c",
      question: "What value can you bring to the startup apart from money?",
      questionDescription:
        "Why should the startup work with you to further develop its product/service? Why should they invest time in a collaboration?",
      help: "-Large customer base <br><br>-Specific industry expertise <br><br>-Visibility and credibility <br><br>-Decision-making experience at scale",
      visibleToStartups: true,
      type: "textarea",
      //canEdit: true,
      //onlyIfAvailable: true,
      step: 3,
      visibility: {
        field: "Challenge_Objective__c",
        value: ["Procurement", "Investment"]
      },
      draftValidation: Yup.string()
        .nullable()
        .notRequired()
        .when("Your_Added_Value__c", {
          is: (value) => value?.length,
          then: (rule) => rule.max(400)
        }),

      fullValidation: Yup.string().nullable().notRequired().min(0).max(400),
      fieldMeta: {
        required: false,
        maxCharacter: 400
      }
      //strength_score: 1.5
    },
    {
      label: "Expiration Date",
      //startupLabel: "Expiration Date",
      //startupOrder: 17,
      id: "Expiration_Date__c",
      question: "When is this challenge expiring?",
      questionDescription:
        "After the selected date, the challenge will be automatically closed. To re-open it, you will need to review it.",
      notes:
        "At expiration Date, all the startups suggested for this challenge that still have not received an evaluation will be closed as 'No Feedback'",
      visibleToStartups: true,
      type: "date",
      //canEdit: true,
      //onlyIfAvailable: true,
      step: 3,
      visibility: true,
      fullValidation: Yup.date()
        .required()
        .min(new Date(), "Your Challenge is expired"),
      fieldMeta: {
        required: true
      },
      options: [
        { value: "1", name: "1 month" },
        { value: "3", name: "3 months" },
        { value: "6", name: "6 months" },
        { value: "12", name: "12 months" }
      ]
      //strength_score: 1
    }
  ];
});
