import { functionsEU } from "@plugins/firebase.js";
import { httpsCallable } from "firebase/functions";
import { default as classes } from "@share/errorClasses.js";
import validate from "validate.js";
export async function getStartupIssues(startupId) {
  try {
    //validation
    const validationRules = {
      startupId: {
        presence: { allowEmpty: false },
        type: "string"
      }
    };
    const validationErrors = validate({ startupId }, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }

    const httpsCallableFunction = httpsCallable(
      functionsEU,
      "getStartupIssues"
    );

    const payload = {
      startupId
    };
    const response = await httpsCallableFunction(payload);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getMatches(startupIds) {
  try {
    //validation
    const validationRules = {
      startupIds: {
        presence: { allowEmpty: false },
        type: "array"
      }
    };
    const validationErrors = validate({ startupIds }, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }

    const httpsCallableFunction = httpsCallable(functionsEU, "getMatches");

    const payload = {
      startupsList: startupIds
    };
    const response = await httpsCallableFunction(payload);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getSponsorshipUser(data) {
  try {
    //validation
    const validationRules = {
      "data.companyId": {
        presence: { allowEmpty: false },
        type: "string"
      },
      "data.collection": {
        presence: { allowEmpty: false },
        type: "string",
        inclusion: {
          within: ["startups", "corporates"]
        }
      }
    };
    const validationErrors = validate({ data }, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }

    const httpsCallableFunction = httpsCallable(
      functionsEU,
      "getSponsorshipUserV3"
    );

    const response = await httpsCallableFunction(data);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getAllSuggestedChallenges(startupId) {
  try {
    //validation
    const validationRules = {
      startupId: {
        presence: { allowEmpty: false },
        type: "string"
      }
    };
    const validationErrors = validate({ startupId }, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }

    const httpsCallableFunction = httpsCallable(
      functionsEU,
      "RialtoFlow-S2C_MatchingQuery"
    );

    const payload = {
      count: true,
      AccountId: startupId
    };
    const response = await httpsCallableFunction(payload);
    return response.data.suggestionsAboveThresholdCount;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
