<template>
  <div class="flex flex-column items-start gap-2">
    <div class="flex flex-row justify-between items-center w-full">
      <span class="text-sm text-black font-medium capitalize">{{
        props.label
      }}</span>
      <slot />
    </div>
    <InputGroup>
      <InputText
        placeholder="DD/MM/YYYY"
        type="text"
        :disabled="true"
        :id="`${name}_${contextUuid}`"
        v-model="formattedDate"
        :pt="{
          root: ({ props, context }) => ({
            class: [
              'm-0 w-[calc(100%-6rem)]',
              'text-black border-1 border-grey3 rounded-l-lg transition-colors duration-200 appearance-none pointer-events-none bg-transparent',

              {
                'text-lg px-4 py-4': props.size == 'large',
                'text-xs px-2 py-2': props.size == 'small',
                'p-3 text-base': props.size == null
              }
            ]
          })
        }"
      />
      <Dropdown
        :options="props.dropdownOptions"
        optionLabel="name"
        placeholder="Set to..."
        :disabled="false"
        @blur="setTouched(true)"
        @change="addExpirationDate($event.value)"
        :pt="{
          root: {
            class: [
              'cursor-pointer inline-flex relative select-none bg-white transition-colors duration-200 ease-in-out rounded-r-lg p-3 border border-grey2 w-[6rem]'
            ]
          },
          input: {
            class: [
              'cursor-pointer block flex flex-auto relative bg-transparent border-0 text-grey2 transition duration-200 bg-transparent appearance-none font-normal text-small focus:outline-none focus:shadow-none'
            ]
          },
          trigger: { class: 'w-auto' }
        }"
      >
        <template #dropdownicon>
          <base-vite-icon
            class=""
            name="chevron-down"
            classes="order-0 fill-none stroke-grey2 stroke-2 w-4 h-4"
          />
        </template>
      </Dropdown>
    </InputGroup>
    <small
      v-if="isInvalid"
      class="text-alert inline-flex items-center gap-1"
      id="text-error"
    >
      <base-vite-icon name="close" classes="w-3.5 h-3.5 stroke-2" />
      {{ errorMessage }}</small
    >
  </div>
</template>

<script setup>
import InputGroup from "primevue/inputgroup";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
// import { DateTime } from "luxon";
import { computed, toRef, onMounted, toRefs, watch } from "vue";
import { addMonths } from "date-fns";
import { useField } from "vee-validate";
import { v4 as uuidv4 } from "uuid";
const contextUuid = uuidv4();

const emit = defineEmits(["update:modelValue"]);

const props = defineProps({
  label: {
    type: String,
    default: ""
  },
  name: {
    type: String,
    default: ""
  },
  value: {
    type: String,
    default: ""
  },
  dropdownOptions: {
    type: Object,
    default: null
  },
  isDraftValidated: {
    type: Boolean,
    default: false
  }
});

const { name, value, isDraftValidated } = toRefs(props);

const {
  value: inputValue,
  errorMessage,
  meta,
  setTouched
} = useField(name, undefined, {
  initialValue: value,
  validateOnMount: true
});

//ONLY SHOW THE ERROR MESSAGE IF
//1. field is dirty and has a error message
//2. A Save draft
const isInvalid = computed(() => {
  return errorMessage.value && (meta.touched || isDraftValidated.value);
});

//TODO: Move to a helper function
const formattedDate = computed(() => {
  //TODO: Move to localization to the store
  const localization = Intl.DateTimeFormat().resolvedOptions();

  return new Date(inputValue.value).toLocaleDateString(localization.locale, {
    day: "numeric",
    month: "short",
    year: "numeric"
  });
});

watch(inputValue, (newVal) => {
  emit("update:modelValue", newVal);
});

const addExpirationDate = (value) => {
  // const months = value.value;
  // const startDate = new Date();
  // const endDateFormatted = addMonths(startDate, months);

  const modelValue = _addExpirationDate(value.value);
  inputValue.value = modelValue;
};
onMounted(() => {
  //when date input gropup was mounted
  //if exist a initial value do nothing
  if (inputValue.value !== "") {
    return;
  }
  //else set initial value (12 months)
  addExpirationDate(props.dropdownOptions[3]);
});
</script>
<script>
export const _addExpirationDate = (value) => {
  const months = value;
  const startDate = new Date();
  const endDateFormatted = addMonths(startDate, months);
  const modelValue = endDateFormatted.toISOString();
  return modelValue;
};
</script>

<style lang="scss" scoped></style>
