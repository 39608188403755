import { onBeforeUnmount, onMounted, ref } from "vue";
import { default as support } from "@share/commonSupport.js";
//isSelfIncluded: true = the component considered as outside
export default function useDetectOutsideClick(
  component,
  callback,
  options = {
    isClickBlocked: false,
    isApexChartElement: false
  }
) {
  if (!component) return;

  const listener = (event) => {
    const isSelfClicked = ref(false);
    //Only use for apex chart
    if (options.isApexChartElement) {
      if (
        (["path", "rect"].includes(event.target.tagName) &&
          event.composedPath().includes(component.value)) ||
        (event.target == component.value &&
          event.composedPath().includes(component.value))
      ) {
        isSelfClicked.value = true;
      } else {
        isSelfClicked.value = false;
      }
    }

    //Using for other components
    if (
      !options.isApexChartElement &&
      event.target !== component.value &&
      event.composedPath().includes(component.value)
    ) {
      isSelfClicked.value = true;
    }

    if (isSelfClicked.value) {
      return;
    } else {
      if (typeof callback === "function") {
        callback();
      }
    }
  };
  onMounted(() => {
    //some conflict on click
    window.addEventListener("mousedown", listener);
    window.addEventListener("touchend", listener);
  });
  onBeforeUnmount(() => {
    window.removeEventListener("mousedown", listener);
    window.removeEventListener("touchend", listener);
  });

  return { listener };
}
