export const featuredListMapping = {
  Events: {
    parentClasses: "bg-data3-light text-data3",
    label: "Event"
  },
  Partner: {
    parentClasses: "bg-data1-light text-data1",
    label: "Partner"
  },
  Research: {
    parentClasses: "bg-data6-light text-data6",
    label: "MTB Curated"
  },
  default: {
    parentClasses: "bg-data5-light text-data5",
    label: ""
  }
};
