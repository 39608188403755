import { defineRule } from "vee-validate";
import { configure } from "vee-validate";
import { localize } from "@vee-validate/i18n";
import { required, email, min, max, url } from "@vee-validate/rules";
import { differenceInMilliseconds, parseISO } from "date-fns";

configure({
  // Generates an English message locale generator
  generateMessage: localize("en", {
    messages: {
      required: "This field is required",
      max: "This field must be 0:{max} characters or fewer.",
      min: "This field must be 0:{min} characters or higher.",
      email: "Please enter a valid email address.",
      url: "Please enter a valid url link."
    }
  })
});

defineRule("required", required);
defineRule("email", email);
defineRule("url", url);
defineRule("min", min);
defineRule("max", max);
defineRule("maxRichText", (value, [limit]) => {
  if (!value || !value.length) {
    return true;
  }
  if (formatRichTextToPlainText(value).length > limit) {
    return `This field must be less than ${limit} characters`;
  }
  return true;
});
defineRule("minRichText", (value, [limit]) => {
  if (!value || !value.length) {
    return true;
  }
  if (formatRichTextToPlainText(value).length < limit) {
    return `This field must be at least ${limit} characters`;
  }
  return true;
});
defineRule("max-words", (value, [limit]) => {
  // Field is empty, should pass
  if (!value || !value.length) {
    return true;
  }
  if (value.length > limit) {
    return `This field must be less than ${limit} characters`;
  }
  return true;
});
defineRule("linkedin", (value) => {
  const linkedinPattern =
    /^((https?:\/\/)?((www|\w\w)\.)?linkedin\.com\/)((([\w]{2,3})?)|([^/]+\/(([\w|\d-&#?=])+\/?){1,}))$/;
  if (!value || !value.length) {
    return true;
  }
  if (value && !linkedinPattern.test(value)) {
    return `This field must be a valid Linkedin url`;
  }
  return true;
});
defineRule("website", (value) => {
  const websitePattern =
    /^(?:https?:\/\/)?(?:www\.)?[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_.~#?&/=]*)$/;
  if (!value || !value.length) {
    return true;
  }
  if (value && !websitePattern.test(value)) {
    return `This field must be a valid website url`;
  }
  return true;
});
defineRule("min-words", (value, [limit]) => {
  // Field is empty, should pass
  if (!value || !value.length) {
    return true;
  }
  if (value.split(" ").length < limit) {
    return `This field must be at least ${limit} words`;
  }
  return true;
});

defineRule("max-words", (value, [limit]) => {
  // Field is empty, should pass
  if (!value || !value.length) {
    return true;
  }
  if (value.split(" ").length > limit) {
    return `This field must be less than ${limit} words`;
  }
  return true;
});

defineRule("checkboxExtra", (value, [target], ctx) => {
  if (
    !value ||
    value === "No" ||
    (value === "Yes" &&
      (ctx.form[target]?.length === 0 ||
        (ctx.form[target]?.length >= 2 && ctx.form[target]?.length <= 400)))
  ) {
    return true;
  }
  return "Please fill out the extra question";
});
defineRule("dateExpiration", (value) => {
  const todayDate = new Date();
  const expirationDate = parseISO(value);
  const timeLeft = differenceInMilliseconds(expirationDate, todayDate);

  if (timeLeft >= 0) {
    return true;
  }

  return "The date is expired";
});

import { setLocale } from "yup";
import { formatRichTextToPlainText } from "@/global/helpers/text";

setLocale({
  string: {
    min: "This field must be ${min} characters or higher.",
    max: "This field must be ${max} characters or fewer."
  }
});
