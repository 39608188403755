import { reactive, computed } from "vue";
import { defineStore } from "pinia";

//OTHER STORES IMPORT
import { useUserAuthStore } from "./user-auth-store.js";
import { useAppStateStore } from "./app-state-store.js";
import { useChallengesStore } from "./challenges-store.js";

//SERVICES IMPORT
import {
  getChallengesOpportunities,
  getStartupOpportunities,
  setOpportunity
} from "../services/opportunities-service.js";
import { getCorporateMeetings } from "@global/services/meetings-service.js";
import { challengeToStartupSuggestions } from "../services/rialto-service.js";
import { getChallengeApplicationPerStartup } from "@/global/services/opportunities-service.js";

import { userErrorHandling } from "@global/hooks/use-error-handling.js";
import { default as stageMapping } from "@share/stageMappingConfig.js";
import { formatMeetingObject } from "@global/helpers/formatObject.js";
import {
  getYCBMCalendarCorporate,
  getYCBMCalendarStartup
} from "../services/meetings-service.js";
import { default as constants } from "@share/envConstant.js";
export const useOpportunitiesStore = defineStore("opportunities", () => {
  const userAuthStore = useUserAuthStore();
  const appStateStore = useAppStateStore();
  const challengeStore = useChallengesStore();
  const { errorManager } = userErrorHandling();

  const state = reactive({
    challengeOpportunities: {
      opportunities: {},
      suggestions: {}
    },
    challengeApplications: [],
    startupAllOpportunities: [],
    allMatchingCalendars: [],
    matchingCalendarsPerCorporate: [],
    corporateMeetings: null
  });
  const getters = reactive({
    getCorporateMeetings: computed(() => {
      if (!state.corporateMeetings) {
        return [];
      }

      return state.corporateMeetings.reduce(
        (
          acc,
          {
            Id,
            Account,
            YCBM_Id__c,
            YCBM_Subdomain__c,
            Ecosystem_Meeting_Date__c,
            Ecosystem_Meeting_Link__c,
            Related_Challenge__c,
            Related_Challenge__r,
            StageName,
            Startup_Opportunity_Status__c,
            LastModifiedDate
          }
        ) => {
          acc.push({
            ...formatMeetingObject({
              Id,
              YCBM_Id__c,
              YCBM_Subdomain__c,
              Ecosystem_Meeting_Date__c,
              Ecosystem_Meeting_Link__c,
              StageName,
              Startup_Opportunity_Status__c,
              LastModifiedDate,
              Account,
              Related_Challenge__r,
              Related_Challenge__c
            })
            // AccountId: Account.Id,
            // AccountName: Account.Name,
            // Short_Description__c: Account.Short_Description__c,
            // Related_Challenge: {
            //   name: Related_Challenge__r?.Name || "No Challenge",
            //   index: Related_Challenge__c
            // },
            // AccountDetail: {
            //   name: Account.Name,
            //   index: Account.Id
            // }
            // Id,
            // YCBM_Id__c,
            // YCBM_Subdomain__c,
            // Ecosystem_Meeting_Date__c: Ecosystem_Meeting_Date__c || null,
            // TimeScope: checkDateStatus(Ecosystem_Meeting_Date__c),
            // Ecosystem_Meeting_Link__c,
            // StageName,
            // Startup_Opportunity_Status__c,
            // LastModifiedDate
            // matchingCalendarStatus: calendar?.status || null
          });

          return acc;
        },
        []
      );
    }),
    getStartupMeetings: computed(() => {
      if (!state.startupAllOpportunities) {
        return [];
      }
      //From Matched, met,...
      const meetingStages = [
        ...stageMapping.stageMap.meeting.includedStage,
        ...stageMapping.stageMap.evaluation.includedStage
      ];

      return Object.values(state.startupAllOpportunities)
        .flat()
        .reduce(
          (
            acc,
            {
              InterestedCompAcc__r,
              Id,
              YCBM_Id__c,
              YCBM_Subdomain__c,
              Ecosystem_Meeting_Date__c,
              Ecosystem_Meeting_Link__c,
              Related_Challenge__c,
              Related_Challenge__r,
              StageName,
              Startup_Opportunity_Status__c
            }
          ) => {
            if (meetingStages.includes(StageName)) {
              acc.push({
                ...formatMeetingObject(
                  {
                    Id,
                    YCBM_Id__c,
                    YCBM_Subdomain__c,
                    Ecosystem_Meeting_Date__c,
                    Ecosystem_Meeting_Link__c,
                    StageName,
                    Startup_Opportunity_Status__c,
                    Account: InterestedCompAcc__r,
                    Related_Challenge__r,
                    Related_Challenge__c
                  },
                  state.allMatchingCalendars
                )
              });
            }

            return acc;
          },
          []
        );
    }),
    getStartupKanbanMeeting: computed(() => {
      if (
        !state.startupAllOpportunities ||
        state.startupAllOpportunities.length == 0
      ) {
        return [];
      }
      //From Matched, met,...
      const meetingStages = [
        ...stageMapping.startupkanbanColumnMap.meeting.includedStage
      ];
      return (
        Object.values(state.startupAllOpportunities)
          .flat()
          .reduce((acc, curr) => {
            if (meetingStages.includes(curr.StageName)) {
              acc = [
                ...acc,
                {
                  ...formatMeetingObject(
                    {
                      ...curr,
                      Account: curr.InterestedCompAcc__r
                    },
                    state.allMatchingCalendars,
                    "kanban"
                  )
                }
              ];
            }
            return acc;
          }, []) || []
      );
    }),
    getStartupKanbanEvaluation: computed(() => {
      if (!state.startupAllOpportunities) {
        return [];
      }
      const evaluationStages = [
        ...stageMapping.startupkanbanColumnMap.evaluation.includedStage
      ];
      return Object.values(state.startupAllOpportunities)
        .flat()
        .reduce((acc, curr) => {
          if (evaluationStages.includes(curr.StageName)) {
            acc = [
              ...acc,
              {
                ...curr,
                companyId: curr.InterestedCompAcc__r.Id,
                companyName: curr.InterestedCompAcc__r.Name,
                challengeId: curr.Related_Challenge__c,
                challengeName: curr.Related_Challenge__r.Name,
                stageName: curr.StageName
              }
            ];
          }
          return acc;
        }, []);
    })
  });

  const actions = reactive({
    //all opps per challenge, included rialto suggestions
    //challenges list [{Id:challengeId}] //required
    setChallengesOpportunities: async (challenges, blockLoading) => {
      const publishedChallenges = challenges.filter((c) => {
        return c.Challenge_State__c !== "Draft";
      });
      const openChallenges = challenges.filter((c) => {
        return c.Challenge_State__c == "Open";
      });
      const promisesOpportunities =
        publishedChallenges.length !== 0
          ? actions.getChallengesOpportunitiesPromise(
              publishedChallenges,
              blockLoading
            )
          : Promise.resolve({});

      const promisesSuggestions =
        openChallenges.length !== 0
          ? actions.getChallengesSuggestionsPromise(
              openChallenges,
              blockLoading
            )
          : Promise.resolve({});
      //AWAIT IS NEEDED
      return await Promise.allSettled([
        promisesOpportunities,
        promisesSuggestions
      ]);
    },
    async getChallengesOpportunitiesPromise(challenges, blockLoading) {
      try {
        appStateStore.actions.setStartProcess(`globalChallengeOpportunities`);
        const accountId = userAuthStore.state.accountInfo?.id;
        if (!accountId) {
          return;
        }
        const recordIdsChunks = chunkArray(challenges, 20);

        let delay = 0;
        const delayIncrement = 200;

        const promises = recordIdsChunks.map(
          (chunk) =>
            new Promise((resolve) => {
              setTimeout(() => {
                chunk.forEach((c) => {
                  if (!blockLoading) {
                    appStateStore.actions.setStartProcess(
                      `challengeOpportunities_${c.Id}`
                    );
                  }
                });

                getChallengesOpportunities({
                  challengesIds: chunk.map((c) => c.Id),
                  corporateId: accountId
                })
                  .then((results) => {
                    const tmpOpportunities = {};

                    results.forEach((result) => {
                      if (!tmpOpportunities[result.Related_Challenge__c]) {
                        tmpOpportunities[result.Related_Challenge__c] = [];
                      }
                      tmpOpportunities[result.Related_Challenge__c].push({
                        Short_Description__c:
                          result.Account.Short_Description__c,
                        AccountName: result.Account.Name,
                        AccountId: result.Account.Id,
                        Id: result.Id,
                        StageName: result.StageName,
                        LeadSource: result.LeadSource,
                        Related_Challenge__c: result.Related_Challenge__c,
                        Startup_Opportunity_Status__c:
                          result.Startup_Opportunity_Status__c,
                        Submission_Date__c: result.Submission_Date__c,
                        YCBM_Id__c: result.YCBM_Id__c,
                        YCBM_Subdomain__c: result.YCBM_Subdomain__c,
                        Ecosystem_Meeting_Date__c:
                          result.Ecosystem_Meeting_Date__c,
                        Ecosystem_Meeting_Link__c:
                          result.Ecosystem_Meeting_Link__c
                      });
                      state.challengeOpportunities.opportunities[
                        result.Related_Challenge__c
                      ] = tmpOpportunities[result.Related_Challenge__c];
                    });
                  })
                  .catch((error) => {
                    appStateStore.actions.setError(
                      "globalChallengeOpportunities",
                      true
                    );
                    chunk.forEach((c) =>
                      appStateStore.actions.setError(
                        `challengeOpportunities_${c.Id}`,
                        true
                      )
                    );
                  })
                  .finally(() => {
                    chunk.forEach((c) =>
                      appStateStore.actions.setLoading(
                        `challengeOpportunities_${c.Id}`,
                        false
                      )
                    );
                    resolve();
                  });
              }, delay);
              delay += delayIncrement;
            })
        );

        await Promise.all(promises);
      } catch (error) {
        errorManager({
          error,
          functionPath: "getChallengesOpportunitiesPromise",
          userErrorMessage: "Cannot load opportunities for the challenges"
        });
        throw error;
      } finally {
        appStateStore.actions.setLoading(`globalChallengeOpportunities`, false);
      }
    },

    getChallengesSuggestionsPromise: async (challenges, blockLoading) => {
      try {
        appStateStore.actions.setStartProcess(`globalChallengeSuggestions`);
        return await challenges.map(async (challenge) => {
          try {
            if (!blockLoading) {
              appStateStore.actions.setStartProcess(
                `challengeSuggestions_${challenge.Id}`
              );
            }
            const relatedResults = await challengeToStartupSuggestions({
              ChallengeId: challenge.Id,
              interestedFields: ["Name", "Id"]
            });

            state.challengeOpportunities.suggestions[challenge.Id] =
              relatedResults.suggestions.map((suggestion) => ({
                AccountName: suggestion.Name,
                AccountId: suggestion.Id,
                Id: null,
                StageName: "MTB Selected",
                LeadSource: "Ecosystem - Rialto Suggestion",
                Submission_Date__c: suggestion.updatedDate,
                Related_Challenge__c: challenge.Id,
                score: suggestion.score,
                warnings: suggestion.warnings
              }));
          } catch (error) {
            appStateStore.actions.setError(
              `challengeSuggestions_${challenge.Id}`,
              false
            );
          } finally {
            appStateStore.actions.setLoading(
              `challengeSuggestions_${challenge.Id}`,
              false
            );
          }
        });
      } catch (error) {
        errorManager({
          error,
          functionPath: "getChallengesSuggestionsPromise",
          userErrorMessage: "Cannot load suggestions for the challenges"
        });
        throw error;
      } finally {
        appStateStore.actions.setLoading(`globalChallengeSuggestions`, false);
      }
    },
    setStartupsOpportunities: async (data, forceLoading = true) => {
      try {
        if (forceLoading) {
          appStateStore.actions.setStartProcess("challengeApplications");
        }

        const result = await Promise.all(
          data.map(async (ele) => {
            const result = await getChallengeApplicationPerStartup(
              ele.companyId
            );
            return result;
          })
        );
        state.challengeApplications = result.flat();
      } catch (error) {
        appStateStore.actions.setError("challengeApplications", true);
        console.error("Failed to load startup applications", error);
        errorManager({
          error,
          functionPath: "setStartupsOpportunities",
          userErrorMessage: "Failed to load startup applications"
        });
      } finally {
        appStateStore.actions.setLoading("challengeApplications", false);
      }
    },
    setStartupAllOpportunities: async (forceLoading = true) => {
      try {
        if (forceLoading) {
          appStateStore.actions.setStartProcess("startupAllOpportunities");
        }
        const accountId = userAuthStore.state.accountInfo?.id;
        if (!accountId) {
          return;
        }
        const result = await getStartupOpportunities(accountId);

        state.startupAllOpportunities = result.map((account) => ({
          ...account,
          AccountId: accountId
        }));
      } catch (error) {
        console.error("Failed to load startup applications");
        errorManager({
          error,
          functionPath: "setStartupsOpportunities",
          userErrorMessage: "Failed to load startup opportunities"
        });
      } finally {
        appStateStore.actions.setLoading("startupAllOpportunities", false);
      }
    },
    setAllMatchingCalendarsCorporate: async () => {
      try {
        appStateStore.actions.setStartProcess("matchingCalendars");
        const result = await getYCBMCalendarCorporate(
          userAuthStore.state.accountInfo.id
        );
        state.matchingCalendarsPerCorporate = result;
      } catch (error) {
        errorManager({
          error,
          functionPath: "setAllMatchingCalendars",
          userErrorMessage: "Failed to load matching calendars"
        });
      } finally {
        appStateStore.actions.setLoading("matchingCalendars", false);
      }
    },
    setAllMatchingCalendars: async () => {
      try {
        appStateStore.actions.setStartProcess("matchingCalendars");
        const result = await getYCBMCalendarStartup();
        state.allMatchingCalendars = result;
      } catch (error) {
        errorManager({
          error,
          functionPath: "setAllMatchingCalendars",
          userErrorMessage: "Failed to load matching calendars"
        });
      } finally {
        appStateStore.actions.setLoading("matchingCalendars", false);
      }
    },
    setLocalChallengeOpportunity: (data) => {
      state.challengeOpportunities.opportunities[data.challengeId] =
        data.opportunities;
      state.challengeOpportunities.suggestions[data.challengeId] =
        data.suggestions;
    },
    setUpdateSalesforceOpportunity: async (data) => {
      try {
        const Operation = data.opportunity.Id ? "update" : "create";
        const accountInfo = userAuthStore.state.accountInfo;
        const userInfo = userAuthStore.state.userInfo;
        return await setOpportunity({
          ...(Operation === "update" && { Id: data.opportunity.Id }),
          StageName: data.opportunity.StageName,
          ...(Operation === "create" && {
            LeadSource: data.opportunity.LeadSource,
            AccountId: data.opportunity.AccountId,
            AccountName: data.opportunity.AccountName,
            CorpName: accountInfo?.name,
            CorpId: accountInfo?.id,
            ChallId: data.opportunity.Related_Challenge__c,
            OwnerId: userInfo?.Claims?.mtb
              ? userInfo?.Claims?.SFUID || "0052E00000Kgt2VQAR"
              : "0052E00000Kgt2VQAR"
          }),
          Operation,
          messages: data.messages,
          tier: userAuthStore.state.accountInfo?.tier,
          notify: data.notify,
          isDemo:
            userAuthStore.getters.isDemoAccount ||
            constants.CORPORATE_TEST_DEMO_ACCOUNTS.includes(
              userAuthStore.state.userInfo.AccountId
            ),
          allowWrite: accountInfo?.id
        });
      } catch (error) {
        errorManager({
          error,
          functionPath: "setUpdateSalesforceOpportunity",
          userErrorMessage: "Cannot save the opportunity"
        });
      } finally {
        //UPDATE store with database opps
        actions.setChallengesOpportunities(
          [
            {
              Id: data.opportunity.Related_Challenge__c,
              Challenge_State__c: "Open"
            }
          ],
          true
        );
      }
    },
    pollOpportunities: async (challengeIds) => {
      await actions.getChallengesOpportunitiesPromise(
        challengeIds.map((e) => ({ Id: e })),
        true
      );
    },
    pollSuggestions: async (challengeIds) => {
      await actions.getChallengesSuggestionsPromise(
        challengeIds.map((e) => ({ Id: e })),
        true
      );
    },
    setCorporateMeetings: async (data) => {
      try {
        appStateStore.actions.setStartProcess(`corporateMeetings`);

        let limit = data.limit || 30;
        let offset = 0;
        let meetings = [];
        let tempMeeting;
        tempMeeting = await getCorporateMeetings({
          ...data,
          limit: limit.toString(),
          offset: offset.toString()
        });
        state.corporateMeetings = tempMeeting;
        meetings = meetings.concat(tempMeeting);

        appStateStore.actions.setLoading("corporateMeetings", false);
        offset += limit;
        do {
          tempMeeting = await getCorporateMeetings({
            ...data,
            limit: limit.toString(),
            offset: offset.toString()
          });

          meetings = meetings.concat(tempMeeting);
          offset += limit;
        } while (tempMeeting.length === limit);
        state.corporateMeetings = meetings;

        appStateStore.actions.setLoading("corporateMeetings", false);
      } catch (error) {
        appStateStore.actions.setError("corporateMeetings", true);
        throw error;
      }
    },
    setAllOpportunitiesLocal: (data) => {
      state.startupAllOpportunities = data;
    },
    setStartupApplicationsLocal: (data) => {
      state.challengeApplications = data;
    },
    reset: () => {
      state.challengeOpportunities = {
        opportunities: {},
        suggestions: {}
      };
      state.challengeApplications = [];
    }
  });

  return {
    state,
    getters,
    actions
  };
});
const chunkArray = (array, chunkSize) => {
  try {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  } catch (error) {
    console.error(
      `something went wrong with array ${array} chunk size ${chunkSize}`,
      error
    );
    throw new Error(error);
  }
};
