import { exportStOppData } from "@/global/services/opportunities-service.js";
import { useUserAuthStore } from "../stores/user-auth-store.js";

export const exportExcel = async (data, fileName) => {
  const user = useUserAuthStore();
  try {
    const exportedExcel = await exportStOppData(data);
    const headers = [
      "Startup Name",
      "Ecosystem Link",
      "Website",
      "Description",
      "Country",
      "Year of Establishment",
      "Number of Employees",
      "Funding",
      "Industries & Technologies",
      "LinkedIn",
      "Favorites",
      "Stage",
      "Stage Details",
      "Startup Feedback",
      "Corporate Feedback",
      "Leadsource",
      "Submission Date",
      "Meeting Date",
      "Meeting Link",
      "Related Challenge",
      "Challenge State",
      "Comments"
    ];
    if (!exportedExcel) {
      throw new Error("Export failed");
    }
    // Merge headers and rows
    const rows = exportedExcel.map((object) => Object.values(object));
    const escapeField = (field) => {
      if (field === null || field === undefined) {
        return "";
      }
      return `"${String(field).replace(/"/g, '""')}"`;
    };

    const csvContent = [headers, ...rows]
      .map((row) => row.map(escapeField).join(";"))
      .join("\n");
    // const csvContent = [headers, ...rows].map((e) => e.join(";")).join("\n");

    // Create a Blob for the CSV content
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    // Create a link and trigger the download
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    const localization = Intl.DateTimeFormat().resolvedOptions();
    link.href = url;
    link.download = `${
      user.state.accountInfo.name
    }_${fileName}_MTBEcosystem_${new Date().toLocaleDateString(
      localization.locale,
      {
        day: "numeric",
        month: "numeric",
        year: "numeric"
      }
    )}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (e) {
    throw e;
  }
};
