import { defineAsyncComponent } from "vue";
import { useDialog } from "primevue/usedialog";
import { useRouter, useRoute } from "vue-router";

export const useChallengeModal = () => {
  const router = useRouter();
  const route = useRoute();
  const BaseModalHeader = defineAsyncComponent(
    () => import("@global/components/base-modal-header.vue")
  );
  const CorporateChallengeModal = defineAsyncComponent(
    () => import("@corporates/components/corporate-challenge-modal.vue")
  );
  const dialog = useDialog();
  const showChallengeModal = (
    currentChallenge,
    errorFields,
    currentStep = 0
  ) => {
    router.replace({
      ...route,
      query: currentChallenge
        ? { challenge_m: "edit_" + currentChallenge.Id }
        : { challenge_m: "new" }
    });
    return new Promise(() => {
      dialog?.open(CorporateChallengeModal, {
        data: {
          header: {
            title: currentChallenge
              ? `Edit ${currentChallenge.Name}`
              : "Create a new challenge",
            subTitle:
              "Select the purpose of the challenge from the options below",
            //TODO: redp the pb
            headerClasses: "px-4 pt-2"
          },
          currentChallenge: currentChallenge,
          errorFields,
          currentStep: currentStep
        },
        props: {
          style: {
            width: "70vw"
          },
          breakpoints: {
            "960px": "75vw",
            "640px": "90vw"
          },

          modal: true,
          draggable: false,
          closable: false
        },
        templates: {
          header: BaseModalHeader
        }
      });
    });
  };
  return { showChallengeModal };
};
