<template>
  <div v-if="props.isLoading" class="w-full flex flex-col gap-4">
    <div v-for="n in 4" :key="n">
      <Skeleton class="w-[50px] h-4"></Skeleton>
      <Skeleton class="w-2/3 h-5" v-if="n < 3"></Skeleton>
      <Skeleton class="w-full h-5" v-else></Skeleton>
    </div>
  </div>
  <div class="flex flex-col items-start pt-2 gap-8 flex-1 self-stretch" v-else>
    <!-- DETAILS + CONTACTS + INDUSTRY-->
    <div
      class="flex flex-wrap items-start self-stretch"
      :class="props.sidebar ? 'gap-y-8 gap-x-4' : 'gap-8'"
    >
      <!-- ===== DETAILS ===== -->
      <div class="flex flex-col items-start gap-4 min-w-[200px]">
        <span class="text-lg font-bold">Details</span>
        <div
          v-for="field in detailsFieldConfig.filter(
            (field) =>
              field.section === 'details' && props.accountDetails[field.id]
          )"
          :key="field.id"
          class="flex flex-col items-start gap-3 text-base text-grey1"
        >
          <div
            class="flex items-center gap-2"
            v-if="props.accountDetails[field.id]"
          >
            <base-vite-icon :name="field.icon" :classes="field.iconClasses" />
            <!-- TODO: clean the data put country and city together -->
            <span
              v-if="field.id === 'Year_of_Establishment__c'"
              class="text-start"
            >
              Founded in
              {{ props.accountDetails.Year_of_Establishment__c }}</span
            >
            <a
              :class="field.labelStyle"
              class="text-start truncate w-[11rem]"
              :href="props.accountDetails[field.id]"
              target="_blank"
              v-else-if="field.id === 'Website'"
              >{{ props.accountDetails[field.id] }}</a
            >
            <a
              :class="field.labelStyle"
              class="text-start truncate w-[11rem]"
              :href="props.accountDetails[field.id]"
              target="_blank"
              v-else-if="field.id === 'pitchDeck'"
              >Pitckdeck</a
            >
            <a
              :class="field.labelStyle"
              class="text-start truncate w-[11rem]"
              :href="props.accountDetails[field.id]"
              target="_blank"
              v-else-if="field.id === 'LinkedIn_URL__c'"
              >Linkedin</a
            >
            <span
              :class="field.labelStyle"
              class="text-start truncate w-[11rem]"
              v-else
              >{{ props.accountDetails[field.id] }}</span
            >
          </div>
        </div>
      </div>
      <!-- ===== CONTACTS ===== -->
      <div
        class="flex flex-col items-start gap-4 min-w-[200px]"
        v-if="
          accountContacts.length > 0 ||
          !support.isDataFalsyOrEmpty(accountMainContact)
        "
      >
        <span class="text-lg font-bold">Contacts</span>

        <div class="flex flex-col items-start gap-3">
          <!-- MAIN CONTACT -->
          <div
            class="flex items-center gap-2"
            v-if="!support.isDataFalsyOrEmpty(accountMainContact)"
          >
            <base-vite-icon
              name="profile"
              classes="stroke-2 w-4 h-4 fill-data3 stroke-data3"
            />
            <div class="flex flex-col items-start">
              <span class="text-base font-medium text-grey1"
                >{{ accountMainContact?.FirstName }}
                {{ accountMainContact?.LastName }}</span
              >
              <span class="text-sm text-grey2 text-left w-[11rem]">{{
                accountMainContact?.Title
              }}</span>
            </div>
          </div>
          <!-- ALL CONTACTS -->
          <div v-else class="flex flex-col justify-center items-start gap-3">
            <div
              class="inline-flex items-center gap-2"
              v-for="contact in accountContacts"
              :key="contact.AccountId"
            >
              <base-vite-icon
                name="profile"
                classes="stroke-2 w-4 h-4 stroke-primary"
              />
              <div class="flex flex-col items-start">
                <span class="text-base font-medium text-grey1"
                  >{{ contact?.FirstName }} {{ contact?.LastName }}</span
                >
                <span class="text-sm text-grey2 text-left w-[11rem]">{{
                  contact?.Title
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Sponsor -->
      <div
        class="flex flex-col items-start gap-4 min-w-[200px]"
        v-if="accountSponsorDetails?.sponsorId"
      >
        <span class="text-lg font-bold">Ecosystem Partnership</span>
        <div
          class="flex flex-col items-start gap-3 text-base text-grey1"
          v-if="userAuthStore.getters.isDemoAccount && isMySponsored"
        >
          <base-logo
            :companyId="userAuthStore.state.accountInfo.id"
            classes="w-8 h-8"
          />
          <p class="max-w-[10rem] text-base text-grey1">
            {{ userAuthStore.state.accountInfo.name }}
          </p>
        </div>
        <div class="flex items-center gap-2.5" v-else>
          <base-logo
            :companyId="accountSponsorDetails.sponsorId"
            classes="w-8 h-8"
          />
          <p class="max-w-[10rem] text-base text-grey1">
            {{ accountSponsorDetails.sponsorName }}
          </p>
        </div>
      </div>
    </div>
    <!-- Industries & technologie -->
    <div
      class="flex flex-col items-start self-stretch gap-4"
      v-if="getCategorization && getCategorization.length > 0"
    >
      <span class="text-lg font-bold">Industries & technologies</span>
      <div class="flex items-center gap-2.5 flex-wrap">
        <div
          class="mtb-chip text-sm bg-data6-light text-data6"
          v-for="label in getCategorization.slice(0, 7)"
          :key="label"
        >
          {{ label }}
        </div>
        <span
          v-if="getCategorization.length > 7"
          @mouseenter="toggleOverlay($event)"
          @mouseleave="toggleOverlay($event)"
          class="mtb-chip text-sm bg-data6-light text-data6"
          >+ {{ getCategorization.length - 7 }}
        </span>
        <OverlayPanel ref="overlay" class="z-10" :dismissable="true">
          <!-- Content based on the selected row's data -->
          {{ getCategorization.slice(7).join(", ") }}
        </OverlayPanel>
      </div>
    </div>
    <!--  DESCRIPTION -->
    <div
      class="flex flex-col items-start self-stretch gap-4"
      :class="
        userAuthStore.state?.accountInfo?.type != 'corporate' &&
        props.accountType == 'startup'
          ? 'max-w-4xl'
          : ''
      "
      v-if="props.accountDetails.Description"
    >
      <span class="text-lg font-bold">Description</span>
      <div class="text-start">
        <span
          v-html="truncatedDescription"
          :class="{ 'line-clamp-3': !expanded && isTruncated }"
          class="text-base text-start text-grey1 mr-2"
        >
        </span>
        <a
          v-if="!expanded && isTruncated"
          @click="expandText"
          href="javascript:void(0)"
          class="text-primary font-medium text-smbase hover:underline"
          >Read more</a
        >
        <a
          v-if="expanded && isTruncated"
          @click="collapseText"
          href="javascript:void(0)"
          class="text-primary font-medium text-smbase hover:underline"
          >Read less</a
        >
      </div>
    </div>
    <!-- FUNDING -->
    <div
      v-if="props.accountType == 'startup'"
      class="flex flex-col items-start self-stretch gap-4"
    >
      <span class="text-lg font-bold">Financials</span>
      <span
        class="text-base text-start text-grey1"
        v-if="props.accountDetails.Total_Funding__c"
        >Raised
        <span class="font-bold">
          {{ props.accountDetails.Total_Funding__c }}M$</span
        >
        {{
          props.accountDetails.Main_Investors__c
            ? "from " + props.accountDetails.Main_Investors__c
            : ""
        }}
      </span>
      <span class="text-base text-start text-grey1">
        Listing status:
        <span class="font-bold"
          >{{ props.accountDetails.Is_Public__c ? "Public" : "Private"
          }}{{ props.accountDetails.Was_Acquired__c ? ", Acquired" : "" }}</span
        >
      </span>
    </div>
  </div>
</template>

<script setup>
import { default as support } from "@share/commonSupport.js";
import { computed, ref, toRef } from "vue";
import { useUserAuthStore } from "@/global/stores/user-auth-store.js";
import { auth } from "@/plugins/firebase.js";
import { useTruncatedText } from "@global/hooks/use-truncated-text.js";
import { useInstitutionMyEcosystemStore } from "@/modules/institutions/stores/institution-myecosystem-store.js";
const institutionMyEcosystemStore = useInstitutionMyEcosystemStore();
const userAuthStore = useUserAuthStore();

const props = defineProps({
  accountDetails: {
    type: Object,
    default: () => {}
  },
  accountMainContact: {
    type: Object,
    default: () => {}
  },
  accountSponsorDetails: {
    type: Object,
    default: () => {}
  },
  containerStye: {
    type: String,
    default: ""
  },
  accountContacts: {
    type: Array,
    default: () => []
  },
  isLoading: {
    type: Boolean,
    default: false
  },
  accountType: {
    type: String,
    default: "startup",
    validator: (value) =>
      ["startup", "institution", "corporate"].includes(value)
  },
  sidebar: {
    type: Boolean,
    default: false
  }
});

const detailsFieldConfig = [
  {
    id: "HQ_Country__c",
    label: "",
    icon: "location",
    iconClasses: "fill-none stroke-primary stroke-2 w-4 h-4",
    section: "details"
  },
  {
    id: "Year_of_Establishment__c",
    label: "",
    icon: "home",
    iconClasses: "fill-none stroke-primary stroke-2 w-4 h-4",
    section: "details"
  },
  {
    id: "Website",
    label: "",
    icon: "globe",
    iconClasses: "fill-primary stroke-0 w-4 h-4",
    labelStyle: "underline",
    section: "details"
  },
  {
    id: "LinkedIn_URL__c",
    label: "",
    icon: "linkedin",
    iconClasses: "fill-primary stroke-0 w-4 h-4",
    labelStyle: "underline",
    section: "details"
  },
  //TODO: double check id
  {
    id: "pitchDeck",
    label: "",
    icon: "document",
    iconClasses: "fill-none stroke-primary stroke-2 w-4 h-4",
    labelStyle: "underline",
    section: "details"
  },
  {
    id: "Employee_Range__c",
    label: "",
    icon: "team",
    iconClasses: "fill-primary stroke-primary stroke-[0.5] w-4 h-4",
    section: "details"
  }
];
const isMySponsored = computed(() => {
  return (
    auth.currentUser &&
    (institutionMyEcosystemStore.getters.getSponsoredStartups.some(
      (item) => item.companyId === props.accountDetails.Id
    ) ||
      institutionMyEcosystemStore.getters.getSponsoredCorporates.some(
        (item) => item.companyId === props.accountDetails.Id
      ))
  );
});

const overlay = ref();
const toggleOverlay = (event) => {
  overlay.value?.toggle(event);
};

const getCategorization = computed(() => {
  const sectors =
    props.accountDetails.Industry_Sector__c &&
    props.accountDetails.Industry_Sector__c
      ? props.accountDetails.Industry_Sector__c.replaceAll("\n", ", ")
          .replaceAll(";", ", ")
          .split(", ")
          .map((sc) => sc.trim())
      : [];
  const keywords = props.accountDetails.Keywords__c
    ? props.accountDetails.Keywords__c.replaceAll("\n", ", ")
        .replaceAll(";", ", ")
        .split(", ")
        .map((kw) => kw.trim())
    : [];
  const technologies = props.accountDetails.Technologies__c
    ? props.accountDetails.Technologies__c.split(";").map((tech) => tech.trim())
    : [];
  const verticals = props.accountDetails.Industry_Verticals__c
    ? props.accountDetails.Industry_Verticals__c.split(";").map((vert) =>
        vert.trim()
      )
    : [];

  // Combine all arrays and create a Set to remove duplicates
  const combined = [
    ...new Set([...sectors, ...verticals, ...technologies, ...keywords])
  ];

  return combined;
});

// REUSED HOOK FOR TRUNCATING TEXT BASE ON CHARACTER
const description = computed(() => props.accountDetails.Description);
const limitCharacter = computed(() =>
  props.sidebar ? 200 : description.value?.length
);
const {
  expanded,
  truncatedDescription,
  expandText,
  collapseText,
  isTruncated
} = useTruncatedText(description, limitCharacter);
</script>

<style lang="scss" scoped>
.line-clamp-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
</style>
