<template>
  <div
    :class="[
      'w-full flex justify-between items-center p-6',
      isRow ? 'mtb-card  h-[85px]' : 'flex-col'
    ]"
  >
    <span class="text-start me-2">
      Oops! Something went wrong.
      {{ reloadVisible ? "Please, click the reload button to retry." : "" }}
      <br />
      If the problem persists,
      <!--TODO change with tech support-->
      <a href="mailto:scouting@mindthebridge.org" class="text-primary underline"
        >contact our support</a
      >
      for assistance.</span
    >
    <base-button
      v-if="reloadVisible"
      :class="[{ 'mt-4': !isRow }]"
      label="Reload"
      severity="primary"
      @click="click"
      class="whitespace-nowrap"
    />
  </div>
</template>
<script setup>
defineProps({
  isRow: {
    type: Boolean,
    default: true
  },
  reloadVisible: {
    type: Boolean,
    default: true
  }
});
const emits = defineEmits(["reload"]);

const click = () => {
  emits("reload");
};
</script>
