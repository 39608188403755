<template>
  <div
    v-if="legend"
    class="flex justify-center mx-auto w-[60%] md:w-[40%] lg:w-[30%]"
  >
    <div
      v-for="({ color, to }, key) in props.legend"
      :key="key"
      class="flex flex-col items-end w-full"
    >
      <div class="flex flex-col items-end w-full">
        <div :style="`background:${color}`" class="w-full h-2" />
        <span class="text-sm mt-2">{{ to }}</span>
      </div>
    </div>
  </div>
</template>
<script setup>
const props = defineProps({
  legend: {
    type: Array,
    default: null
  }
});
</script>
<style lang="scss" scoped></style>
