export function register(app) {
  //DEBT, with the new library we should remove this piece of code, but the chart doesn't works
  function registerAllComponent(context) {
    Object.keys(context).forEach((key) => {
      if (key.includes("base-chart")) {
        const componentModule = context[key];
        const componentName = key
          ?.split("/")
          ?.pop()
          ?.replace(/\.vue$/, "");
        if (componentName) {
          app.component(componentName, componentModule.default);
        }
      }
    });
  }

  const components = import.meta.glob("../global/components/*.vue", {
    eager: true
  });

  registerAllComponent(components);
}
