<template>
  <div class="flex justify-between">
    <!-- CHART TITLE -->
    <div class="flex gap-x-2 items-center">
      <div class="flex text-xl font-bold">
        {{ props.chartTitle }}
      </div>
      <span
        v-if="props.tooltip"
        @mouseenter="toggleOverlay($event)"
        @mouseleave="toggleOverlay($event)"
        ><base-vite-icon name="info" classes="w-4 rotate-180 relative" />
      </span>
      <OverlayPanel ref="overlay" class="z-10" :dismissable="true">
        <!--In this way we can customize the tooltip as we want-->
        <slot name="custom-tooltip" />
      </OverlayPanel>
    </div>
    <div v-if="props.actionIsVisible" class="hstack gap-4">
      <!-- DROPDOWN TO CHANGE CHART -->
      <base-dropdown
        v-if="props.dropdownOptionsChart"
        v-model="selectedOptionChart"
        :title="selectedOptionChart.name"
        titleStyle="text-base"
        iconStyle="order-0 fill-none stroke-black stroke-2 w-5 h-5"
        :options="dropdownOptionsChart"
        :defaultselectedOption="dropdownOptionsChart?.[0]"
      />
      <div class="relative">
        <MTB-Button
          type="button"
          @click="toggle"
          aria-haspopup="true"
          aria-controls="overlay_menu"
          :pt="{
            root: 'border-none'
          }"
        >
          <template #icon>
            <base-vite-icon name="download" classes="w-6" />
          </template>
        </MTB-Button>

        <MTB-Menu
          ref="menu"
          id="overlay_menu"
          :model="downloadOptions"
          :popup="true"
          :pt="{
            root: 'py-1 bg-white text-black border border-grey3  rounded-md w-48',
            menu: {
              class: ['m-0 p-0 list-none', 'outline-none']
            },

            action: {
              class: [
                'text-black text-sm font-sans py-2 px-3 select-none',
                'cursor-pointer flex items-center no-underline overflow-hidden relative'
              ]
            },

            transition: TRANSITIONS.overlay
          }"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, watchEffect } from "vue";
const emits = defineEmits(["change-chart", "download-csv", "download-png"]);
const props = defineProps({
  tooltip: {
    type: Boolean,
    default: false
  },
  chartTitle: {
    type: String,
    required: true
  },
  actionIsVisible: {
    type: Boolean,
    default: false
  },
  dropdownOptionsChart: {
    type: Array,
    default: null
  }
});
const selectedOptionChart = ref(props.dropdownOptionsChart?.[0]);
watchEffect(() => {
  if (selectedOptionChart.value && props.dropdownOptionsChart) {
    emits("change-chart", selectedOptionChart.value);
  }
});

const downloadOptions = ref([
  {
    label: "Export as CSV",
    command: () => {
      emits("download-csv");
    }
  },
  {
    label: "Export as PNG",
    command: () => {
      emits("download-png");
    }
  }
]);
const menu = ref();
const toggle = (event) => {
  menu.value.toggle(event);
};
const overlay = ref();
const toggleOverlay = (event) => {
  overlay.value.toggle(event);
};
const TRANSITIONS = {
  overlay: {
    enterFromClass: "opacity-0 scale-75",
    enterActiveClass:
      "transition-transform transition-opacity duration-150 ease-in",
    leaveActiveClass: "transition-opacity duration-150 ease-linear",
    leaveToClass: "opacity-0"
  }
};
</script>
