<template>
  <div
    class="position-absolute mtb-chart-dropdown-menu"
    :style="{
      '--position-x': menuPosition.x,
      '--position-y': menuPosition.y
    }"
  >
    <div
      role="button"
      class="mtb-btn-muted border-0 w-full btn-sm"
      v-for="option in chartDropdownOption"
      :key="option.eventName"
      @click="methods.select(option.eventName)"
    >
      {{ option.label }}
    </div>
  </div>
</template>

<script setup>
import { reactive } from "vue";

defineProps({
  menuPosition: {
    type: Object,
    default: () => {}
  },
  chartDropdownOption: {
    type: Array,
    default: () => []
  }
});
const emit = defineEmits(["select"]);
const methods = reactive({
  select(event) {
    emit("select", event);
  }
});
</script>

<style lang="scss" scoped></style>
