<template>
  <div class="flex flex-row items-end justify-center gap-10">
    <base-button
      class="w-2/5 h-full"
      label="Clear All"
      :text="true"
      severity="primary"
    />
    <base-button class="w-2/5" label="Save" severity="primary" />
  </div>
</template>

<script setup></script>

<style lang="scss" scoped></style>
