import { functionsEU, database, functionsUS } from "@plugins/firebase.js";
import {
  getStorage,
  ref,
  listAll,
  getMetadata,
  getDownloadURL
} from "firebase/storage";
import { httpsCallable } from "firebase/functions";
import {
  getDocs,
  getDoc,
  doc,
  collection,
  orderBy,
  query
} from "firebase/firestore";
import { default as classes } from "@share/errorClasses.js";
import validate from "validate.js";
const options = {
  timeout: 180000
};
export async function getAccountFirestore(type, accountId) {
  try {
    //validation
    const validationRules = {
      type: {
        presence: { allowEmpty: false },
        type: "string"
      },
      accountId: { presence: { allowEmpty: false }, type: "string" }
    };
    const validationErrors = validate({ type, accountId }, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }
    const accountDocRef = doc(database, `${type}s`, accountId);
    const accountDoc = await getDoc(accountDocRef);
    if (accountDoc.exists()) {
      return accountDoc.data();
    } else {
      return null;
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function createAccountFirestore(data) {
  try {
    //validation
    const validationRules = {
      data: {
        presence: { allowEmpty: false },
        type: "object"
      },
      "data.accountType": {
        presence: { allowEmpty: false },
        type: "string"
      },
      "data.accountId": {
        presence: { allowEmpty: false },
        type: "string"
      },
      "data.data": {
        presence: { allowEmpty: false },
        type: "object"
      }
    };
    const validationErrors = validate({ data }, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }
    const httpsCallableFunction = httpsCallable(
      functionsEU,
      "createNewAccountOnFirestore"
    );

    const payload = data;
    const response = await httpsCallableFunction(payload);
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getEmployeesList(accountId) {
  try {
    //validation
    const validationRules = {
      accountId: {
        presence: { allowEmpty: false },
        type: "string"
      }
    };
    const validationErrors = validate({ accountId }, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }
    const httpsCallableFunction = httpsCallable(
      functionsUS,
      "getEmployeesListV3"
    );

    const payload = {
      accountId
    };
    const response = await httpsCallableFunction(payload);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
export async function getFullEmployeesList(accountId) {
  try {
    //validation
    const validationRules = {
      accountId: {
        presence: { allowEmpty: false },
        type: "string"
      }
    };
    const validationErrors = validate({ accountId }, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }
    const httpsCallableFunction = httpsCallable(
      functionsUS,
      "getFullEmployeesListV3"
    );

    const payload = {
      accountId
    };

    const response = await httpsCallableFunction(payload);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getInvitedAccountUsers(accountId) {
  try {
    //validation
    const validationRules = {
      accountId: {
        presence: { allowEmpty: false },
        type: "string"
      }
    };
    const validationErrors = validate({ accountId }, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }

    const httpsCallableFunction = httpsCallable(
      functionsEU,
      "getInvitedAccountUsers"
    );

    const payload = {
      accountId
    };
    const response = await httpsCallableFunction(payload);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
export async function getAccountsList(accountType) {
  try {
    //validation
    const validationRules = {
      accountType: {
        presence: { allowEmpty: false },
        type: "string",
        inclusion: {
          within: ["corporates", "institutions", "startups"]
        }
      }
    };
    const validationErrors = validate({ accountType }, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }

    const queryRef = query(collection(database, accountType), orderBy("name"));
    const querySnapshot = await getDocs(queryRef);
    return querySnapshot.docs.map((doc) => {
      return doc.data();
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getLastPitchDeck(documentId) {
  try {
    //validation
    const validationRules = {
      documentId: {
        presence: { allowEmpty: false },
        type: "string"
      }
    };
    const validationErrors = validate({ documentId }, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }

    const documentRef = doc(database, "pitchdecks_cache", documentId);
    const docSnapshot = await getDoc(documentRef);
    if (!docSnapshot.exists()) {
      return false;
    }
    const data = docSnapshot.data();
    const latestDeck =
      data.latestDeck ||
      data.decks?.sort((a, b) => {
        return new Date(b.timeCreated) - new Date(a.timeCreated);
      })[0] ||
      null;
    if (!latestDeck) {
      return false;
    }
    return latestDeck;
  } catch (error) {
    throw error;
  }
}

export async function getPitchdecks(accountId) {
  try {
    // Validation
    const validationRules = {
      accountId: {
        presence: { allowEmpty: false },
        type: "string"
      }
    };
    const validationErrors = validate({ accountId }, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }

    const storage = getStorage();
    const storageRef = ref(storage, `gs://mtb_pitch-decks/${accountId}`);
    const files = await listAll(storageRef);

    // Use Promise.all to fetch metadata and download URLs in parallel
    const pitchdeckPromises = files.items.map(async (pd) => {
      const [metadata, url] = await Promise.all([
        getMetadata(pd),
        getDownloadURL(pd)
      ]);

      return {
        url,
        name: metadata.name,
        timeCreated: metadata.timeCreated
      };
    });

    // Wait for all promises to resolve
    const allPitchdecks = await Promise.all(pitchdeckPromises);

    let currentPitchDeck = null;
    if (allPitchdecks.length > 0) {
      currentPitchDeck = allPitchdecks.sort(
        (a, b) => new Date(b.timeCreated) - new Date(a.timeCreated)
      )[0];
    }

    return {
      currentPitchDeck,
      allPitchdecks
    };
  } catch (error) {
    throw error;
  }
}

// import { httpsCallableFromURL } from "firebase/functions"; // see note1, note2, note3 below

//HOW TO USE: pass an array with a single ID to retrieve the info for one account, a list of IDs to get a batch of accounts.
//fieldsToRetrieve is not mandatory, if you don't pass it the CF will thake:
// ["HQ_City__c", "HQ_Country__c", "Year_of_Establishment__c", "Id"]
export async function getAccountsListDetails(data) {
  try {
    //validation
    const validationRules = {
      recordIds: {
        presence: { allowEmpty: false },
        type: "array"
      },
      allDetails: {
        presence: true,
        type: "boolean"
      }
    };
    const validationErrors = validate(data, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }

    const httpsCallableFunction = httpsCallable(
      functionsEU,
      "getaccountinfov3",
      options
    );
    const result = await httpsCallableFunction(data);

    return result.data || [];
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function updateAccountProfile(data) {
  try {
    //validation
    const validationRules = {
      accountId: {
        presence: { allowEmpty: false },
        type: "string"
      },
      contacts: {
        presence: false,
        type: "array"
      },
      logo: {
        presence: false,
        type: "string"
      },
      details: {
        presence: false,
        type: "object"
      }
    };
    const validationErrors = validate(data, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }

    const httpsCallableFunction = httpsCallable(
      functionsEU,
      "updateAccountProfile"
    );
    const result = await httpsCallableFunction(data);
    return result.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
