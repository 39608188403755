<template>
  <div
    :id="modalId"
    :ref="modalId"
    class="modal fade"
    tabindex="-1"
    :style="multipleModal ? 'background:rgba(0, 0, 0, 0.2705882353)' : ''"
    :data-bs-backdrop="staticBackdrop ? 'static' : null"
    :data-bs-keyboard="!staticBackdrop"
    :aria-labelledby="modalTitle"
    aria-hidden="true"
  >
    <!-- TODO modal dialog center, avaluate if all modal must have , dimension as props-->
    <div
      :class="[
        'modal-dialog modal-dialog-scrollable',
        { 'disable-scroll': !isScrollable },
        `modal-${modalDimension}`,
        { 'modal-dialog-centered': isModalCentered }
      ]"
    >
      <div class="modal-content">
        <div class="modal-header d-flex justify-content-between">
          <h5 :id="modalTitle" class="modal-title">{{ modalTitle }}</h5>
          <div class="d-flex align-items-center">
            <slot name="icon_extra"></slot>
            <button
              v-if="!preventCloseOnIcon"
              type="button"
              class="btn-close mtb-custom-icon-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
            <button
              v-if="preventCloseOnIcon"
              type="button"
              class="btn-close mtb-custom-icon-modal"
              aria-label="Close"
              @click="preventClosing"
            ></button>
          </div>
        </div>
        <div :class="['modal-body', { 'disable-scroll': !isScrollable }]">
          <slot name="mtb-modal-body" />
        </div>
        <div
          v-if="labelButtonSecondary || labelButtonPrimary"
          class="modal-footer"
        >
          <button
            v-if="closeOnAction && labelButtonSecondary"
            type="button"
            class="btn btn-outline-primary"
            data-bs-dismiss="modal"
            :disabled="isSecondaryButtonDisable"
            @click="secondaryAction"
          >
            {{ labelButtonSecondary }}
          </button>
          <button
            v-if="closeOnAction && labelButtonPrimary"
            type="button"
            class="btn bg-primary hover:bg-white text-white hover:text-primary"
            data-bs-dismiss="modal"
            :disabled="isPrimaryButtonDisable"
            @click="primaryAction"
          >
            {{ labelButtonPrimary }}
          </button>
          <button
            v-if="!closeOnAction && labelButtonSecondary"
            type="button"
            class="btn btn-outline-primary"
            :disabled="isSecondaryButtonDisable"
            @click="secondaryAction"
          >
            {{ labelButtonSecondary }}
          </button>
          <button
            v-if="!closeOnAction && labelButtonPrimary"
            type="button"
            class="btn bg-primary hover:bg-white text-white hover:text-primary"
            :disabled="isPrimaryButtonDisable"
            @click="primaryAction"
          >
            {{ labelButtonPrimary }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { bus } from "@/main.js";
export default {
  props: {
    isScrollable: {
      type: Boolean,
      default: true
    },
    modalId: {
      type: String,
      default: "defaultModal"
    },
    modalTitle: {
      type: String,
      default: ""
    },
    labelButtonPrimary: {
      type: [String, null],
      default: null
    },
    labelButtonSecondary: {
      type: [String, null],
      default: null
    },
    isPrimaryButtonDisable: {
      type: Boolean,
      default: false
    },
    isSecondaryButtonDisable: {
      type: Boolean,
      default: false
    },
    modalDimension: {
      type: String,
      default: "xl",
      validator(value) {
        return ["xl", "lg", "md", "sm"].includes(value);
      }
    },
    isModalCentered: {
      type: Boolean,
      default: false
    },
    closeOnAction: {
      type: Boolean,
      default: false
    },
    needsUpdate: {
      type: Boolean,
      default: false
    },
    staticBackdrop: {
      type: Boolean,
      default: false
    },
    preventCloseOnIcon: {
      type: Boolean,
      default: false
    },
    multipleModal: {
      type: Boolean,
      default: false
    }
  },
  emits: ["primaryAction", "secondaryAction"],
  data() {
    return {
      modalIdIsChange: false
    };
  },
  watch: {
    modalId() {
      this.modalIdIsChange = true;
    }
  },
  updated() {
    //modal challenge needs update
    //TODO add here all modals that need update
    if (this.needsUpdate && this.modalIdIsChange) {
      bus.emit("modal-is-update", this.modalId);
      this.modalIdIsChange = false;
    }
  },
  methods: {
    primaryAction() {
      this.$emit("primaryAction");
    },
    secondaryAction() {
      this.$emit("secondaryAction");
    },
    preventClosing() {
      bus.emit("prevent-modal-close");
    }
  }
};
</script>
<style lang="scss" scoped>
.mtb-custom-icon-modal {
  outline: none;
  border: none;
  &:focus {
    box-shadow: none;
  }
}

.disable-scroll {
  overflow: hidden;
}
:deep(.modal-dialog) {
  height: calc(100vh - 2 * var(--bs-modal-margin));
}
</style>
