import { challengeFieldModalConfig } from "@corporates/configs/challenge-field-modal-config.js";
import { format } from "date-fns";
import { useChallengesStore } from "../stores/challenges-store.js";

export const useChallengeDetails = () => {
  const challengesStore = useChallengesStore();
  const allNeededFields = (challenge) => {
    return challengeFieldModalConfig.value?.filter(
      (field) =>
        field.visibility === true ||
        field.visibility?.value?.includes(challenge?.Challenge_Objective__c)
    );
  };
  //TODO: rework on showing fields with error validation if click on publish button
  const challengesField = (challenge, startupView, isValidated = false) => {
    return challengeFieldModalConfig.value?.filter((field) => {
      const isFieldVisible =
        //global visibility
        (field.visibility === true ||
          //visibility per type: check if field.visibility?.value include the current challenge objective type
          field.visibility?.value?.includes(
            challenge?.Challenge_Objective__c
          )) &&
        (startupView ? field.visibleToStartups : true);

      return (
        (challenge?.[field.id] && isFieldVisible && !isValidated) ||
        (isFieldVisible && isValidated)
      );
    });
  };
  //TODO: reuse the function in challenge modal
  const formatValue = (field, inputText) => {
    if (!inputText && inputText !== false) return "-";
    if (field.type === "tags") {
      console.log("check inputText", inputText);
      return inputText?.replace(/;/g, ", ") + ".";
    } else if (field.type === "single") {
      return field.options?.find(
        (ele) => ele.value.toString() === inputText.toString()
      )?.label;
    } else if (field.type === "date") {
      return format(new Date(inputText), "dd MMM yyyy");
    } else {
      return inputText;
    }
  };
  const isMyCorporate = (challenge) => {
    return !!challengesStore.getters.getChallengesForSponsoredCorporates.find(
      ({ Id }) => {
        return Id === challenge.Id;
      }
    );
  };
  return { challengesField, formatValue, isMyCorporate, allNeededFields };
};
