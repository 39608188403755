import { queryMemory } from "@/global/hooks/use-filter-url-bind.js";
import { useNavigationGuard } from "@/global/hooks/use-navigation.js";

const Module = () => import("@/global/Module.vue");
const MyEcosystemShow = () => import("./views/institution-ecosystem-show.vue");
const InstitutionAnalytics = () => import("./views/institution-analytics.vue");
const InstitutionChallenges = () =>
  import("./views/institution-challenges.vue");

//CONSTANT FOR ROUTE NAME
export const PARTNER_ECOSYSTEM_ROUTE_NAME = "institution-ecosystem";
export const PARTNER_STARTUPS_ROUTE_NAME = "institution-ecosystem-startups";
export const PARTNER_CORPORATES_ROUTE_NAME = "institution-ecosystem-corporates";
export const PARTNER_CHALLENGES_ROUTE_NAME = "institution-challenges";
export const PARTNER_ANALYTICS_ROUTE_NAME = "institution-analytics";
export const PARTNER_ANALYTICS_STARTUP_ROUTE_NAME =
  "institution-analytics-startups";
export const PARTNER_ANALYTICS_CORPORATE_ROUTE_NAME =
  "institution-analytics-corporates";
export const PARTNER_PROFILE_ROUTE_NAME = "institution-my-profile";
export const PARTNER_STARTUP_APPLICATION_ROUTE_NAME =
  "institution-startup-applications";

export const INSTITUTION_MOUDULE_ROUTES = [
  {
    path: "/partner",
    name: "institution-pages",
    component: Module,
    redirect: () => {
      return { name: "homepage" };
    },
    children: [
      //List sponsored startups, corporates
      {
        path: "my-ecosystem",
        name: PARTNER_ECOSYSTEM_ROUTE_NAME,
        component: MyEcosystemShow,
        redirect: () => {
          return {
            name: PARTNER_STARTUPS_ROUTE_NAME,
            query: queryMemory(PARTNER_STARTUPS_ROUTE_NAME)
          };
        },
        children: [
          {
            name: PARTNER_STARTUPS_ROUTE_NAME,
            path: "my-startups",
            component: () =>
              import("./views/institution-ecosystem-startups.vue")
          },
          {
            name: PARTNER_CORPORATES_ROUTE_NAME,
            path: "my-corporates",
            component: () =>
              import("./views/institution-ecosystem-corporates.vue")
          }
        ],
        meta: {
          title: "My Ecosystem",
          size: "text-3xl",
          undo: false
        }
      },
      //List challenges
      {
        path: "challenges-list/:section?",
        name: PARTNER_CHALLENGES_ROUTE_NAME,
        component: InstitutionChallenges,
        meta: {
          title: "Challenges",
          size: "text-3xl",
          undo: false,
          beforeEnter: (to, from, next) => {
            if (!to.params.section) {
              next({
                name: PARTNER_CHALLENGES_ROUTE_NAME,
                params: { section: "all-challenges" },
                query: queryMemory(PARTNER_CHALLENGES_ROUTE_NAME)
              });
            } else {
              next();
            }
          }
        }
      },
      //Analytics
      {
        path: "analytics",
        name: PARTNER_ANALYTICS_ROUTE_NAME,
        component: InstitutionAnalytics,
        // component: Module,
        redirect: () => {
          return {
            name: PARTNER_ANALYTICS_STARTUP_ROUTE_NAME,
            query: queryMemory(PARTNER_ANALYTICS_STARTUP_ROUTE_NAME)
          };
        },
        children: [
          {
            name: PARTNER_ANALYTICS_STARTUP_ROUTE_NAME,
            path: "startups",
            component: () =>
              import("./views/institution-analytics-startups.vue")
          },
          {
            name: PARTNER_ANALYTICS_CORPORATE_ROUTE_NAME,
            path: "corporates",
            component: () =>
              import("./views/institution-analytics-corporates.vue")
          }
        ],
        meta: {
          title: "Analytics",
          size: "text-3xl",
          undo: false
        }
      },
      {
        path: "my-startup-applications",
        name: PARTNER_STARTUP_APPLICATION_ROUTE_NAME,
        component: () => import("./views/institution-startup-applications.vue"),
        meta: {
          title: "Startup Applications",
          size: "text-2xl",
          undo: true,
          undoLink: "homepage"
        }
      }
    ].map((route) => {
      return {
        ...route,
        meta: {
          ...route.meta,
          navbar: true,
          auth: true
        },
        async beforeEnter(from, to, next) {
          const { newNavigation, mtbTeleportTrigger } = useNavigationGuard([
            "institution"
          ]);

          mtbTeleportTrigger();
          const path = await newNavigation();
          if (!path && route.meta?.beforeEnter) {
            //I can go ahead with current route meta.beforeEnter
            route.meta.beforeEnter(from, to, next);
          } else {
            next(path);
          }
        }
      };
    })
  }
];
