<template>
  <div>
    <div
      @click.stop="toggle"
      role="button"
      class="w-[268px] flex-col justify-start items-start gap-2 inline-flex"
    >
      <div class="text-black text-sm font-medium ml-2 w-full">
        Select Date Range
      </div>
      <div
        class="w-full self-stretch px-4 py-3 rounded-lg border border-grey3 justify-start items-start gap-6 inline-flex"
      >
        <div
          class="grow shrink basis-0 text-grey2 text-sm font-normal"
          v-if="!dateRangeSelected"
        >
          DD MM YYYY
        </div>
        <div class="grow shrink basis-0 text-grey2 text-sm font-normal" v-else>
          {{ format(new Date(dateRangeSelected[0]), "dd MMM yyyy") }} -
          {{ format(new Date(dateRangeSelected[1]), "dd MMM yyyy") }}
        </div>
      </div>

      <base-calendar-input
        v-model="dateRangeSelected"
        :value="dateRangeSelected"
        selectionMode="range"
        :showTime="false"
        :inline="true"
        :minDate="minDate"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watchEffect, watch } from "vue";
import { format } from "date-fns";

// const emit = defineEmits(["updateFilter", "updateSort"]);
const emit = defineEmits(["update:modelValue"]);

const props = defineProps({
  staticLabel: {
    type: String,
    default: ""
  },

  sortable: {
    type: Boolean,
    default: true
  },
  value: {
    type: Array,
    default: null
  },
  minDate: {
    type: Object,
    default: null
  }
});

const dateRangeSelected = ref(props.value);

const isOpen = ref(false);
const toggle = () => {
  isOpen.value = !isOpen.value;
};
watch(dateRangeSelected, (newVal) => {
  emit("update:modelValue", newVal);
});
watch(
  () => props.value,
  () => {
    dateRangeSelected.value = props.value;
  }
);
</script>

<style lang="scss" scoped></style>
