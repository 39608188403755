export function objectsEqual(o1, o2) {
  if (Object.keys(o1).length === Object.keys(o2).length) {
    const ob1ob2 = Object.keys(o1).filter((key) => {
      return o1[key] !== o2[key];
    });
    const ob2ob1 = Object.keys(o2).filter((key) => {
      return o1[key] !== o2[key];
    });
    return ob1ob2?.length === 0 && ob2ob1?.length === 0;
  } else {
    return false;
  }
}

export const uniqueArrayObject = (myObjArray, field) => {
  let uniqueObjArray = [
    ...new Map(myObjArray?.map((item) => [item[field], item]) || []).values()
  ];
  return uniqueObjArray;
};

//TODO: move in timeHelper in shared folder
export function getTodayDay() {
  const now = new Date();
  const dateWithoutTime = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    0, // set hours to 0 (midnight)
    0, // set minutes to 0
    0, // set seconds to 0
    0 // set milliseconds to 0
  );
  return dateWithoutTime;
}

export function factorialSum(arr) {
  return arr.map((_elem, index) =>
    arr.slice(0, index + 1).reduce((a, b) => a + b)
  );
}
