<template>
  <div :id="id" class="flex flex-col items-center justify-center w-full">
    <apexchart
      class="pt-3 w-full"
      type="bar"
      :height="350"
      :options="chartOptions"
      :series="series"
      @dataPointSelection="showDropdownMenu"
    ></apexchart>
  </div>
</template>

<script setup>
import {
  grid,
  singleStateChart,
  toolbar,
  xaxis,
  yaxis
} from "@/global/configs/analytics.js";
import colors from "@/plugins/color.js";
import { computed } from "vue";

const props = defineProps({
  id: {
    type: String,
    required: true
  },
  chartProps: {
    type: Object,
    required: true
  }
});

const dataAfterSortSlice = computed(() => {
  let chartData = { ...props.chartProps.chartData };
  //convert to data to this format {x:3,y:5}
  for (let key in chartData) {
    let startups = chartData[key].length;
    chartData[key] = startups;
  }
  //sort if needed
  if (props.chartProps.sort) {
    let sortedArr = Object.entries(chartData).sort((a, b) => b[1] - a[1]);
    chartData = Object.fromEntries(sortedArr);
  }
  //slide the sorted object if needed
  if (props.chartProps.showLess.isShowLess) {
    let slicedArr = Object.entries(chartData).slice(
      0,
      props.chartProps.showLess.totalQuantity
    );
    chartData = Object.fromEntries(slicedArr);
  }

  return chartData;
});
const categories = computed(() => {
  return Object.keys(dataAfterSortSlice.value);
});

const series = computed(() => {
  return [
    {
      data: Object.values(dataAfterSortSlice.value)
    }
  ];
});

const chartOptions = computed(() => {
  return {
    chart: {
      height: props.chartProps.chartHeight,
      type: "bar",
      toolbar: toolbar()
    },
    plotOptions: {
      bar: {
        horizontal: props.chartProps.isHorizontal,
        dataLabels: {
          position: "top"
        }
      }
    },
    states: singleStateChart,
    dataLabels: {
      enabled: true,
      enabledOnSeries: [0],
      ...(props.chartProps.isHorizontal && { offsetX: 25 }),
      ...(!props.chartProps.isHorizontal && { offsetY: -20 }),
      style: {
        fontSize: "14px",
        fontFamily: "Helvetica, Arial, sans-serif",
        fontWeight: "400",
        colors: ["#6c757d"]
      }
    },
    tooltip: {
      custom: function ({ series, dataPointIndex }) {
        return `
            <div class="p-1 chart-tooltip">
            ${categories.value[dataPointIndex]}${
              !props.chartProps.isHorizontal &&
              categories.value[dataPointIndex] != "N.A"
                ? `<span class="text-capitalize"> ${props.chartProps.chartType}</span>`
                : ""
            }:
            <b>
            ${series[0][dataPointIndex]} </b>

            </div>`;
      }
    },
    yaxis: {
      ...yaxis,
      //need some space for the external label
      max:
        Math.max(
          ...(Object.values(props.chartProps?.chartData) || []).map(
            (e) => e.length || 0
          )
        ) * 1.1
    },
    grid: grid,
    xaxis: xaxis(categories.value, props.chartProps.chartType),
    legend: {
      show: false
    },
    colors: colors.indigo[700],
    fill: {
      type: "solid",
      opacity: 1 // Set opacity to 1
    }
    // fill: {
    //   opacity: 1,
    //   type: "gradient",
    //   gradient: {
    //     type: props.chartProps.isHorizontal ? "horizontal" : "vertical",
    //     colorStops: [
    //       [
    //         { offset: 0, color: colors.indigo[700] },
    //         { offset: 100, color: colors.indigo[400] }
    //       ],
    //       [
    //         { offset: 0, color: colors.indigo[300] },
    //         { offset: 100, color: colors.indigo[100] }
    //       ]
    //     ]
    //   }
    // }
  };
});
const emits = defineEmits(["dataPointSelection"]);
function showDropdownMenu(a, _b, c) {
  const mousePosition = {
    offsetX: a.offsetX,
    offsetY: a.offsetY
  };
  const title = Object.keys(dataAfterSortSlice.value)[c.dataPointIndex];
  emits("dataPointSelection", { mousePosition, title });
}
</script>

<style></style>
