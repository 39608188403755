<template>
  <base-single-select
    :options="listOfAllAccounts"
    :defaultSelectedOption="selectedOption"
    optionLabel="name"
    :filter="true"
    @update:modelValue="changeAccount"
  >
    <template #option="slotProps">
      <div class="flex align-items-center justify-content-between">
        <div>{{ slotProps.option.name }}</div>
        <div>{{ slotProps.option.type }}</div>
      </div>
    </template>
  </base-single-select>
</template>

<script setup>
import { computed, ref, watch, onMounted } from "vue";
import { useAccountsStore } from "@/global/stores/accounts-store.js";
import { useUserAuthStore } from "@/global/stores/user-auth-store.js";
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";
import { bus } from "@/main.js";
import { useRouter } from "vue-router";
const router = useRouter();
const accountsStore = useAccountsStore();
const userAuthStore = useUserAuthStore();
const confirm = useConfirm();
const toast = useToast();

const selectedOption = ref({
  type: userAuthStore.state.accountInfo?.type,
  name: userAuthStore.state.accountInfo?.name,
  id: userAuthStore.state.accountInfo?.id
});
const changeAccount = async (value) => {
  const newAccount = listOfAllAccounts.value.find(
    (account) => account.id === value.id
  );
  if (!newAccount || newAccount.id === selectedOption.value.id) {
    return;
  }

  confirm.require({
    message: `Are you sure you want to impersonate ${value.name}?`,
    rejectClass:
      "mtb-button cursor-pointer transition duration-200 ease-in-out bg-transparent text-primary border border-primary rounded-md",
    acceptClass:
      "mtb-button cursor-pointer transition duration-200 ease-in-out text-white bg-primary border-primary border rounded-md",
    accept: async () => {
      try {
        await router.push({
          name: `homepage`
        });
        await userAuthStore.actions.setUserInfo(newAccount);
        await userAuthStore.actions.setAccountInfo();
        bus.emit("account-is-changed");
        updateSelectedOption(newAccount);
        toast.add({
          severity: "info",
          summary: "Be careful!!",
          detail: `You are now impersonating ${newAccount.name}.`,
          life: 3000
        });
      } catch (error) {
        toast.add({
          severity: "error",
          summary: "Rejected",
          detail: "Impossible to switch account",
          life: 3000
        });
      }
    },

    reject: () => {
      bus.emit("reset-option", selectedOption.value);
    }
  });
};
const updateSelectedOption = (account) => {
  selectedOption.value = {
    type: account.type,
    name: account.name,
    id: account.id
  };
};

const listOfAllAccounts = computed(() => {
  if (
    !accountsStore.state.corporatesList ||
    !accountsStore.state.startupsList ||
    !accountsStore.state.institutionsList
  ) {
    return [];
  }
  const allAccounts = [
    ...accountsStore.state.corporatesList,
    ...accountsStore.state.startupsList,
    ...accountsStore.state.institutionsList
  ].map((account) => {
    return {
      type: account.type,
      name: account.name,
      id: account.id
    };
  });

  return allAccounts;
});

watch(
  () => userAuthStore.state.accountInfo,
  (currentUser, prevUser) => {
    if (!prevUser || currentUser?.id !== prevUser?.id) {
      updateSelectedOption(currentUser);
    }
  }
);
onMounted(() => {
  accountsStore.actions.setCorporatesList(false);
  accountsStore.actions.setInstitutionsList(false);
  accountsStore.actions.setStartupsList(false);
});
</script>

<style scoped lang="scss">
#teleport-selector {
  min-width: 100px;
  width: 12vw;
  max-width: 15vw;
}
#teleport-selector > select {
  font-size: 0.8rem !important;
}
//TODO put into a file
.large-select {
  :deep(.multiselect__content-wrapper) {
    width: auto !important;
  }
  :deep(.multiselect__option) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: break-spaces;
    line-height: 1.5;
  }
}
</style>
