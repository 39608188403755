import { ref, watch, watchEffect } from "vue";

export const useProcessing = (data) => {
  const isProcessing = ref(true);
  let timeoutId = null;
  watchEffect(() => {
    if (data.value) {
      isProcessing.value = true;
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        isProcessing.value = false;
      }, 200);
    }
  });

  watch(
    isProcessing,
    (value) => {
      if (!value) {
        clearTimeout(timeoutId);
      }
    },
    { immediate: true }
  );
  return { isProcessing };
};
