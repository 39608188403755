<template>
  <div class="flex items-center justify-center">
    <Button
      :icon="props.icon"
      :size="props.size"
      :loading="props.isLoading"
      :label="props.label"
      :severity="props.severity"
      :disabled="props.isLoading || props.disabled || props.isPremium"
      :text="props.text"
      :outlined="props.outlined"
      :badge="props.badge"
      :badgeClass="props.badgeClass"
      :type="props.type"
      :raised="props.raised"
      :rounded="props.rounded"
      :pt="pt"
      @click="handleClick"
    >
      <template #icon>
        <base-vite-icon
          v-if="props.isPremium"
          name="lock"
          :classes="iconClass"
        />
        <base-vite-icon
          v-else-if="icon.length > 0 && !isClicked"
          :name="icon"
          :classes="iconClass"
        />
      </template>
    </Button>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import Button from "primevue/button";
import { bus } from "@/main.js";

const props = defineProps({
  type: {
    type: String,
    default: "button"
  },
  size: {
    type: String,
    default: "medium"
  },
  label: {
    type: String,
    default: ""
  },
  icon: {
    type: String,
    default: ""
  },
  iconClass: {
    type: String,
    default: ""
  },
  iconPos: {
    type: String,
    default: "left",
    validator: (value) => {
      return ["left", "right", "top", "bottom"].includes(value);
    }
  },
  severity: {
    type: String,
    default: "primary"
  },
  text: {
    type: Boolean,
    default: false
  },
  outlined: {
    type: Boolean,
    default: false
  },
  raised: {
    type: Boolean,
    default: false
  },
  rounded: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  badge: {
    type: String,
    default: ""
  },
  badgeClass: {
    type: String,
    default: null
  },
  isLoading: {
    type: Boolean,
    default: false
  },
  pt: {
    type: Object,
    default: () => null
  },
  isPremium: {
    type: Boolean,
    default: false
  },
  stopClick: {
    type: Boolean,
    default: true
  }
});
const emit = defineEmits(["click"]);
const isClicked = ref(false);

function handleClick(event) {
  if (props.stopClick) {
    event.stopImmediatePropagation();
  }
  if (props.isPremium) {
    return;
  }

  emit("click");
  bus.emit("click");
}
</script>

<!-- ================= PRIME VUE PASSTHROUGH STYLE =================-->
// TODO: REMOVE IT IF THE TAILWIND.JS STYLE IS WORKING
<script>
const generateButtonClasses = (props) => {
  //text
  if (props.text && props.severity) {
    if (props.severity === "alert") {
      return `bg-transparent border-none hover:border-none p-0 text-alert`;
    } else if (props.severity === "primary") {
      return `bg-transparent border-none hover:border-none p-0 text-primary`;
    } else if (props.severity === "warning") {
      return `bg-transparent border-none hover:border-none p-0 text-warning`;
    } else if (props.severity === "grey2") {
      return `bg-transparent border-none hover:border-none p-0 text-grey2`;
    } else if (props.severity === "success") {
      return `bg-transparent border-none hover:border-none p-0 text-success`;
    }
  } else if (props.outlined && props.severity) {
    //outline
    if (props.severity === "alert") {
      return `bg-transparent text-alert border border-alert`;
    } else if (props.severity === "primary") {
      return `bg-transparent text-primary border border-primary`;
    } else if (props.severity === "warning") {
      return `bg-transparent text-warning border border-warning`;
    } else if (props.severity === "grey2") {
      return `bg-transparent text-grey2 border border-grey2`;
    } else if (props.severity === "success") {
      return `bg-transparent text-success border border-success`;
    } else if (props.severity === "mtb") {
      return `bg-transparent text-mtb border border-mtb`;
    }
  } else if (props.severity) {
    //full
    if (props.severity === "alert") {
      return `text-white bg-alert border-alert border`;
    } else if (props.severity === "primary") {
      return `text-white bg-primary border-primary border`;
    } else if (props.severity === "warning") {
      return `text-white bg-warning border-warning border`;
    } else if (props.severity === "grey2") {
      return `text-white bg-grey2 border-grey2 border`;
    } else if (props.severity === "success") {
      return `text-white bg-success border-success border`;
    }
  }
};

export default {
  button: {
    root: ({ props, context }) => ({
      class: [
        { "px-[26px] py-2": props.size === "small" },
        "mtb-button cursor-pointer",
        "transition duration-200 ease-in-out",
        generateButtonClasses(props),
        { "shadow-lg": props.raised },
        { "rounded-md": !props.rounded, "rounded-full": props.rounded },
        { "shadow-lg": props.raised && props.text },
        { "opacity-60 pointer-events-none cursor-default": context.disabled }
      ]
    }),
    label: ({ props }) => {
      //TODO: find a way to make label style more flexible
      return {
        class: props.label
          ? [
              "text-base font-bold whitespace-nowrap",
              { "text-sm ": props.size === "small" }
            ]
          : "hidden"
      };
    },
    icon: ({ props }) => ({
      class: [
        "mx-0",
        {
          "mr-2": props.iconPos == "left" && props.label != null,
          "ml-2": props.iconPos == "right" && props.label != null,
          "mb-2": props.iconPos == "top" && props.label != null,
          "mt-2": props.iconPos == "bottom" && props.label != null
        }
      ]
    }),
    badge: ({ props }) => ({
      class: [
        {
          "ml-2 w-4 h-4 leading-none flex items-center justify-center":
            props.badge
        }
      ]
    }),
    loadingicon: ({ props }) => {
      return {
        class: { "custom-spinner": props.loading }
      };
    }
  }
};
</script>

<style lang="scss">
//TODO: maybe add it in more global scope not only here
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.custom-spinner {
  animation: spin 2s linear infinite;
}
</style>
