<template>
  <div class="mtb-card p-4" :class="props.size">
    <!-- === Header ==== -->
    <section class="flex flex-col justify-end w-full h-12">
      <div class="flex flex-row items-start justify-between w-full">
        <!-- Title + Logo -->
        <div class="flex flex-row items-center justify-start gap-3 w-5/6">
          <!-- logo -->
          <Skeleton class="w-8 h-8 mb-0"></Skeleton>
          <!-- Title + sponsorship -->
          <div class="flex flex-col justify-center items-start w-2/3 gap-2">
            <Skeleton class="w-full h-4 mb-0"></Skeleton>
            <Skeleton class="w-4/5 h-4 mb-0"></Skeleton>
          </div>
        </div>
      </div>
    </section>
    <!-- ==== Footer ==== -->
    <section :class="props.footerClass">
      <Skeleton class="w-full h-5 mb-0"></Skeleton>
    </section>
  </div>
</template>

<script setup>
const props = defineProps({
  size: {
    type: String,
    default: "card-sm"
  },
  footerClass: {
    type: String,
    default: "flex flex-row items-center justify-center w-full h-7"
  }
});
</script>

<style lang="scss" scoped></style>
