<template>
  <div :id="id" class="flex flex-col items-center justify-center w-full">
    <mtb-custom-map
      ref="mapRef"
      class="py-6 h-[350px]"
      :country-data="state.countryDataGeoChart"
      :default-country-fill-color="colors.grey3[300]"
      :available-color="legendLabel"
    />
    <base-chart-legend class="mt-3" :legend="legendLabel" />
  </div>
</template>
<script setup>
import * as statesData from "@/global/constants/geo.json";
import colors from "@/plugins/color.js";
import MtbCustomMap from "@/assets/custom/map/mtb-custom-map.vue";
import { computed, onMounted, reactive, ref, watch, onUnmounted } from "vue";
import { ERROR, IS_LOADING } from "@/global/constants";
import { bus } from "@/main.js";

const props = defineProps({
  id: {
    type: String,
    required: true
  },
  chartProps: {
    type: Object,
    required: true
  }
});
const mapRef = ref();

const state = reactive({
  download: false,
  maxValue: 0,
  countryDataGeoChart: {},
  showDropDown: false,
  areaSelected: null,
  menuPosition: { x: 0, y: 0 }
});

const wrappedDataset = computed(() => {
  if (
    props.chartProps.chartData === IS_LOADING ||
    props.chartProps.chartData === ERROR
  ) {
    return null;
  }
  // Reduce dataset, merge by country and push all startup id per country
  const collectionArray = Object.values(
    props.chartProps.chartData?.reduce((acc, { collection, startupId }) => {
      if (!acc[collection]) {
        acc[collection] = { collection, startupId: [startupId] };
      } else {
        acc[collection].startupId.push(startupId);
      }
      return acc;
    }, {}) || {}
  );
  return collectionArray;
});

const legendLabel = computed(() => {
  const max = state.maxValue;
  let step = {};
  let numRanges = 5;

  if (max > 10) {
    numRanges = 5;
  } else if (max <= 10 && max > 8) {
    numRanges = 4;
  } else if (max <= 8 && max > 4) {
    numRanges = 3;
  } else if (max <= 4 && max > 1) {
    numRanges = 2;
  } else if (max <= 1 && max > 0) {
    numRanges = 1;
  }

  const colorRanges = {
    [`step${numRanges}`]: colors.chart[900],
    [`step${numRanges - 1}`]: colors.chart[700],
    [`step${numRanges - 2}`]: colors.chart[500],
    [`step${numRanges - 3}`]: colors.chart[300],
    [`step${numRanges - 4}`]: colors.chart[100],
    [`step${numRanges - 5}`]: colors.grey3[300]
  };

  for (let i = 1; i <= numRanges; i++) {
    const percentageGap = 100 / numRanges;
    step[`step${i}`] = Math.round((max * (percentageGap * i)) / 100);
  }

  let ranges = [
    {
      to: 0,
      from: 0,
      step: 0,
      color: colors.grey3[300]
    }
  ];
  for (let i = 1; i <= numRanges; i++) {
    let range = {
      to: step[`step${i}`],
      from: step[`step${i - 1}`] || 0 + 0.1,
      step: i,
      color: colorRanges[`step${i}`]
    };
    ranges.push(range);
  }
  return ranges;
});

const methods = reactive({
  updateJSONCountry: (dataset) => {
    // Find the max value in the dataset
    state.maxValue = Math.max(
      ...dataset.map((item) => {
        return item.startupId.length;
      })
    );
    // Change JSON: add the how many items are associated to a specific country
    state.countryDataGeoChart = statesData.features.reduce((acc, country) => {
      let countryIndex = dataset.findIndex((info) => {
        return info.collection === "Hong Kong"
          ? country.properties.name_ciawf === "China"
          : country.properties.name_ciawf === info.collection;
      });
      if (countryIndex !== -1) {
        acc[country.properties.iso_a2_eh] =
          dataset[countryIndex].startupId.length;
      }
      return acc;
    }, {});
  }
});
const emits = defineEmits(["dataPointSelection"]);
onMounted(() => {
  bus.on("dataPointSelection", (data) => {
    showDropdownMenu(data.mousePosition, data.title);
  });
});
function showDropdownMenu(mousePosition, title) {
  emits("dataPointSelection", { mousePosition, title });
}
onUnmounted(() => {
  bus.off("dataPointSelection");
});
watch(
  () => wrappedDataset.value,
  (dataset) => {
    if (!dataset) {
      return;
    }
    methods.updateJSONCountry(dataset);
  },
  { immediate: true }
);
</script>
