import { functionsEU } from "@plugins/firebase.js";
import { httpsCallable } from "firebase/functions";
import { default as classes } from "@share/errorClasses.js";
import validate from "validate.js";

import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
  updateDoc
} from "firebase/firestore";
import { format } from "date-fns";
export async function createComment(data) {
  try {
    const validationRules = {
      accountId: {
        presence: { allowEmpty: false },
        type: "string"
      },
      context: {
        presence: { allowEmpty: false },
        type: "string",
        inclusion: {
          within: [
            "Application Notes (Why Fit)",
            "Internal Corporate Comment",
            "Internal Rejection to Startup",
            "Rialto Rejection",
            "Message to Startup",
            "MTB Internal Notes",
            "MTB Notes",
            "Public Rejection to Startup",
            "Rejection To Startup Application"
          ]
        }
      },
      text: {
        presence: { allowEmpty: false },
        type: "string"
      },
      opportunityId: {
        presence: { allowEmpty: false },
        type: "string"
      }
    };
    const validationErrors = validate(data, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }

    const httpsCallableFunction = httpsCallable(
      functionsEU,
      "comments-createComment"
    );

    const response = await httpsCallableFunction(data);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
export async function getComments(data) {
  try {
    const validationRules = {
      accountId: {
        presence: { allowEmpty: false },
        type: "string"
      },
      opportunityId: {
        presence: { allowEmpty: false },
        type: "string"
      }
    };

    const validationErrors = validate(data, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }

    const httpsCallableFunction = httpsCallable(
      functionsEU,
      "comments-getComments"
    );

    const response = await httpsCallableFunction(data);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getStartupApplicationRejectionReason(data) {
  try {
    const validationRules = {
      startupId: {
        presence: { allowEmpty: false },
        type: "string"
      },
      challengeId: {
        presence: { allowEmpty: false },
        type: "string"
      }
    };

    const validationErrors = validate(data, validationRules);

    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }

    const firestore = getFirestore();

    const applicationsRef = collection(
      firestore,
      `startups/${data.startupId}/applications`
    );
    const q = query(applicationsRef, where("ChallId", "==", data.challengeId));

    const querySnapshot = await getDocs(q);

    const rejectedApplication = querySnapshot.docs.filter((doc) => {
      const applicationData = doc.data();
      return (
        applicationData.suggested === false && applicationData.rejectionReason
      );
    });

    return rejectedApplication.map((application) => {
      const applicationData = application.data();
      return {
        Contact__c: null,
        Context__c: "Rejection To Startup Application",
        MTBer_Email__c: applicationData.Advisor,
        SystemModstamp: timestampToUtc(applicationData.appliedDate),
        Text__c: applicationData.rejectionReason
      };
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
}
export async function setApplicationRejectionReason(data) {
  try {
    const validationRules = {
      message: {
        presence: { allowEmpty: false },
        type: "string"
      },
      accountId: {
        presence: { allowEmpty: false },
        type: "string"
      },
      applicationId: {
        presence: { allowEmpty: false },
        type: "string"
      }
    };

    const validationErrors = validate(data, validationRules);

    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }
    const firestore = getFirestore();
    const startupRef = doc(firestore, "startups", data.accountId);
    const applicationDocRef = doc(
      startupRef,
      "applications",
      data.applicationId
    );
    const applicationDocSnap = await getDoc(applicationDocRef);
    if (applicationDocSnap.exists()) {
      const applicationData = applicationDocSnap.data();
      const updatedData = {
        ...applicationData,
        rejectionReason: data.message
      };
      await updateDoc(applicationDocRef, updatedData);
    } else {
      throw new Error("Application not found", data.applicationId);
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getStartupApplicationComments(data) {
  try {
    const validationRules = {
      startupId: {
        presence: { allowEmpty: false },
        type: "string"
      },
      challengeId: {
        presence: { allowEmpty: false },
        type: "string"
      },
      opportunityId: {
        presence: { allowEmpty: false },
        type: "string"
      }
    };

    const validationErrors = validate(data, validationRules);

    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }

    const firestore = getFirestore();

    const applicationsRef = collection(
      firestore,
      `startups/${data.startupId}/applications`
    );
    const q = query(
      applicationsRef,
      where("ChallId", "==", data.challengeId),
      where("OppId", "==", data.opportunityId)
    );

    const querySnapshot = await getDocs(q);

    //Pitchdeck query

    const whyFitApplications = querySnapshot.docs.filter((doc) => {
      const applicationData = doc.data();
      return applicationData.suggested === true && applicationData.whyFit;
    });

    return whyFitApplications.reduce((acc, application) => {
      const applicationData = application.data();
      return [
        ...acc,
        {
          Contact__c: null,
          Context__c: "Application Notes (Why Fit)",
          MTBer_Email__c: applicationData.Advisor,
          SystemModstamp: timestampToUtc(applicationData.appliedDate),
          Text__c: applicationData.whyFit
        },
        {
          Contact__c: null,
          //TODO: copy
          Context__c: "Opportunity Pitch-deck",
          MTBer_Email__c: applicationData.Advisor,
          SystemModstamp: timestampToUtc(applicationData.appliedDate),
          Text__c: "pitch-deck.pdf",
          Pitcheck_URL: applicationData.pitchdeckURL
        }
      ];
    }, []);
  } catch (error) {
    console.error(error);
    throw error;
  }
}

function timestampToUtc(timestamp) {
  if (!timestamp || !timestamp.seconds || !timestamp.nanoseconds) {
    const currentDate = new Date();
    return format(currentDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
  }

  const milliseconds =
    timestamp.seconds * 1000 + (timestamp.nanoseconds || 0) / 1000000;
  const date = new Date(milliseconds);
  return format(date, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
}
