<template>
  <div :id="id" class="flex flex-col items-center justify-center w-full">
    <apexchart
      class="w-full"
      type="donut"
      :height="350"
      :options="chartOptions"
      :series="series"
      @dataPointSelection="showDropdownMenu"
    ></apexchart>

    <div class="flex flex-col justify-center mt-3 w-full">
      <div
        v-for="(legend, index) in categories"
        :key="legend"
        class="flex items-center justify-between gap-x-2"
      >
        <div class="flex items-center text-mtb-text gap-x-2">
          <div
            class="w-4 h-4 rounded-full"
            :style="`background:${activeColors[index]}`"
          />
          {{ legend }}
        </div>
        <div class="fw-bold text-mtb-text">
          {{ series[index] }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { singleStateChart, toolbar } from "@/global/configs/analytics.js";
import colors from "@/plugins/color.js";
import { computed } from "vue";

const props = defineProps({
  id: {
    type: String,
    required: true
  },
  chartProps: {
    type: Object,
    required: true
  }
});

const dataAfterSort = computed(() => {
  let chartData = { ...props.chartProps.chartData };
  //convert to data to this format {x:3,y:5}
  for (let key in chartData) {
    let startups = chartData[key].length;
    chartData[key] = startups;
  }
  //sort if needed
  if (props.chartProps.sort) {
    let sortedArr = Object.entries(chartData).sort((a, b) => b[1] - a[1]);
    chartData = Object.fromEntries(sortedArr);
  }
  return chartData;
});
const series = computed(() => {
  //[1,2,3]
  return Object.values(dataAfterSort.value);
});

const categories = computed(() => {
  //['a','b','c']
  return Object.keys(dataAfterSort.value);
});
const activeColors = computed(() => {
  return [colors.chart[900], colors.chart[500], colors.chart[200]];
});
const chartOptions = computed(() => {
  if (props.chartProps.isLoading || props.chartProps.isError) {
    return {};
  }
  return {
    states: singleStateChart,
    legend: {
      show: false,
      position: "bottom"
    },
    labels: categories.value,
    chart: {
      height: props.chartProps.chartHeight,
      type: "donut",
      toolbar: toolbar(-40)
    },
    chartTitle: {
      text: ""
    },
    dataLabels: {
      enabled: false,
      style: {
        fontSize: "14px"
      }
    },
    tooltip: {
      theme: "light",
      custom: function ({ series, seriesIndex, w }) {
        return `
            <div class="p-1 chart-tooltip d-flex align-items-center"><span class='svg-icon'><svg height="25" viewBox="0 96 960 960" width="25"><path d="M480.191 804Q386 804 319 737.191q-67-66.808-67-161Q252 482 318.809 415q66.808-67 161-67Q574 348 641 414.809q67 66.808 67 161Q708 670 641.191 737q-66.808 67-161 67Z" fill="${w.globals.colors[seriesIndex]}"/></svg></span>
            ${categories.value[seriesIndex]}:
            <b>
            ${series[seriesIndex]} </b>
            </div>`;
      }
    },
    plotOptions: {
      pie: {
        donut: {
          enableShades: false, // Disable default opacity
          labels: {
            show: true,
            name: {
              show: true
            },
            value: {
              show: true
            },
            total: {
              show: true,
              showAlways: true,
              label: "Total"
            }
          },
          expandOnClick: false
        }
      }
    },
    colors: activeColors.value,
    fill: {
      type: "solid",
      opacity: 1 // Set opacity to 1
    }
  };
});

const emits = defineEmits(["dataPointSelection"]);
function showDropdownMenu(a, _b, c) {
  const mousePosition = {
    offsetX: a.offsetX,
    offsetY: a.offsetY
  };
  const title = categories.value[c.dataPointIndex];
  emits("dataPointSelection", { mousePosition, title });
}
</script>

<style scoped lang="scss">
:deep(.apexcharts-legend.apexcharts-align-center.apx-legend-position-bottom) {
  display: flex;
  flex-direction: column;
}
:deep(.apexcharts-legend-marker) {
  display: none;
}
:deep(.apexcharts-legend-text) {
  width: 100%;
}
</style>
