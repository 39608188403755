import { sentryErrorManagement } from "@plugins/sentry.js";
import { default as errorUtil } from "@share/errorUtil.js";
import { useToast } from "primevue/usetoast";

//TODO: add a error handling that will not show a toast to user but sending to sentry
export const userErrorHandling = () => {
  const toast = useToast();
  const errorManager = ({
    error,
    functionPath,
    userErrorMessage,
    interfaceAction
  }) => {
    const errorOnClientSide = {
      errorName: error.name,
      errorMessage: error.message,
      errorStack: error.stack,
      functionPath,
      userErrorMessage,
      suggestionType: errorUtil.getErrorSuggestion(error),
      interfaceAction: interfaceAction || "TOAST"
    };
    if (process.env.NODE_ENV === "development") {
      console.log("####ERROR: ", errorOnClientSide);
    }
    if (isTemporaryError(error.message)) {
      if (process.env.NODE_ENV === "development") {
        console.log("####ERROR: ", errorOnClientSide);
      }
      //if temporary error skip the interface action and skip sentry
      return;
    }
    //send to sentry the error object only if the error is in the FE
    if (error.details !== errorUtil.CLOUD_FUNCTION_ERROR) {
      sentryErrorManagement(errorOnClientSide);
    }
    //start user interaction, es toast
    clientErrorFeedback(errorOnClientSide);
  };
  function isTemporaryError(message) {
    //TODO populate with other error message
    return message.includes("deadline-exceeded");
  }
  const clientErrorFeedback = (error) => {
    if (error.interfaceAction === "TOAST") {
      toast.add({
        severity: "error",
        summary: `${error.userErrorMessage || "Something went wrong"}`,
        ...(error.suggestionType && { detail: error.suggestionType }),
        life: 10000
      });
    }
  };

  return { errorManager };
};
