import { functionsEU } from "@plugins/firebase.js";
import { httpsCallable } from "firebase/functions";
import validate from "validate.js";

import { default as classes } from "@share/errorClasses.js";
const options = {
  timeout: 180000
};
export async function challengeToStartupSuggestions(data) {
  //    ChallengeId: this.seedChallId,
  // internalUse: this.internalMTBUse
  try {
    //validation
    const validationRules = {
      data: {
        presence: { allowEmpty: false },
        type: "object"
      },
      "data.ChallengeId": {
        presence: { allowEmpty: false },
        type: "string"
      },
      "data.internalUse": {
        presence: false,
        type: "boolean"
      }
    };
    const validationErrors = validate({ data }, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }

    const httpsCallableFunction = httpsCallable(
      functionsEU,
      "RialtoFlow-C2S_MatchingQuery",
      options
    );
    const result = await httpsCallableFunction(data);
    return result.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

//used in "my ecosystem" corporate tab for institutions
export async function challengeToStartupSuggestionsWithoutOpps(data) {
  //    ChallengeId: this.seedChallId,
  // internalUse: this.internalMTBUse
  try {
    //validation
    const validationRules = {
      data: {
        presence: { allowEmpty: false },
        type: "object"
      },
      "data.ChallengeId": {
        presence: { allowEmpty: false },
        type: "string"
      }
    };
    const validationErrors = validate({ data }, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }

    const httpsCallableFunction = httpsCallable(
      functionsEU,
      "RialtoFlow-C2S_MatchingQueryV3",
      options
    );
    const result = await httpsCallableFunction(data);
    return result.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getSimilarStartups(data) {
  try {
    //validation
    const validationRules = {
      AccountId: {
        presence: { allowEmpty: false },
        type: "string"
      }
    };
    const validationErrors = validate(data, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }
    const httpsCallableFunction = httpsCallable(
      functionsEU,
      "RialtoFlow-StartupSimilarityQuery"
    );
    const result = await httpsCallableFunction(data);
    return result.data;
  } catch (error) {
    throw error;
  }
}

export async function getStartupSuggestedChallenges(startupId) {
  try {
    //validation
    const validationRules = {
      startupId: {
        presence: { allowEmpty: false },
        type: "string"
      }
    };
    const validationErrors = validate({ startupId }, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }

    const httpsCallableFunction = httpsCallable(
      functionsEU,
      "RialtoFlow-S2C_MatchingQuery",
      options
    );

    const payload = {
      AccountId: startupId
    };
    const response = await httpsCallableFunction(payload);
    return response.data.suggestions;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
