export const getBaseCss = ({
  defaultCountryFillColor,
  countryStrokeColor,
  legendHeaderBackgroundColor,
  legendContentBackgroundColor,
  legendFontColorHeader,
  legendFontColorContent,
  legendBorderRadius,
  legendBorderColor,
  legendBoxShadow
}) =>
  `.vue-world-map .land{
    fill:${defaultCountryFillColor};
    stroke:${countryStrokeColor};
  }
  .vue-map-legend-header{
    background:${legendHeaderBackgroundColor}
  }
  .vue-map-legend .vue-map-legend-content{
    background:${legendContentBackgroundColor}
  }
  .vue-map-legend-content span{
    color:${legendFontColorContent}
  }
  .vue-map-legend-header span{
    color:${legendFontColorHeader}
  }
  .vue-map-legend{
    border-color: ${legendBorderColor}!important;
    border-radius:${legendBorderRadius}px;
    box-shadow: ${legendBoxShadow ? "3px 4px #00000017" : "none"};
  }
  `;
function getColor(total, availableColor) {
  let value = availableColor.find(
    (ele) => ele.from <= total && total <= ele.to
  );
  return value?.color;
}
export const getDynamicMapCss = (
  countryData,
  availableColor,
  fallbackColor
) => {
  let mapColor = {};
  const css = [];
  Object.keys(countryData).forEach((key) => {
    const value = countryData[key];
    const color = getColor(value, availableColor);
    mapColor[key] = color || fallbackColor;
    css.push(`.vue-world-map #${key} { fill: ${color || fallbackColor}; }`);
  });
  return { css, mapColor };
};

export const getCombinedCssString = (baseCss, dynamicCss) => {
  dynamicCss.push(baseCss);

  return dynamicCss.join(" ");
};
