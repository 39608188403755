<template>
  <div>
    <Chips
      :disabled="props.disabled"
      :inputProps="{
        inlineEditable: props.inlineEditable,
        editable: props.editable
      }"
      ref="test"
      :model-value="value"
      separator=","
      :allowDuplicate="false"
      :placeholder="placeholder"
      :inputClass="{ 'bg-red-300': errorMessage }"
      aria-label="Tags"
      :isValid="!errorMessage"
      aria-describedby="chips-error"
      :class="containerClass"
      @add="addNewTag"
      @remove="removeTag"
    >
      <template #removetokenicon="{ removeCallback }">
        <div @click="removeCallback" class="cursor-pointer">
          <base-vite-icon
            v-if="props.editable"
            name="close"
            classes=" ms-2 w-5 y-5 stroke-primary stroke-2 "
          /></div></template
    ></Chips>
    <small v-if="errorMessage" class="text-rose-700" id="chips-error">{{
      errorMessage
    }}</small>
  </div>
</template>

<script setup>
import { ref, watch, onMounted, watchEffect, onUnmounted } from "vue";
import * as yup from "yup";
import Chips from "primevue/chips";
import { bus } from "@/main.js";
// import { useField } from "vee-validate";

const emit = defineEmits(["update:modelValue", "tag-changed"]);
const props = defineProps({
  addedTags: {
    type: Array,
    default: () => []
  },
  placeholder: {
    type: String,
    default: () => "select an option"
  },
  name: {
    type: String,
    default: () => ""
  },
  type: {
    type: String,
    default: () => "text"
  },
  disabled: {
    type: Boolean,
    default: false
  },
  inlineEditable: {
    type: Boolean,
    default: true
  },
  editable: {
    type: Boolean,
    default: true
  },
  containerClass: {
    type: String,
    default: "border-0"
  }
});
const errorMessage = ref(null);

const value = ref(props.addedTags);

// const { value, errorMessage } = useField(
//   props.name,
//   yup.lazy((value) => validationSchema()),
//   {
//     initialValue: addedTags
//   }
// );
async function addNewTag(inputValue) {
  try {
    await validationSchema().validate(inputValue.value);
    value.value = inputValue.value;
    if (errorMessage.value) errorMessage.value = null;
    emit("tag-changed", value.value);
  } catch (error) {
    console.log(error);
    inputValue.value.pop();
    value.value = inputValue.value;
    errorMessage.value = error.message;
  }
}
function removeTag(removedValue) {
  const clonedValue = [...value.value];
  const index = clonedValue.findIndex((ele) => ele === removedValue.value[0]);
  clonedValue.splice(index, 1);
  value.value = clonedValue;
  emit("tag-changed", value.value);
}
function validationSchema() {
  if (props.type === "email") {
    return yup
      .array()
      .of(yup.string().email("Please provide a valid email").required());
  } else if (props.type === "number") {
    return yup
      .array()
      .of(yup.number("Please provide a valid number").required());
  } else {
    return yup
      .array()
      .of(yup.string("Please provide a valid string").required());
  }
}

watch(
  () => value.value,
  (newVal) => {
    emit("update:modelValue", newVal);
  }
);
watchEffect(() => {
  value.value = props.addedTags;
});
onMounted(() => {
  bus.on("discard-changes", () => {
    errorMessage.value = null;
    value.value = props.addedTags;
  });
});
onUnmounted(() => {
  bus.off("discard-changes");
});
</script>

<style lang="scss" scoped></style>
