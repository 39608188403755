<template>
  <mtb-sidebar-template
    id="mtb-filter-sidebar"
    :group-config="filterSideBarConfig.filter((ele) => ele.isShow)"
    aria-labelledby="filterSidebar"
    classes="offcanvas-start"
    group-classes="mtb-sidebar-body--gap-sm"
  >
    <template #mtb-sidebar-group-body-searchBy>
      <mtb-vue-tags-input
        :placeholder="
          !keywordsQuery || keywordsQuery.length === 0
            ? 'Search by Keywords...'
            : ''
        "
        classes="mtb-vue-tag-input--sm"
        :tags="filterKeywords"
        @onTagsChanged="onTagsChanged"
        @onClearAll="clearKeywordSearch"
      />
    </template>

    <template #mtb-sidebar-group-body-filterBy>
      <div
        class="mtb-sidebar-group-body-item"
        :class="Object.keys(filterConfigBar).length > 3 && 'scrollable'"
        :style="{
          '--max-height':
            filterSideBarConfig.filter((ele) => ele.isShow).length < 2
              ? '80vh'
              : '48vh'
        }"
      >
        <div
          v-for="(thisValue, name) in filterConfigBar"
          :key="name"
          ref="selectors"
          class="form-group"
        >
          <multiselect
            v-if="thisValue.showSelector !== false"
            :id="name"
            :ref="name"
            v-model="filterQueryKeys[name]"
            :max="thisValue.max ? thisValue.max : computedOptions[name].length"
            :loading="thisValue.serverSide && serverSideFiltersLoader[name]"
            :taggable="true"
            label="label"
            track-by="value"
            :placeholder="thisValue.name"
            :clear-on-select="false"
            :multiple="true"
            :show-labels="false"
            :close-on-select="false"
            :options="computedOptions[name]"
          >
            <template #caret="{ toggle }">
              <mtb-arrow-icon-select :toggle="toggle" />
            </template>
          </multiselect>
        </div>
      </div>
    </template>

    <template #mtb-sidebar-group-body-sortBy>
      <div class="hstack gap-2">
        <select
          :value="sortQuery.sortby || null"
          class="form-control"
          @change="
            (event) => {
              onSortingSelect(event);
            }
          "
        >
          <option disabled :value="null">-- Select a field to sort--</option>
          <option
            v-for="se in sortConfig.options"
            :key="se.path"
            :value="se.path"
          >
            {{ se.name }}
          </option>
        </select>
        <button
          :disabled="!sortQuery.sortby"
          class="mtb-btn-secondary ms-1 d-flex btn-sm"
          @click="
            () => {
              onSortingButton();
            }
          "
        >
          <mtb-svg :icon-name="sortIcon" :scale="6" />
        </button>
      </div>
    </template>
    <template #mtb-sidebar-footer>
      <div class="w-100">
        <button class="btn btn-primary mt-2" @click="ClearFilters">
          Clear Filters
        </button>
      </div>
    </template>
  </mtb-sidebar-template>
</template>

<script>
import { bus } from "@/main.js";
import { spacialSortObject } from "@/modules/corporates/helpers/analytics.js";
import { objectsEqual } from "@/helpers/function/util.js";
import { safAccObj } from "@global/helpers/util.js";
import { debounce } from "lodash";
import Multiselect from "vue-multiselect";
export default {
  components: { Multiselect },

  props: {
    /** An array containing the paths of the fields where I should look for KEYWORD SEARCH
     * @type {String[]} Every field has to be a string and can use dot notation (e.g. Challenge_Corporate_Sponsor__r.Industry_Sector__c)
     */
    keywordSearchScope: {
      type: Array,
      default: () => {
        return [];
      }
    },

    /**
     * Type definition of filterConfigObj
     * @typedef filterConfigObj
     * @type {Object}
     * @property {String} name - Name of the property, which will be displayed in the filter selector
     * @property {String} path - Path where the property to be filtered by can be accessed in the Object. Should be a string and can use dot notation (e.g. Challenge_Corporate_Sponsor__r.Industry_Sector__c)
     * @property {';' | String | null } [split] Character by which a list of property should be splitted. SF.com generally stores e.g. Verticals as a string whith values separated by ';'
     * @property {Array.<{value: String, label: String}>} [values] Predetermined values in the dropdown menu. If serverSide is true, values have to be provided.
     * @property  {Boolean} [showNull] When filter by one property, should items with a null/empty value for that property be shown?
     * @property {Boolean} [serverSide] The filtering of this property will be taken care by the server. This component will only serve as a selector for this property
     * @property {Boolean} [showSelector] Wether to show the selector or not. Used to provide a filter, without giving the user the option to filter :)
     */

    /**
     * Configuration object for FILTER. Give each object a unique name, that will be used in the URL
     * @type {{filterConfigObj}}
     * */
    filterConfigBar: {
      type: Object,
      default: () => ({}),
      validator: (val) => {
        return Object.keys(val).every((el) => {
          if (el.serverSide && !el.values) {
            console.error(
              `${val} key filter config is serverSide, but you provided no values`
            );
            return false;
          }
          if (
            el.values &&
            el.values.some((el) => !("value" in el) || !("label" in el))
          ) {
            console.error(
              `property values contain invalid data. Should be an array of object each of them containing label and value properties.`
            );
            return false;
          }

          return true;
        });
      }
    },

    /**
     * @typedef sortConfigOpts
     * @type {Object}
     * @property {String} name Name of the property, which will be displayed in the sort selector
     * @property {String} path Field to sort by. Has to be a string and can use dot notation (e.g. Challenge_Corporate_Sponsor__r.Industry_Sector__c)
     */

    /**
     * @typedef sortConfigObj
     * @type {Object}
     * @property {sortConfigOpts[]} options Name of the property, which will be displayed in the sort selector
     * @property {Boolean} [serverSide] The sorting will be taken care by the server. This component will only serve as a selector
     */

    /**
     * The configuration object for the SORTING
     * @type {sortConfigObj}
     */
    sortConfig: {
      type: Object,
      default: () => ({})
    },
    /**
     * Input data can be an array of objects or an object of objects. Where each object is an item that should be filtered/sorted/searched for.
     * @type {{Object}[] | {Object}}
     */
    inputData: {
      type: Array,
      default: () => {
        return [];
      }
    },
    isServerSide: {
      type: Boolean,
      default: false
    }
  },
  emits: ["filter-applied", "filter-applied-model", "server-filter-needed"],
  data() {
    return {
      /**
       * Resulting filtered/sorted/searched items for every fields that is not marked as serverSide
       * @type  {{Object}[] | {Object}}
       */
      outputData: [],
      show: false,
      filterKeywords: [],
      keywordsQuery: [],
      filterQueryKeys: {},
      sortQuery: {},
      firstActiveServerUpdate: true,
      serverSideFiltersLoader: {},
      //List of custom sort
      specialSortList: ["StageName"],
      firstTrigger: false
    };
  },
  computed: {
    filterSideBarConfig() {
      return [
        {
          groupId: "searchBy",
          title: "Search...",
          iconName: "search",
          subTitle: "",
          iconStyle: {
            scale: 5,
            fill: "mtb-heading",
            stroke: "mtb-heading",
            strokeWidth: 2,
            iconPosition: "icon-front" //'icon-back'
          },
          isShow: this.keywordSearchScope.length > 0
        },
        {
          groupId: "sortBy",
          title: "Sort By...",
          iconName: "sort_by_alpha",
          subTitle: "",
          iconStyle: {
            scale: 5,
            fill: "mtb-heading",
            stroke: "mtb-heading",
            strokeWidth: 2,
            iconPosition: "icon-front" //'icon-back'
          },
          isShow: this.sortConfig?.options?.length > 0
        },
        {
          groupId: "filterBy",
          title: "Filter By...",
          iconName: "filter_list",
          subTitle: "",
          iconStyle: {
            scale: 5,
            fill: "mtb-heading",
            stroke: "mtb-heading",
            strokeWidth: 2,
            iconPosition: "icon-front" //'icon-back'
          },
          isShow: Object.keys(this.filterConfigBar).length > 0
        }
      ];
    },
    sortIcon() {
      return this.sortQuery.sortdir === "asc" ? "sort_up" : "sort_down";
    },
    frontFilterKeys() {
      // returns the keys (values) where the filtering should be done FrontEnd
      return Object.keys(this.filterQueryKeys).filter(
        (k) => k in this.filterConfigBar && !this.filterConfigBar[k]?.serverSide
      );
    },
    activeServerFilterKeys() {
      return Object.fromEntries(
        Object.keys(this.filterQueryKeys)
          .filter(
            (k) =>
              k in this.filterConfigBar && this.filterConfigBar[k]?.serverSide
          )
          .map((key) => [key, this.filterQueryKeys[key]])
      );
    },
    keywordFilterActive() {
      return (
        this.keywordSearchScope.length > 0 && this.keywordsQuery?.length > 0
      );
    },
    inputIsArray() {
      return Array.isArray(this.inputData);
    },
    normalizedInputData() {
      return this.inputIsArray
        ? this.inputData
        : Object.entries(this.inputData).map((entry) => {
            return { ...entry[1], tempID: entry[0] };
          });
    },
    computedOptions() {
      // console.log("I'm calculating options - calculating computedOptions");
      // Calculates the different options or uses the predefined 'values' for every filed

      return Object.entries(this.filterConfigBar).reduce((acc, curr) => {
        let currObj = { ...curr[1], id: curr[0] };
        let valuesSet = new Set(
          this.inputData.reduce((allValues, thisItem) => {
            let rawValue = (safAccObj(thisItem, currObj.path) || "").toString();

            return [
              ...allValues,
              ...(currObj.split ? rawValue.split(currObj.split) : [rawValue])
            ];
          }, [])
        );
        valuesSet.delete("");

        // If the parent is passing the list of values uses those
        if (currObj.values)
          acc[currObj.id] = currObj.values.reduce((options, v) => {
            if (
              !v.value ||
              (currObj.hideUnavailable && !valuesSet.has(v.value))
            )
              return options;

            options.push({
              value: v.value,
              label: v.label
            });
            return options;
          }, []);
        // Otherwise it just 'creates' it's list of values from the values existing in the data.
        else {
          acc[currObj.id] = Array.from(valuesSet)
            .sort()
            .map((v) => ({
              value: v,
              label: v
            }));
        }
        return acc;
      }, {});
    },
    overallQuery() {
      let res = {
        ...this.sortQuery,
        ...this.filterQueryKeys
      };
      if (this.keywordsQuery?.length) res["kwd"] = this.keywordsQuery;
      // console.log("I'm calculating overallQuery", res);

      return res;
    }
  },
  watch: {
    sortConfig: {
      deep: true,
      handler() {
        this.sortQuery = {
          sortby:
            (this.sortConfig.defaultSort?.sortby ||
              this.sortConfig.options?.[0]?.path) ??
            "",
          sortdir: this.sortConfig?.defaultSort?.sortdir || "asc"
        };
      }
    },
    filterQueryKeys: {
      deep: true,
      handler() {
        Object.keys(this.filterQueryKeys).map((filterKey) => {
          if (this.filterQueryKeys[filterKey].length === 0) {
            delete this.filterQueryKeys[filterKey];
          }
        });
      }
    },
    inputData: {
      deep: true,
      async handler() {
        // console.log(
        //   "Input Data has changed - will run updateRouteQueryFromFilters"
        // );
        setTimeout(async () => {
          this.updateRouteQueryFromFilters();
          await this.$nextTick();
          this.calculateOutputData();
        }, 0);
      }
    },
    overallQuery: {
      deep: true,
      async handler() {
        // console.log(
        //   "Overall Query has changed - will run updateRouteQueryFromFilters"
        // );
        // console.info();
        this.updateRouteQueryFromFilters();
        await this.$nextTick();
        this.calculateOutputData();
      }
    }
  },
  async created() {
    // Assign sort query to a default value
    this.sortQuery = {
      sortby:
        (this.sortConfig.defaultSort?.sortby ||
          this.sortConfig.options?.[0]?.path) ??
        "",
      sortdir: this.sortConfig?.defaultSort?.sortdir || "asc"
    };
    window.addEventListener("keydown", (e) => {
      if (e.ctrlKey && e.key === "f") {
        e.preventDefault();
        this.show = this.show ? false : true;
      }
    });

    //  This event has to be triggered to change the filter from outside the filter, after having changed the  queryKeys prop

    (async () => {
      this.updateFiltersFromRouteQuery();
      await this.$nextTick();
      this.calculateOutputData();
    })();

    // Listen to update sorting event from table
    bus.on("update-sorting", (data) => {
      this.sortQuery = data;
    });
    bus.on("update-keyword-tags", (newTags) => {
      this.filterKeywords = newTags;
      this.keywordsQuery = newTags;
      this.calculateOutputData();
    });
    bus.on("filter-from-recap-g", (newValues) => {
      this.sortQuery = Object.fromEntries(
        Object.entries(newValues).filter(([k]) =>
          ["sortby", "sortdir"].includes(k)
        )
      );
      this.keywordsQuery = Object.fromEntries(
        Object.entries(newValues).filter(([k]) => k == "kwd")
      ).kwd;
      // Also updates the "working array" of the vue-tag component

      this.filterKeywords = Object.entries(newValues)
        .filter(([k]) => k == "kwd")
        .map(([k, v]) => (v === "string" ? v : v[0]));
      this.filterQueryKeys = Object.fromEntries(
        Object.entries(newValues).filter(([k]) => k in this.filterConfigBar)
      );
    });
    // When this event is triggered all the filters are cleared
    bus.on("clear-filters-g", async () => {
      this.ClearFilters();
    });
  },
  methods: {
    onSortingButton() {
      this.sortQuery["sortdir"] =
        this.sortQuery.sortdir === "asc" ? "desc" : "asc";
      if (this.sortConfig.serverSide) {
        this.serverSideFiltersLoader["sort"] = true;
        if (this.isServerSide) {
          this.debouncedServerFilterEmit();
        }
      }
    },
    onSortingSelect(event) {
      this.sortQuery["sortby"] = event.target.value;
      if (this.sortConfig.serverSide) {
        this.serverSideFiltersLoader["sort"] = true;
        if (this.isServerSide) {
          this.debouncedServerFilterEmit();
        }
      }
      //If no direction is previously set, asc by default
      if (!this.sortQuery.sortdir) this.sortQuery["sortdir"] = "asc";
    },

    onTagsChanged(newTags) {
      this.filterKey;
      this.keywordsQuery = Object.values(newTags);
    },
    calculateOutputData() {
      //debugger;

      // console.log("I'm Filtering Data - calculating outputData");
      // Initializes process variable

      let filterData = [...this.normalizedInputData];
      // debugger;
      // Enters the filter only if needed

      if (this.frontFilterKeys.length > 0 || this.keywordFilterActive) {
        // console.log(
        //   "Gonna filter based on this",
        //   this.frontFilterKeys,
        //   this.keywordFilterActive
        // );

        filterData = [...this.normalizedInputData].reduce(
          (filterResult, obj) => {
            let shouldShow = {};
            // Filter based on FilterKeys Conditions
            for (let fk of this.frontFilterKeys) {
              // Dev can define for each key if nullish values (null|undefined|'') are included or excluded by default

              let PropertyText = safAccObj(obj, this.filterConfigBar[fk].path);

              // if value is null|undefined|'' just confirms the value given above (always includes/excludes)
              // TODO: is it necessary to filter by null values?
              if (PropertyText == null || PropertyText === "") {
                shouldShow[fk] = this.filterConfigBar[fk].showNull
                  ? true
                  : false;
                continue;
              }

              if (this.filterConfigBar[fk].split) {
                // When the value comes in a string separated by e.g. ";" like in sf.com Verticals

                let valuesFromItem = PropertyText.split(
                  this.filterConfigBar[fk].split
                );

                if (
                  this.filterQueryKeys[fk].some((el) =>
                    valuesFromItem.includes(el.value)
                  )
                )
                  shouldShow[fk] = true;
                else shouldShow[fk] = false;
              } else if (
                this.filterQueryKeys[fk].some((el) => {
                  return el.value.toString() == PropertyText.toString();
                })
              ) {
                // When the value is a simple
                shouldShow[fk] = true;
              } else {
                shouldShow[fk] = false;
              }
            }

            //Filters based on text search
            if (this.keywordFilterActive) {
              let KeywordSearchTarget = this.keywordSearchScope
                .map((field) => safAccObj(obj, field))
                .join(" ")
                .toLowerCase();

              shouldShow["keywords"] = this.keywordsQuery?.some((kw) => {
                let keyWord = typeof kw === "string" ? kw.toLowerCase() : "";
                if (kw.startsWith('"') && kw.endsWith('"'))
                  keyWord = " " + keyWord + " ";
                return KeywordSearchTarget.includes(keyWord);
              });
            }

            let shouldPassFilter = Object.values(shouldShow).reduce(
              (acc, curr) => {
                return acc && curr;
              },
              true
            );

            if (shouldPassFilter) {
              filterResult.push(obj);
            }

            return filterResult;
          },
          []
        );
      }

      //Sort Filtered Data

      let sortedData;
      // var presetOrder = ["jim", "steve", "david"]; // needn't be hardcoded

      // function sortSpecial(arr, key) {
      //   var result = [],
      //     i,
      //     j;
      //   for (i = 0; i < presetOrder.length; i++)
      //     while (-1 != (j = arr.indexOf(presetOrder[i])))
      //       result.push(arr.splice(j, 1)[0]);
      //   return result.concat(arr);
      // }

      // var sorted = sortSpecial(["bob", "david", "steve", "darrel", "jim"]);

      if (this.sortQuery?.sortby && !this.sortConfig.serverSide) {
        // console.log("check filterData", filterData);
        // if (this.sortQuery?.sortby === "default") {
        //   sortedData = filterData;
        // } else {
        const specialSortingKey = this.specialSortList.find((sort) =>
          this.sortQuery?.sortby.includes(sort)
        );
        if (specialSortingKey) {
          sortedData = spacialSortObject({
            object: filterData,
            path: this.sortConfig.options?.find(
              (option) => option.path === this.sortQuery?.sortby
            )?.path,
            key: specialSortingKey
          });
        } else {
          sortedData = filterData.sort((a, b) =>
            new Intl.Collator().compare(
              a[this.sortQuery?.sortby] || "_",
              b[this.sortQuery?.sortby] || "_"
            )
          );
          // console.log("check sortedData", sortedData);
        }

        if (this.sortQuery?.sortdir == "desc") sortedData.reverse();
        // }
      } else {
        sortedData = filterData;
      }

      // TODO: ADD SORTING OF DATA
      this.outputData = this.inputIsArray
        ? sortedData
        : Object.fromEntries(sortedData.map((fd) => [fd.tempID, fd]));
      /**
       * Triggers when updated outputData is available for the parent
       * @property {{Object}[] | {Object}} outputData
       */
      // DEBT: use this for the default sort which is a multi sort, should replace with a better logic
      if (
        this.sortQuery?.sortby === "default" &&
        Object.keys(this.filterQueryKeys)?.length == 0 &&
        this.keywordsQuery?.length === 0
      ) {
        this.$emit("filter-applied");
      } else {
        this.$emit("filter-applied", this.outputData);
      }
      bus.emit("filter-applied", this.sortQuery);

      // console.log("check outputData", this.outputData);
      if (this.isServerSide) {
        this.debouncedServerFilterEmit();
      }
    },

    debouncedServerFilterEmit: debounce(
      function () {
        /**
         * Triggers to alert parent that a reload of the server query is needed
         * Is debounced to only call the server maximum every 2seconds
         * What kind of values the filter should be done with must be taken from v-model
         * @property {Object} activeServerFilterKeys  The updated list of active serverSide properties that need filtering
         * @property {Boolean} serverSorting is sorting required on the server side ?
         */
        this.$emit(
          "server-filter-needed",
          this.activeServerFilterKeys,
          Boolean(this.sortConfig.serverSide && this.sortQuery?.sortby)
        );
        delete this.serverSideFiltersLoader.name;
      },
      1000,
      true,
      false
    ),

    clearKeywordSearch() {
      this.filterKeywords = [];
      this.keywordsQuery = [];
    },
    /**
     * Looks in the $route.query for potential filters/sort/keywordsearch parameters that should be considered by the filter and applies them to filterQueryKeys, keywordsQuery, sortQuery
     */
    updateFiltersFromRouteQuery() {
      //Initialize filters from address bar

      let initFilterQueryKeys = {};
      let initKeywordsQuery = [];
      let initSortQuery = {};

      let urlHasFilters = false;

      for (let qkey in this.$route.query) {
        if (!this.$route.query[qkey]) continue;

        // normalize to array of strings
        let arrRoutQuery =
          typeof this.$route.query[qkey] === "string"
            ? this.$route.query[qkey].split(";")
            : this.$route.query[qkey];

        // Keywords Search
        if (qkey === "kwd") {
          initKeywordsQuery = arrRoutQuery;
        } else if (qkey === "sortby") {
          initSortQuery.sortby = arrRoutQuery[0];
        } else if (qkey === "sortdir") {
          initSortQuery.sortdir = arrRoutQuery[0];
        } else if (qkey in this.filterConfigBar) {
          for (let rq of arrRoutQuery) {
            if (this.filterConfigBar[qkey]?.values) {
              // If values are provided, I have to look for the corresponding value/label
              let thisValue = this.filterConfigBar[qkey].values.find(
                (v) => v.value === rq
              );
              // if I find the value, I add it to the object
              if (thisValue) {
                initFilterQueryKeys[qkey] = !initFilterQueryKeys[qkey] && [];
                initFilterQueryKeys[qkey].push(thisValue);
              }
            } else {
              initFilterQueryKeys[qkey] = arrRoutQuery.map((rq) => ({
                value: rq,
                label: rq
              }));
            }
          }
        } else {
          continue;
        }
        // This gets triggered if it went into one of the initial ifs
        urlHasFilters = true;
      }

      if (urlHasFilters) {
        this.filterQueryKeys = initFilterQueryKeys;
        this.filterKeywords = initKeywordsQuery;
        this.keywordsQuery = initKeywordsQuery;
        // Unless there is a sort passing in router query => take the default value
        this.sortQuery =
          Object.keys(initSortQuery).length > 0
            ? initSortQuery
            : this.sortQuery;
        // console.log("I have gathered info from the URL");
      }
    },
    updateRouteQueryFromFilters() {
      this.$emit("filter-applied-model", this.overallQuery);

      let updatedQuery = Object.fromEntries([
        // Combines existing query...
        ...Object.entries(this.$route.query).filter(
          ([q]) =>
            !(
              q in this.filterConfigBar ||
              ["kwd", "sortby", "sortdir"].includes(q)
            )
        ),
        // ...With new query
        ...Object.entries(this.overallQuery).map(([key, value]) => {
          let strVal = "";
          if (Array.isArray(value))
            strVal = value.map((v) => v.value || v).join(";");
          else strVal = value;

          return [key, strVal];
        })
      ]);

      if (objectsEqual(this.$route.query, updatedQuery)) {
        return;
      }
      if (
        !this.$route.query.sortby &&
        !this.$route.query.sortdir &&
        !this.firstTrigger
      ) {
        this.$router
          .push({
            query: updatedQuery,
            replace: true
          })
          .catch(() => {});
        this.firstTrigger = true;
      } else {
        this.$router
          .push({
            query: updatedQuery
          })
          .catch(() => {});
      }
    },

    ClearFilters() {
      this.filterQueryKeys = {};
      this.clearKeywordSearch();
      this.sortQuery = {};
    }
  }
};
</script>

<style scoped lang="scss">
/* THIS MAKES THE SEARCH BY KEYWORD TEXT CENTERED */
.ti-new-tag-input {
  font-size: 1rem;
}
.offcanvas {
  max-width: 20rem !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.offcanvas.offcanvas-start {
  border-right: none !important;
}
</style>

<style>
.offcanvas-backdrop {
  opacity: 0 !important;
}
</style>
