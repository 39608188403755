<template>
  <!-- TODO: Having trouble if rendering a dropdown menu inside the sidebar. need rework on  v-click-outside -->
  <div
    v-click-outside="
      () => {
        visibleRight = false;
      }
    "
  >
    <Sidebar v-model:visible="visibleRight" position="right" @click.stop>
      <!-- SIDEBAR HEADER (NAME, LOGO, LINK...)-->
      <template #header>
        <div class="flex w-full items-center justify-between me-2">
          <div class="flex flex-row gap-4">
            <!-- ACCOUNT NAME -->
            <div v-if="accountDetails" class="flex items-center gap-3">
              <Skeleton v-if="isLoadingAccount" class="w-8 h-8 mb-0"></Skeleton>
              <base-logo
                v-else
                :companyId="accountDetails.Id"
                classes="w-8 h-8"
              />
              <Skeleton
                v-if="isLoadingAccount"
                class="w-40 h-8 mb-0"
              ></Skeleton>
              <p v-else class="font-bold text-xl truncate max-w-[150px]">
                {{ accountDetails.Name }}
              </p>
            </div>
            <baseFavouriteStartup
              v-if="ctaVisibility"
              :startupId="accountDetails.Id"
            ></baseFavouriteStartup>
          </div>

          <div class="flex flex-row gap-4 items-center">
            <base-dropdown
              v-if="
                userAuthStore.state?.userInfo?.Claims.mtb &&
                !userAuthStore.state?.isDemoModeActive
              "
              :icon="accountType === 'startup' ? 'menu_dot' : 'menu'"
              title=""
              titleStyle=""
              iconStyle="fill-mtb stroke-mtb stroke-2 w-5 h-5 cursor-pointer"
              :isIconOnly="true"
              :options="
                mtbActions.filter((ele) => ele.visibility.includes(accountType))
              "
              @update:modelValue="handleMTBAction"
            />
            <!-- <base-vite-icon
              name="menu"
              classes="fill-mtb stroke-mtb stroke-2 w-5 h-5 cursor-pointer"
            /> -->
            <router-link
              v-if="viewProfile?.params?.id && accountType && !isLoadingAccount"
              :to="viewProfile"
              @click="visibleRight = false"
            >
              <base-vite-icon
                name="arrows-pointing-out"
                classes="stroke-black stroke-0 w-5 h-5 cursor-pointer"
              />
            </router-link>
          </div>
        </div>
      </template>
      <!-- SIDEBAR HEADER CLOSE ICON-->
      <template #closeicon>
        <base-vite-icon name="close" classes="stroke-black stroke-2 h-6 w-6" />
      </template>
      <!-- SIDEBAR BODY - INFO DETAILS -->
      <mtb-account-details
        :isLoading="isLoadingAccount"
        :accountType="accountType"
        :accountDetails="accountDetails"
        :accountMainContact="accountMainContacts"
        :accountContacts="accountContacts"
        :accountSponsorDetails="accountSponsorDetails"
        :sidebar="true"
      />
      <!-- SIDEBAR BODY - OPPORTUNITIES PART (ONLY FOR STARTUP WITH SOME OPP)-->
      <div
        class="flex flex-col mt-8"
        v-if="opportunitySectionVisible && accountId"
      >
        <div class="flex gap-x-6">
          <div class="text-xl font-bold text-black">Challenges</div>
          <base-button
            v-if="ctaVisibility"
            :label="addToChallengeConfig.label"
            :text="true"
            icon="add"
            :iconClass="`w-4 h-4 stroke-2 stroke-${addToChallengeConfig.color}`"
            :severity="addToChallengeConfig.color"
            @click="
              openAddToChallengeModal({
                startupId: accountDetails.Id,
                startupName: accountDetails.Name
              })
            "
          />
        </div>
        <base-startup-profile-opportunities-show
          :fromChallenge="fromChallenge"
          :startupId="accountId"
          :isSidebar="true"
          v-if="opportunitySectionVisible && accountId"
        />
      </div>

      <!--SIDEBAR BODY - SPONSORED STARTUPS AND CORPORATE (ONLY FOR PARTNER) -->
      <div class="w-full mt-8" v-if="sponsoredCompanySize > 0 && accountId">
        <div class="w-full flex items-center justify-between mb-4">
          <span class="text-lg font-bold">Partners & Portfolio</span>
          <base-button
            v-if="sponsoredCompanySize > 4 && accountType && accountId"
            :label="`See All (+${sponsoredCompanySize - 4})`"
            :text="true"
            icon="chevron-right"
            iconClass="w-4 h-4 stroke-2 stroke-primary order-1"
            severity="primary"
            @click="goToPartnerProfile"
          />
        </div>
        <div class="w-full flex flex-col gap-4" v-if="isLoadingSponsor">
          <base-card-small-skeleton
            v-for="n in 4"
            :key="n"
            size="card-sm flex flex-row items-center justify-between w-full"
            footerClass="w-6 h-7"
          />
        </div>

        <div class="w-full flex flex-col gap-4" v-else>
          <base-card-small
            v-for="startup in slicedSponsoredStartups"
            :key="startup.companyId"
            :companyId="startup.companyId"
            :sub-title="
              accountStore.state.accountFullDetails[startup.companyId]
                ?.HQ_Country__c || ''
            "
            account-type="startup"
            :title="
              accountStore.state.accountFullDetails[startup.companyId]?.name ||
              accountStore.state.accountFullDetails[startup.companyId]?.Name
            "
            :isNew="false"
            class="w-full"
            size="card-sm flex flex-row items-center w-full"
            :ctaButtonConfig="null"
            :forceRouting="{
              name: `startup-profile`,
              params: {
                id: startup.companyId
              }
            }"
            :forceOpenSidebar="false"
          />
        </div>
      </div>
    </Sidebar>
  </div>
</template>

<script setup>
import { mixpanelMtbTracking } from "@/plugins/mixpanel.js";
import Sidebar from "primevue/sidebar";
import {
  ref,
  onMounted,
  onBeforeUnmount,
  watchEffect,
  watch,
  computed,
  defineAsyncComponent
} from "vue";
import { bus } from "@/main.js";
import { useAccountDetail } from "@global/hooks/use-account-details.js";
import { useUserAuthStore } from "../stores/user-auth-store.js";
import { useAccountsStore } from "../stores/accounts-store.js";
import { useInstitutionMyEcosystemStore } from "@/modules/institutions/stores/institution-myecosystem-store.js";
import { auth } from "@/plugins/firebase.js";
import { useRoute, useRouter } from "vue-router";
import { useOpportunityModals } from "@/modules/corporates/hooks/use-opportunity-modals.js";
import { useOpportunitiesStore } from "@/global/stores/opportunities-store.js";
import { SALESFORCE_LINK_ACCOUNT } from "@global/constants/index.js";
import { useEditStartupProfileModal } from "@startups/hooks/use-edit-startup-profile-modal.js";
const { showEditStartupProfileModal } = useEditStartupProfileModal();
import { getLastPitchDeck } from "@/global/services/accounts-service.js";
const baseFavouriteStartup = defineAsyncComponent(
  () => import("@/modules/corporates/components/base-favourite-startup.vue")
);
const opportunitiesStore = useOpportunitiesStore();
const { openAddToChallengeModal } = useOpportunityModals();
const router = useRouter();
const route = useRoute();
const accountStore = useAccountsStore();
const userAuthStore = useUserAuthStore();
const institutionMyEcosystemStore = useInstitutionMyEcosystemStore();
const visibleRight = ref(false);
const accountId = ref();

const accountDetails = ref([]);
const accountContacts = ref([]);
const accountType = ref(null);
const accountMainContacts = ref({});
const accountSponsorDetails = ref({});
const isLoadingAccount = ref(true);

const mtbActions = ref([
  {
    name: "Open Account in Salesforce",
    id: "openAccountSF",
    titleClass: "text-mtb",
    visibility: ["startup", "corporate", "institution"]
  },
  {
    name: "Edit Startup Profile <div class='ms-2 mtb-chip text-sm bg-alert-light text-alert'>New</div>",
    id: "editStProfile",
    titleClass: "text-mtb",
    visibility: ["startup"]
  }
]);
const addToChallengeConfig = computed(() => {
  return userAuthStore.getters.isMtbUser &&
    !userAuthStore.state.isDemoModeActive
    ? {
        label: "Suggest to Corporate",
        color: "mtb"
      }
    : {
        label: "Add to Challenge",
        color: "primary"
      };
});
const isMyProfile = computed(() => {
  return (
    auth.currentUser && accountId.value === userAuthStore.state?.accountInfo?.id
  );
});

const isMySponsored = computed(() => {
  return (
    auth.currentUser &&
    userAuthStore.state?.accountInfo?.type == "institution" &&
    (institutionMyEcosystemStore.getters.getSponsoredStartups.some(
      (item) => item.companyId === accountId.value
    ) ||
      institutionMyEcosystemStore.getters.getSponsoredCorporates.some(
        (item) => item.companyId === accountId.value
      ))
  );
});

const viewProfile = computed(() => {
  return accountType.value === "corporate"
    ? null
    : {
        name: `${accountType.value}-profile`,
        params: {
          id: accountId.value
        },
        ...(fromChallenge.value && {
          query: {
            from: fromChallenge.value
          }
        })
      };
});
const ctaVisibility = computed(() => {
  return (
    !isLoadingAccount.value &&
    accountDetails.value?.Name &&
    accountType.value === "startup" &&
    userAuthStore.state?.accountInfo?.type === "corporate"
  );
});
const opportunitySectionVisible = computed(() => {
  return (
    !isLoadingAccount.value &&
    accountId.value &&
    accountType.value === "startup" &&
    userAuthStore.state?.accountInfo?.type === "corporate"
  );
});
const fromChallenge = computed(() => {
  return opportunitySectionVisible.value &&
    route.path.includes("corporate/challenge/")
    ? route.params.id
    : null;
});
const handleMTBAction = (data) => {
  console.log("===>data", data);
  let url;
  if (data.id === "openOpportunitySF") {
    // url = `${SALESFORCE_LINK_OPPORTUNITY}${accountId.value}/view`;
  } else if (data.id === "openAccountSF") {
    url = `${SALESFORCE_LINK_ACCOUNT}${accountId.value}/view`;
  } else if (data.id === "editStProfile") {
    //DEBT: check the dropdown behaviour without timeout
    // setTimeout(() => {
    showEditStartupProfileModal({
      accountDetails: accountDetails.value
    });
    // }, 200);
  }
  if (url) window.open(encodeURI(url), "_blank");
};
onMounted(() => {
  setTimeout(() => {
    if (route.query?.a) {
      visibleRight.value = true;
      accountId.value = route.query?.a;
    }
  }, 100);

  bus.on("reload-account-details-sidebar", () => loadAccountDetails());

  bus.on("open-account-detail-sidebar", (data) => {
    visibleRight.value = true;
    accountId.value = data.id;
    router.replace({
      ...route,
      query: {
        ...route.query,
        a: data.id
      }
    });
  });
});

watchEffect(() => {
  // NOTE: need to be separated, only whit && condition the watch observe only the condition result not the single variable
  const isVisible = visibleRight.value;
  const queryAccount = route.query?.a;
  if (isVisible && queryAccount) {
    return;
  }
  setTimeout(() => {
    //re-check, should be different
    if (visibleRight.value && route.query?.a) {
      return;
    }
    visibleRight.value = false;
    const updatedQuery = { ...route.query };

    delete updatedQuery?.a;

    router.replace({
      ...route,
      query: updatedQuery
    });
  }, 500);
});
const { getAccountDetail } = useAccountDetail();

async function loadAccountDetails() {
  isLoadingAccount.value = true;
  const [account, latestDeck] = await Promise.all([
    getAccountDetail(accountId.value, true),
    auth.currentUser ? getLastPitchDeck(accountId.value) : Promise.resolve([])
  ]);
  accountDetails.value = account.accountDetails.value;
  accountType.value = account.accountType.value;
  accountDetails.value.pitchDeck = latestDeck
    ? `https://storage.googleapis.com/mtb_pitch-decks/${encodeURIComponent(
        latestDeck.name
      )}`
    : null;
  if (
    isMyProfile.value ||
    isMySponsored.value ||
    account.accountType.value == "institution"
  ) {
    accountContacts.value = account.accountContacts.value;
    accountMainContacts.value = account.accountMainContact.value;
  } else {
    accountContacts.value = [];
    accountMainContacts.value = {};
  }
  accountSponsorDetails.value = account.accountSponsorDetails.value;
  isLoadingAccount.value = false;
}

watch(
  () => accountId.value,
  async (newValue, oldValue) => {
    if (newValue && newValue !== oldValue) {
      await loadAccountDetails();
    }
  }
);

watch(
  () => accountDetails.value,
  (newValue, oldValue) => {
    if (newValue && newValue?.Id !== oldValue?.Id) {
      mixpanelTrack();
    }
  },
  { immediate: true }
);
function isSuggestion() {
  //it's suggested if it came from the search or if we have some suggestions
  return !!(
    route.name === "search" ||
    !!Object.values(
      opportunitiesStore.state.challengeOpportunities?.suggestions
    )
      ?.flat()
      ?.find((item) => item.AccountId === accountDetails.value?.Id)
  );
}
function getEventName() {
  switch (accountType.value) {
    case "startup":
      return "Startup Exposure";
    case "corporate":
      return "Corporate Exposure";
    case "institution":
      return "Institution Profile View";
    default:
      return null;
  }
}
function mixpanelTrack() {
  if (!isMyProfile.value) {
    const eventName = getEventName();
    if (!eventName) {
      return;
    }
    const forMixpanel = {
      visitor_id: userAuthStore.state?.accountInfo?.id,
      visitor_name: userAuthStore.state?.accountInfo?.name,
      visitor_type: userAuthStore.state?.accountInfo?.type,
      visited_id: accountDetails.value?.Id,
      visited_name: accountDetails.value?.Name,
      suggested: isSuggestion()
    };
    mixpanelMtbTracking({
      eventName: eventName,
      properties: forMixpanel
    });
  }
}
onBeforeUnmount(() => {
  bus.off("open-account-detail-sidebar");
  bus.off("reload-account-details-sidebar");
});

// ALL CODES FOR RENDERING THE INSTITUTION'S SPONSORED STARTUPS & CORPORATE
import { useLoading } from "../hooks/use-loading.js";
const { isInstitutionListLoading, isStartupListLoading } = useLoading();
const isLoadingSponsor = computed(() => {
  return isInstitutionListLoading.value || isStartupListLoading.value;
});
import { useParnerSponsorship } from "@global/hooks/use-parner-sponsorship.js";
const { sponsoredCompanySize, slicedSponsoredStartups } =
  useParnerSponsorship(accountId);

const goToPartnerProfile = () => {
  router.push({
    name: `${accountType.value}-profile`,
    params: {
      id: accountId.value
    }
  });
};
</script>

<style lang="scss" scoped></style>
