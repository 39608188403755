<template>
  <div
    class="select-custom-dropdown clickable-item"
    @mousedown.prevent.stop="handleToggle"
  >
    <mtb-arrow-icon
      :classes="`me-3 ${!isOpenChild ? 'mtb-rotate-315' : 'mtb-rotate-135'} `"
      color="gray-muted"
    />
  </div>
</template>
<script setup>
import { ref } from "vue";

const props = defineProps({
  toggle: {
    type: Function,
    default() {
      return;
    }
  },
  isOpen: {
    type: Boolean,
    default: false
  }
});
const isOpenChild = ref(props, "isOpen");
const handleToggle = (e) => {
  isOpenChild.value = !isOpenChild.value;
  props.toggle();
};
</script>

<style lang="scss" scoped>
:deep(.multiselect--active) {
  .triangle-icon-bottom {
    transform: rotate(-45deg);
  }
}
.select-custom-dropdown {
  position: absolute;
  right: 0.5rem;
  zoom: 0.8;
  top: 50%;
  transform: translateY(-35%);
}
</style>
