import { database, functionsEU } from "@plugins/firebase.js";
import { collection, deleteDoc, doc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import validate from "validate.js";

import { default as classes } from "@share/errorClasses.js";
const options = {
  timeout: 180000
};
export async function handleOpportunity(data) {
  try {
    //validation
    const validationRules = {
      data: {
        presence: { allowEmpty: false },
        type: "object"
      },
      "data.Operation": {
        presence: { allowEmpty: false },
        type: "string",
        inclusion: {
          within: ["create", "update", "delete"]
        }
      },
      "data.Id": {
        presence: false,
        type: "string"
      }
    };
    const validationErrors = validate({ data }, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }

    const httpsCallableFunction = httpsCallable(
      functionsEU,
      "handleOpportunities-CreateOrUpdate"
    );
    const result = await httpsCallableFunction(data);
    return result.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
export async function setOpportunity(data) {
  try {
    //validation
    const validationRules = {
      Operation: {
        presence: { allowEmpty: false },
        type: "string",
        inclusion: {
          within: ["create", "update", "delete"]
        }
      }
    };
    const validationErrors = validate(data, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }

    const httpsCallableFunction = httpsCallable(
      functionsEU,
      "handleOpportunities-setChallengeOpportunityV3"
    );
    const result = await httpsCallableFunction(data);
    return result.data?.id;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getChallengeApplicationPerStartup(startupId) {
  try {
    //validation
    const validationRules = {
      startupId: {
        presence: { allowEmpty: false },
        type: "string"
      }
    };
    const validationErrors = validate({ startupId }, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }

    const httpsCallableFunction = httpsCallable(
      functionsEU,
      "challengeApplications-getApplicationPerStartupV3",
      options
    );
    const result = await httpsCallableFunction(startupId);
    return result.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getChallengeApplications(data = {}) {
  try {
    const httpsCallableFunction = httpsCallable(
      functionsEU,
      "challengeApplications-getChallengeApplications",
      options
    );
    const result = await httpsCallableFunction(data);
    return result.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getStartupOpportunities(startupId) {
  try {
    const httpsCallableFunction = httpsCallable(
      functionsEU,
      "handleOpportunities-getStartupOpportunitiesV3",
      options
    );
    const result = await httpsCallableFunction({ startupId });
    return result.data;
  } catch (error) {
    throw error;
  }
}

export async function getChallengeOpportunities(data) {
  try {
    const validationRules = {
      corporateId: {
        presence: { allowEmpty: false },
        type: "string"
      },
      challengeId: {
        presence: { allowEmpty: false },
        type: "string"
      }
    };
    const validationErrors = validate(data, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }
    const httpsCallableFunction = httpsCallable(
      functionsEU,
      "handleOpportunities-getChallengeOpportunitiesV3",
      options
    );
    const result = await httpsCallableFunction(data);
    return result.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
export async function getChallengesOpportunities(data) {
  try {
    const validationRules = {
      corporateId: {
        presence: { allowEmpty: false },
        type: "string"
      },
      challengesIds: {
        presence: { allowEmpty: false },
        type: "array"
      }
    };
    const validationErrors = validate(data, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }
    const httpsCallableFunction = httpsCallable(
      functionsEU,
      "handleOpportunities-getChallengesOpportunitiesV3",
      options
    );
    const result = await httpsCallableFunction(data);
    return result.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function setStartupApplicationFeedbackPerChallenge(data) {
  try {
    //validation
    const validationRules = {
      data: {
        presence: { allowEmpty: false },
        type: "object"
      },
      "data.suggested": {
        presence: true,
        type: "boolean"
      }
    };
    const validationErrors = validate({ data }, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }

    const httpsCallableFunction = httpsCallable(
      functionsEU,
      "challengeApplications-setStApplicationFeedback"
    );
    const result = await httpsCallableFunction(data);
    return result.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function deleteStartupApplication(data) {
  try {
    //validation
    const validationRules = {
      data: {
        presence: { allowEmpty: false },
        type: "object"
      },
      "data.startupId": {
        presence: { allowEmpty: false },
        type: "string"
      },
      "data.challengeId": {
        presence: { allowEmpty: false },
        type: "string"
      }
    };
    const validationErrors = validate({ data }, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }

    const startupsCollection = collection(database, "startups");
    const startupDoc = doc(startupsCollection, data.startupId);
    const applicationsCollection = collection(startupDoc, "applications");
    const applicationDoc = doc(
      applicationsCollection,
      `${data.challengeId}_${data.startupId}`
    );

    deleteDoc(applicationDoc);
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getOpportunitiesWithAccount(data) {
  try {
    //validation
    const validationRules = {
      data: {
        presence: { allowEmpty: false },
        type: "object"
      },
      "data.StartupId": {
        presence: { allowEmpty: false },
        type: "string"
      },
      "data.CorporateId": {
        presence: { allowEmpty: false },
        type: "string"
      }
    };
    const validationErrors = validate({ data }, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }

    const httpsCallableFunction = httpsCallable(
      functionsEU,
      "LoadStartupsLists-my_opps_with"
    );
    const result = await httpsCallableFunction(data);
    return result.data || [];
  } catch (error) {
    throw error;
  }
}

export async function getOpportunityPitchdeck(startupId, opportunityId) {
  //TESTING companyId "0012E00001z5EqvQAE"
  if (!startupId) return;
  try {
    //validation
    const validationRules = {
      opportunityId: {
        presence: { allowEmpty: false },
        type: "string"
      }
    };
    const validationErrors = validate({ opportunityId }, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }
    const storage = getStorage();
    const storageRef = ref(storage, `gs://mtb_pitch-decks_opps/${startupId}`);
    const files = await listAll(storageRef);

    const downloadPromises = files.items.map(async (file) => {
      const downloadUrl = await getDownloadURL(file);
      return downloadUrl;
    });

    // Un array di tutti i file pitchdeck
    const pitchdecksCompany = await Promise.all(downloadPromises);
    const currentDeck = pitchdecksCompany.find((pitch) => {
      //TESTING challengeOpportunityId "a062E00001dSzXyQAK"
      return pitch.indexOf(opportunityId) !== -1;
    });
    return currentDeck;
  } catch (error) {
    throw error;
  }
}

export async function exportStOppData(data) {
  //opportunityData is an array of objects like this:
  // [
  //   {
  //     challengeId: "dhfasjd",
  //     startupIds: ["a062E00001dSzXyQAK", "sadfjsdafj", "dfakjsd"]
  //   },
  //   {...}
  // ]
  try {
    //validation
    const validationRules = {
      data: {
        presence: { allowEmpty: false },
        type: "object"
      },
      "data.companyId": {
        presence: { allowEmpty: false },
        type: "string"
      },
      "data.opportunityData": {
        presence: { allowEmpty: false },
        type: "array"
      }
    };
    const validationErrors = validate({ data }, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }

    const httpsCallableFunction = httpsCallable(functionsEU, "downloadExcel");

    const response = await httpsCallableFunction(data);
    return response.data || [];
  } catch (error) {
    throw error;
  }
}

export async function getOpportunity(data) {
  try {
    const validationRules = {
      opportunityId: {
        presence: { allowEmpty: false },
        type: "string"
      }
    };
    const validationErrors = validate(data, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }
    const httpsCallableFunction = httpsCallable(
      functionsEU,
      "handleOpportunities-getOpportunityV3",
      options
    );
    const result = await httpsCallableFunction(data);

    return result.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
