<template>
  <div class="flex flex-col gap-y-4">
    <Calendar
      :id="`${name}_${contextUuid}`"
      v-model="inputValue"
      :selectionMode="props.selectionMode"
      hourFormat="24"
      dateFormat="dd/mm/yy"
      :inline="inline"
      :placeholder="
        props.showTime && !isMultiCalendar ? '--/--/-- hh:mm' : '--/--/--'
      "
      :minDate="props.minDate"
      :maxDate="props.maxDate"
      :showTime="props.showTime && !isMultiCalendar"
      :showButtonBar="props.showButtonBar"
      class="w-full"
      @blur="setTouched(true)"
    />
    <div class="flex gap-x-4 justify-between">
      <Calendar
        :modelValue="startTimeRef"
        @update:modelValue="
          startTimeRef = parseDateTimeMultiComponent(new Date(), $event)
        "
        class="w-full"
        placeholder="hh:mm"
        v-if="isMultiCalendar"
        :stepMinute="15"
        timeOnly
        :id="`${name}_startTime_${contextUuid}`"
      ></Calendar>
      <Calendar
        :modelValue="endTimeRef"
        @update:modelValue="
          endTimeRef = parseDateTimeMultiComponent(new Date(), $event)
        "
        class="w-full"
        placeholder="hh:mm"
        v-if="isMultiCalendar"
        timeOnly
        :stepMinute="15"
        :id="`${name}_endTime_${contextUuid}`"
      ></Calendar>
    </div>
    <small
      v-if="isInvalid"
      class="text-alert inline-flex items-center gap-1"
      id="text-error"
    >
      <base-vite-icon name="close" classes="w-3.5 h-3.5 stroke-2" />
      {{ errorMessage }}</small
    >
  </div>
</template>

<script setup>
import { ref, watch, toRefs, computed } from "vue";
import Calendar from "primevue/calendar";
import { useField } from "vee-validate";
import { v4 as uuidv4 } from "uuid";
const contextUuid = uuidv4();
const props = defineProps({
  name: {
    type: String,
    default: ""
  },
  inline: {
    type: Boolean,
    default: false
  },
  maxDate: {
    type: Object,
    default: () => null
  },
  minDate: {
    type: Object,
    default: new Date()
  },
  value: {
    type: [Array, String],
    default: () => null
  },
  reset: {
    type: Boolean,
    default: false
  },
  showTime: {
    type: Boolean,
    default: true
  },
  selectionMode: {
    type: String,
    default: "single"
  },
  showButtonBar: {
    type: Boolean,
    default: false
  },
  isDraftValidated: {
    type: Boolean,
    default: false
  }
});
const emit = defineEmits(["update:modelValue"]);
// const datetime24h = ref(props.value);
const { value, name, isDraftValidated } = toRefs(props);
const {
  value: inputValue,
  errorMessage,
  meta,
  setTouched
} = useField(name, undefined, {
  initialValue: value,
  validateOnMount: true
});

const startDefaultTime = new Date();
const endDefaultDate = new Date();

startDefaultTime.setHours(8, 0, 0, 0);
endDefaultDate.setHours(17, 0, 0, 0);
const startTimeRef = ref(startDefaultTime);
const endTimeRef = ref(endDefaultDate);
const isMultiCalendar = computed(() => {
  return props.showTime && props.selectionMode == "range";
});
//add invalid if same date and enddate before start date
const isInvalid = computed(() => {
  return errorMessage.value && (meta.touched || isDraftValidated.value);
});

watch(
  () => props.reset,
  (newVal) => {
    if (newVal === true) {
      inputValue.value = "";
    }
  }
);
watch(
  () => props.value,
  (newVal) => {
    inputValue.value = newVal;
  }
);
function parseDateTimeMultiComponent(date, time) {
  const startDate = date;
  const newTime = new Date(time);

  // Set year, month, and date from startDate
  newTime.setFullYear(startDate.getFullYear());
  newTime.setMonth(startDate.getMonth());
  newTime.setDate(startDate.getDate());

  // Get hour and min from newTime
  const hours = newTime.getHours();
  const minutes = newTime.getMinutes();
  newTime.setHours(hours, Math.round(minutes / 15) * 15, 0, 0);

  return newTime;
}
watch(
  () => [startTimeRef.value, endTimeRef.value],
  (newVal, oldVal) => {
    if (
      JSON.stringify(newVal) !== JSON.stringify(oldVal) &&
      isMultiCalendar.value &&
      inputValue.value?.[0] &&
      inputValue.value?.[1]
    ) {
      inputValue.value = [
        parseDateTimeMultiComponent(inputValue.value[0], newVal[0]),
        parseDateTimeMultiComponent(inputValue.value[1], newVal[1])
      ];
    }
  },
  { immediate: true }
);

watch(inputValue, (newVal, oldVal) => {
  if (JSON.stringify(newVal) == JSON.stringify(oldVal)) {
    return;
  }

  if (isMultiCalendar.value && newVal?.[0] && newVal?.[1]) {
    const parsedDateTime = [
      parseDateTimeMultiComponent(newVal[0], startTimeRef.value),
      parseDateTimeMultiComponent(newVal[1], endTimeRef.value)
    ];
    inputValue.value = parsedDateTime;
    emit("update:modelValue", parsedDateTime);
  } else {
    emit("update:modelValue", newVal);
  }
});
</script>

<style lang="scss" scoped></style>
