<template>
  <div :class="props.classes">
    <!-- Header -->
    <div class="flex flex-row justify-between items-center">
      <!-- Title (text or dropdown) + CTA button -->
      <div
        :class="[
          'flex flex-row  items-center gap-8',
          spaceBetween ? 'justify-between w-full ' : 'justify-center'
        ]"
      >
        <base-dropdown
          v-model="selectedOption"
          :title="formattedTitle"
          titleStyle="text-xl font-bold text-black"
          iconStyle="order-0 fill-none stroke-black stroke-2 w-5 h-5"
          :options="dropdownOptions"
          :defaultSelectedOption="dropdownOptions?.[0]"
        />
        <base-button
          v-if="ctaButton"
          v-tooltip.top="ctaButton.tooltip"
          :label="ctaButton.label"
          :severity="ctaButton.severity"
          :disabled="ctaButton.disabled"
          :icon="ctaButton.icon"
          :iconClass="ctaButton.iconClass"
          :outlined="ctaButton.outlined"
          :text="ctaButton.text"
          @click="openModal"
        />
      </div>
      <!-- See All -->
      <base-button
        v-if="props.isRedirect"
        label="See all"
        severity="grey2"
        :text="true"
        icon="arrow-short-right"
        iconClass="order-1 fill-none stroke-2 stroke-grey2 w-5 h-5"
        :isLoading="false"
        @click="changeRoute"
      />
    </div>
    <!-- Content -->
    <slot />
  </div>
</template>

<script setup>
import { computed, ref, watchEffect, watch } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();
const emits = defineEmits(["update-options", "click-cta"]);
const props = defineProps({
  classes: {
    type: String,
    default: "flex flex-col"
  },
  title: {
    type: String,
    default: ""
  },
  titleCounter: {
    type: Number,
    default: 0
  },
  dropdownOptions: {
    type: Array,
    default: null
  },
  ctaButton: {
    type: Object,
    default: null,
    validator: (value) => {
      if (value) {
        return ["label", "severity"].every((key) => key in value && value[key]);
      } else {
        return true;
      }
    }
  },
  seeAllRoutePath: {
    type: Object,
    default: () => {},
    validator: (value) => {
      return "name" in value;
    }
  },
  isRedirect: {
    type: Boolean,
    default: true
  },
  spaceBetween: {
    type: Boolean,
    default: false
  }
});

const formattedTitle = computed(() => {
  const titleCounter = props.titleCounter > 0 ? props.titleCounter : "";
  return props.title + titleCounter;
});
const changeRoute = () => {
  router.push({
    name: props.seeAllRoutePath.name,
    params: props.seeAllRoutePath?.params
  });
  console.log("I should change route here");
};
const openModal = () => {
  emits("click-cta");
};
const selectedOption = ref(props.dropdownOptions?.[0]);

watchEffect(() => {
  if (selectedOption.value && props.dropdownOptions) {
    emits("update-options", selectedOption.value);
    if (process.env.NODE_ENV === "development") {
      console.log("I should reload the data ", selectedOption.value);
    }
  }
});
</script>

<style lang="scss" scoped></style>
