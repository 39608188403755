<template>
  <div
    v-click-outside="
      () => {
        visibleRight = false;
      }
    "
  >
    <Sidebar v-model:visible="visibleRight" position="right" @click.stop>
      <template #header>
        <div v-if="challenge" class="flex items-center gap-3 mr-auto">
          <base-vite-icon
            v-if="
              !isCorporateChallengeVisible({
                Challenge_Visibility__c: challenge.Challenge_Visibility__c,
                Challenge_Corporate_Sponsor__c:
                  challenge.Challenge_Corporate_Sponsor__c
              })
            "
            :name="visibilityMapping[challenge.Challenge_Visibility__c].icon"
            :classes="`${
              visibilityMapping[challenge.Challenge_Visibility__c].iconClasses
            } h-7 w-7`"
          />
          <base-logo
            v-else
            classes="w-8 h-8"
            :companyId="challenge.Challenge_Corporate_Sponsor__c"
          />

          <p class="font-bold text-xl">{{ challenge.Name }}</p>
        </div>
      </template>
      <template #closeicon>
        <base-vite-icon name="close" classes="stroke-black stroke-2 w-6 h-6" />
      </template>
      <!-- TODO: does the field order matter? -->
      <div class="flex flex-col gap-4 mt-8" v-if="challenge">
        <!-- TODO:  -->
        <div
          v-if="
            isCorporateChallengeVisible({
              Challenge_Visibility__c: challenge.Challenge_Visibility__c,
              Challenge_Corporate_Sponsor__c:
                challenge.Challenge_Corporate_Sponsor__c
            })
          "
          class="flex flex-col gap-1.5"
        >
          <p class="font-bold text-lg text-black">Challenge Owner</p>
          <p class="text-grey1 text-base flex items-center">
            <base-logo
              classes="h-8 w-8 me-2"
              :companyId="challenge.Challenge_Corporate_Sponsor__c"
            />
            {{ challenge.Challenge_Corporate_Sponsor__r.Name }}
          </p>
          <div class="flex gap-1.5 items-center">
            <base-vite-icon
              name="globe"
              classes="fill-primary stroke-0 w-4 h-4"
            />
            <a
              class="text-start truncate w-[15rem] underline text-grey1 text-base"
              :href="challenge.Challenge_Corporate_Sponsor__r?.Website"
              target="_blank"
              >{{ challenge.Challenge_Corporate_Sponsor__r?.Website }}</a
            >
          </div>
        </div>

        <div class="flex flex-col gap-1.5">
          <p class="font-bold text-lg text-black">Status</p>
          <div>
            <div
              class="mtb-chip font-bold text-sm"
              :class="
                statusMapping[challenge?.Challenge_State__c].parentClasses
              "
            >
              <span class="text-base font-normal text-left">{{
                challenge["Challenge_State__c"]
              }}</span>
            </div>
          </div>
        </div>

        <div
          v-for="field in filteredFields"
          :key="field"
          class="flex flex-col gap-1.5"
        >
          <p class="font-bold text-lg text-black">
            {{ field.label }}
          </p>
          <div v-if="field.Id == 'Challenge_Objective__c'">
            <div
              :class="`mtb-chip text-sm ${
                objectiveMapping[challenge[field.id]]?.parentClasses ||
                objectiveMapping.default.parentClasses
              }`"
            >
              <base-vite-icon
                :name="
                  objectiveMapping[challenge[field.id]]?.icon ||
                  objectiveMapping.default.icon
                "
                :classes="`${
                  objectiveMapping[challenge[field.id]]?.iconClasses ||
                  objectiveMapping.default.iconClasses
                } h-4 w-4`"
              />{{
                objectiveMapping[challenge[field.id]]?.label ||
                challenge[field.id]
              }}
            </div>
          </div>
          <p class="text-grey1 text-base" v-else>
            {{ formatValue(field, challenge[field.id]) }}
          </p>
        </div>
      </div>
    </Sidebar>
  </div>
</template>

<script setup>
import Sidebar from "primevue/sidebar";
import { ref, onMounted, onBeforeUnmount, computed, watch } from "vue";
import {
  visibilityMapping,
  statusMapping
} from "@global/configs/challenge-mapping.js";
import { mixpanelMtbTracking } from "@/plugins/mixpanel.js";
import { isCorporateChallengeVisible } from "@global/helpers/util.js";
import { bus } from "@/main.js";
import { useChallengesStore } from "@/global/stores/challenges-store.js";
import { useUserAuthStore } from "@/global/stores/user-auth-store.js";
import { useRoute } from "vue-router";
import { useChallengeDetails } from "../hooks/use-challenge-details.js";
import { objectiveMapping } from "../configs/challenge-mapping.js";
const route = useRoute();
const visibleRight = ref(false);
const challenge = ref(null);
const challengeStore = useChallengesStore();
const userAuthStore = useUserAuthStore();
const { challengesField, formatValue, isMyCorporate } = useChallengeDetails();

const startupView =
  userAuthStore.state?.accountInfo?.type == "corporate" ? false : true;

const filteredFields = computed(() => {
  if (challenge.value) {
    //second param is to show fields in startupView or corporateView
    return challengesField(challenge.value, startupView);
  }
  return [];
});

onMounted(() => {
  bus.on("open-challenge-details-sidebar", (data) => {
    challenge.value =
      challengeStore.state?.ecosystemPublishedChallengesList.find(
        (challenge) => challenge.Id === data
      );

    visibleRight.value = true;
  });
});
watch(
  () => challenge?.value?.Id,
  (val, oldVal) => {
    if (val && val !== oldVal) {
      mixpanelTrack();
    }
  },
  { immediate: true }
);
function isSuggestion() {
  //it's suggested if it came from the search or if we have some suggestions
  return !!(
    route.name === "search" ||
    challengeStore.state.startupSuggestedChallenges?.find(
      (item) => item.Id === challenge?.value?.Id
    )
  );
}
function mixpanelTrack() {
  const forMixpanel = {
    visitor_id: userAuthStore.state?.accountInfo?.id,
    visitor_name: userAuthStore.state?.accountInfo?.name,
    visitor_type: userAuthStore.state?.accountInfo?.type,
    visited_id: challenge.value?.Challenge_Corporate_Sponsor__c,
    visited_name: challenge.value?.Challenge_Corporate_Sponsor__r?.Name,
    challenge_id: challenge?.value?.Id,
    suggested: isSuggestion()
  };
  mixpanelMtbTracking({
    eventName: "Challenge Exposure",
    properties: forMixpanel
  });
}
onBeforeUnmount(() => bus.off("open-challenge-details-sidebar"));
</script>

<style lang="scss" scoped></style>
