import { DEMO_MODE_ACCOUNT_LIST } from "@/global/constants";
import { default as classes } from "@share/errorClasses";

import { getAccountFirestore } from "@/global/services/accounts-service.js";
import { getCompanySponsorShip } from "@/global/services/sponsorships-service.js";
import { mixpanelPeople } from "@/plugins/mixpanel.js";
import { auth } from "@/plugins/firebase.js";
import { defineStore } from "pinia";
import { computed, reactive } from "vue";
import { getCurrentUserInformation } from "../services/auth-service.js";
import { useAppStateStore } from "./app-state-store.js";
import {
  STATE_USER_INFO,
  STATE_SPONSORSHIP,
  STATE_ACCOUNT_INFO
} from "@global/constants/loading-state.js";
import { encryptLocalStorage } from "@/plugins/pinia.js";

export const useUserAuthStore = defineStore(
  "user-auth",
  () => {
    const appStateStore = useAppStateStore();
    const state = reactive({
      init: new Date(), //we can put a watcher if is more than 2 weeks force logout!!! we can use the same approach for reloading
      login: null,
      userInfo: null,
      accountInfo: null,
      isDemoModeActive: true
    });
    const getters = reactive({
      isLogin: computed(
        () => !!state.login && !!state.userInfo && !!state.accountInfo
      ),
      getFullProfileInformation: computed(() => {
        if (!state.userInfo) {
          return null;
        }
        return {
          ...state.userInfo,
          ...state.accountInfo
        };
      }),
      isDemoAccount: computed(() => {
        if (!state.userInfo) {
          return false;
        }
        return DEMO_MODE_ACCOUNT_LIST.includes(state.userInfo.AccountId);
      }),
      isMtbUser: computed(() => {
        if (!state.userInfo) {
          return false;
        }
        return state.userInfo.Claims.mtb;
      })
      // getCreationDate: computed(() => {
      //   debugger;
      //   const date = state.accountInfo?.creationDate;
      //   if (!date) {
      //     return constants.FALLBACK_CREATION_ACCOUNT_DATE;
      //   }
      //   if (typeof date === "object" && date?.seconds && date?.nanoseconds) {
      //     //Timestamp
      //     const milliseconds = date.seconds * 1000 + date.nanoseconds / 1e6;

      //     return format(new Date(milliseconds), "yyyy-MM-dd").toString();
      //   } else {
      //     return format(new Date(date), "yyyy-MM-dd").toString();
      //   }
      // })
    });
    async function getMtbUser(claims, forceAccountInfo) {
      // THIS IS TO MANAGE THE FIRST AUTHENTICATION OF AN MTB USER - CLAIMS ARE GIVEN ONLY AFTER CREATION OF USER, SO SHOULD WAIT A SECOND AND INFO HAS TO BE RELOADED
      if (
        auth.currentUser.email.endsWith("@mindthebridge.org") &&
        !claims.mtb
      ) {
        await auth.currentUser.reload();
        await new Promise((resolve) => {
          return setTimeout(async () => {
            // AWAITS FOR THE EXECUTION OF THE CLAIMS TO HAPPEN
            claims = (await auth.currentUser?.getIdTokenResult(true))?.claims;
            return resolve();
          }, 3000);
        });
      }

      if (claims.mtb) {
        if (process.env.NODE_ENV === "development") {
          console.info(
            "You're a MTB USER. I will Assign your userInfo in a different way"
          );
        }
        //if there isnt forceAccountInfo get localStorage
        const mtbUserInfo = {
          Email: auth.currentUser.email,
          FirstName: auth.currentUser.displayName
            ? auth.currentUser.displayName.split(" ")[0]
            : "MTB Employee",
          Id: auth.currentUser.uid,
          LastName: auth.currentUser.displayName
            ? auth.currentUser.displayName.split(" ")[1]
            : auth.currentUser.email,
          Account: forceAccountInfo
            ? {
                Name: forceAccountInfo.name,
                Type: forceAccountInfo.type
              }
            : {
                Name: "Mind the Bridge",
                Type: "corporate"
              },
          AccountId:
            forceAccountInfo?.id ||
            state.userInfo?.AccountId ||
            "0012E00002W4vEaQAJ", // fallback ECOSYSTEM BASIC CORPORATE
          Claims: claims
        };
        //save in local storage
        return mtbUserInfo;
      }
    }
    async function getEcosystemUser(claims) {
      //move in a service
      const ecosystemUser = await getCurrentUserInformation();

      if (ecosystemUser) {
        return {
          AccountId: ecosystemUser.companyId,
          Email: ecosystemUser.email,
          FirstName: ecosystemUser.FirstName || auth.currentUser.email,
          LastName: ecosystemUser.LastName || "",
          Id: auth.currentUser.uid,
          Account: {
            Name: ecosystemUser.companyName,
            Type: ecosystemUser.type
          },
          Claims: claims
        };
      } else {
        auth.signOut();
        throw new Error("Unable to understand who you are");
      }
    }
    const actions = reactive({
      setLogin: (currentUser) => {
        state.login = currentUser;
      },
      setUserInfo: async (forceAccountInfo) => {
        try {
          appStateStore.actions.setStartProcess(STATE_USER_INFO);
          if (!auth.currentUser) {
            state.userInfo = null;
            state.accountInfo = null;
            throw new classes.Unauthenticated("classes.Unauthenticated");
          }
          const claims = (await auth.currentUser?.getIdTokenResult())?.claims;
          const userInfo =
            auth.currentUser.email.endsWith("@mindthebridge.org") || claims.mtb
              ? await getMtbUser(claims, forceAccountInfo)
              : await getEcosystemUser(claims);
          if (DEMO_MODE_ACCOUNT_LIST.includes(userInfo.AccountId)) {
            state.isDemoModeActive = true;
          } else {
            state.isDemoModeActive = false;
          }
          state.userInfo = userInfo;
        } catch (error) {
          appStateStore.actions.setError(STATE_USER_INFO, true);
          console.log("####ERROR", error);
          auth.signOut();
          state.userInfo = null;
          throw new Error("Unable to understand who you are");
        } finally {
          appStateStore.actions.setLoading(STATE_USER_INFO, false);
        }
      },
      //TODO: currently not used anywhere
      setSponsorships: async () => {
        try {
          appStateStore.actions.setStartProcess(STATE_SPONSORSHIP);
          const sponsorships = await getCompanySponsorShip(
            state.userInfo.Account.Type,
            state.userInfo.AccountId
          );
          state.accountInfo = {
            ...state.accountInfo,
            sponsorships
          };
        } catch (error) {
          appStateStore.actions.setError(STATE_SPONSORSHIP, true);
          console.log("####ERROR", error);
        } finally {
          appStateStore.actions.setLoading(STATE_SPONSORSHIP, false);
        }
      },
      setAccountInfo: async () => {
        if (process.env.NODE_ENV === "development") {
          console.info("###ENTER ACCOUNT", state.userInfo, state.accountInfo);
        }
        try {
          appStateStore.actions.setStartProcess(STATE_ACCOUNT_INFO);
          if (!state.userInfo) {
            await actions.setUserInfo();
          }
          const accountInfo =
            //service accepts two params: type and accountId
            await getAccountFirestore(
              state.userInfo.Account.Type,
              state.userInfo.AccountId
            );

          if (!accountInfo) {
            //user doesn't exist
            //TODO: manage the error and the case scenario
            throw new Error("Cannot find any account for the user");
          }

          state.accountInfo = accountInfo;
          actions.setSponsorships();

          // needed for the teleport
          mixpanelPeople({
            userData: getters.getFullProfileInformation,
            once: false
          });
        } catch (error) {
          appStateStore.actions.setError(STATE_ACCOUNT_INFO, true);
          console.log("####ERROR", error);
        } finally {
          appStateStore.actions.setLoading(STATE_ACCOUNT_INFO, false);
        }
      },
      setDemoMode: (isDemoModeActive) => {
        if (
          state.userInfo.AccountId &&
          DEMO_MODE_ACCOUNT_LIST.includes(state.userInfo.AccountId)
        ) {
          state.isDemoModeActive = isDemoModeActive;
        } else {
          state.isDemoModeActive = false;
        }
      },
      reset: () => {
        state.userInfo = null;
        state.accountInfo = null;
      }
    });
    // onMounted(() => {
    //   if (
    //     localStorage.getItem("user-auth") &&
    //     !localStorage.getItem("user-auth-expiration")
    //   ) {
    //     localStorage.setItem("user-auth-expiration", new Date());
    //   }
    // });
    return {
      state,
      getters,
      actions
    };
  },
  {
    persist: {
      storage: encryptLocalStorage
    }
  }
);
