import { functionsEU } from "@plugins/firebase.js";
import { httpsCallable } from "firebase/functions";
import validate from "validate.js";
import {
  getStorage,
  ref,
  getDownloadURL,
  uploadBytes,
  getBytes,
  getBlob
} from "firebase/storage";
import { default as classes } from "@share/errorClasses.js";

export async function getCurrentPitchdeck(data) {
  try {
    //validation
    const validationRules = {
      AccountId: {
        presence: { allowEmpty: false },
        type: "string"
      }
    };
    const validationErrors = validate(data, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }
    const httpsCallableFunction = httpsCallable(
      functionsEU,
      "pitchdecks-exists"
    );
    const result = await httpsCallableFunction({ startupId: data.AccountId });

    return result.data;
  } catch (error) {
    throw error;
  }
}

export async function getOpportunityPitchdeck(pitchdeckURL) {
  try {
    if (!pitchdeckURL) throw new Error("pitchdeckURL is required!");
    const storage = getStorage();
    const storageRef = ref(storage, pitchdeckURL);

    const url = await getDownloadURL(storageRef);
    window.open(url, "_blank");
  } catch (error) {
    throw error;
  }
}

export async function copyProfilePitchdeck(data) {
  try {
    //validation
    const validationRules = {
      pitchdeckURL: {
        presence: { allowEmpty: false },
        type: "string"
      },
      pitchdeckName: {
        presence: { allowEmpty: false },
        type: "string"
      },
      startupId: {
        presence: { allowEmpty: false },
        type: "string"
      },
      startupName: {
        presence: { allowEmpty: false },
        type: "string"
      },
      challId: {
        presence: { allowEmpty: false },
        type: "string"
      }
    };
    const validationErrors = validate(data, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }
    const httpsCallableFunction = httpsCallable(
      functionsEU,
      "pitchdecks-copyProfilePitchdeck"
    );
    const result = await httpsCallableFunction({
      pitchdeckURL: data.pitchdeckURL,
      pitchdeckName: data.pitchdeckName,
      startupId: data.startupId,
      startupName: data.startupName,
      challId: data.challId
    });

    return result.data;
  } catch (error) {
    throw error;
  }
}

export async function setPitchdeck(payload) {
  try {
    const validationRules = {
      startupName: {
        presence: { allowEmpty: false },
        type: "string"
      },
      startupId: {
        presence: { allowEmpty: false },
        type: "string"
      },
      challId: {
        presence: { allowEmpty: false },
        type: "string"
      }
    };
    const validationErrors = validate(payload, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }
    if (!payload?.file || payload?.file?.byteLength == undefined) {
      throw new classes.Api400Error("Invalid bufferArray");
    }

    const storage = getStorage();
    const storageRef = ref(
      storage,
      `gs://mtb_pitch-decks_opps/${payload.startupId}/${payload.startupName}_${payload.challId}_${new Date().toISOString().split("T")[0]}`
    );

    const metadata = {
      contentType: "application/pdf",
      customMetadata: {
        startupId: payload.startupId,
        challengeId: payload.challId
      }
    };

    const snapshot = await uploadBytes(storageRef, payload.file, metadata);
    return snapshot.metadata.fullPath;
  } catch (error) {
    console.error(error);
    throw error; // Re-throw the error for handling upstream
  }
}

export async function setProfilePitchdeck(payload) {
  try {
    const validationRules = {
      accountName: {
        presence: { allowEmpty: false },
        type: "string"
      },
      accountId: {
        presence: { allowEmpty: false },
        type: "string"
      }
    };
    const validationErrors = validate(payload, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }
    if (!payload?.file || payload?.file?.byteLength == undefined) {
      throw new classes.Api400Error("Invalid bufferArray");
    }

    const storage = getStorage();
    const storageRef = ref(
      storage,
      `gs://mtb_pitch-decks/${payload.accountId}/${payload.accountName}_${new Date().toISOString().split("T")[0]}`
    );

    const metadata = {
      contentType: "application/pdf",
      customMetadata: {
        accountId: payload.accountId
      }
    };

    const snapshot = await uploadBytes(storageRef, payload.file, metadata);
    return snapshot.metadata.fullPath;
  } catch (error) {
    console.error(error);
    throw error; // Re-throw the error for handling upstream
  }
}
