<template>
  <div
    @mouseover="hover = true"
    @mouseleave="hover = false"
    :class="[
      ' w-full text-center relative bg-grey4 p-6 h-[200px]  rounded-2xl flex justify-center',
      newLogo ? 'drag-enter bg-primary-light' : 'drag-leave bg-grey4'
    ]"
  >
    <div v-if="!newLogo" class="w-[150px] h-[150px]">
      <img
        :src="loadLogo(logoId) + '?' + sessionId"
        :class="[
          'rounded object-contain align-center my-auto opacity-100 w-full h-full bg-grey3',
          { 'opacity-20': hover }
        ]"
        alt="Impossible to reach the image"
        @error="
          (e) => {
            e.target.src =
              'https://img.icons8.com/carbon-copy/100/555555/no-image.png';
          }
        "
      />
      <div
        :class="[
          'opacity-0  flex flex-col items-center justify-center gap-y-6  absolute w-full h-full top-0 left-0',
          { 'opacity-100': hover }
        ]"
      >
        <base-vite-icon name="edit" classes="h-5 w-5 stroke-grey2 stroke-2" />
        <span class="text-grey2 text-base font-bold">Click to change Logo</span>
      </div>

      <input
        v-show="!newLogo"
        title="Upload new Logo"
        type="file"
        accept="image/*"
        class="opacity-0 w-full h-full cursor-pointer absolute top-0 left-0"
        @change="(e) => uploadNewImage(e)"
      />
    </div>

    <!-- CROPPER, for when an image is selected-->

    <div v-else class="flex relative align-center justify-center">
      <!--MIGRATION-->
      <cropper
        ref="logo-cropper"
        style="height: 150px; width: 150px"
        :src="newLogo"
        :stencil-size="{
          width: 130,
          height: 130
        }"
        :stencil-props="{
          handlers: {},
          movable: false,
          scalable: true
        }"
        :resize-image="{
          adjustStencil: false
        }"
        image-restriction="stencil"
      />
      <base-vite-icon
        name="close"
        role="button"
        @click="removeLogo"
        classes="absolute top-[-14px] right-[-14px] h-8 w-8 stroke-grey2 stroke-2 cursor-pointer bg-primary-solid rounded-full"
      />

      <span
        class="absolute bottom-[-18px] mx-auto w-[200%] text-grey2 text-base font-bold text-center"
      >
        Drag and scroll to resize and re-position
      </span>
    </div>
  </div>
</template>
<script setup>
import { ref, watchEffect } from "vue";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import { loadLogo } from "@global/helpers/image.js";
import { useToast } from "primevue/usetoast";
import { v4 as uuidv4 } from "uuid";

const toast = useToast();
const newLogo = ref(null);
const hover = ref(false);
const emits = defineEmits(["newLoadedLogo"]);
const props = defineProps({
  logoId: {
    type: String,
    required: true
  }
});
const sessionId = uuidv4();
function uploadNewImage(e) {
  const image = e.target.files[0];

  if (!image.type.includes("image/")) {
    return toast.add({
      severity: "error",
      summary: `The file you are trying to upload is not a valid image.`,
      life: 10000
    });
  }

  const reader = new FileReader();
  reader.readAsDataURL(image);
  reader.onload = (e) => {
    newLogo.value = e.target.result;
  };
}
watchEffect(() => {
  emits("newLoadedLogo", newLogo.value);
});
function removeLogo() {
  newLogo.value = null;
}
</script>
