<template>
  <div class="flex h-20 shrink-0 items-center" id="support-button">
    <div id="sentry-report-issue" style="display: none"></div>

    <div class="relative">
      <MTB-Button
        type="button"
        @click="toggle"
        aria-haspopup="true"
        aria-controls="overlay_menu"
        :pt="{
          root: 'border-none'
        }"
      >
        <template #icon>
          <base-vite-icon name="question" classes="w-8 fill-white" />
        </template>
      </MTB-Button>
      <MTB-Menu
        ref="menu"
        id="overlay_menu"
        :model="[
          {
            label: 'Report an issue',
            icon: 'bug',
            command: () => {
              showSentryFeedback();
            }
          },
          {
            label: 'Feature Request/Feedback',
            icon: 'idea',
            command: () => {
              showAsanaModal();
            }
          }
        ]"
        :popup="true"
        :pt="{
          root: 'py-1 bg-white text-black border border-grey3  rounded-md w-auto',
          menu: {
            class: ['m-0 p-0 list-none', 'outline-none']
          },

          action: {
            class: [
              'text-black text-sm font-sans py-2 px-3 select-none',
              'cursor-pointer flex items-center no-underline overflow-hidden relative'
            ]
          }
        }"
      >
        <template #item="{ item, props }">
          <a class="flex items-center gap-x-2" v-bind="props.action">
            <base-vite-icon :name="item.icon" classes="w-4 h-4 stroke-grey1" />
            <span class="text-grey1 font-semibold">{{ item.label }}</span>
          </a>
        </template>
      </MTB-Menu>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted } from "vue";
import { feedbackIntegration } from "@sentry/vue";
import { useDialog } from "primevue/usedialog";
import { defineAsyncComponent } from "vue";
const AsanaModal = defineAsyncComponent(
  () => import("@/global/components/asana-feedback-modal.vue")
);
const dialog = useDialog();
const menu = ref();

const toggle = (event) => {
  menu.value.toggle(event);
};

let sentryFeedback = null;

const setupSentryFeedback = () => {
  if (!sentryFeedback) {
    sentryFeedback = feedbackIntegration({
      autoInject: false,
      colorScheme: "light"
    });

    sentryFeedback.attachTo(document.getElementById("sentry-report-issue"), {
      formTitle: "Report an Issue"
    });
  }
};

const showAsanaModal = () => {
  dialog?.open(AsanaModal, {
    props: {
      header: `Feature Request/Feedback`,
      style: {
        width: "560px"
      },
      breakpoints: {
        "640px": "90vw"
      },
      modal: true,
      draggable: false
    }
  });
};
const showSentryFeedback = () => {
  setupSentryFeedback();
  // Programmatically open the feedback form
  document.getElementById("sentry-report-issue").click();
};
onMounted(() => {
  setupSentryFeedback();
});
</script>
<style>
#sentry-feedback {
  --bottom: auto;
  --right: auto;
  --top: auto;
  --left: auto;
  --z-index: 100000;
  --font-family: system-ui, "Helvetica Neue", Arial, sans-serif;
  --font-size: 14px;
  --background: #ffffff;
  --background-hover: #f6f6f7;
  --foreground: #0d0c0f;
  --error: #df3338;
  --success: #268d75;
  --border: 1.5px solid rgba(41, 35, 47, 0.13);
  --border-radius: 25px;
  --box-shadow: 0px 4px 24px 0px rgba(#1c39b9, 0.05);
  --submit-background: #0d2697;
  --submit-background-hover: #1c39b9;
  --submit-border: #1c39b9;
  --submit-outline-focus: #0d2697;
  --submit-foreground: #ffffff;
  --submit-foreground-hover: #ffffff;
  --accent-background: #0d2697;
  --outline: #0d2697;
  --input-outline-focus: #1c39b9;
  --form-border-radius: 16px;
  --form-content-border-radius: 6px;
}
:host {
  --accent-background: #0d2697;
}
</style>
