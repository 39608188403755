<template>
  <div class="flex items-center">
    <Dropdown
      v-model="selectedOption"
      :options="options"
      optionLabel="name"
      :placeholder="props.title"
      :pt="dropdownPt"
      :disabled="isDisable"
      @change="handleDropdownChange"
    >
      <template #dropdownicon>
        <base-vite-icon
          class="ml-3"
          v-if="!isDisable"
          :name="props.icon"
          :classes="iconStyle"
        />
        <span else></span>
      </template>
      <template #value="slotProps">
        <div
          v-if="slotProps.value && !props.isIconOnly"
          class="flex align-items-center"
        >
          <div>{{ slotProps.value.name }}</div>
        </div>
        <span v-else>
          {{ slotProps.placeholder }}
        </span>
      </template>
      <template #option="{ option }">
        <div class="flex flex-row items-center gap-2">
          <base-vite-icon
            v-if="option.icon"
            :name="option.icon"
            :classes="option.iconClass"
          />
          <p :class="option.titleClass" v-html="option.name"></p>
        </div>
      </template>
    </Dropdown>
  </div>
</template>

<script setup>
import Dropdown from "primevue/dropdown";
import { ref, watch, toRefs, onMounted, computed, onUnmounted } from "vue";
import { bus } from "@/main.js";
import { default as support } from "@share/commonSupport.js";

const props = defineProps({
  options: {
    type: Array,
    default: null,
    validator: (value) => {
      return (
        value.every((element) =>
          ["name", "id"].every((key) => key in element && element[key])
        ) || !value
      );
    }
  },
  title: {
    default: ""
  },
  titleStyle: {
    type: String,
    default: "text-base text-black"
  },
  iconStyle: {
    type: String,
    default: "fill-none stroke-grey2 w-5 h-5"
  },
  defaultSelectedOption: {
    type: Object,
    default: () => {}
  },
  disable: {
    type: Boolean,
    default: false
  },
  icon: {
    type: String,
    default: "chevron-down"
  },
  isIconOnly: {
    type: Boolean,
    default: false
  }
});
const emit = defineEmits(["update:modelValue"]);
const selectedOption = ref(props.defaultSelectedOption);
const { options } = toRefs(props);
const isDisable = computed(
  () => props.disable || support.isDataFalsyOrEmpty(props.options)
);
const handleDropdownChange = () => {
  emit("update:modelValue", selectedOption.value);
};

// watch(selectedOption, (newVal) => {
//   emit("update:modelValue", newVal);
// });
onMounted(() => {
  bus.on("reset-option", (value) => {
    selectedOption.value = value;
  });
});
onUnmounted(() => {
  bus.off("reset-option");
});
const dropdownPt = {
  root: {
    class: [
      "border-none w-full inline-flex relative select-none items-center",
      isDisable.value ? "cursor-normal" : "cursor-pointer"
    ]
  },
  //Dropdown title
  input: {
    class: [
      "focus:outline-none focus:shadow-none", //important
      "text-ellipsis whitespace-nowrap capitalize",
      props.titleStyle
    ]
  },
  //icon arrow down
  trigger: { class: [props.options ? "w-8" : "hidden"] },
  //Dropdown menu including filter
  panel: { class: "rounded-2xl shadow-mtb-card" },
  wrapper: {
    class: [
      "pl-6 pr-14 py-6 inline-flex flex-col justify-start items-start items-start gap-5 overflow-auto",
      "bg-white rounded-2xl"
    ]
  },
  item: ({ context }) => {
    return {
      class: [
        "cursor-pointer overflow-hidden relative whitespace-nowrap",
        { "font-normal text-grey2": !context.selected },
        { "font-bold text-black": context.selected }
      ]
    };
  },
  list: {
    class: "list-none m-0 flex flex-col gap-5"
  },
  transition: {
    enterFromClass: "opacity-0 scale-75",
    enterActiveClass:
      "transition-transform transition-opacity duration-150 ease-in",
    leaveActiveClass: "transition-opacity duration-150 ease-linear",
    leaveToClass: "opacity-0"
  }
};
</script>

<style lang="scss" scoped></style>
