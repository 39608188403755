import { defineAsyncComponent } from "vue";
import { useDialog } from "primevue/usedialog";

export function userShareModal() {
  const ShareModal = defineAsyncComponent(
    () => import("@global/components/base-share-modal.vue")
  );
  const dialog = useDialog();
  const showShareModal = (data) => {
    dialog?.open(ShareModal, {
      data: { ...data },
      props: {
        header: `Share ${data.modalTitle}`,
        style: {
          width: "50vw"
        },
        breakpoints: {
          "960px": "75vw",
          "640px": "90vw"
        },
        modal: true,
        draggable: false
      }
    });
  };
  return {
    showShareModal
  };
}
