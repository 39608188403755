import {
  getFeaturedList,
  getStartupListForCurrentFeatured
} from "@/global/services/featured-list-service.js";
import { defineStore } from "pinia";
import { computed, reactive } from "vue";
import { useAppStateStore } from "./app-state-store.js";
import {
  STATE_FEATURED_LIST,
  STATE_ADDITIONAL_INFO_FEATURED_LIST
} from "../constants/loading-state.js";
import { encryptLocalStorage } from "@/plugins/pinia.js";

export const useFeaturedListStore = defineStore(
  "featured-list",
  () => {
    const appStateStore = useAppStateStore();
    const state = reactive({
      featuredList: null,
      featuredListStartups: {}
    });
    const getters = reactive({
      getFeaturedList: computed(() => {
        if (!state.featuredList) {
          return [];
        }
        return state.featuredList;
      })
    });

    const actions = reactive({
      setFeaturedList: async (data, force) => {
        try {
          if (!state.featuredList || state.featuredList.length == 0 || force) {
            appStateStore.actions.setStartProcess(STATE_FEATURED_LIST);
          }
          const featuredLists = await getFeaturedList(data);
          const arrayToObject = (array) => {
            return array.reduce((obj, item) => {
              if (item.Id) {
                obj[item.Id] = { ...(obj[item.Id] || {}), ...item };
              }
              return obj;
            }, {});
          };
          const mergeObjects = (obj1, obj2) => {
            const merged = { ...obj1 };
            for (const key in obj2) {
              if (key in obj2) {
                merged[key] = { ...(merged[key] || {}), ...obj2[key] };
              }
            }
            return merged;
          };

          const stateObject = arrayToObject(state.featuredList || []);
          const featuredListsObject = arrayToObject(featuredLists || []);
          const mergedObject = mergeObjects(stateObject, featuredListsObject);

          const mergedArray = Object.values(mergedObject);
          state.featuredList = mergedArray;
          if (!featuredLists || featuredLists.length == 0) {
            return;
          }
        } catch (error) {
          appStateStore.actions.setError(STATE_FEATURED_LIST, true);
          throw new Error("Unable to load featured lists");
        } finally {
          appStateStore.actions.setLoading(STATE_FEATURED_LIST, false);
        }
      },
      setAdditionalInfoFeaturedList: async (featuredListId) => {
        try {
          const currentStartupList = state.featuredListStartups[featuredListId];
          if (!currentStartupList) {
            appStateStore.actions.setStartProcess(
              STATE_ADDITIONAL_INFO_FEATURED_LIST
            );
          }

          const startupLists =
            await getStartupListForCurrentFeatured(featuredListId);

          // Assuming you want to attach the startupLists to the corresponding featured list
          // This step depends on how you want to structure your data

          state.featuredListStartups = {
            ...state.featuredListStartups,
            [featuredListId]: startupLists
          };
        } catch (error) {
          appStateStore.actions.setError(
            STATE_ADDITIONAL_INFO_FEATURED_LIST,
            true
          );
          throw new Error("Unable to load additional info featured lists");
        } finally {
          appStateStore.actions.setLoading(
            STATE_ADDITIONAL_INFO_FEATURED_LIST,
            false
          );
        }
      },
      reset: () => {
        state.featuredList = null;
        state.featuredListStartups = {};
      }
    });

    return {
      state,
      getters,
      actions
    };
  },
  {
    persist: {
      storage: encryptLocalStorage
    }
  }
);
