<template>
  <div class="relative py-6 h-full">
    <!--=========== LEFT PART =============-->

    <div
      :class="[
        'flex items-start px-6 gap-14 self-stretch flex-1  h-full overflow-y-scroll',
        { 'lg:pe-[45%]': isMyProfile }
      ]"
      v-if="accountDetails"
    >
      <div
        class="flex flex-col items-start pt-2 gap-8 self-stretch"
        :class="
          userAuthStore.state?.accountInfo?.type == 'corporate'
            ? 'info-wrapper-corps'
            : 'info-wrapper'
        "
      >
        <!-- STARTUP NAME + LOGO -->
        <!-- <div class="flex items-center gap-4 w-full">
          <Skeleton v-if="isLoadingAccount" class="w-8 h-8 mb-0"></Skeleton>
          <base-logo
            v-else
            :companyId="accountDetails.Id"
            classes="w-8 h-8 flex-none"
          />
          <Skeleton v-if="isLoadingAccount" class="w-40 h-8 mb-0"></Skeleton>
          <div
            v-else
            class="w-[calc(100%-48px)] flex justify-between gap-x-4 items-center flex-wrap sm:flex-nowrap gap-y-2 sm:gap-y-0"
          >
            <div class="flex items-center gap-4">
              <span
                class="text-3xl font-bold overflow-hidden text-ellipsis text-start"
                >{{ accountDetails?.Name }}</span
              >
              <baseFavouriteStartup
                :startupId="accountDetails.Id"
                v-if="starVisibility"
              />

              <base-button
                :text="true"
                icon="share_fill"
                iconClass="w-6 h-6 fill-primary"
                @click="openShareModal"
              />
            </div>
            <div class="flex items-center gap-2">
              <base-button
                v-if="meetingVisibility"
                size="small"
                class="hidden sm:flex"
                :disabled="!props.auth"
                label="Request Meeting"
                iconClass="h-5 w-5"
                icon="calendar_empty"
                severity="primary"
                @click="openMeeting"
              />

              <base-button
                v-if="isMyProfile"
                size="small"
                label="Edit Profile"
                iconClass="h-5 w-5"
                icon="edit"
                severity="primary"
                @click="editProfile"
              />

              <base-dropdown
                v-if="mtbEditable"
                icon="menu_dot"
                title=""
                titleStyle=""
                iconStyle="fill-mtb stroke-mtb stroke-2 w-5 h-5 cursor-pointer"
                :isIconOnly="true"
                :options="mtbActions"
                @update:modelValue="handleMTBAction"
              />
            </div>
          </div>
        </div> -->
        <base-profile-action-buttons
          :isLoading="isLoadingAccount"
          :isMyProfile="isMyProfile"
          :starVisibility="starVisibility"
          :meetingVisibility="meetingVisibility"
          :auth="props.auth"
          :companyId="accountDetails.Id"
          :company-name="accountDetails.Name"
          companyType="startup"
          @requestMeeting="openMeeting"
          @editProfile="editProfile"
          @share="openShareModal"
        />
        <mtb-account-details
          :isLoading="isLoadingAccount"
          :accountDetails="accountDetails"
          :accountMainContact="accountMainContact"
          :accountContacts="accountContacts"
          :accountSponsorDetails="accountSponsorDetails"
          accountType="startup"
          :sidebar="false"
        />
        <rialto-similar-startups
          v-if="!isMyProfile"
          :startupId="accountDetails.Id"
          :isLoadingAccount="isLoadingAccount"
        />
        <!--TODO hidden for unauth + define visibility for other user-->
        <base-startup-profile-opportunities
          v-if="
            !isMyProfile &&
            userAuthStore.state?.accountInfo?.type == 'corporate'
          "
          :accountDetails="accountDetails"
          :fromChallenge="fromChallenge"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { mixpanelMtbTracking } from "@/plugins/mixpanel.js";
import {
  ref,
  watch,
  computed,
  defineAsyncComponent,
  onMounted,
  onUnmounted
} from "vue";
import { bus } from "@/main.js";
import { useAccountDetail } from "@/global/hooks/use-account-details.js";
import { getLastPitchDeck } from "@/global/services/accounts-service.js";
import { useUserAuthStore } from "../stores/user-auth-store.js";
import { useInstitutionMyEcosystemStore } from "@/modules/institutions/stores/institution-myecosystem-store.js";
import { useOpportunityModals } from "@/modules/corporates/hooks/use-opportunity-modals.js";
import { useRoute, useRouter } from "vue-router";
import { useEditStartupProfileModal } from "@startups/hooks/use-edit-startup-profile-modal.js";
const { showEditStartupProfileModal } = useEditStartupProfileModal();
const baseFavouriteStartup = defineAsyncComponent(
  () => import("@/modules/corporates/components/base-favourite-startup.vue")
);

import { SALESFORCE_LINK_ACCOUNT } from "@global/constants/index.js";
const route = useRoute();
const router = useRouter();
const userAuthStore = useUserAuthStore();
const institutionMyEcosystemStore = useInstitutionMyEcosystemStore();

const accountDetails = ref([]);
const accountMainContact = ref({});
const accountContacts = ref([]);
const accountSponsorDetails = ref({});
const isLoadingAccount = ref(true);
const { openMeetingModal } = useOpportunityModals();
const props = defineProps({
  auth: {
    type: Boolean,
    default: false
  },
  isMyProfile: {
    type: Boolean,
    default: false
  },
  startupId: {
    type: String,
    required: true
  },
  baseRouteName: {
    type: String,
    required: true
  }
});
const fromChallenge = computed(() => {
  return route.query?.from;
});

const isMyProfile = computed(() => {
  return props.auth && props.startupId === userAuthStore.state?.accountInfo?.id;
});

const mtbEditable = computed(() => {
  return (
    userAuthStore.getters.isMtbUser &&
    userAuthStore.state?.userInfo?.Claims.mtb &&
    !userAuthStore.state?.isDemoModeActive
  );
});

const isMySponsored = computed(() => {
  return (
    props.auth &&
    userAuthStore.state?.accountInfo?.type == "institution" &&
    (institutionMyEcosystemStore.getters.getSponsoredStartups.some(
      (item) => item.companyId === props.startupId
    ) ||
      institutionMyEcosystemStore.getters.getSponsoredCorporates.some(
        (item) => item.companyId === props.startupId
      ))
  );
});
const meetingVisibility = computed(() => {
  return !props.auth || userAuthStore.state?.accountInfo?.type == "corporate";
});
const starVisibility = computed(() => {
  return (
    !isLoadingAccount.value &&
    accountDetails.value.Id &&
    userAuthStore.state?.accountInfo?.type == "corporate"
  );
});
const { getAccountDetail } = useAccountDetail();

// watchEffect(async () => {
//   if (props.startupId) {
//     await loadAccountDetails();
//     mixpanelTrack();
//     if (route.params.editable === "edit") {
//       editProfile();
//     }
//   }
// });
//hotfix: changing to watch cause triggered 3 times when entering this page directly from URL link
watch(
  () => props.startupId,
  async (newValue, oldValue) => {
    if (newValue && newValue !== oldValue) {
      await loadAccountDetails();
      mixpanelTrack();
      if (route.params.editable === "edit") {
        editProfile();
      }
    }
  },
  { immediate: true }
);
onMounted(() => {
  bus.on("reload-account-details", () => loadAccountDetails(true));
});
onUnmounted(() => {
  bus.off("reload-account-details");
});
function mixpanelTrack() {
  const regex = /\?a=[0-9A-Za-z]{15,18}/;
  //track the event if it's not my profile and the prev route wasn't the sidebar
  if (!isMyProfile.value && !regex.test(router.options.history.state["back"])) {
    const forMixpanel = {
      visitor_id: userAuthStore.state?.accountInfo?.id,
      visitor_name: userAuthStore.state?.accountInfo?.name,
      visitor_type: userAuthStore.state?.accountInfo?.type,
      visited_id: accountDetails.value?.Id,
      visited_name: accountDetails.value?.Name,
      suggested: false
    };
    mixpanelMtbTracking({
      eventName: "Startup Exposure",
      properties: forMixpanel
    });
  }
}
async function loadAccountDetails(force) {
  isLoadingAccount.value = true;
  const account = await getAccountDetail(props.startupId, !!force);

  accountDetails.value = account.accountDetails.value;
  const latestDeck = props.auth
    ? await getLastPitchDeck(props.startupId)
    : null;
  accountDetails.value.pitchDeck = latestDeck
    ? `https://storage.googleapis.com/mtb_pitch-decks/${encodeURIComponent(
        latestDeck.name
      )}`
    : null;
  if (isMyProfile.value || isMySponsored.value) {
    accountContacts.value = account.accountContacts.value;
    accountMainContact.value = account.accountMainContact.value;
  } else {
    accountContacts.value = [];
    accountMainContact.value = {};
  }
  accountSponsorDetails.value = account.accountSponsorDetails.value;
  isLoadingAccount.value = false;
}
function openMeeting() {
  if (!props.auth) {
    return;
  }
  openMeetingModal({
    selectedStartupIds: [accountDetails.value.Id],
    selectedChallenge: null,
    startupTagEditable: false,
    challengeEditable: true,
    forceSuggestions: [
      {
        AccountName: accountDetails.value.Name,
        AccountId: accountDetails.value.Id
      }
    ],
    SFSearch: false,
    resetOnChallengeChange: false
  });
}
function editProfile() {
  if (!(isMyProfile.value || mtbEditable.value)) {
    const params = { ...route.params };
    delete params["editable"];
    router.replace({ ...route, params });
    return;
  }
  if (route.params.editable != "edit") {
    const query = { ...route.query };
    router.replace({
      ...route,
      ...query,
      params: {
        editable: "edit"
      }
    });
  }

  showEditStartupProfileModal({
    accountDetails: accountDetails.value,
    updateRoute: true
  });
}
const mtbActions = ref([
  {
    name: "Open Account in Salesforce",
    id: "openAccountSF",
    titleClass: "text-mtb"
  },
  {
    name: "Edit Startup Profile <div class='ms-2 mtb-chip text-sm bg-alert-light text-alert'>New</div>",
    id: "editStProfile",
    titleClass: "text-mtb"
  }
]);

const handleMTBAction = (data) => {
  let url;
  if (data.id === "openAccountSF") {
    url = `${SALESFORCE_LINK_ACCOUNT}${accountDetails.value.Id}/view`;
  } else if (data.id === "editStProfile") {
    setTimeout(() => {
      editProfile();
    }, 500);
  }
  if (url) window.open(encodeURI(url), "_blank");
};

import { getUrl } from "@global/helpers/url.js";
import { userShareModal } from "@global/hooks/use-share-modal.js";
const { showShareModal } = userShareModal();

const openShareModal = async () => {
  showShareModal({
    modalTitle: `${accountDetails.value.Name}`,
    sharingUrl: getUrl(
      `startup-profile/${accountDetails.value.Id}?_share=true`
    ),
    pageName: "Startup Profile"
  });
};

//META TAGS:
import { useMetaTags } from "@global/hooks/use-meta-head.js";

useMetaTags({
  data: accountDetails,
  pageName: "Startup Profile",
  sharingLink: getUrl(`startup-profile/${accountDetails.value.Id}`)
});
</script>

<style lang="scss" scoped>
.info-wrapper-corps {
  width: 100%;
  @media (min-width: 1280px) {
    max-width: 48%;
  }
}
.info-wrapper {
  width: 100% !important;
}
</style>
