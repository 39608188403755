import { startOfWeek, addWeeks, isBefore, isAfter } from "date-fns";

export const parseDateTime = (date) => {
  const localization = Intl.DateTimeFormat().resolvedOptions();
  const dateOptions = {
    day: "numeric",
    month: "short",
    year: "numeric"
  };
  const timeOptions = {
    hour: "2-digit",
    minute: "2-digit"
  };

  const formattedDate = new Date(date).toLocaleDateString(
    localization.locale,
    dateOptions
  );
  const formattedTime = new Date(date).toLocaleTimeString(
    localization.locale,
    timeOptions
  );

  return `${formattedDate} ${formattedTime}`;
};

export function checkDateStatus(dateToCheck) {
  if (!dateToCheck) return "";

  const today = new Date();
  const startOfNextWeek = startOfWeek(addWeeks(today, 1));

  const meetingDate = new Date(dateToCheck);

  if (isBefore(meetingDate, today)) {
    return "In the past";
  } else if (isBefore(meetingDate, startOfNextWeek)) {
    if (meetingDate.getDay() === today.getDay()) {
      // Check if meeting is on the same day as today
      if (meetingDate.getTime() <= today.getTime()) {
        return "In the past";
      } else {
        return "This week";
      }
    } else {
      return "This week";
    }
  } else if (isAfter(meetingDate, startOfNextWeek)) {
    return "In the future";
  }
}
