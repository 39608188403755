import { ref, computed, watch } from "vue";

export function useTruncatedText(description, limitCharacter = 200) {
  const limit = limitCharacter.value || limitCharacter;

  const expanded = ref(false);

  const isTruncated = computed(() => {
    return description.value.length > limit;
  });

  const truncatedDescription = computed(() => {
    const desc = description?.value || ""; // Ensure description is a string
    if (expanded.value) {
      return desc;
    } else {
      return desc.length > limit ? desc.slice(0, limit) + "..." : desc;
    }
  });

  const expandText = () => {
    expanded.value = true;
  };

  const collapseText = () => {
    expanded.value = false;
  };

  return {
    expanded,
    truncatedDescription,
    expandText,
    collapseText,
    isTruncated
  };
}
