import { functionsEU } from "@plugins/firebase.js";
import { httpsCallable } from "firebase/functions";
import validate from "validate.js";

import { default as classes } from "@share/errorClasses.js";
import { sentryErrorManagement } from "@plugins/sentry.js";

export async function searchStartups(data) {
  try {
    //validation
    const validationRules = {
      query: {
        presence: { allowEmpty: false },
        type: "string"
      },
      excludeOpportunity: {
        presence: { allowEmpty: false },
        type: "boolean"
      },
      accountId: {
        presence: false,
        type: "string"
      },
      challengeId: {
        presence: false,
        type: "string"
      },
      excludeAccountIds: {
        presence: false,
        type: "array"
      }
    };
    const validationErrors = validate(data, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }
    const httpsCallableFunction = httpsCallable(
      functionsEU,
      "searchStartupsSF"
    );
    const result = await httpsCallableFunction(data);
    return result.data;
  } catch (error) {
    sentryErrorManagement({
      errorName: error.name,
      errorMessage: error.message,
      errorStack: error.stack,
      functionPath: "searchStartups"
    });
    return [];
  }
}
export async function exploreStartupsPinecone(data) {
  try {
    //validation
    const validationRules = {
      query: {
        presence: { allowEmpty: false },
        type: "string"
      },
      filters: {
        presence: { allowEmpty: false },
        type: "object"
      }
    };
    const validationErrors = validate(data, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }
    const httpsCallableFunction = httpsCallable(
      functionsEU,
      "RialtoFlow-searchStartupPinecone"
    );
    const result = await httpsCallableFunction(data);
    return result.data?.suggestions || [];
  } catch (error) {
    throw error;
  }
}
export async function exploreStartupsSalesforce(data) {
  try {
    //validation
    const validationRules = {
      query: {
        presence: { allowEmpty: false },
        type: "string"
      },
      filters: {
        presence: { allowEmpty: false },
        type: "object"
      }
    };
    const validationErrors = validate(data, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }
    const httpsCallableFunction = httpsCallable(functionsEU, "searchAccountSF");
    const result = await httpsCallableFunction({
      ...data,
      accountType: "startup"
    });
    return result.data;
  } catch (error) {
    throw error;
  }
}
export async function exploreChallengesPinecone(data) {
  try {
    //validation
    const validationRules = {
      query: {
        presence: { allowEmpty: false },
        type: "string"
      },
      restrictForUser: {
        presence: false,
        type: "string"
      },
      filters: {
        presence: { allowEmpty: false },
        type: "object"
      }
    };
    const validationErrors = validate(data, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }
    const httpsCallableFunction = httpsCallable(
      functionsEU,
      "RialtoFlow-searchChallengePinecone"
    );
    const result = await httpsCallableFunction(data);
    return result.data?.suggestions || [];
  } catch (error) {
    throw error;
  }
}
export async function exploreChallengesSalesforce(data) {
  try {
    //validation
    const validationRules = {
      query: {
        presence: { allowEmpty: false },
        type: "string"
      },
      restrictForUser: {
        presence: false,
        type: "string"
      },
      filters: {
        presence: { allowEmpty: false },
        type: "object"
      }
    };
    const validationErrors = validate(data, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }
    const httpsCallableFunction = httpsCallable(
      functionsEU,
      "searchChallengeSF"
    );
    const result = await httpsCallableFunction(data);
    return result.data;
  } catch (error) {
    throw error;
  }
}
export async function explorePartnersSalesforce(data) {
  try {
    //validation
    const validationRules = {
      query: {
        presence: { allowEmpty: false },
        type: "string"
      },
      filters: {
        presence: { allowEmpty: false },
        type: "object"
      }
    };
    const validationErrors = validate(data, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }
    const httpsCallableFunction = httpsCallable(functionsEU, "searchAccountSF");
    const result = await httpsCallableFunction({
      ...data,
      accountType: "institution"
    });
    return result.data;
  } catch (error) {
    throw error;
  }
}

export async function updateUserQueryHistory(payload) {
  try {
    const httpsCallableFunction = httpsCallable(
      functionsEU,
      "exploreQuery-saveAndUpdateQuery"
    );
    const result = await httpsCallableFunction(payload);
    return result.data;
  } catch {
    (err) => {
      console.error(err);
    };
  }
}
export async function getUserQueryHistory(payload) {
  try {
    const httpsCallableFunction = httpsCallable(
      functionsEU,
      "exploreQuery-getQueryListPerUser"
    );
    const result = await httpsCallableFunction(payload);
    return result.data;
  } catch {
    (err) => {
      console.error(err);
    };
  }
}
export async function mixpanelTrackingExplore(payload) {
  try {
    const httpsCallableFunction = httpsCallable(
      functionsEU,
      "exploreQuery-mixpanelTrackingExplore"
    );
    if (process.env.NODE_ENV !== "development") {
      await httpsCallableFunction(payload);
    }
  } catch (err) {
    throw err;
  }
}
