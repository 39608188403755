<template>
  <div :id="id" class="flex flex-col items-center justify-center w-full">
    <!-- MAIN CHART -->
    <apexchart
      type="treemap"
      height="350"
      class="w-full"
      :options="chartOptions"
      :series="series"
      @dataPointSelection="showDropdownMenu"
    ></apexchart>

    <!-- CUSTOM LEGEND -->
    <base-chart-legend class="mt-3" :legend="ranges" />
  </div>
</template>

<script setup>
import { singleStateChart, toolbar } from "@/global/configs/analytics.js";
import colors from "@/plugins/color.js";
import { computed } from "vue";
const emits = defineEmits(["dataPointSelection"]);

const props = defineProps({
  id: {
    type: String,
    required: true
  },
  chartProps: {
    type: Object,
    required: true
  }
});
function showDropdownMenu(a, _b, c) {
  const mousePosition = {
    offsetX: a.offsetX,
    offsetY: a.offsetY
  };
  const title = series.value[0]?.data?.[c.dataPointIndex]?.x || "";
  emits("dataPointSelection", { mousePosition, title });
}
const series = computed(() => {
  const initialData = props.chartProps.chartData || {};
  const data = Object.keys(initialData).map((element) => {
    return { x: element, y: props.chartProps.chartData[element]?.length };
  });
  const sortedData = data.sort((a, b) => b.y - a.y);
  return [
    {
      data: props.chartProps.needSorting ? sortedData : data
    }
  ];
});
const ranges = computed(() => {
  const data = props.chartProps.chartData || {};
  const values = Object.values(data).map((ele) => ele.length) || [];
  const max = Math.max(...values);
  const firstRangeMax = Math.round(max * 0.1); //step1
  const secondRangeMax = Math.round(max * 0.4); //step2

  let numRanges = 3;
  if (max <= 4 && max > 1) {
    numRanges = 2;
  } else if (max <= 1) {
    numRanges = 1;
  }
  let ranges = [];

  //3
  for (let i = 1; i <= numRanges; i++) {
    let range = {};
    if (i === numRanges) {
      range = {
        to: max,
        from: secondRangeMax + 0.1,
        color: colors.chart[900]
      };
    } else if (i === numRanges - 1) {
      range = {
        to: secondRangeMax,
        from: firstRangeMax + 0.1,
        color: colors.chart[500]
      };
    } else {
      range = {
        to: firstRangeMax,
        from: 0,
        color: colors.chart[200]
      };
    }
    ranges.push(range);
  }
  return ranges;
});

const chartOptions = computed(() => {
  return {
    states: singleStateChart,
    legend: {
      show: true
    },
    chart: {
      height: 350,
      type: "treemap",
      toolbar: toolbar()
    },
    title: {
      text: ""
    },
    tooltip: {
      custom: function ({ series, dataPointIndex, w }) {
        let colorRange = "";
        colorRange = ranges.value.find(
          (element) =>
            series[0][dataPointIndex] >= element.from &&
            series[0][dataPointIndex] <= element.to
        ).color;
        return `
            <div class="p-1 chart-tooltip d-flex align-items-center"><span class='svg-icon'><svg height="25" viewBox="0 96 960 960" width="25"><path d="M480.191 804Q386 804 319 737.191q-67-66.808-67-161Q252 482 318.809 415q66.808-67 161-67Q574 348 641 414.809q67 66.808 67 161Q708 670 641.191 737q-66.808 67-161 67Z" fill="${colorRange}"/></svg></span>
            ${w.globals.categoryLabels[dataPointIndex]}: <b>${series[0][dataPointIndex]}
            </b>
            </div>`;
      }
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: "14px"
      },
      formatter: function (text, op) {
        if (
          op.value >= ranges.value[0].from &&
          op.value <= ranges.value[0].to &&
          ranges.value.length === 3
        ) {
          text = "";
          op.value = "";
        } else if (text.length > 20) {
          text = text.substring(0, 15) + "...";
        }
        return [text, op.value];
      },
      offsetY: -4
    },
    plotOptions: {
      treemap: {
        enableShades: false, // Disable default opacity
        colorScale: {
          ranges: ranges.value
        }
      }
    },
    fill: {
      type: "solid",
      opacity: 1 // Set opacity to 1
    }
  };
});
</script>

<style lang="scss" scoped></style>
