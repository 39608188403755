import { functionsEU } from "@plugins/firebase.js";
import { httpsCallable } from "firebase/functions";
import { default as classes } from "@share/errorClasses.js";
import validate from "validate.js";

export async function getFavouriteList(data) {
  try {
    //validation
    const validationRules = {
      data: {
        presence: { allowEmpty: false },
        type: "object"
      },
      "data.Account_Id__c": {
        presence: { allowEmpty: false },
        type: "string"
      }
    };
    const validationErrors = validate({ data }, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }

    const httpsCallableFunction = httpsCallable(
      functionsEU,
      "getFavouriteLists"
    );
    const result = await httpsCallableFunction(data);
    return result.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getStartupsFromFavouriteList(data) {
  try {
    //validation
    const validationRules = {
      data: {
        presence: { allowEmpty: false },
        type: "object"
      },
      "data.Favourite__c": {
        presence: { allowEmpty: false },
        type: "string"
      }
    };
    const validationErrors = validate({ data }, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }

    const httpsCallableFunction = httpsCallable(
      functionsEU,
      "getStartupsFromFavouriteList"
    );
    const result = await httpsCallableFunction(data);
    return result.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function createFavouriteList(data) {
  try {
    //validation
    const validationRules = {
      data: {
        presence: { allowEmpty: false },
        type: "object"
      },
      "data.Account_Id__c": {
        presence: { allowEmpty: false },
        type: "string"
      },
      "data.Name": {
        presence: { allowEmpty: false },
        type: "string"
      },
      "data.List_Owner__c": {
        presence: false,
        type: "string"
      }
    };
    const validationErrors = validate({ data }, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }

    const httpsCallableFunction = httpsCallable(
      functionsEU,
      "createFavouriteList"
    );
    const result = await httpsCallableFunction(data);
    return result.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function updateFavouriteList(data) {
  try {
    //validation
    const validationRules = {
      data: {
        presence: { allowEmpty: false },
        type: "object"
      },
      "data.Id": {
        presence: { allowEmpty: false },
        type: "string"
      },
      "data.Name": {
        presence: { allowEmpty: false },
        type: "string"
      }
    };
    const validationErrors = validate({ data }, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }

    const httpsCallableFunction = httpsCallable(
      functionsEU,
      "updateFavouriteList"
    );
    const result = await httpsCallableFunction(data);
    return result.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function deleteFavouriteList(id, name) {
  try {
    //validation
    const validationRules = {
      id: {
        presence: { allowEmpty: false },
        type: "string"
      }
    };
    const validationErrors = validate({ id }, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }

    const httpsCallableFunction = httpsCallable(
      functionsEU,
      "deleteFavouriteList"
    );
    await httpsCallableFunction({ id, name });
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function addStartupToFavouriteList(data) {
  try {
    //validation
    const validationRules = {
      data: {
        presence: { allowEmpty: false },
        type: "object"
      },
      //favourite list ID
      "data.Favourite__c": {
        presence: { allowEmpty: false },
        type: "string"
      },
      //startup ID
      "data.Account__c": {
        presence: { allowEmpty: false },
        type: "string"
      }
    };
    const validationErrors = validate({ data }, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }

    const httpsCallableFunction = httpsCallable(
      functionsEU,
      "addStartupToFavouriteList"
    );
    const result = await httpsCallableFunction(data);
    return result.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

//do we prefer to pass favourite list id and startup id?
export async function removeStartupFromFavouriteList(id) {
  try {
    //validation
    const validationRules = {
      //favourite_account id
      id: {
        presence: { allowEmpty: false },
        type: "string"
      }
    };
    const validationErrors = validate({ id }, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }

    const httpsCallableFunction = httpsCallable(
      functionsEU,
      "removeStartupFromFavouriteList"
    );
    const result = await httpsCallableFunction(id);
    return result.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function addFavouritesToChallenge(data) {
  try {
    //validation
    const validationRules = {
      //favourite_account id
      data: {
        presence: { allowEmpty: false },
        type: "object"
      },
      "data.startups": {
        presence: { allowEmpty: false },
        type: "array"
      },
      "data.ChallId": {
        presence: { allowEmpty: false },
        type: "string"
      },
      "data.CorpName": {
        presence: { allowEmpty: false },
        type: "string"
      },
      "data.CorpId": {
        presence: { allowEmpty: false },
        type: "string"
      },
      "data.OwnerId": {
        presence: { allowEmpty: false },
        type: "string"
      }
    };
    const validationErrors = validate({ data }, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }

    const httpsCallableFunction = httpsCallable(
      functionsEU,
      "addFavouritesToChall"
    );
    const result = await httpsCallableFunction(data);
    return result.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
