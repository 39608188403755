<template>
  <div class="flex items-center gap-4 w-full">
    <!-- LOGO  -->
    <Skeleton v-if="isLoading" class="w-8 h-8 mb-0"></Skeleton>
    <base-logo v-else :companyId="companyId" classes="w-8 h-8 flex-none" />
    <!-- NAME + BUTTONS -->
    <Skeleton v-if="isLoading" class="w-40 h-8 mb-0"></Skeleton>
    <div
      v-else
      class="w-[calc(100%-48px)] flex justify-between gap-x-4 items-center flex-wrap sm:flex-nowrap gap-y-2 sm:gap-y-0"
    >
      <!-- Name + star button + share button -->
      <div class="flex items-center gap-4">
        <!-- Company Name -->
        <span
          class="text-3xl font-bold overflow-hidden text-ellipsis text-start"
          >{{ companyName }}</span
        >
        <!-- Favorite button -->
        <baseFavouriteStartup
          :startupId="companyId"
          v-if="starVisibility && !isEmbedMode"
        />

        <!-- Share button -->
        <base-button
          v-if="!isEmbedMode"
          :text="true"
          icon="share_fill"
          iconClass="w-6 h-6 fill-primary"
          @click="openShareModal"
        />
      </div>
      <!-- Edit profile and others -->
      <div class="flex items-center gap-2" v-if="!isEmbedMode">
        <base-button
          v-if="props.meetingVisibility"
          size="small"
          class="hidden sm:flex"
          :disabled="!props.auth"
          label="Request Meeting"
          iconClass="h-5 w-5"
          icon="calendar_empty"
          severity="primary"
          @click="openMeeting"
        />

        <base-button
          v-if="props.isMyProfile && !isShareLink"
          size="small"
          label="Edit Profile"
          iconClass="h-5 w-5"
          icon="edit"
          severity="primary"
          @click="editProfile"
        />

        <base-dropdown
          v-if="showMTBActions && !isShareLink"
          icon="menu_dot"
          title=""
          titleStyle=""
          iconStyle="fill-mtb stroke-mtb stroke-2 w-5 h-5 cursor-pointer"
          :isIconOnly="true"
          :options="mtbActions"
          @update:modelValue="handleMTBAction"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, computed } from "vue";
import { useRoute, useRouter } from "vue-router";

import { useUserAuthStore } from "../stores/user-auth-store.js";
import { SALESFORCE_LINK_ACCOUNT } from "@global/constants/index.js";
const emits = defineEmits(["requestMeeting", "editProfile", "share"]);
const props = defineProps({
  companyId: {
    type: String,
    default: ""
  },
  companyName: {
    type: String,
    default: ""
  },
  starVisibility: {
    type: Boolean,
    default: false
  },
  meetingVisibility: {
    type: Boolean,
    default: false
  },
  isMyProfile: {
    type: Boolean,
    default: false
  },
  auth: {
    type: Boolean,
    default: false
  },
  companyType: {
    type: String,
    default: ""
  },
  isLoading: {
    type: Boolean,
    default: true
  }
});
const userAuthStore = useUserAuthStore();
const route = useRoute();
const isEmbedMode = computed(() => route.query._embed === "true");
const isShareLink = computed(() => route.query._share === "true");
const showMTBActions = computed(() => {
  return (
    userAuthStore.getters.isMtbUser &&
    userAuthStore.state?.userInfo?.Claims.mtb &&
    !userAuthStore.state?.isDemoModeActive
  );
});

const mtbActions = ref([
  {
    name: "Open Account in Salesforce",
    id: "openAccountSF",
    titleClass: "text-mtb"
  },
  ...(props.companyType === "startup"
    ? [
        {
          name: "Edit Startup Profile <div class='ms-2 mtb-chip text-sm bg-alert-light text-alert'>New</div>",
          id: "editStProfile",
          titleClass: "text-mtb"
        }
      ]
    : [])
]);
const handleMTBAction = (data) => {
  let url;
  if (data.id === "openAccountSF") {
    url = `${SALESFORCE_LINK_ACCOUNT}${props.companyId}/view`;
  } else if (data.id === "editStProfile") {
    setTimeout(() => {
      editProfile();
    }, 500);
  }
  if (url) window.open(encodeURI(url), "_blank");
};
const openMeeting = () => {
  emits("requestMeeting");
};
const editProfile = () => {
  emits("editProfile");
};
const openShareModal = () => {
  emits("share");
};
</script>

<style lang="scss" scoped></style>
