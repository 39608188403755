const errorSuggestionType = {
  RETRY: "Please, try again later",
  CONTACT_US: "Contact our team" //TODO later when we implemente some user feedback logic
};
const CLOUD_FUNCTION_ERROR = "CLOUD_FUNCTION_ERROR";
const suggestionsMap = {
  ReferenceError: null, //in the future contct us
  TypeError: null, //in the future contct us
  RangeError: errorSuggestionType.RETRY,
  URIError: null, //in the future contct us
  CONTENT_HUB_AUTHENTICATION_EXCEPTION: errorSuggestionType.RETRY,
  API_CURRENTLY_DISABLED: errorSuggestionType.RETRY,
  API_DISABLED_FOR_ORG: errorSuggestionType.RETRY,
  CANT_ADD_STANDARD_PORTAL_USER_TO_TERRITORY: errorSuggestionType.RETRY,
  FirebaseCollection: null, //in the future contct us
  CLONE_NOT_SUPPORTED: null, //in the future contct us
  CLONE_FIELD_INTEGRITY_EXCEPTION: null, //in the future contct us
  DUPLICATE_VALUE: null, //in the future contct us
  INVALID_TYPE: null, //in the future contct us
  INVALID_FIELD: null //in the future contct us
};
function isTemporaryError(error) {
  // Check if the error is a temporary error that can be retried
  //ADD other temporary error code here
  return (
    error.message.includes("deadline-exceeed") ||
    error.message.includes("ECONNRESET") ||
    error.message.includes("ECONNABORTED") ||
    error.message.includes("ETIMEDOUT") ||
    error.message.includes("ECONNREFUSED") ||
    error.message.includes("INVALID_SESSION_ID") ||
    error.message.includes("ERROR_HTTP_406") ||
    error.message.includes("connection termination")
  );
}

function getErrorSuggestion(error) {
  if (isTemporaryError(error)) {
    return errorSuggestionType.RETRY;
  } else {
    const currentError = Object.keys(suggestionsMap)?.find((key) =>
      error.message.includes(key)
    );
    return suggestionsMap?.[currentError] || null;
  }
}

const sentryInitObject = {
  dsn: "https://364358c07a5e40a89dfa10f431d0eb48@o1263386.ingest.sentry.io/6360747",
  maxBreadcrumbs: 100,
  attachStacktrace: true,
  autoSessionTracking: true,
  debug: false
};
const errorLevel = {
  ECONNRESET: "warning",
  ECONNABORTED: "warning",
  ETIMEDOUT: "warning",
  SyntaxError: "error",
  ReferenceError: "error",
  TypeError: "error",
  RangeError: "error",
  URIError: "error",
  CONTENT_HUB_AUTHENTICATION_EXCEPTION: "error",
  API_CURRENTLY_DISABLED: "warning",
  API_DISABLED_FOR_ORG: "warning",
  CANT_ADD_STANDARD_PORTAL_USER_TO_TERRITORY: "error",
  FirebaseCollection: "critical",
  CLONE_NOT_SUPPORTED: "critical",
  CLONE_FIELD_INTEGRITY_EXCEPTION: "critical",
  DUPLICATE_VALUE: "critical",
  INVALID_TYPE: "critical",
  INVALID_FIELD: "critical",
  Error: "error"
};

module.exports = {
  getErrorSuggestion,
  isTemporaryError,
  sentryInitObject,
  errorLevel,
  CLOUD_FUNCTION_ERROR
};
