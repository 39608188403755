export const visibilityMapping = {
  Public: {
    dropdownIconClass: "stroke-2 w-5 h-5 stroke-success fill-none",
    iconClasses: "fill-success w-5 h-5",
    parentClasses: "bg-success-light text-success",
    icon: "web_fill",
    label: "Public"
  },
  Anonymous: {
    dropdownIconClass: "stroke-data5 fill-none stroke-2 w-5 h-5",
    iconClasses: "fill-data5 stroke-data5 w-5 h-5",
    parentClasses: "bg-data5-light text-data5",
    icon: "show",
    label: "Anonymous"
  },
  Hidden: {
    dropdownIconClass: "stroke-grey1 fill-none stroke-2 w-5 h-5",
    iconClasses: "fill-grey1 w-5 h-5",
    parentClasses: "bg-data5-light text-grey1",
    icon: "hide",
    label: "Hidden"
  }
};
export const objectiveMapping = {
  Procurement: {
    iconClasses: "fill-data3 w-4 h-4",
    parentClasses: "bg-data3-light text-data3",
    icon: "cart_fill",
    label: "Procurement"
  },
  Landscaping: {
    iconClasses: "fill-data1 w-4 h-4",
    parentClasses: "bg-data1-light text-data1",
    icon: "search_fill",
    label: "Exploration"
  },
  Investment: {
    iconClasses: "fill-data6 w-4 h-4",
    parentClasses: "bg-data6-light text-data6",
    icon: "investment_fill",
    label: "Investment"
  },
  default: {
    iconClasses: "fill-data5 w-4 h-4",
    parentClasses: "bg-data5-light text-data5",
    icon: "check-circle_fill"
  }
};

export const statusMapping = {
  Open: {
    parentClasses: "bg-success-light text-success",
    label: "Open"
  },
  Draft: {
    parentClasses: "bg-grey4 text-grey2",
    label: "Draft"
  },
  Closed: {
    parentClasses: "bg-alert-light text-alert",
    label: "Closed"
  }
};

export const scoreMapping = {
  "Very High": {
    parentClasses: "bg-success-light text-success",
    label: "Very High"
  },
  High: {
    parentClasses: "bg-success-light text-success",
    label: "High"
  },
  Medium: {
    parentClasses: "bg-warning-light text-warning",
    label: "Medium"
  },
  Low: {
    parentClasses: "bg-alert-light text-alert",
    label: "Low"
  }
};
