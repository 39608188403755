import { functionsEU } from "@/plugins/firebase.js";
import { httpsCallable } from "firebase/functions";

export async function getVerticalPicklist() {
  try {
    const httpsCallableFunction = httpsCallable(
      functionsEU,
      "getSector-getVerticalPickList"
    );
    const result = await httpsCallableFunction({});
    return result.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getChallengeVerticalPicklist() {
  try {
    const httpsCallableFunction = httpsCallable(
      functionsEU,
      "getSector-getChallengeVerticalSectorPickList"
    );
    const result = await httpsCallableFunction({});
    return result.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
export async function getCountryPicklist() {
  try {
    const httpsCallableFunction = httpsCallable(
      functionsEU,
      "getSector-getCountryPickList"
    );
    const result = await httpsCallableFunction({});
    return result.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
export async function getTechnologyPicklist() {
  try {
    const httpsCallableFunction = httpsCallable(
      functionsEU,
      "getSector-getTechnologiesPickList"
    );
    const result = await httpsCallableFunction({});
    return result.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getEmployeePicklist() {
  try {
    const httpsCallableFunction = httpsCallable(
      functionsEU,
      "getSector-getEmployeePicklist"
    );
    const result = await httpsCallableFunction({});
    return result.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
export async function getMultiTechnologiesPicklist() {
  try {
    const httpsCallableFunction = httpsCallable(
      functionsEU,
      "getSector-getMultiTechnologiesPicklist"
    );
    const result = await httpsCallableFunction({});
    return result.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
