const filterKeys = {
  KEYWORDS: "_kwd",
  APPLICATION_STATUS: "_appStat",
  INTEREST: "_inrst",
  CAMPAIGN: "_cmp",
  STAGE_NAME: "_stgn",
  CHALLENGE: "_chall",
  VERTICAL: "_vert",
  TECHNOLOGY: "_tech",
  EMPLOYEE: "_empl",
  YEARS_RANGE: "_yrs",
  CHALLENGE_STATUS: "_status",
  GEOGRAPHY: "_geo",
  COUNTRY: "_cnt",
  EXPIRATION_STATUS: "_expStat",
  OBJECTIVE: "_obj",
  CHALLENGE_VISIBILITY: "_vis",
  TYPE: "_type",
  PREMIUM_SPONSORSHIP: "_spons",
  CHALLENGE_STAGE: "consideredChallengeState",
  TIME_RANGE: "_date",
  //CORPORATE LIST
  INDUSTRY_SECTOR: "_sect",
  CORPORATE_SPONSORSHIP: "_sponsor"
};

module.exports = {
  filterKeys
};
