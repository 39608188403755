<template>
  <FormTemplate
    :id="formId"
    :ref="formId"
    :initial-values="formValues"
    :validation-schema="schema"
    @submit="submitForm"
    @invalid-submit="onInvalidSubmit"
  >
    <slot name="form-body"></slot>
    <slot name="form-action"></slot>
  </FormTemplate>
</template>

<script setup>
import { Form as FormTemplate } from "vee-validate";
import { reactive } from "vue";
const emit = defineEmits(["submitForm", "invalidForm"]);
defineProps({
  schema: { type: Object, default: () => {} },
  formId: { type: String, default: "form" },
  formValues: { type: Object, default: () => {} }
});

// const schema = toRefs(props, "schema");

const formCompiled = reactive({});

const submitForm = (values) => {
  Object.assign(formCompiled, values);

  emit("submitForm", values);

  // if (process.env.NODE_ENV === "development") {
  //   console.log("###Current values: ", values);
  // }
};
const onInvalidSubmit = (values) => {
  emit("invalidForm", values.errors);
  if (process.env.NODE_ENV === "development") {
    console.error("###Current values ERROR: ", values.errors, values.values);
  }
};
</script>

<style></style>
