import { ref, computed, watch } from "vue";
import { useAccountsStore } from "../stores/accounts-store.js";
export function useParnerSponsorship(partnerId) {
  const accountsStore = useAccountsStore();

  const currentPartner = computed(() => {
    return accountsStore.getters.getInstituionList.find(
      (obj) => obj.id == partnerId.value
    );
  });

  const mySponsoredStartups = computed(() => {
    return currentPartner.value?.sponsorships?.startups || [];
  });
  const activeStartups = computed(() => {
    return mySponsoredStartups.value.filter((startup) => {
      return startup.active;
    });
  });

  const mySponsoredCorporates = computed(() => {
    return currentPartner.value?.sponsorships?.corporates || [];
  });
  const activeCorporates = computed(() => {
    return mySponsoredCorporates.value.filter((corporate) => {
      return corporate.active;
    });
  });

  const sponsoredCompanySize = computed(() => {
    return [...(activeStartups.value || []), ...(activeCorporates.value || [])]
      .length;
  });
  const slicedSponsoredStartups = computed(() => {
    const corporates = activeCorporates.value?.slice(0, 1) || [];
    const startups =
      corporates.length == 0
        ? activeStartups.value?.slice(0, 4)
        : activeStartups.value?.slice(0, 3);
    return [...(startups || []), ...corporates];
  });
  const findStartup = (id) => {
    const startup = accountsStore.getters.getStartupsList?.find(
      (st) => st?.Id == id
    );

    return startup;
  };
  watch(
    () => currentPartner.value,
    (partner, oldPartner) => {
      if (partner && partner?.id !== oldPartner?.id) {
        const preloadedCorporate = accountsStore.state.corporatesList.filter(
          (data) => {
            return activeCorporates.value.find(({ id }) => data.id == id);
          }
        );
        const isCorporateLoaded =
          preloadedCorporate.length !== 0 &&
          preloadedCorporate.every((el) => {
            return (
              "Name" in el &&
              "Industry_Sector__c" in el &&
              "HQ_Country__c" in el
            );
          });
        const preloadStartups = accountsStore.state.startupsList.filter(
          (data) => {
            return activeStartups.value.find(({ id }) => data.id == id);
          }
        );
        const isStartupLoaded =
          preloadStartups.length !== 0 &&
          preloadStartups.every((el) => {
            return (
              "Name" in el &&
              "Industry_Sector__c" in el &&
              "HQ_Country__c" in el
            );
          });
        !isCorporateLoaded &&
          accountsStore.actions.setAdditionalCorporateInfo(
            activeCorporates.value.map((el) => {
              return { id: el.companyId };
            }),
            true
          );
        !isStartupLoaded &&
          accountsStore.actions.setAdditionalStartupsInfo(
            activeStartups.value.map((el) => {
              return { id: el.companyId };
            }),
            true
          );
      }
    },
    { immediate: true }
  );
  return {
    activeStartups,
    activeCorporates,
    mySponsoredCorporates,
    mySponsoredStartups,
    sponsoredCompanySize,
    slicedSponsoredStartups,
    findStartup
  };
}
