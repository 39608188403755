export const iconConfig = {
  warning_fill: {
    variant: "yellow",
    strokeWidth: 0,
    hover: "",
    viewBox: "0 0 50 50"
  },
  more_horiz: {
    variant: "grey-muted",
    strokeWidth: 4,
    hover: "",
    viewBox: "0 0 50 50"
  },
  more_vert: {
    variant: "grey-muted",
    strokeWidth: 8,
    hover: "",
    viewBox: "0 0 50 50"
  },
  rocket_launch: {
    variant: "black",
    strokeWidth: 1,
    hover: "",
    viewBox: "0 0 50 50"
  },
  rocket_launch_rotated: {
    variant: "white",
    strokeWidth: 0,
    hover: "",
    viewBox: "0 0 50 50"
  },
  psycology: {
    variant: "white",
    strokeWidth: 1,
    hover: "",
    viewBox: "0 0 50 50"
  },
  request_meeting: {
    variant: "primary",
    strokeWidth: 2,
    hover: "",
    viewBox: "0 0 50 50"
  },
  thumb_up: {
    variant: "success",
    strokeWidth: 2,
    hover: "",
    viewBox: "0 0 50 50"
  },
  thumb_up_fill: {
    variant: "success",
    strokeWidth: 2,
    hover: "",
    viewBox: "0 0 50 50"
  },
  thumb_down: {
    variant: "danger",
    strokeWidth: 2,
    hover: "",
    viewBox: "0 0 50 50"
  },
  meeting_requested: {
    variant: "primary",
    strokeWidth: 2,
    hover: "",
    viewBox: "0 0 50 50"
  },
  bookmarked: {
    variant: "success",
    strokeWidth: 2,
    hover: "",
    viewBox: "0 0 50 50"
  },
  thumb_down_fill: {
    variant: "danger",
    strokeWidth: 2,
    hover: "",
    viewBox: "0 0 50 50"
  },
  matched: {
    variant: "primary",
    strokeWidth: 2,
    hover: "",
    viewBox: "0 0 50 50"
  },
  flag: {
    variant: "yellow",
    strokeWidth: 2,
    hover: "",
    viewBox: "0 0 50 50"
  },
  mtber_arrange_meeting: {
    variant: "success",
    strokeWidth: 2,
    hover: "",
    viewBox: "0 0 50 50"
  },
  mtber_suggest_to_corp: {
    variant: "primary",
    strokeWidth: 2,
    hover: "",
    viewBox: "0 0 50 50"
  },
  note_add: {
    variant: "black",
    strokeWidth: 1,
    hover: "",
    viewBox: "0 0 50 50"
  },
  file_earmark_ppt: {
    variant: "primary",
    strokeWidth: 1,
    hover: "",
    viewBox: "0 0 50 50"
  },
  open_in_new: {
    variant: "grey-muted",
    strokeWidth: 1,
    hover: "",
    viewBox: "0 0 50 50"
  },
  language: {
    variant: "primary",
    strokeWidth: 1,
    hover: "",
    viewBox: "0 0 50 50"
  },
  bs_linkedin: {
    variant: "black",
    strokeWidth: 0,
    hover: "",
    viewBox: "0 0 50 50"
  },
  slideshow_fill: {
    variant: "primary",
    strokeWidth: 0,
    hover: "",
    viewBox: "0 0 50 50"
  },
  map: {
    variant: "body",
    strokeWidth: 1,
    hover: "",
    viewBox: "0 0 50 50"
  },
  pin_drop: {
    variant: "body",
    strokeWidth: 1,
    hover: "",
    viewBox: "0 0 50 50"
  },
  event: {
    variant: "body",
    strokeWidth: 1,
    hover: "",
    viewBox: "0 0 50 50"
  },
  technology: {
    variant: "body",
    strokeWidth: 1,
    hover: "",
    viewBox: "0 0 50 50"
  },
  work: {
    variant: "body",
    strokeWidth: 1,
    hover: "",
    viewBox: "0 0 50 50"
  },
  payments: {
    variant: "body",
    strokeWidth: 1,
    hover: "",
    viewBox: "0 0 50 50"
  },
  people: {
    variant: "body",
    strokeWidth: 1,
    hover: "",
    viewBox: "0 0 50 50"
  },
  bs_facebook: {
    variant: "black",
    strokeWidth: 0,
    hover: "",
    viewBox: "0 0 50 50"
  },
  bs_instagram: {
    variant: "black",
    strokeWidth: 0,
    hover: "",
    viewBox: "0 0 50 50"
  },
  bs_youtube: {
    variant: "black",
    strokeWidth: 0,
    hover: "",
    viewBox: "0 0 50 50"
  },
  search: {
    variant: "grey-muted",
    strokeWidth: 1,
    hover: "",
    viewBox: "0 0 50 50"
  },
  horizontal_distribute: {
    variant: "grey-muted",
    strokeWidth: 1,
    hover: "white",
    viewBox: "0 0 50 50"
  },
  vertical_align_bottom: {
    variant: "grey-muted",
    strokeWidth: 1,
    hover: "white",
    viewBox: "0 0 50 50"
  },
  star_half: {
    variant: "grey-muted",
    strokeWidth: 1,
    hover: "white",
    viewBox: "0 0 50 50"
  },
  manage_search: {
    variant: "grey-muted",
    strokeWidth: 1,
    hover: "white",
    viewBox: "0 0 50 50"
  },
  bs_grid_3x3_gap_fill: {
    variant: "grey-muted",
    strokeWidth: 1,
    hover: "",
    viewBox: "0 0 50 50"
  },
  query_stats: {
    variant: "primary",
    strokeWidth: 4,
    hover: "",
    viewBox: "0 0 50 50"
  },
  add_shopping_cart: {
    variant: "primary",
    strokeWidth: 4,
    hover: "",
    viewBox: "0 0 50 50"
  },
  local_atm: {
    variant: "primary",
    strokeWidth: 4,
    hover: "",
    viewBox: "0 0 50 50"
  },
  start: {
    variant: "muted",
    strokeWidth: 1,
    hover: "",
    viewBox: "0 0 50 50"
  },
  start_left: {
    variant: "muted",
    strokeWidth: 1,
    hover: "",
    viewBox: "0 0 50 50"
  },
  add_circle: {
    variant: "primary",
    strokeWidth: 2,
    hover: "white",
    viewBox: "0 0 50 50"
  },
  add_circle_fill: {
    variant: "primary",
    strokeWidth: 0,
    hover: "",
    viewBox: "0 0 50 50"
  },
  cancel_circle: {
    variant: "white",
    strokeWidth: 1,
    hover: "",
    viewBox: "0 0 50 50"
  },
  arrow_circle_up: {
    variant: "white",
    strokeWidth: 1,
    hover: "",
    viewBox: "0 0 50 50"
  },
  keyboard_arrow_down: {
    variant: "muted",
    strokeWidth: 1,
    hover: "",
    viewBox: "0 0 50 50"
  },
  keyboard_arrow_up: {
    variant: "muted",
    strokeWidth: 1,
    hover: "",
    viewBox: "0 0 50 50"
  },
  hourglass_bottom: {
    variant: "danger",
    strokeWidth: 1,
    hover: "",
    viewBox: "0 0 50 50"
  },
  edit_note: {
    variant: "danger",
    strokeWidth: 1,
    hover: "",
    viewBox: "0 0 50 50"
  }
};
