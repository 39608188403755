export function noImage(event) {
  event.target.src =
    "https://firebasestorage.googleapis.com/v0/b/mtb-platform.appspot.com/o/logo%2Fdefault.png?alt=media&token=4b2a4994-bb36-4717-8672-ef72fab08782";
}

export function loadLogo(id) {
  return `https://storage.googleapis.com/mtb_logos/original/${id}`;
}

export function loadMetaLogo(name) {
  if (!name) {
    return null;
  }
  return `https://firebasestorage.googleapis.com/v0/b/mtb_meta_logos/o/logo_${name}_landscape.png?alt=media`;
}
