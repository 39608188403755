<template>
  <div
    class="flex flex-col items-start justify-end gap-2 w-full"
    :class="props.headerClasses"
  >
    <div class="w-full flex justify-between items-center">
      <span class="font-bold text-black text-2xl">{{ props.title }}</span>
      <div>
        <base-vite-icon
          name="close"
          classes="w-6 h-6 stroke-black stroke-2 fill-black cursor-pointer"
          @click="closeModal"
        />
      </div>
    </div>

    <span
      v-if="!props.isProgressbarVisible && props.subTitle"
      class="text-grey1 text-base"
      >{{ props.subTitle }}</span
    >
    <div class="w-full pt-4" v-else>
      <ProgressBar
        :value="props.progressValue"
        :pt="progressbarPt"
      ></ProgressBar>
    </div>
  </div>
</template>

<script setup>
import { inject, onMounted, reactive, onUnmounted } from "vue";
import { bus } from "@/main.js";
import ProgressBar from "primevue/progressbar";

const dialogRef = inject("dialogRef");

const props = reactive({
  title: "",
  subTitle: "",
  headerClasses: "",
  isProgressbarVisible: false,
  progressValue: 0
});
const progressbarPt = _pt;
const closeModal = () => {
  bus.emit("modal-closed");
};
onMounted(() => {
  const params = dialogRef.value.data;

  props.title = params?.header?.title;
  props.subTitle = params?.header?.subTitle;
  props.headerClasses = params?.header?.headerClasses;
  bus.on("challenge-modal-show-progress-bar", (data) => {
    props.isProgressbarVisible = true;
    props.progressValue = data;
  });
  bus.on("challenge-modal-hide-progress-bar", () => {
    props.isProgressbarVisible = false;
  });
});
onUnmounted(() => {
  bus.off("challenge-modal-hide-progress-bar");
  bus.off("challenge-modal-show-progress-bar");
});
</script>
<script>
const _pt = {
  root: ({ props }) => ({
    class: [
      // Position and Overflow
      "relative overflow-hidden w-full h-2",
      // Shape and Size
      "border-0 bg-grey3",
      "rounded"
    ]
  }),
  value: ({ props }) => {
    return {
      class: [
        // Flexbox & Overflow & Position
        {
          "absolute flex items-center justify-center":
            props.mode !== "indeterminate"
        },

        // Colors
        "bg-primary",

        // Spacing & Sizing
        "m-0",
        { "h-2": props.mode !== "indeterminate" },

        // Shape
        "border-0 rounded-md",

        // Transitions
        {
          "transition-width duration-1000 ease-in-out":
            props.mode !== "indeterminate",
          "progressbar-value-animate": props.mode == "indeterminate"
        },

        // Before & After (indeterminate)
        {
          "before:absolute before:top-0 before:left-0 before:bottom-0 before:bg-inherit ":
            props.mode == "indeterminate",
          "after:absolute after:top-0 after:left-0 after:bottom-0 after:bg-inherit after:delay-1000":
            props.mode == "indeterminate"
        }
      ]
    };
  },
  label: {
    class: [
      // Flexbox
      "inline-flex justify-end",
      "absolute inset-0 mr-1 -top-[1.15rem]",

      // Font and Text
      "text-sm text-surface-600",
      "leading-none"
    ]
  }
};
</script>

<style lang="scss" scoped></style>
