import PrimeVue from "primevue/config";
import { initMixpanelInstance } from "./mixpanel";
import { registerPrimeVueComponent } from "./primevue";
import { register } from "./registration-components";
import { initSentry } from "./sentry";
import CustomTailwind from "./tailwind";
import router from "./router.js";
import { initPiniaStore } from "./pinia.js";
import vClickOutside from "@/global/directives/v-click-outside.js";
export default {
  install: (app, options) => {
    //PINIA MUST BE FIRST
    initPiniaStore(app);
    register(app);

    initMixpanelInstance();
    initSentry(app);
    app.use(PrimeVue, { unstyled: true, pt: CustomTailwind });
    app.use(router);
    app.directive("click-outside", vClickOutside);
    registerPrimeVueComponent(app);
  }
};
