import { auth, database, functionsUS } from "@/plugins/firebase.js";

import { default as classes } from "@share/errorClasses.js";
import validate from "validate.js";
import {
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithRedirect,
  signInWithPopup
} from "firebase/auth";

import { doc, getDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";

export function initNewProvider(userEmail) {
  try {
    //validation
    const validationRules = {
      userEmail: {
        presence: { allowEmpty: false },
        type: "string",
        email: true
      }
    };
    const validationErrors = validate({ userEmail }, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }
    const provider = new GoogleAuthProvider().setCustomParameters({
      login_hint: userEmail
    });
    provider.setCustomParameters({
      prompt: "select_account"
    });
    return provider;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
export async function loginUser(params) {
  try {
    //validation
    const validationRules = {
      params: {
        presence: { allowEmpty: false },
        type: "object"
      },
      "params.email": {
        presence: { allowEmpty: false },
        type: "string",
        email: true
      },
      "params.password": {
        presence: { allowEmpty: false },
        type: "string"
      }
    };
    const validationErrors = validate({ params }, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }
    await signInWithEmailAndPassword(auth, params.email, params.password);
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function loginMTB(provider) {
  try {
    await signInWithPopup(auth, provider);
    // await signInWithRedirect(auth, provider);
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getCurrentUserInformation() {
  try {
    const userDocRef = doc(database, "users", auth.currentUser.uid);
    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
      return userDoc.data();
    } else {
      return null;
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function createAccountRequest(data) {
  try {
    const httpsCallableFunction = httpsCallable(
      functionsUS,
      "createAccountRequest"
    );
    const response = await httpsCallableFunction(data);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function createAccountValidation(data) {
  try {
    const validationRules = {
      hashId: {
        presence: { allowEmpty: false },
        type: "string"
      },
      type: {
        presence: false,
        type: "string"
      },
      password: {
        presence: { allowEmpty: false },
        type: "string"
      }
    };
    const validationErrors = validate(data, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }
    const httpsCallableFunction = httpsCallable(functionsUS, "createAccount");
    const response = await httpsCallableFunction(data);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function fetchAccountData(data) {
  try {
    const validationRules = {
      hashId: {
        presence: { allowEmpty: false },
        type: "string"
      },
      inputEmail: {
        presence: { allowEmpty: false },
        type: "string"
      }
    };
    const validationErrors = validate(data, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }
    const httpsCallableFunction = httpsCallable(
      functionsUS,
      "fetchAccountData"
    );
    const response = await httpsCallableFunction(data);
    return response.data;
  } catch (error) {
    throw error;
  }
}
