//Naming rule: STATE_<STORE ACTION NAME>
export const STATE_CORPORATE_CAMPAIGN = "corporateScoutingRounds";
export const STATE_USER_INFO = "userInfo";
export const STATE_SPONSORSHIP = "sponsorship";
export const STATE_ACCOUNT_INFO = "accountInfo";
export const STATE_FEATURED_LIST = "featuredList";
export const STATE_ADDITIONAL_INFO_FEATURED_LIST = "additionalInfoFeaturedList";
export const STATE_FAVOURITE_LIST = "favouriteLists";
export const STATE_ADDITIONAL_INFO_FAVOURITE_LIST =
  "additionalInfoFavouriteLists";
export const STATE_APPLICATIONS_LIKES = "applicationsLikes";
export const STATE_AVAILABLE_APPLICATIONS = "availableApplications";
