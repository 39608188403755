import { functionsEU } from "@plugins/firebase.js";
import { httpsCallable } from "firebase/functions";
import { default as classes } from "@share/errorClasses.js";
import validate from "validate.js";
const options = {
  timeout: 180000
};
export async function getFeaturedList(data) {
  try {
    const httpsCallableFunction = httpsCallable(
      functionsEU,
      "getFeaturedList-getAllFeaturedListV3",
      options
    );
    const result = await httpsCallableFunction({ ...(data || {}) });
    return result.data;
  } catch (error) {
    console.log("check error:", error);
    throw error;
  }
}

export async function getStartupListForCurrentFeatured(featuredId) {
  try {
    //validation
    const validationRules = {
      featuredId: {
        presence: { allowEmpty: false },
        type: "string"
      }
    };
    const validationErrors = validate({ featuredId }, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }
    const httpsCallableFunction = httpsCallable(
      functionsEU,
      "getFeaturedList-getStartupListForCurrentFeaturedV3",
      options
    );
    const result = await httpsCallableFunction(featuredId);
    return result.data;
  } catch (error) {
    console.log("check error:", error);
    throw error;
  }
}
