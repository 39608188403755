<template>
  <div v-if="props.isLoading" class="flex items-center h-full justify-center">
    <Skeleton class="w-[110px] h-[80px] mt-4"></Skeleton>
  </div>
  <div v-else class="flex flex-col items-center justify-center gap-0 h-full">
    <span class="text-base text-grey2 min-h-[36.5px] flex items-center"
      >{{ props.label }}
      <span
        v-if="props.tooltip"
        v-tooltip.top="props.tooltip"
        class="prime-tooltip"
      >
        <base-vite-icon name="info" classes="ms-2 w-4 rotate-180"
      /></span>
    </span>

    <span class="text-3xl text-black font-bold">{{ props.count }}</span>
    <span class="text-base text-grey2 text-sm flex items-center">{{
      props.metrics
    }}</span>
  </div>
</template>
<script setup>
const props = defineProps({
  isLoading: {
    default: true,
    type: Boolean
  },
  label: {
    type: String,
    default: ""
  },
  count: {
    type: [Number, String],
    default: 0
  },
  metrics: {
    type: String,
    default: ""
  },
  tooltip: {
    type: [String, null],
    default: null
  }
});
</script>
