import { functionsEU } from "@plugins/firebase.js";
import { httpsCallable } from "firebase/functions";
import { default as classes } from "@share/errorClasses.js";
import validate from "validate.js";
export async function getAnalyticsCorporatesExposure(data) {
  try {
    const validationRules = {
      corporateIds: {
        //sponsored corporates Id
        type: "array"
      },
      challengesIds: {
        //sponsored challenges Id
        type: "array"
      }
    };
    const validationErrors = validate(data, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }
    const httpsCallableFunction = httpsCallable(
      functionsEU,
      "getAnalyticsCorporatesExposureV3"
    );

    const response = await httpsCallableFunction(data);
    return response.data || [];
  } catch (error) {
    throw error;
  }
}

export async function getAnalyticsInstitutionExposure(data) {
  try {
    const validationRules = {
      institutionId: {
        presence: { allowEmpty: false },
        type: "string"
      }
    };
    const validationErrors = validate(data, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }
    const httpsCallableFunction = httpsCallable(
      functionsEU,
      "getAnalyticsInstitutionExposureV3"
    );

    const response = await httpsCallableFunction(data);
    return response.data || [];
  } catch (error) {
    throw error;
  }
}
export async function getChallengeSuggestionEmail(data) {
  try {
    const validationRules = {
      startupIds: {
        presence: { allowEmpty: false },
        type: "array"
      }
    };
    const validationErrors = validate(data, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }
    const httpsCallableFunction = httpsCallable(
      functionsEU,
      "getChallengeSuggestionEmailV3"
    );

    const response = await httpsCallableFunction(data);
    return response.data || [];
  } catch (error) {
    throw error;
  }
}

export async function getCorporateEngagement(data) {
  try {
    const validationRules = {
      startupsList: {
        presence: { allowEmpty: false },
        type: "array"
      },
      timeRange: {
        presence: { allowEmpty: false },
        type: "array"
      }
    };
    const validationErrors = validate(data, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }
    const httpsCallableFunction = httpsCallable(
      functionsEU,
      "getAccountEngagementV3"
    );

    const response = await httpsCallableFunction(data);
    return response.data || [];
  } catch (error) {
    throw error;
  }
}
export async function getS2CMatches(data) {
  try {
    const validationRules = {
      startupsList: {
        presence: { allowEmpty: false },
        type: "array"
      },
      timeRange: {
        presence: { allowEmpty: false },
        type: "array"
      }
    };
    const validationErrors = validate(data, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }
    const httpsCallableFunction = httpsCallable(functionsEU, "getMatchesV3");

    const response = await httpsCallableFunction(data);
    return response.data || [];
  } catch (error) {
    throw error;
  }
}
export async function getC2SMatches(data) {
  try {
    const validationRules = {
      corporateList: {
        presence: { allowEmpty: false },
        type: "array"
      },
      timeRange: {
        presence: { allowEmpty: false },
        type: "array"
      }
    };
    const validationErrors = validate(data, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }
    const httpsCallableFunction = httpsCallable(functionsEU, "getMatchesV3");

    const response = await httpsCallableFunction(data);
    return response.data || [];
  } catch (error) {
    throw error;
  }
}
export async function getStartupEngagement(data) {
  try {
    const validationRules = {
      corporateList: {
        presence: { allowEmpty: false },
        type: "array"
      },
      timeRange: {
        presence: { allowEmpty: false },
        type: "array"
      }
    };
    const validationErrors = validate(data, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }
    const httpsCallableFunction = httpsCallable(
      functionsEU,
      "getAccountEngagementV3"
    );

    const response = await httpsCallableFunction(data);
    return response.data || [];
  } catch (error) {
    throw error;
  }
}
