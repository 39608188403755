export const institutionProfileExposureMock = [
  {
    mp_event_name: "Institution Profile View",
    mp_current_url:
      "https://ecosystem.mindthebridge.com/partner-profile/0012E00001z24bHQAQ",
    distinct_id: "0032E00003GrkcjQAB",
    date_time: "2023-08-03",
    mp_country_code: "IT"
  },
  {
    mp_event_name: "Institution Profile View",
    mp_current_url:
      "https://ecosystem.mindthebridge.com/partner-profile/0012E00001z24bHQAQ",
    distinct_id: "0032E00003GrkcjQAB",
    date_time: "2023-05-17",
    mp_country_code: "US"
  },
  {
    mp_event_name: "Institution Profile View",
    mp_current_url:
      "https://ecosystem.mindthebridge.com/partner-profile/0012E00001z24bHQAQ",
    distinct_id: "0034U00002tHDtyQAG",
    date_time: "2023-04-23",
    mp_country_code: "GB"
  },
  {
    mp_event_name: "Institution Profile View",
    mp_current_url:
      "https://ecosystem.mindthebridge.com/partner-profile/0012E00001z24bHQAQ",
    distinct_id: "0032E00003GrkcjQAB",
    date_time: "2023-07-28",
    mp_country_code: "GB"
  },
  {
    mp_event_name: "Institution Profile View",
    mp_current_url:
      "https://ecosystem.mindthebridge.com/partner-profile/0012E00001z24bHQAQ",
    distinct_id: "0034U000035jP2RQAU",
    mp_country_code: "CG",
    date_time: "2023-10-12"
  }
];
export const sponsoredStartupExposureMock = [
  {
    mp_event_name: "Startup Exposure",
    account_startup_id: "0012E00002cCrUBQA0",
    account_corporate_id: "0012E00002E4zAOQAZ",
    mp_country_code: "CG",
    date_time: "2023-08-21"
  },
  {
    mp_event_name: "Startup Exposure",
    account_startup_id: "0012E00002cCrUBQA0",
    account_corporate_id: "0012E00001z2wknQAA",
    mp_country_code: "CG",
    date_time: "2023-07-15"
  },
  {
    mp_event_name: "Startup Exposure",
    account_startup_id: "0012E00002cCrUBQA0",
    account_corporate_id: "0012E00002j3QkPQAU",
    mp_country_code: "CG",
    date_time: "2023-09-01"
  },
  {
    mp_event_name: "Startup Exposure",
    account_startup_id: "0012E00002cCrUBQA0",
    account_corporate_id: "0012E00002j3QkPQAU",
    mp_country_code: "GB",
    date_time: "2023-09-01"
  },
  {
    mp_event_name: "Startup Exposure",
    account_startup_id: "0012E00002cCrUBQA0",
    account_corporate_id: "0014U00002ooVLDQA2",
    mp_country_code: "GB",
    date_time: "2023-09-29"
  },
  {
    mp_event_name: "Startup Exposure",
    account_startup_id: "0012E00002cCrUBQA0",
    account_corporate_id: "0014U00002ooVLDQA2",
    mp_country_code: "GB",
    date_time: "2023-09-29"
  },
  {
    mp_event_name: "Startup Exposure",
    account_startup_id: "0012E00002cCrUBQA0",
    account_corporate_id: "0012E00001z2wknQAA",
    mp_country_code: "US",
    date_time: "2023-07-27"
  },
  {
    mp_event_name: "Startup Exposure",
    account_startup_id: "0012E00002cCrUBQA0",
    account_corporate_id: "0014U00002ooVLDQA2",
    mp_country_code: "US",
    date_time: "2023-08-08"
  },
  {
    mp_event_name: "Startup Exposure",
    account_startup_id: "0012E00002cCrUBQA0",
    account_corporate_id: "0012E00002j3QkPQAU",
    mp_country_code: "US",
    date_time: "2023-09-07"
  },
  {
    mp_event_name: "Startup Exposure",
    account_startup_id: "0012E00002cCrUBQA0",
    account_corporate_id: "0012E00002j3QkPQAU",
    mp_country_code: "US",
    date_time: "2023-09-07"
  },
  {
    mp_event_name: "Startup Exposure",
    account_startup_id: "0012E00001z5EteQAE",
    account_corporate_id: "0012E00001z2wk1QAA",
    mp_country_code: "US",
    date_time: "2023-11-08"
  },
  {
    mp_event_name: "Startup Exposure",
    account_startup_id: "0012E00002cCrUBQA0",
    account_corporate_id: "0012E00001z2wknQAA",
    mp_country_code: "IT",
    date_time: "2023-11-08"
  },
  {
    mp_event_name: "Startup Exposure",
    account_startup_id: "0012E00002cCrUBQA0",
    account_corporate_id: "0012E00001z2wknQAA",
    mp_country_code: "IT",
    date_time: "2023-07-17"
  },
  {
    mp_event_name: "Startup Exposure",
    account_startup_id: "0012E00002cCrUBQA0",
    account_corporate_id: "0012E00001z2wknQAA",
    mp_country_code: "IT",
    date_time: "2023-07-17"
  },
  {
    mp_event_name: "Startup Exposure",
    account_startup_id: "0012E00002cCrUBQA0",
    account_corporate_id: "0012E00002j3QkPQAU",
    mp_country_code: "IT",
    date_time: "2023-08-25"
  },
  {
    mp_event_name: "Startup Exposure",
    account_startup_id: "0012E00002cCrUBQA0",
    account_corporate_id: "0012E00002j3QkPQAU",
    mp_country_code: "IT",
    date_time: "2023-09-06"
  },
  {
    mp_event_name: "Startup Exposure",
    account_startup_id: "0012E00002cCrUBQA0",
    account_corporate_id: "0012E00002j3QkPQAU",
    mp_country_code: "ES",
    date_time: "2023-09-06"
  },
  {
    mp_event_name: "Startup Exposure",
    account_startup_id: "0012E00002cCrUBQA0",
    account_corporate_id: "0012E00002j3QkPQAU",
    mp_country_code: "ES",
    date_time: "2023-09-06"
  },
  {
    mp_event_name: "Startup Exposure",
    account_startup_id: "0012E00002cCrUBQA0",
    account_corporate_id: "0012E00002j3QkPQAU",
    mp_country_code: "ES",
    date_time: "2023-09-06"
  },
  {
    mp_event_name: "Startup Exposure",
    account_startup_id: "0012E00002cCrUBQA0",
    account_corporate_id: "0014U00002ooVLDQA2",
    mp_country_code: "ES",
    date_time: "2023-10-10"
  },
  {
    mp_event_name: "Startup Exposure",
    account_startup_id: "0012E00002cCrUBQA0",
    account_corporate_id: "0012E00001z1ka0QAA",
    mp_country_code: "ES",
    date_time: "2023-10-10"
  },
  {
    mp_event_name: "Startup Exposure",
    account_startup_id: "0012E00002cCrUBQA0",
    account_corporate_id: "0012E00001z1ka0QAA",
    mp_country_code: "ES",
    date_time: "2023-10-10"
  },
  {
    mp_event_name: "Startup Exposure",
    account_startup_id: "0012E00002cCrUBQA0",
    account_corporate_id: "0012E00001z1ka0QAA",
    mp_country_code: "NA",
    date_time: "2023-10-10"
  },
  {
    mp_event_name: "Startup Exposure",
    account_startup_id: "0012E00002cCrUBQA0",
    account_corporate_id: "0012E00002j3QkPQAU",
    mp_country_code: "NA",
    date_time: "2023-09-05"
  },
  {
    mp_event_name: "Startup Exposure",
    account_startup_id: "0012E00002cCrUBQA0",
    account_corporate_id: "0012E00002j3QkPQAU",
    mp_country_code: "NA",
    date_time: "2023-09-05"
  },
  {
    mp_event_name: "Startup Exposure",
    account_startup_id: "0012E00002cCrUBQA0",
    account_corporate_id: "0012E00001z1ka0QAA",
    mp_country_code: "NA",
    date_time: "2023-10-04"
  },
  {
    mp_event_name: "Startup Exposure",
    account_startup_id: "0012E00002cCrUBQA0",
    account_corporate_id: "0012E00001z2wdsQAA",
    mp_country_code: "NA",
    date_time: "2023-07-21"
  },
  {
    mp_event_name: "Startup Exposure",
    account_startup_id: "0012E00002cCrUBQA0",
    account_corporate_id: "0012E00001z2wdsQAA",
    mp_country_code: "NA",
    date_time: "2023-07-20"
  },
  {
    mp_event_name: "Startup Exposure",
    account_startup_id: "0012E00002cCrUBQA0",
    account_corporate_id: "0012E00002E4zAOQAZ",
    mp_country_code: "NA",
    date_time: "2023-08-02"
  },
  {
    mp_event_name: "Startup Exposure",
    account_startup_id: "0012E00002cCrUBQA0",
    account_corporate_id: "0014U00002ooVLDQA2",
    mp_country_code: "DE",
    date_time: "2023-10-03"
  },
  {
    mp_event_name: "Startup Exposure",
    account_startup_id: "0012E00002cCrUBQA0",
    account_corporate_id: "0014U00002ooVLDQA2",
    mp_country_code: "DE",
    date_time: "2023-10-03"
  },
  {
    mp_event_name: "Startup Exposure",
    account_startup_id: "0012E00002cCrUBQA0",
    account_corporate_id: "0014U00002ooVLDQA2",
    mp_country_code: "DE",
    date_time: "2023-10-02"
  },
  {
    mp_event_name: "Startup Exposure",
    account_startup_id: "0012E00002cCrUBQA0",
    account_corporate_id: "0012E00001z2wdsQAA",
    mp_country_code: "DE",
    date_time: "2023-08-15"
  },
  {
    mp_event_name: "Startup Exposure",
    account_startup_id: "0012E00002cCrUBQA0",
    account_corporate_id: "0012E00001z1ka0QAA",
    mp_country_code: "DE",
    date_time: "2023-08-29"
  },
  {
    mp_event_name: "Startup Exposure",
    account_startup_id: "0012E00002cCrUBQA0",
    account_corporate_id: "0012E00002E4zAOQAZ",
    mp_country_code: "DE",
    date_time: "2023-07-10"
  },
  {
    mp_event_name: "Startup Exposure",
    account_startup_id: "0012E00002cCrUBQA0",
    account_corporate_id: "0014U00002ooVLDQA2",
    mp_country_code: "DE",
    date_time: "2023-09-27"
  },
  {
    mp_event_name: "Startup Exposure",
    account_startup_id: "0012E00002cCrUBQA0",
    account_corporate_id: "0014U00002ooVLDQA2",
    mp_country_code: "DE",
    date_time: "2023-09-27"
  },
  {
    mp_event_name: "Startup Exposure",
    account_startup_id: "0012E00002cCrUBQA0",
    account_corporate_id: "0012E00002j3QkPQAU",
    mp_country_code: "FR",
    date_time: "2023-08-30"
  },
  {
    mp_event_name: "Startup Exposure",
    account_startup_id: "0012E00002cCrUBQA0",
    account_corporate_id: "0014U00002ooVLDQA2",
    mp_country_code: "FR",
    date_time: "2023-10-06"
  },
  {
    mp_event_name: "Startup Exposure",
    account_startup_id: "0012E00002cCrUBQA0",
    account_corporate_id: "0012E00001z2wknQAA",
    mp_country_code: "FR",
    date_time: "2023-09-13"
  },
  {
    mp_event_name: "Startup Exposure",
    account_startup_id: "0012E00002cCrUBQA0",
    account_corporate_id: "0012E00001z2wknQAA",
    mp_country_code: "FR",
    date_time: "2023-09-13"
  },
  {
    mp_event_name: "Startup Exposure",
    account_startup_id: "0012E00002cCrUBQA0",
    account_corporate_id: "0012E00002E4zAOQAZ",
    mp_country_code: "FR",
    date_time: "2023-07-26"
  },
  {
    mp_event_name: "Startup Exposure",
    account_startup_id: "0012E00001z5EteQAE",
    account_corporate_id: "0012E00002W4vEaQAJ",
    mp_country_code: "FR",
    date_time: "2023-08-04"
  },
  {
    mp_event_name: "Startup Exposure",
    account_startup_id: "0012E00002cCrUBQA0",
    account_corporate_id: "0012E00002j3QkPQAU",
    mp_country_code: "FR",
    date_time: "2023-09-11"
  },
  {
    mp_event_name: "Startup Exposure",
    account_startup_id: "0012E00002cCrUBQA0",
    account_corporate_id: "0014U00002ooVLDQA2",
    mp_country_code: "FR",
    date_time: "2023-10-05"
  },
  {
    mp_event_name: "Startup Exposure",
    account_startup_id: "0012E00002cCrUBQA0",
    account_corporate_id: "0014U00002ooVLDQA2",
    mp_country_code: "FR",
    date_time: "2023-10-05"
  }
];

export const sponsoredCorporateExposureMock = [
  {
    mp_event_name: "Challenge Details Opened",
    account_startup_id: null,
    date_time: "2023-04-18",
    mp_country_code: "FR"
  },
  {
    mp_event_name: "Challenge Details Opened",
    account_startup_id: null,
    date_time: "2023-06-06",
    mp_country_code: "FR"
  },
  {
    mp_event_name: "Challenge Details Opened",
    account_startup_id: null,
    date_time: "2023-04-19",
    mp_country_code: "FR"
  },
  {
    mp_event_name: "Challenge Details Opened",
    account_startup_id: null,
    date_time: "2023-04-19",
    mp_country_code: "FR"
  },
  {
    mp_event_name: "Challenge Details Opened",
    account_startup_id: null,
    date_time: "2023-04-19",
    mp_country_code: "CG"
  },
  {
    mp_event_name: "Challenge Details Opened",
    account_startup_id: "0012E00002gb4oHQAQ",
    date_time: "2023-08-31",
    mp_country_code: "CG"
  },
  {
    mp_event_name: "Challenge Details Opened",
    account_startup_id: "0012E00002oO6twQAC",
    date_time: "2023-10-09",
    mp_country_code: "CG"
  },
  {
    mp_event_name: "Challenge Details Opened",
    account_startup_id: null,
    date_time: "2023-05-04",
    mp_country_code: "CG"
  },
  {
    mp_event_name: "Challenge Details Opened",
    account_startup_id: "0014U000036HvrhQAC",
    date_time: "2023-08-14",
    mp_country_code: "IT"
  },
  {
    mp_event_name: "Challenge Details Opened",
    account_startup_id: "0012E00002c3A3BQAU",
    date_time: "2023-11-07",
    mp_country_code: "IT"
  },
  {
    mp_event_name: "Challenge Details Opened",
    account_startup_id: "0014U00002ye8Y6QAI",
    date_time: "2023-08-03",
    mp_country_code: "IT"
  },
  {
    mp_event_name: "Challenge Details Opened",
    account_startup_id: "0012E00002jwoueQAA",
    date_time: "2023-08-28",
    mp_country_code: "IT"
  },
  {
    mp_event_name: "Challenge Details Opened",
    account_startup_id: null,
    date_time: "2023-05-27",
    mp_country_code: "IT"
  },
  {
    mp_event_name: "Challenge Details Opened",
    account_startup_id: null,
    date_time: "2023-05-15",
    mp_country_code: "IT"
  },
  {
    mp_event_name: "Challenge Details Opened",
    account_startup_id: "0014U00002iYMCpQAO",
    date_time: "2023-10-06",
    mp_country_code: "IT"
  },
  {
    mp_event_name: "Challenge Details Opened",
    account_startup_id: null,
    date_time: "2023-05-09",
    mp_country_code: "IT"
  },
  {
    mp_event_name: "Challenge Details Opened",
    account_startup_id: null,
    date_time: "2023-06-16",
    mp_country_code: "IT"
  },
  {
    mp_event_name: "Challenge Details Opened",
    account_startup_id: "0012E00002SXbxOQAT",
    date_time: "2023-08-27",
    mp_country_code: "ES"
  },
  {
    mp_event_name: "Challenge Details Opened",
    account_startup_id: "0014U00002nylY4QAI",
    mp_country_code: "ES",
    date_time: "2023-10-10"
  },
  {
    mp_event_name: "Challenge Details Opened",
    account_startup_id: "0014U000030J9EqQAK",
    mp_country_code: "ES",
    date_time: "2023-08-10"
  },
  {
    mp_event_name: "Challenge Details Opened",
    account_startup_id: null,
    mp_country_code: "ES",
    date_time: "2023-06-02"
  },
  {
    mp_event_name: "Challenge Details Opened",
    account_startup_id: "0014U00002l8uDYQAY",
    mp_country_code: "ES",
    date_time: "2023-10-04"
  },
  {
    mp_event_name: "Challenge Details Opened",
    account_startup_id: "0014U00002l8uDYQAY",
    mp_country_code: "ES",
    date_time: "2023-10-04"
  },
  {
    mp_event_name: "Challenge Details Opened",
    account_startup_id: null,
    mp_country_code: "ES",
    date_time: "2023-05-02"
  },
  {
    mp_event_name: "Challenge Details Opened",
    account_startup_id: null,
    mp_country_code: "US",
    date_time: "2023-05-02"
  },
  {
    mp_event_name: "Challenge Details Opened",
    mp_country_code: "US",
    account_startup_id: null,
    date_time: "2023-05-02"
  },
  {
    mp_event_name: "Challenge Details Opened",
    mp_country_code: "US",
    account_startup_id: null,
    date_time: "2023-05-02"
  },
  {
    mp_event_name: "Challenge Details Opened",
    mp_country_code: "US",
    account_startup_id: null,
    date_time: "2023-05-02"
  },
  {
    mp_event_name: "Challenge Details Opened",
    mp_country_code: "US",
    account_startup_id: null,
    date_time: "2023-05-02"
  },
  {
    mp_event_name: "Challenge Details Opened",
    mp_country_code: "US",
    account_startup_id: null,
    date_time: "2023-05-02"
  },
  {
    mp_event_name: "Challenge Details Opened",
    mp_country_code: "US",
    account_startup_id: null,
    date_time: "2023-05-02"
  },
  {
    mp_event_name: "Challenge Details Opened",
    account_startup_id: "0014U000030Ia1fQAC",
    mp_country_code: "US",
    date_time: "2023-08-22"
  },
  {
    mp_event_name: "Challenge Details Opened",
    account_startup_id: "0012E000024zLsVQAU",
    mp_country_code: "US",
    date_time: "2023-09-25"
  },
  {
    mp_event_name: "Challenge Details Opened",
    account_startup_id: null,
    mp_country_code: "GB",
    date_time: "2023-06-20"
  },
  {
    mp_event_name: "Challenge Details Opened",
    account_startup_id: null,
    mp_country_code: "GB",
    date_time: "2023-06-20"
  },
  {
    mp_event_name: "Challenge Details Opened",
    account_startup_id: "0012E000024zLsVQAU",
    mp_country_code: "GB",
    date_time: "2023-07-18"
  },
  {
    mp_event_name: "Challenge Details Opened",
    account_startup_id: "0014U00002iYMCpQAO",
    mp_country_code: "GB",
    date_time: "2023-07-17"
  },
  {
    mp_event_name: "Corporate Exposure",
    account_startup_id: "0014U000036HvriQAC",
    mp_country_code: "GB",
    date_time: "2023-08-31"
  },
  {
    mp_event_name: "Corporate Exposure",
    account_startup_id: "0014U00003B2BBMQA3",
    mp_country_code: "GB",
    date_time: "2023-09-15"
  },
  {
    mp_event_name: "Corporate Exposure",
    account_startup_id: "0014U00003DStAeQAL",
    mp_country_code: "GB",
    date_time: "2023-10-31"
  },
  {
    mp_event_name: "Challenge Details Opened",
    account_startup_id: null,
    mp_country_code: "GB",
    date_time: "2023-05-31"
  },
  {
    mp_event_name: "Corporate Exposure",
    account_startup_id: "0014U000035nagtQAA",
    mp_country_code: "GB",
    date_time: "2023-10-03"
  }
];

export const challengeSuggestionEmailMock = [
  {
    mp_event_name: "Email Sent",
    account_startup_id: "0012E00002cCrUBQA0",
    date_time: "2023-08-15"
  },
  {
    mp_event_name: "Email Sent",
    account_startup_id: "0012E00002cCrUBQA0",
    date_time: "2023-08-15"
  },
  {
    mp_event_name: "Email Sent",
    account_startup_id: "0012E00002cCrUBQA0",
    date_time: "2023-08-15"
  },
  {
    mp_event_name: "Email Sent",
    account_startup_id: "0012E00002cCrUBQA0",
    date_time: "2023-08-15"
  },
  {
    mp_event_name: "Email Sent",
    account_startup_id: "0012E00002cCrUBQA0",
    date_time: "2023-08-08"
  },
  {
    mp_event_name: "Email Sent",
    account_startup_id: "0012E00002cCrUBQA0",
    date_time: "2023-08-08"
  }
];
