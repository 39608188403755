import { useRequest } from "vue-request";
import { useAccountsStore } from "../stores/accounts-store.js";
import { useChallengesStore } from "../stores/challenges-store.js";
import { useOpportunitiesStore } from "../stores/opportunities-store.js";
import { useFeaturedListStore } from "../stores/featured-list-store.js";
import { useInstitutionMyEcosystemStore } from "@/modules/institutions/stores/institution-myecosystem-store.js";
import { useInstitutionAnalyticsStore } from "@/modules/institutions/stores/institution-analytics-store.js";
import { useCorporateFavouritesStore } from "@/modules/corporates/stores/corporate-favourites-store.js";
import { useStartupApplicationsStore } from "@/modules/startups/stores/startup-applications-store.js";

export function useFetch() {
  /**-----STORES----**/
  const accountsStore = useAccountsStore();
  const challengesStore = useChallengesStore();
  const opportunityStore = useOpportunitiesStore();
  const institutionMyEcosystemStore = useInstitutionMyEcosystemStore();
  const institutionAnalyticsStore = useInstitutionAnalyticsStore();
  const corporateFavouritesStore = useCorporateFavouritesStore();
  const startupApplicationsStore = useStartupApplicationsStore();
  /**---------------**/
  //account store actions
  const setCorporatesList = useRequest(
    accountsStore.actions.setCorporatesList,
    {
      pollingInterval: 3 * 24 * 60 * 60 * 1000, //3 days
      manual: true
    }
  );
  const setStartupsList = useRequest(accountsStore.actions.setStartupsList, {
    pollingInterval: 3 * 24 * 60 * 60 * 1000, //3 days
    manual: true
  });
  const setInstitutionsList = useRequest(
    accountsStore.actions.setInstitutionsList,
    {
      pollingInterval: 3 * 24 * 60 * 60 * 1000, //3 days
      manual: true
    }
  );
  const setSponsorshipsList = useRequest(
    accountsStore.actions.setSponsorshipsList,
    {
      pollingInterval: 3 * 24 * 60 * 60 * 1000, //3 days
      manual: true
    }
  );

  //challenges store actions
  const setAccountChallengesList = useRequest(
    challengesStore.actions.setAccountChallengesList,
    {
      pollingInterval: 24 * 60 * 60 * 1000, //1 day
      manual: true
    }
  );
  const setStartupSuggestedChallenges = useRequest(
    challengesStore.actions.setStartupSuggestedChallenges,
    {
      pollingInterval: 24 * 60 * 60 * 1000, //1 day
      manual: true
    }
  );
  const setStartupDislikeChallenges = useRequest(
    challengesStore.actions.setStartupDislikeChallenges,
    {
      pollingInterval: 24 * 60 * 60 * 1000, //1 day
      manual: true
    }
  );
  const setStartupLikeChallenges = useRequest(
    challengesStore.actions.setStartupLikeChallenges,
    {
      pollingInterval: 24 * 60 * 60 * 1000, //1 day
      manual: true
    }
  );
  const setEcosystemPublishedChallengesList = useRequest(
    challengesStore.actions.setEcosystemPublishedChallengesList,
    {
      pollingInterval: 6 * 60 * 60 * 1000, //1 day
      manual: true
    }
  );
  //opportunity store actions
  const setStartupsOpportunities = useRequest(
    opportunityStore.actions.setStartupsOpportunities,
    {
      pollingInterval: 24 * 60 * 60 * 1000, //1 day
      manual: true
    }
  );
  const setCorporateMeetings = useRequest(
    opportunityStore.actions.setCorporateMeetings,
    {
      pollingInterval: 3 * 60 * 60 * 1000, //3 hours
      manual: true
    }
  );
  const setStartupAllOpportunities = useRequest(
    opportunityStore.actions.setStartupAllOpportunities,
    {
      pollingInterval: 24 * 60 * 60 * 1000, //1 day
      manual: true
    }
  );
  const setAllMatchingCalendars = useRequest(
    opportunityStore.actions.setAllMatchingCalendars,
    {
      pollingInterval: 24 * 60 * 60 * 1000, //1 day
      manual: true
    }
  );
  const setAllMatchingCalendarsCorporate = useRequest(
    opportunityStore.actions.setAllMatchingCalendarsCorporate,
    {
      pollingInterval: 24 * 60 * 60 * 1000, //1 day
      manual: true
    }
  );

  //institution my ecosystem store
  const setAdditionalInfoStartups = useRequest(
    institutionMyEcosystemStore.actions.setAdditionalInfoStartups,
    {
      pollingInterval: 3 * 24 * 60 * 60 * 1000, //3 days
      manual: true
    }
  );
  const setAdditionalInfoCorporates = useRequest(
    institutionMyEcosystemStore.actions.setAdditionalInfoCorporates,
    {
      pollingInterval: 3 * 24 * 60 * 60 * 1000, //3 days
      manual: true
    }
  );

  //institution analytics store
  const setInstitutionProfileExposure = useRequest(
    institutionAnalyticsStore.actions.setInstitutionProfileExposure,
    {
      pollingInterval: 24 * 60 * 60 * 1000, //1 day
      manual: true
    }
  );
  const setSponsoredCorporateExposure = useRequest(
    institutionAnalyticsStore.actions.setSponsoredCorporateExposure,
    {
      pollingInterval: 24 * 60 * 60 * 1000, //1 day
      manual: true
    }
  );
  const setSponsoredStartupsExposure = useRequest(
    institutionAnalyticsStore.actions.setSponsoredStartupsExposure,
    {
      pollingInterval: 24 * 60 * 60 * 1000, //1 day
      manual: true
    }
  );
  const setChallengeSuggestionEmail = useRequest(
    institutionAnalyticsStore.actions.setChallengeSuggestionEmail,
    {
      pollingInterval: 3 * 24 * 60 * 60 * 1000, //3 days
      manual: true
    }
  );

  //corporate store

  const setFavouriteLists = useRequest(
    corporateFavouritesStore.actions.setFavouriteLists,
    {
      pollingInterval: 3 * 24 * 60 * 60 * 1000, //3 days
      manual: true
    }
  );

  //startup store
  const setAvailableApplications = useRequest(
    startupApplicationsStore.actions.setAvailableApplications,
    {
      pollingInterval: 3 * 24 * 60 * 60 * 1000, //3 days
      manual: true
    }
  );

  function restore() {
    setCorporatesList.cancel();
    setStartupsList.cancel();
    setInstitutionsList.cancel();
    setSponsorshipsList.cancel();
    setStartupsOpportunities.cancel();
    setStartupAllOpportunities.cancel();
    setAllMatchingCalendars.cancel();
    setAllMatchingCalendarsCorporate.cancel();
    setAdditionalInfoStartups.cancel();
    setAdditionalInfoCorporates.cancel();
    setInstitutionProfileExposure.cancel();
    setSponsoredCorporateExposure.cancel();
    setSponsoredStartupsExposure.cancel();
    setChallengeSuggestionEmail.cancel();
    setAccountChallengesList.cancel();
    setStartupSuggestedChallenges.cancel();
    setStartupDislikeChallenges.cancel();
    setStartupLikeChallenges.cancel();
    setFavouriteLists.cancel();
    setCorporateMeetings.cancel();
    setAvailableApplications.cancel();
    setEcosystemPublishedChallengesList.cancel();
  }
  return {
    restore,
    setCorporatesList,
    setStartupsList,
    setInstitutionsList,
    setSponsorshipsList,
    setStartupsOpportunities,
    setStartupAllOpportunities,
    setAllMatchingCalendars,
    setAllMatchingCalendarsCorporate,
    setAdditionalInfoStartups,
    setAdditionalInfoCorporates,
    setInstitutionProfileExposure,
    setSponsoredCorporateExposure,
    setSponsoredStartupsExposure,
    setChallengeSuggestionEmail,
    setAccountChallengesList,
    setStartupSuggestedChallenges,
    setStartupDislikeChallenges,
    setStartupLikeChallenges,
    setFavouriteLists,
    setCorporateMeetings,
    setAvailableApplications,
    setEcosystemPublishedChallengesList
  };
}
