<template>
  <div
    class="grid grid-cols-1 md:grid-cols-3 gap-x-6 h-full overflow-scroll md:overflow-hidden"
  >
    <div
      class="mtb-card p-8 mb-2 flex flex-col items-start self-stretch md:overflow-hidden"
      v-for="section in sectionConfig"
      :key="section.index"
    >
      <Skeleton v-if="isLoading" class="h-full w-full" />
      <div
        v-else
        class="flex flex-col items-start self-stretch md:overflow-scroll"
      >
        <!-- STATUS BADGE -->
        <div
          v-if="section.index == 0"
          class="flex flex-col items-start gap-1.5 mb-6"
        >
          <span class="text-lg text-black font-bold">Status</span>
          <!-- <span class="text-base text-grey1 font-normal text-left">{{
          props.currentChallenge["Challenge_State__c"]
        }}</span> -->

          <div
            class="mtb-chip font-bold text-sm"
            :class="
              statusMapping[props.currentChallenge?.Challenge_State__c]
                .parentClasses
            "
          >
            <span class="text-base font-normal text-left">{{
              props.currentChallenge["Challenge_State__c"]
            }}</span>
          </div>
        </div>
        <!-- CHALLENGE OWNER -->
        <div
          v-if="section.index == 0"
          class="flex flex-col items-start gap-1.5 mb-6"
        >
          <span class="text-lg text-black font-bold">Owner</span>
          <span class="text-base text-grey1 font-normal text-left">{{
            props.currentChallenge.Corporate_Challenge_Owner__r?.Name ||
            "MTB Advisor"
          }}</span>
        </div>
        <!-- SECTION TITLE -->
        <div class="flex flex-row gap-6">
          <span class="text-xl text-black font-bold mb-6">{{
            section.label
          }}</span>
          <div
            class=""
            :class="
              isValidated &&
              errorFields?.length > 0 &&
              !loadingState.editChallenge &&
              props.challengeFields
                .filter((field) => field.step === section.index + 1)
                .some((field) => props.errorFields.includes(field.id)) &&
              'click-me-edit'
            "
          >
            <base-vite-icon
              name="edit"
              classes="w-5 h-5 stroke-2 stroke-primary cursor-pointer"
              @click="editChallenge(section.index + 1)"
            />
          </div>
        </div>
        <!-- SECTION CONTENT -->
        <div class="flex flex-col items-start gap-4">
          <!-- TODO: currently not showing the fields without value,should we consider to show it? -->

          <div
            class="flex flex-col items-start w-full gap-1.5"
            v-for="field in filteredFields.filter((ele) =>
              section.step?.includes(ele.step)
            )"
            :key="field"
          >
            <div class="flex items-center justify-between gap-2 w-full">
              <span
                class="text-lg font-bold text-left"
                :class="
                  errorFields.includes(field.id)
                    ? 'text-alert w-3/4 lg:w-auto'
                    : 'text-black'
                "
                >{{ field.label }}
              </span>
              <base-vite-icon
                v-if="errorFields.includes(field.id)"
                name="info"
                classes="w-4 h-4 stroke-alert stroke-2"
              />
            </div>

            <span class="text-base text-grey1 font-normal text-left">{{
              formatValue(field, props.currentChallenge[field.id])
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, ref } from "vue";
import { bus } from "@/main.js";
import { useChallengeDetails } from "../hooks/use-challenge-details.js";
import { statusMapping } from "@global/configs/challenge-mapping.js";
import { useLoading } from "@/global/hooks/use-loading.js";
const { isGlobalUserInfoLoading, isAccountChallengesLoading } = useLoading();
const props = defineProps({
  currentChallenge: {
    type: Object,
    default: null
  },
  startupView: {
    type: Boolean,
    default: false
  },
  errorFields: {
    type: Array,
    default: () => []
  },
  isValidated: {
    type: Boolean,
    default: false
  },
  loadingState: {
    type: Object,
    default: null
  },
  challengeFields: {
    type: Object,
    default: null
  }
});

const sectionConfig = [
  {
    label: "Basic Info",
    index: 0,
    step: [0, 1]
  },
  {
    label: "Needs & Details",
    index: 1,
    step: [2]
  },
  {
    label: "Additional Information",
    index: 2,
    step: [3]
  }
];
const { allNeededFields, formatValue } = useChallengeDetails();
const filteredFields = computed(() => {
  if (props.currentChallenge) {
    return allNeededFields(props.currentChallenge).filter((field) =>
      props.startupView ? field.visibleToStartups : true
    );
  }
  return [];
});
const isLoading = computed(
  () =>
    isGlobalUserInfoLoading.value ||
    isAccountChallengesLoading.value ||
    !props.currentChallenge
);
function editChallenge(step = 1) {
  bus.emit("edit-challenge", step);
}
</script>

<style lang="scss" scoped>
.click-me-edit {
  display: inline-block;
  // svg {
  //   stroke: #ff934f !important;
  // }
}

.click-me-edit::after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: #0d2697;
  animation: shakeme 2s infinite;
}

@keyframes shakeme {
  0% {
    transform: scale(1);
    width: 0;
  }
  100% {
    transform: scale(1);
    width: 100%;
  }
}
</style>
