export const ICON_DEFAULT = {
  color: "black",
  strokeColor: "transparent",
  strokeWidth: 0,
  role: "",
  scale: 1,
  hover: "",
  icon: "warning"
};
export const PITCHDECK_LINK =
  "https://europe-west2-mtb-platform.cloudfunctions.net/pitchdecks-serve_latest?sfid=";
export const SALESFORCE_LINK_ACCOUNT =
  "https://mindthebridge.lightning.force.com/lightning/r/Account/";
export const SALESFORCE_LINK_OPPORTUNITY =
  "https://mindthebridge.lightning.force.com/lightning/r/Opportunity/";
export const SALESFORCE_LINK_CHALLENGE =
  "https://mindthebridge.lightning.force.com/lightning/r/Challenge__c/";
