const stageMappingConfig = {
  leadSource: {
    "Ecosystem - Rialto Suggestion": {
      value: "Ecosystem - Rialto Suggestion",
      label: "AI Suggestion",
      iconClasses: "fill-data2 w-4 h-4",
      chipClasses: "bg-data2-light text-data2 font-bold",
      icon: "ai-sparkle"
    },
    "Ecosystem - Corporate Selection": {
      value: "Ecosystem - Corporate Selection",
      label: "Corporate Selection",
      iconClasses: "fill-data6 w-4 h-4",
      chipClasses: "bg-data6-light text-data6 font-bold",
      icon: "like_fill"
    },
    "Ecosystem - MTB Selection": {
      value: "Ecosystem - MTB Selection",
      label: "MTB Suggestion",
      iconClasses: "fill-data6 w-4 h-4",
      chipClasses: "bg-data6-light text-data6 font-bold",
      icon: "like_fill"
    },
    "Ecosystem - Startup Application": {
      value: "Ecosystem - Startup Application",
      label: "Startup Application",
      iconClasses: "fill-data4 w-4 h-4",
      chipClasses: "bg-data4-light text-data4 font-bold",
      icon: "profile_fill"
    },
    default: {
      value: "Ecosystem - MTB Selection",
      label: "MTB Suggestion",
      iconClasses: "fill-data6 w-4 h-4",
      chipClasses: "bg-data6-light text-data6 font-bold",
      icon: "like_fill"
    }
  },
  stageName: {
    "MTB Selected": {
      value: "MTB Selected",
      label: "To Evaluate",
      iconClasses: "fill-data6 w-4 h-4",
      chipClasses: "bg-data6-light text-data6 font-bold",
      icon: "like_fill",
      isNegative: false,
      section: "suggestion"
    },
    "No Feedback": {
      value: "No Feedback",
      label: "No Feedback",
      iconClasses: "stroke-primary w-4 h-4",
      chipClasses: "text-primary font-bold",
      icon: "comment",
      isNegative: false,
      section: "suggestion"
    },
    "MTB Discarded": {
      value: "MTB Discarded",
      label: "MTB Discarded",
      iconClasses: "stroke-primary w-4 h-4",
      chipClasses: "text-primary font-bold",
      icon: "comment",
      isNegative: true,
      section: "suggestion"
    },
    Bookmarked: {
      value: "Bookmarked",
      label: "Selected",
      iconClasses: "fill-data3 w-4 h-4",
      chipClasses: "bg-data3-light text-data3 font-bold",
      icon: "clock_fill",
      isNegative: false,
      section: "selection"
    },
    "Not Selected": {
      value: "Not Selected",
      label: "Not Interested",
      iconClasses: "stroke-primary w-4 h-4",
      chipClasses: "text-primary font-bold",
      icon: "comment",
      isNegative: true,
      section: "selection"
    },
    Selected: {
      value: "Selected",
      label: "Meeting Requested",
      iconClasses: "fill-data3 w-4 h-4",
      chipClasses: "bg-data3-light text-data3 font-bold",
      icon: "clock_fill",
      isNegative: false,
      section: "meeting"
    },
    Matched: {
      value: "Matched",
      label: "Meeting Scheduled",
      iconClasses: "fill-data4 w-4 h-4",
      chipClasses: "bg-data4-light text-data4 font-bold",
      icon: "calendar_fill",
      isNegative: false,
      section: "meeting"
    },
    Met: {
      value: "Met",
      label: "Meeting Done",
      iconClasses: "fill-success w-4 h-4",
      chipClasses: "bg-success-light text-success font-bold",
      icon: "check-circle_fill",
      isNegative: false,
      section: "meeting"
    },
    "Not a fit": {
      value: "Not a fit",
      label: "Not a fit",
      iconClasses: "stroke-primary w-4 h-4 ",
      chipClasses: "text-primary font-bold",
      icon: "comment",
      isNegative: true,
      section: "meeting"
    },

    "Under Evaluation": {
      value: "Under Evaluation",
      label: "Under Evaluation",
      iconClasses: "fill-grey2 w-4 h-4",
      chipClasses: "bg-grey4 text-grey2 font-bold",
      icon: "document_fill",
      isNegative: false,
      section: "evaluation"
    },
    "PoC Approved": {
      value: "PoC Approved",
      label: "PoC Approved",
      iconClasses: "fill-grey2 w-4 h-4",
      chipClasses: "bg-grey4 text-grey2 font-bold",
      icon: "document_fill",
      isNegative: false,
      section: "evaluation"
    },
    "PoC Started": {
      value: "PoC Started",
      label: "PoC Started",
      iconClasses: "fill-grey2 w-4 h-4",
      chipClasses: "bg-grey4 text-grey2 font-bold",
      icon: "document_fill",
      isNegative: false,
      section: "evaluation"
    },
    "PoC Ended": {
      value: "PoC Ended",
      label: "PoC Ended",
      iconClasses: "fill-grey2 w-4 h-4",
      chipClasses: "bg-grey4 text-grey2 font-bold",
      icon: "document_fill",
      isNegative: false,
      section: "evaluation"
    },
    "Industrialization/Adoption": {
      value: "Industrialization/Adoption",
      label: "Industrialization/Adoption",
      iconClasses: "fill-grey2 w-4 h-4",
      chipClasses: "bg-grey4 text-grey2 font-bold",
      icon: "document_fill",
      isNegative: false,
      section: "evaluation"
    },
    Investment: {
      value: "Investment",
      label: "Investment",
      iconClasses: "fill-grey2 w-4 h-4",
      chipClasses: "bg-grey4 text-grey2 font-bold",
      icon: "document_fill",
      isNegative: false,
      section: "evaluation"
    },
    "Deal Rejected": {
      value: "Deal Rejected",
      label: "Deal Rejected",
      iconClasses: "fill-grey2 w-4 h-4",
      chipClasses: "bg-grey4 text-grey2 font-bold",
      icon: "document_fill",
      isNegative: true,
      section: "evaluation"
    }
  },
  Startup_Opportunity_Status__c: {
    Interested: {
      value: "Interested",
      label: "Interested",
      isNegative: false
    },
    "Not Interested": {
      value: "Not Interested",
      label: "No from Startup",
      iconClasses: "fill-data6 w-4 h-4",
      chipClasses: "bg-data6-light text-data6 font-bold",
      icon: "investment_fill",
      isNegative: true
    }
  }
};

const stageMap = {
  suggestion: {
    includedStage: ["MTB Selected", "No Feedback", "MTB Discarded"],
    label: "Discovery",
    index: 0
  },
  selection: {
    includedStage: ["Not Selected", "Bookmarked"],
    label: "Selection",
    index: 1
  },
  meeting: {
    includedStage: ["Selected", "Matched", "Met", "Not a fit"],
    label: "Meeting",
    index: 2
  },
  evaluation: {
    includedStage: [
      "Deal Rejected",
      "Under Evaluation",
      "PoC Approved",
      "PoC Started",
      "PoC Ended",
      "Industrialization/Adoption",
      "Investment"
    ],
    label: "Evaluation",
    index: 3
  }
};

const startupkanbanColumnMap = {
  suggestion: {
    includedStage: ["MTB Selected"],
    label: "Suggestions",
    index: 0
  },
  application: {
    includedStage: [
      "MTB Selected",
      "Applied",
      "Bookmarked",
      "Not Selected",
      "No Feedback",
      "Not a fit" //suggested: false
    ],
    label: "Applications",
    index: 1
  },
  meeting: {
    includedStage: ["Selected", "Matched", "Met", "Not a fit"],
    label: "Meeting",
    index: 2
  },
  evaluation: {
    includedStage: [
      "Deal Rejected",
      "Under Evaluation",
      "PoC Approved",
      "PoC Started",
      "PoC Ended",
      "Industrialization/Adoption",
      "Investment"
    ],
    label: "Evaluation",
    index: 3
  },
  discarded: {
    includedStage: [
      "Not Interested",
      "disliked",
      "Not Selected",
      "Deal Rejected", //no activate
      "No Feedback", //no activate
      "Not a fit" //no activate
    ],
    label: "Evaluation",
    index: 4
  },
  noActivate: {
    includedStage: ["Deal Rejected", "No Feedback", "Not a fit"]
  }
};

const startupStageConfig = {
  "MTB Selected": {
    value: "Accepted",
    label: "Accepted",
    iconClasses: "fill-success w-4 h-4",
    chipClasses: "bg-success-light text-success font-bold",
    icon: "check-circle_fill",
    isNegative: false,
    section: "applications"
  },
  Bookmarked: {
    value: "Shortlisted",
    label: "Shortlisted",
    iconClasses: "fill-success w-4 h-4",
    chipClasses: "bg-success-light text-success font-bold",
    icon: "check-circle_fill",
    isNegative: false,
    section: "applications"
  },
  "Not a fit": {
    value: "Not a fit",
    label: "Not a fit",
    iconClasses: "stroke-primary stroke-2 w-4 h-4",
    chipClasses: "bg-primary-light text-primary font-bold",
    icon: "comment",
    isNegative: true,
    section: "applications"
  },
  "No Feedback": {
    value: "No Feedback",
    label: "No Feedback",
    iconClasses: "stroke-grey1 w-4 h-4",
    chipClasses: "text-grey1 font-bold",
    icon: "comment",
    isNegative: true,
    section: "suggestion"
  },
  "Not Selected": {
    value: "Not Selected",
    label: "Not Interested",
    iconClasses: "stroke-data5 w-4 h-4",
    chipClasses: "text-data5 font-bold",
    icon: "comment",
    isNegative: true,
    section: "selection"
  }
};

module.exports = {
  stageMappingConfig,
  stageMap,
  startupStageConfig,
  startupkanbanColumnMap
};
