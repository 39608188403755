<template>
  <base-form-validation
    class="w-full"
    :schema="schema"
    form-id="feedbackForm"
    @submitForm="sendFeedback"
  >
    <template #form-body>
      <div class="w-full flex flex-col gap-y-4 max-h-[65vh] overflow-y-auto">
        <div class="flex flex-col text-grey2 gap-y-2">
          <base-input-text
            class="w-full"
            label="Name"
            name="Name"
            type="input-text"
            v-model="formData.Name"
            :value="formData.Name"
            placeholder="John Doe"
          >
          </base-input-text>
        </div>

        <div class="flex flex-col text-grey2 gap-y-2">
          <base-input-text
            class="w-full"
            label="Email"
            name="Email"
            type="input-text"
            v-model="formData.Email"
            :value="formData.Email"
            placeholder="john.doe@example.com"
          >
          </base-input-text>
        </div>
        <div class="flex flex-col text-grey2 gap-y-2">
          <label class="text-sm font-medium capitalize text-black"
            >Request Type</label
          >
          <base-single-select
            class="w-full"
            name="Request"
            :options="[
              { label: 'New Feature', value: '1201906034435850' },
              { label: 'Improvement', value: '1203123937237096' },
              { label: 'Remove Feature', value: '1203123937238244' }
            ]"
            v-model="formData.Request"
            :default-selected-option="{
              label: 'New Feature',
              value: '1201906034435850'
            }"
            placeholder="Select a request type"
          />
        </div>
        <div class="flex flex-col text-grey2 gap-y-2">
          <base-input-text
            class="w-full"
            label="Request Title"
            name="Title"
            type="input-text"
            v-model="formData.Title"
            :value="formData.Title"
            placeholder="Add a title for your request"
          >
            <span class="text-grey2 font-normal text-xs">Optional</span>
          </base-input-text>
        </div>
        <div class="flex flex-col text-grey2 gap-y-2">
          <base-text-area
            type="input-text"
            label="Request Description"
            v-model="formData.Description"
            placeholder="Provide a detailed description of your request"
            name="Description"
          />
        </div>
        <div class="flex flex-col text-grey2 gap-y-2">
          <label class="text-sm font-medium capitalize text-black"
            >Attachments</label
          >
          <div class="relative">
            <base-button
              class=""
              label="Choose Files"
              icon="add"
              iconClass="w-5"
              severity="primary"
              :outlined="true"
            />
            <input
              type="file"
              ref="fileInput"
              @change="handleFileUpload"
              multiple
              class="absolute top-0 left-0 opacity-0 h-full w-full cursor-pointer"
            />
          </div>

          <!-- <label for="fileInput" class="btn btn-primary">Choose Files</label> -->
          <div v-if="formData.Attachments.length > 0">
            <ul class="flex flex-col gap-y-2">
              <li
                v-for="(file, index) in formData.Attachments"
                :key="file.name"
                class="flex items-center justify-between"
              >
                <div class="flex items-center gap-x-2">
                  <base-vite-icon
                    name="document"
                    classes="stroke-primary stroke-2 w-4 h-4"
                  />
                  {{ file.name }}
                </div>
                <base-vite-icon
                  role="button"
                  name="close"
                  classes="stroke-primary stroke-2 w-6 h-6 cursor-pointer"
                  @click="removeFile(index)"
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </template>
    <template #form-action>
      <div class="flex gap-x-4 w-full mt-4">
        <base-button
          label="Create Task"
          severity="primary"
          type="submit"
          :isLoading="loading"
          class="w-full"
        />
      </div>
    </template>
  </base-form-validation>
</template>

<script setup>
import { ref, watchEffect, inject } from "vue";
import { useUserAuthStore } from "@/global/stores/user-auth-store.js";

import { useToast } from "primevue/usetoast";
const dialogRef = inject("dialogRef");
const fileInput = ref();
const loading = ref(false);
const toast = useToast();
const userAuthStore = useUserAuthStore();
const getFullName = () => {
  const firstName = userAuthStore.state.userInfo?.FirstName || "";
  const lastName = userAuthStore.state.userInfo?.LastName || "";
  return `${firstName} ${lastName}`.trim();
};
const formData = ref({
  Name: getFullName(),
  Email: userAuthStore.state.userInfo?.Email,
  Request: "",
  Description: "",
  Title: "",
  Attachments: []
});

const schema = {
  Name: {
    required: true
  },
  Email: {
    required: true,
    email: true
  },
  Request: {
    required: true
  },
  Description: {
    required: true
  }
};

const handleFileUpload = (event) => {
  const files = event.target.files;

  for (let i = 0; i < files.length; i++) {
    if (files[i].size <= 5000000) {
      const reader = new FileReader();
      reader.onload = (e) => {
        console.log("###ENTER", files);
        formData.value.Attachments.push({
          file: files[i],
          name: files[i].name,
          size: files[i].size,
          type: files[i].type,
          preview: e.target.result
        });
      };
      reader.readAsDataURL(files[i]);
    } else {
      toast.add({
        severity: "error",
        summary: `File ${files[i].name} is too large. Maximum size is 5MB.`,
        life: 50000
      });
    }
  }

  setTimeout(() => {
    if (fileInput?.value?.value) {
      fileInput.value.value = "";
    }
  }, 100);
};
const removeFile = (index) => {
  formData.value.Attachments.splice(index, 1);
};
const sendFeedback = async (value) => {
  try {
    loading.value = true;
    const BASE_URL =
      process.env.NODE_ENV !== "development"
        ? "https://us-central1-mtb-platform.cloudfunctions.net/createAsanaTask"
        : "http://localhost:5000/mtb-platform/us-central1/createAsanaTask";
    const response = await fetch(BASE_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(formData.value)
    });
    if (response.ok) {
      toast.add({
        severity: "success",
        summary: `Request created successfully.`,
        life: 10000
      });
      dialogRef.value.close();
    } else {
      toast.add({
        severity: "error",
        summary: `Cannot create your Request.`,
        life: 50000
      });
    }
  } catch (error) {
    toast.add({
      severity: "error",
      summary: `Cannot create your Request: ${error}`,
      life: 50000
    });
  } finally {
    loading.value = false;
  }
};
</script>
