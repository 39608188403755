import { database, functionsEU } from "@plugins/firebase.js";
import {
  getDocs,
  query,
  where,
  collection,
  doc,
  getDoc
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { useUserAuthStore } from "@/global/stores/user-auth-store.js";
import validate from "validate.js";

import { default as classes } from "@share/errorClasses.js";
export async function getPendingMeetings(advisorEmail) {
  try {
    //validation
    const validationRules = {
      advisorEmail: {
        presence: { allowEmpty: false },
        type: "string",
        email: true
      }
    };
    const validationErrors = validate({ advisorEmail }, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }

    const httpsCallableFunction = httpsCallable(
      functionsEU,
      "mtbEmailing-pendingMeetingList"
    );
    const payload = { advisorEmail };
    const response = await httpsCallableFunction(payload);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 *
 * @param {*} data
 * example: 
 *    {
 *      OppInfo: Opportunity.value,
        StartupParticipants: StartupParticipants,
        CorporateParticipants: CorporateParticipants,
        startTimeDate: startTimeDate,
        endTimeDate: endTimeDate,
        GCalendarId: state.form.target_cal.id
      }
 * @returns
 */
export async function createCalendarInvitation(data) {
  try {
    //validation
    const validationRules = {
      data: {
        presence: { allowEmpty: false },
        type: "object"
      },
      "data.OppInfo": {
        presence: { allowEmpty: false },
        type: "object"
      },
      "data.StartupParticipants": {
        presence: { allowEmpty: false },
        type: "array"
      },
      "data.CorporateParticipants": {
        presence: { allowEmpty: false },
        type: "array"
      },
      //check date validation rules
      "data.startTimeDate": {
        presence: { allowEmpty: false },
        type: "string"
      },
      //check date validation rules
      "data.endTimeDate": {
        presence: { allowEmpty: false },
        type: "string"
      },
      "data.GCalendarId": {
        presence: { allowEmpty: false },
        type: "string"
      }
    };
    const validationErrors = validate({ data }, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }

    const httpsCallableFunction = httpsCallable(
      functionsEU,
      "handleCalendarInvitation-create"
    );
    const response = await httpsCallableFunction(data);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
export async function deleteMatchingCalendar(matchingCalendarId) {
  try {
    //validation
    const validationRules = {
      matchingCalendarId: {
        presence: { allowEmpty: false },
        type: "string"
      }
    };
    const validationErrors = validate({ matchingCalendarId }, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }

    const response = await httpsCallable(
      functionsEU,
      "handleMatchingCalendars-delete"
    )({
      MatchingCalendarId: matchingCalendarId
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getListGoogleCalendars() {
  try {
    const httpsCallableFunction = httpsCallable(
      functionsEU,
      "handleMatchingCalendars-listGCalendars"
    );
    const response = await httpsCallableFunction({});
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
export async function updateMatchingCalendar(data) {
  try {
    //validation
    const validationRules = {
      data: {
        presence: { allowEmpty: false },
        type: "object"
      },
      "data.status": {
        presence: { allowEmpty: false },
        type: "string",
        inclusion: {
          // from cloud function: !["ONLINE", "OFFLINE"].includes(data.status)
          within: ["ONLINE", "OFFLINE"]
        }
      },
      "data.CalendarName": {
        presence: false,
        type: "string"
      },
      "data.CalendarSlots": {
        presence: false,
        type: "object"
      },
      "data.CorpParticipants": {
        presence: false,
        type: "array"
      },
      "data.MatchingCalendarId": {
        presence: { allowEmpty: false },
        type: "string"
      },
      "data.CalendarScope": {
        presence: false,
        type: "object"
      }
    };
    const validationErrors = validate({ data }, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }

    const response = await httpsCallable(
      functionsEU,
      "handleMatchingCalendars-update"
    )(data);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

//currently using firestore to retrieve users, but we can use getEmployeesList cloud function (main/Admin/getEmployeesList) thru getEmployeesList function in accounts-service
export async function getListOfEmployees(data) {
  try {
    //validation
    const validationRules = {
      data: {
        presence: { allowEmpty: false },
        type: "object"
      },
      "data.corporateId": {
        presence: { allowEmpty: false },
        type: "string"
      }
    };
    const validationErrors = validate({ data }, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }

    const queryRef = collection(database, "users");
    const q = query(queryRef, where("companyId", "==", data.corporateId));
    const querySnapshot = await getDocs(q);
    const response = querySnapshot.docs.map((d) => {
      return { ...d.data(), id: d.id };
    });
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function createMatchingCalendar(data) {
  console.log("debug data", data);
  try {
    //validation
    const validationRules = {
      data: {
        presence: { allowEmpty: false },
        type: "object"
      },
      "data.CorpId": {
        presence: { allowEmpty: false },
        type: "string"
      },
      "data.CalendarName": {
        presence: { allowEmpty: false },
        type: "string"
      },
      "data.requestedEmail": {
        presence: { allowEmpty: false },
        type: "string"
      }
    };
    const validationErrors = validate({ data }, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }
    const response = await httpsCallable(
      functionsEU,
      "handleMatchingCalendars-createV3"
    )({
      CorpId: data.CorpId,
      CampaignId: data.CampaignId,
      CalendarScope: data.CalendarScope,
      CorpParticipants: data.CorpParticipants,
      CalendarName: data.CalendarName,
      CalendarSlots: data.CalendarSlots,
      requestedEmail: data.requestedEmail
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
//TODO: to be removed, we are not using it
// export async function getFireStoreMatchingCalendar(data) {
//   try {
//     //validation
//     const validationRules = {
//       data: {
//         presence: { allowEmpty: false },
//         type: "object"
//       },
//       "data.campaignId": {
//         presence: { allowEmpty: false },
//         type: "string"
//       }
//     };
//     const validationErrors = validate({ data }, validationRules);
//     if (validationErrors) {
//       throw new classes.Api400Error(JSON.stringify(validationErrors));
//     }

//     const authenticatedUser = useUserAuthStore();

//     // const collectionRef = collection(database, "matching_calendars");
//     // const filters = [
//     //   where("CampaignId", "==", data.campaignId),
//     //   where("CorpId", "==", authenticatedUser.state.userInfo?.AccountId)
//     // ];
//     // const queryMatchingCalendar = query(collectionRef, ...filters);

//     // const response = (await getDocs(queryMatchingCalendar)).docs.map((doc) => {
//     //   return { ...doc.data(), id: doc.id };
//     // });
//     const response = await httpsCallable(
//       functionsEU,
//       "getMatchingCalendarV3"
//     )({
//       corporateId: authenticatedUser.state.userInfo?.AccountId,
//       CampaignId: data.campaignId
//     });

//     return response.data;
//   } catch (error) {
//     console.error(error);
//     throw error;
//   }
// }
export async function getYCBMCalendarCorporate(corporateId) {
  try {
    const response = await httpsCallable(
      functionsEU,
      "getMatchingCalendarV3"
    )({
      corporateId
    });

    return response.data.sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );
  } catch (error) {
    console.error(error);
    return false;
    // throw error;
  }
}
export async function getYCBMCalendarStartup(startupId) {
  try {
    const res = await httpsCallable(
      functionsEU,
      "getMatchingCalendarV3"
    )({ startupId });

    return res.data;
  } catch (error) {
    console.error(error);
    return false;
    // throw error;
  }
}

export async function getYCBMCalendarInfo(data) {
  try {
    const response = await httpsCallable(
      functionsEU,
      "getYCBMCalendarV3"
    )(data);

    return response.data;
  } catch (error) {
    console.error(error);
  }
}
export async function getCorporateMeetings(data) {
  try {
    //validation
    const validationRules = {
      data: {
        presence: { allowEmpty: false },
        type: "object"
      },
      "data.corporateId": {
        presence: { allowEmpty: false },
        type: "string"
      },
      "data.limit": {
        presence: { allowEmpty: false },
        type: "string"
      },
      "data.offset": {
        presence: { allowEmpty: false },
        type: "string"
      }
    };
    const validationErrors = validate({ data }, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }
    const response = await httpsCallable(
      functionsEU,
      "getcorporatemeetingv3"
    )(data);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getAllMatchingCalendars() {
  try {
    const res = await httpsCallable(functionsEU, "getMatchingCalendarV3")({});

    return res.data;
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
}
