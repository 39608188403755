<template>
  <mtb-tooltip :title="tooltip" :custom-class="tooltipCustomClass">
    <button
      :class="[
        classes,
        iconStyle.iconPosition,
        (disabled || showSpinner) && 'disabled'
      ]"
      class="d-inline-flex align-items-center"
      :data-bs-toggle="eventEmitter === 'filter' ? 'offcanvas' : ''"
      data-bs-target="#mtb-filter-sidebar"
      aria-controls="filterSidebar"
      @click="handleClick"
    >
      <div
        v-if="showSpinner"
        style="width: 29px; height: 29px"
        class="d-flex align-items-center justify-content-center"
      >
        <div
          class="spinner-border spinner-border-sm text-primary"
          role="status"
        ></div>
      </div>

      <mtb-svg
        v-else
        :icon-name="icon"
        :scale="iconStyle.scale"
        :stroke-width="iconStyle.strokeWidth"
        :stroke="iconStyle.stroke"
      />

      <div v-if="showLabel" class="ms-1">{{ label }}</div>
    </button>
    <!-- <template #content>
      <div>{{ tooltip }}</div>
    </template> -->
  </mtb-tooltip>
</template>

<script setup>
import { useRoute } from "vue-router";
import { bus } from "@/main.js";
import { onMounted, ref } from "vue";

const props = defineProps({
  icon: { type: String, default: "", require: true },
  // DEBT: put back the default config for icon style
  iconStyle: {
    type: Object,
    default: () => {}
  },
  iconComponent: { type: String, default: "mtb-svg" },
  label: { type: String, default: "" },
  showLabel: {
    type: Boolean,
    default: true
  },
  classes: { type: String, default: "" },
  tooltip: {
    type: String,
    default: null
  },
  tooltipCustomClass: {
    type: String,
    default: "tooltip-secondary"
  },
  hasSpinner: {
    type: Boolean,
    default: false
  },
  eventEmitter: {
    type: String,
    default: ""
  },
  disabled: {
    type: Boolean,
    default: false
  },
  startup: { type: Object, default: () => {} }
});
const route = useRoute();
const emit = defineEmits(["click-applied"]);
const showSpinner = ref(false);
const handleClick = () => {
  if (props.hasSpinner) showSpinner.value = true;
  if (["Feedback_CC_", "Arrange Meeting"].includes(props.eventEmitter)) {
    emit(
      "click-applied",
      props.startup?.Opportunity?.Related_Challenge__c,
      props.startup.Id,
      props.eventEmitter,
      props.label
    );
  } else {
    let eventName = `${props.eventEmitter}-${route?.name}`;
    bus.emit(eventName);
  }
};
onMounted(() => {
  bus.on("disable-button-spinner", () => {
    showSpinner.value = false;
  });
});
</script>

<style></style>
