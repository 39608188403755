import { functionsEU } from "@plugins/firebase.js";
import { httpsCallable } from "firebase/functions";
import { default as classes } from "@share/errorClasses.js";
import validate from "validate.js";
export async function getAnalyticsStartupsExposure(data) {
  try {
    const validationRules = {
      startupIds: {
        presence: { allowEmpty: false },
        type: "array"
      }
    };
    const validationErrors = validate(data, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }
    const httpsCallableFunction = httpsCallable(
      functionsEU,
      "getAnalyticsStartupsExposureV3"
    );

    const response = await httpsCallableFunction(data);
    return response.data || [];
  } catch (error) {
    throw error;
  }
}
