<template>
  <div class="relative w-full">
    <AutoComplete
      id="autocompleteRef"
      @click="goSearch"
      @keyup.enter="submit"
      @item-select="searchQueryFromHistory"
      v-model="search"
      :suggestions="searchHistory"
      :loading="false"
      panelClass="min-w-[60px] mx-[16px] "
      :pt="{
        input: {
          class:
            'h-full placeholder-tertiary text-tertiary placeholder-opacity-60 pl-14 pr-36 border rounded-full w-full focus:outline-none outline-none border-tertiary placeholder-shown:text-ellipsis'
        },
        list: {
          style: {
            width: `${autocompleteOptionWidth}px`
          },
          class: 'p-0 list-none m-0'
        },
        item: {
          class: 'py-3 px-1 m-0'
        },
        emptyMessage: 'hidden'
      }"
      :class="{
        'placeholder-animate': !isPlaceholderHidden,
        'placeholder-hidden': isPlaceholderHidden
      }"
      :placeholder="placeholder"
      ><template #loadingicon><span></span></template>
      <template #option="{ option }"
        ><div class="flex w-full justify-between items-center">
          <div class="flex gap-x-2 items-center">
            <base-vite-icon
              name="history"
              classes="stroke-grey1 w-4 h-4 stroke-2"
            />
            <span>{{ option.Query }}</span>
          </div>
          <base-vite-icon
            role="button"
            name="close"
            classes="stroke-grey1 w-4 h-4 stroke-2"
            @click.stop="deleteQueryFromHistory(option)"
          /></div
      ></template>

      <template #footer
        ><span
          class="p-3 text-grey2"
          role="button"
          @click="loadQueryHistory(5)"
          v-if="totalQueryRecords > 5 && searchHistory?.length <= 5"
          >Load more</span
        ></template
      >
    </AutoComplete>

    <span
      class="absolute left-6 top-1/2 transform -translate-y-1/2"
      :class="{ 'cursor-pointer': search.length !== 0 }"
      @click="clear"
    >
      <base-vite-icon
        name="search_linear"
        classes="stroke-tertiary "
        v-show="search.length === 0"
      />
      <base-vite-icon
        name="close"
        classes="stroke-tertiary w-5 h-5"
        v-show="search.length !== 0"
      />
    </span>
    <div
      class="absolute right-6 top-1/2 transform -translate-y-1/2 flex items-center space-x-2 opacity-30"
    >
      <span class="text-sm text-tertiary whitespace-nowrap">Powered by AI</span>
      <base-vite-icon name="ai-sparkle" classes="fill-tertiary w-6 h-6" />
    </div>
  </div>
</template>

<script setup>
import { mixpanelMtbTracking } from "@/plugins/mixpanel.js";
import { bus } from "@/main.js";
import { computed, ref, watchEffect, watch, onMounted, onUnmounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useUserAuthStore } from "@/global/stores/user-auth-store.js";
import {
  getUserQueryHistory,
  updateUserQueryHistory
} from "@/global/services/search-service.js";
const router = useRouter();
const route = useRoute();
const userAuthStore = useUserAuthStore();
const autocompleteOptionWidth = ref(100);
const isFiltered = ref(false);
const totalQueryRecords = ref(0);
const placeholders = [
  "Solution for blockchain-based secure digital identity verification for financial institutions...",
  "Technology for a 3D printing system that uses living cells to create functional human organs for transplant...",
  "Solution for an IoT-powered system that monitors and optimizes indoor air quality in large commercial buildings...",
  "Software for a virtual event platform that simulates real-world interactions and integrates with popular collaboration tools..",
  "Technology for a portable, handheld device that uses ultrasound to diagnose and treat a range of medical conditions...",
  "Solution for a renewable energy microgrid that uses AI to manage energy distribution and reduce costs...",
  "Software for a web-based tool that uses machine learning to automatically generate product descriptions and titles for...",
  "Technology for augmented reality smart glasses that can provide real-time visual overlays for manufacturing and logistics...",
  "Software for a cloud-based project management platform that integrates with popular development tools...",
  "Technology for self-driving delivery vehicles that can navigate in urban areas and deliver packages without human...."
];
const searchHistory = ref([]);
const placeholderIndex = ref(0);
const search = ref("");
const isPlaceholderHidden = ref(false);
watch(
  () => route.name,
  (route, oldRoute) => {
    if (route !== oldRoute) {
      search.value = "";
    }
  }
);
function changePlaceholder() {
  isPlaceholderHidden.value = true;
  setTimeout(() => {
    placeholderIndex.value = (placeholderIndex.value + 1) % placeholders.length;
    isPlaceholderHidden.value = false;
  }, 1000);
}

setInterval(changePlaceholder, 7000);

const placeholder = computed(() => placeholders[placeholderIndex.value]);
function goSearch() {
  if (route.name === "search") {
    return;
  }
  router.push({
    name: "search"
  });
}

function clear() {
  if (search.value?.length !== 0) {
    search.value = "";
    bus.emit("clear-filter");
  }
}

onMounted(async () => {
  observeSearchContainer();
  bus.on("is-filtered", (e) => (isFiltered.value = e));
  bus.on("reset-search", () => (search.value = ""));
});
onUnmounted(() => {
  bus.off("is-filtered");
  bus.off("reset-search");
});
watchEffect(async () => {
  if (route.name !== "search") {
    return;
  }
  await loadQueryHistory();
});
const observeSearchContainer = () => {
  const autocompleteRef = document.getElementById("autocompleteRef");
  const resizeObserver = new ResizeObserver(() => {
    autocompleteOptionWidth.value = autocompleteRef.offsetWidth - 32;
  });

  if (autocompleteRef) {
    resizeObserver.observe(autocompleteRef);
  }
};

async function submit(e) {
  if (e.keyCode == 13 && search.value.length !== 0 && route.name === "search") {
    searchQuery(search.value, false);
  }
}
function searchQueryFromHistory(e) {
  if (!e.value?.Query) {
    return;
  }
  search.value = e.value.Query;
  searchQuery(e.value.Query, true);
}
function getSection() {
  const type = userAuthStore.state.accountInfo?.type;
  return type == "startup" ? "challenges" : "startups";
}
async function searchQuery(e, fromHistory) {
  bus.emit("search", e);
  const forMixpanel = {
    query: e,
    history: !!fromHistory,
    category: route.params.section || getSection()
  };
  mixpanelMtbTracking({
    eventName: "Search Query Submitted",
    properties: forMixpanel
  });

  if (!searchHistory.value.some((ele) => ele.Query === search.value)) {
    await updateUserQueryHistory({
      operation: "create",
      isFiltered: isFiltered.value,
      isExceedLimitText: search.value.length > 450 ? true : false,
      queryData: {
        Query: search.value.toLocaleLowerCase(),
        User: userAuthStore.state.userInfo.Id
      }
    });
  }
}
async function loadQueryHistory(limit = 0) {
  let defaultLimit = 5;
  try {
    let result = await getUserQueryHistory({
      userId: userAuthStore.state.userInfo.Id,
      index: defaultLimit + limit,
      query: search.value
    });

    searchHistory.value = result?.queryList || [];
    totalQueryRecords.value = result?.totalRecords || 0;
  } catch (err) {
    throw new Error("Could not load query history." + err);
  }
}
async function deleteQueryFromHistory(query) {
  try {
    const index = searchHistory.value.findIndex((ele) => ele.Id === query.Id);
    searchHistory.value.splice(index, 1);
    //set Deleted property in query object
    query.Deleted = true;
    // Update database
    await updateUserQueryHistory({
      corporateId: userAuthStore.state.accountInfo.id,
      operation: "update",
      queryData: query
    });
    // fetch new queries list
    await loadQueryHistory();
  } catch (e) {
    throw new Error("Could not update search query:" + e.message);
  }
}
</script>

<style lang="scss" scoped>
.placeholder-animate::placeholder {
  opacity: 1;
  transition: opacity 0.5s;
}

.placeholder-hidden::placeholder {
  opacity: 0;
  transition: opacity 0.5s;
}
input:focus {
  outline: none;
  box-shadow: -2px 4px 16px 0px rgba(88, 28, 185, 0.15);
}
</style>
