export default {
  indigo: {
    100: "#ECF0FF",
    200: "#C3CEFF",
    300: "#9AADFF",
    400: "#718BFF",
    500: "#4769FD",
    600: "#304FDB",
    700: "#1C39B9",
    800: "#0D2697",
    900: "#021875"
  },
  sky: {
    100: "#E7F1FF",
    200: "#BBDAFF",
    300: "#90C2FF",
    400: "#65AAFF",
    500: "#3A92FF",
    600: "#0E78FB",
    700: "#005ED2",
    800: "#004CA9",
    900: "#003A81"
  },
  violet: {
    100: "#F3ECFF",
    200: "#DAC3FF",
    300: "#C19AFF",
    400: "#A771FF",
    500: "#8D47FD",
    600: "#7130DB",
    700: "#581CB9",
    800: "#420D9",
    900: "#2E0275"
  },
  green: {
    100: "#EDFFED",
    200: "#C4FFC4",
    300: "#9BFF9B",
    400: "#72FF72",
    500: "#46F546",
    600: "#2ED32E",
    700: "#1BB11B",
    800: "#0C8F0C",
    900: "#026D02"
  },
  red: {
    100: "#FFEFEF",
    200: "#FFCCCC",
    300: "#FFA9A9",
    400: "#FF8686",
    500: "#FF6363",
    600: "#E44E4E",
    700: "#C23939",
    800: "#A02828",
    900: "#7E1A1A"
  },
  stone: {
    100: "#F0F0F0",
    200: "#DCDCDC",
    300: "#C7C7C7",
    400: "#B3B3B3",
    500: "#9F9F9F",
    600: "#8A8A8A",
    700: "#767676",
    800: "#616161",
    900: "#4D4D4D"
  },
  neutral: {
    100: "#EFEFEF",
    200: "#DBDBDB",
    300: "#C7C7C7",
    400: "#B2B2B2",
    500: "#9E9E9E",
    600: "#898989",
    700: "#757575",
    800: "#615F5F",
    900: "#4C4A4A"
  },
  grey3: {
    300: "#D9D9D9"
  },
  grey4: {
    300: "#F3F3F3"
  },
  data1: "#5BACB4", //TODO: add as teal
  data2: "#A10EFB", //TODO: add as purple
  data3: "#CA9352", //TODO: add as yellow
  data4: "#86AB6E", //TODO: add as lime
  data5: "#214A4E", //TODO: add as Emerald or teal
  data6: "#1F2E72", //maybe add as blue or another color of indigo
  chart: {
    100: "#D3DAF8",
    200: "#B8C3F4",
    300: "#9EADF0",
    400: "#8396EC",
    500: "#6880E8",
    600: "#4E6AE4",
    700: "#3353E0",
    800: "#2042D5",
    900: "#1C39B9"
  }
};
