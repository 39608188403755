<template>
  <div
    v-if="globalVisibility"
    class="mtb-card flex flex-col justify-between items-start flex-1 self-stretch xl:absolute static top-6 xl:h-[calc(100%-48px)] xl:w-[48%] xl:right-[24px] w-full"
  >
    <div class="p-8 flex flex-col flex-1 gap-4 self-stretch h-full">
      <mtb-homepage-recap
        :title="tabsConfig.title"
        :dropdownOptions="tabsConfig.dropdownOptions"
        :ctaButton="accountDetails ? tabsConfig.ctaButton : null"
        :isRedirect="false"
        :spaceBetween="true"
        classes="h-full block"
        @update-options="
          (e) => {
            selectedSection = e;
          }
        "
        @click-cta="
          openAddToChallengeModal({
            startupId: accountDetails.Id,
            startupName: accountDetails.Name
          })
        "
      >
        <div
          v-if="selectedSection?.id === 'challenges'"
          class="flex flex-col justify-between h-full"
        >
          <base-startup-profile-opportunities-show
            v-if="accountDetails.Id"
            :fromChallenge="fromChallenge"
            :startupId="accountDetails.Id"
            @selected-opportunity="selectedOpportunity = $event"
            @selected-challenge="selectedChallenge = $event"
          />

          <div
            v-if="selectedOpportunity"
            :class="{
              'opacity-60 poiter-events-none cursor-default':
                isSavingComment || commentTooLong
            }"
            class="shadow-mtb-scrolling max-height-[200px] w-[calc(100%+64px)] ml-[-32px] mb-[-8px] flex ps-4 pe-8 pb-6 justify-between flex-nowrap items-end"
          >
            <base-editor
              class="w-11/12"
              :light="true"
              placeholder="Write your message here"
              editorStyle="max-height: 200px"
              :maxLenght="2000"
              :modelValue="comment"
              name="Message"
              :injected-error="!!commentTooLong ? commentTooLong : ''"
              @update:modelValue="
                (e) => {
                  comment = e;
                }
              "
            />

            <base-vite-icon
              name="message_fill"
              :classes="`w-8 fill-primary ${
                !comment || comment.length == 0
                  ? 'opacity-60 pointer-events-none'
                  : 'cursor-pointer'
              }`"
              @click="saveComment"
            />
          </div>
        </div>
        <base-startup-profile-interactions
          v-else-if="selectedSection?.id === 'history'"
          :startupId="accountDetails.Id"
          :corporateId="userAuthStore.state.accountInfo?.id"
        />
      </mtb-homepage-recap>
    </div>
  </div>
</template>
<script setup>
import { computed, ref } from "vue";
import { useUserAuthStore } from "../stores/user-auth-store.js";
import { auth } from "@/plugins/firebase.js";
import { useRoute } from "vue-router";
import { bus } from "@/main.js";
import { createComment } from "../services/comments-service.js";
import { useOpportunityModals } from "@/modules/corporates/hooks/use-opportunity-modals.js";
import { useOpportunitiesStore } from "../stores/opportunities-store.js";
import { richTextSingleValidation } from "../helpers/text.js";
const { openAddToChallengeModal } = useOpportunityModals();
const route = useRoute();

const userAuthStore = useUserAuthStore();
const opportunitiesStore = useOpportunitiesStore();
const props = defineProps({
  readOnly: {
    type: Boolean,
    default: false
  },
  accountDetails: {
    type: Object,
    default: () => null
  },
  fromChallenge: {
    type: String,
    default: null
  }
});
const comment = ref("");
const commentTooLong = computed(() => {
  return richTextSingleValidation(comment.value, 2000);
});
const selectedOpportunity = ref(null);
// const tabVisibility = computed(() => {
//   return (
//     //tabs are visible if the profile is mine or if there are some active sponsorships
//     props.isMyProfile ||
//     (activeCorporates.value?.length !== 0 && activeStartups.value?.length !== 0)
//   );
// });
const selectedSection = ref(null);
const selectedChallenge = ref(null);

const userType = computed(() => {
  return userAuthStore.state.accountInfo?.type;
});
const dropdownConfig = computed(() => {
  if (userType.value === "corporate") {
    return [
      {
        name: "Challenges",
        id: "challenges"
      },
      {
        name: "History",
        id: "history"
      }
    ];
  }
  return [];
});
const addToChallengeConfig = computed(() => {
  return userAuthStore.getters.isMtbUser &&
    !userAuthStore.state.isDemoModeActive
    ? {
        label: "Suggest to Corporate",
        tooltip:
          "Add this startup to scouting list. You can add notes and decide to notify the company.",
        color: "mtb"
      }
    : {
        label: "Add to Challenge",
        color: "primary"
      };
});
const tabsConfig = computed(() => {
  return {
    title: dropdownConfig.value[0]?.name,
    ...(!props.readOnly &&
      userType.value === "corporate" && {
        ctaButton: {
          label: addToChallengeConfig.value.label,
          severity: addToChallengeConfig.value.color,
          tooltip: addToChallengeConfig.value.tooltip,
          text: true,
          icon: "add",
          iconClass: `w-4 h-4 stroke-2 stroke-${addToChallengeConfig.value.color}`
        }
      }),
    dropdownOptions: dropdownConfig.value
  };
});

// const isMyProfile = computed(() => {
//   if (userType.value !== "startup") {
//     return false;
//   }
//   return (
//     auth?.currentUser &&
//     props.startupId === userAuthStore.state?.accountInfo?.id
//   );
// });
// const isMySponsored = computed(() => {
//   if (userType.value !== "institution") {
//     return false;
//   }
//   return (
//     auth?.currentUser &&
//     userAuthStore.state?.accountInfo?.type == "institution" &&
//     (institutionMyEcosystemStore.getters.getSponsoredStartups.some(
//       (item) => item.companyId === props.startupId
//     ) ||
//       institutionMyEcosystemStore.getters.getSponsoredCorporates.some(
//         (item) => item.companyId === props.startupId
//       ))
//   );
// });
//VISIBILITY LOGIC
const globalVisibility = computed(() => {
  return (
    auth.currentUser && userType.value === "corporate"
    // (isMyProfile.value || isMySponsored.value || userType.value === "corporate") ==>use these condition to show the component with inst && stratup
  );
});
const isSavingComment = ref(false);
async function saveComment() {
  try {
    isSavingComment.value = true;
    if (!selectedOpportunity.value) {
      return;
    }
    if (!selectedOpportunity.value.Id) {
      await createOpportunity(selectedOpportunity.value);
    } else {
      await createComment({
        accountId: userAuthStore.state.accountInfo.id,
        context: userAuthStore.getters.isMtbUser
          ? "MTB Notes"
          : "Internal Corporate Comment",
        text: comment.value,
        opportunityId: selectedOpportunity.value.Id
      });
    }
    comment.value = "";
    setTimeout(() => {
      bus.emit("reload-comments");
    }, 100);
  } catch (error) {
    console.error(error);
  } finally {
    isSavingComment.value = false;
  }
}
async function createOpportunity(opportunity) {
  try {
    if (selectedChallenge.value.Id !== opportunity.Related_Challenge__c) {
      throw new Error("Mismatch between challenge and suggestion");
    }
    const parsedOpportunity = {
      StageName: opportunity.StageName,
      LeadSource: opportunity.LeadSource,
      AccountName: opportunity.AccountName,
      AccountId: opportunity.AccountId,
      Related_Challenge__c: opportunity.Related_Challenge__c
    };
    const data = {
      opportunity: parsedOpportunity,
      messages: [
        {
          message: comment.value,
          context: userAuthStore.getters.isMtbUser
            ? "MTB Notes"
            : "Internal Corporate Comment"
        }
      ]
    };

    //Create opportunity + save comment + reload store
    await opportunitiesStore.actions.setUpdateSalesforceOpportunity(data);
    await opportunitiesStore.actions.setChallengesOpportunities([
      selectedChallenge.value
    ]);
    selectedOpportunity.value =
      opportunitiesStore.state.challengeOpportunities.opportunities[
        selectedChallenge.value.Id
      ].find(({ AccountId }) => AccountId === opportunity.AccountId);
  } catch (error) {
    throw error;
  }
}
</script>
