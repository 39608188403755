<template>
  <div
    v-click-outside="
      () => {
        visibleRight = false;
      }
    "
  >
    <Sidebar
      v-model:visible="visibleRight"
      position="right"
      @click.stop
      :pt="{
        content: 'h-full w-full grow overflow-y-hidden'
      }"
    >
      <template #header>
        <div v-if="title" class="flex items-center gap-3 mr-auto">
          <p class="text-xl">
            <b>{{ title }}</b>
            {{
              `(${items.length} ${items.length === 1 ? "startup" : "startups"})`
            }}
          </p>
        </div>
      </template>
      <template #closeicon>
        <base-vite-icon name="close" classes="stroke-black stroke-2 w-6 h-6" />
      </template>
      <div class="h-full flex flex-col justify-between">
        <div class="relative py-4">
          <InputText
            v-model="state.searchInput"
            :pt="inputtext"
            placeholder="Type to search..."
          />

          <span
            class="absolute left-3 top-1/2 transform -translate-y-1/2"
            :class="{ 'cursor-pointer': state.searchInput.length !== 0 }"
            @click="state.searchInput = ''"
          >
            <base-vite-icon
              name="search_linear"
              classes="stroke-grey2 h-3 w-3 stroke-1"
              v-show="state.searchInput.length === 0"
            />
            <base-vite-icon
              name="close"
              classes="stroke-grey2 h-6 w-6 stroke-1"
              v-show="state.searchInput.length !== 0"
            />
          </span>
        </div>
        <div class="flex flex-col gap-y-4 overflow-y-auto h-full">
          <div
            v-for="item in state.filteredItems"
            :key="item"
            class="hstack gap-2 items-center content-start"
          >
            <base-logo classes="w-8 h-8" :companyId="item.startupId" />
            <a
              class="fs-7 mtb-link text-start text-capitalize"
              target="_blank"
              :href="`${state.windowLocation}/startup-profile/${item.startupId}`"
              >{{ item.name }}
            </a>
          </div>
        </div>
        <div>
          <div class="mtb-toast-info mt-2">
            <div class="hstack gap-2 align-items-start">
              <mtb-svg
                icon-name="info"
                fill="mtb-text"
                stroke="mtb-text"
                stroke-width="2"
                :scale="5"
              />

              <div class="text-start text-mtb-text text-small">
                The list does not include <b>AI-suggested startups </b>that have
                not yet
                <router-link
                  :to="{
                    name: CORPORATE_CHALLENGES_ROUTE_NAME,
                    params: {
                      section: ACTIVE_CHALLENGES
                    }
                  }"
                  class="mtb-link"
                  @click="visibleRight = false"
                >
                  been evaluated by you.
                </router-link>
              </div>
            </div>
          </div>
          <div class="flex justify-between mt-4">
            <!-- <base-button
              class="w-2/5"
              label="See in Startups"
              severity="primary"
              :outlined="true"
              @click="seeInStartup"
            /> -->
            <base-button
              :isLoading="isDownloadInProgress"
              class="w-full"
              label="Export"
              :isPremium="userAuthStore.state.accountInfo?.tier === 'basic'"
              severity="primary"
              @click="download"
            />
          </div>
        </div>
      </div>
    </Sidebar>
  </div>
</template>

<script setup>
import InputText from "primevue/inputtext";
import { bus } from "@/main.js";
import {
  computed,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  watchEffect
} from "vue";
import { useRoute, useRouter } from "vue-router";
import Sidebar from "primevue/sidebar";
import { uniqueArrayObject } from "@/helpers/function/util.js";
import {
  ACTIVE_CHALLENGES,
  CORPORATE_CHALLENGES_ROUTE_NAME,
  CORPORATE_STARTUPS_ROUTE_NAME,
  CHALLENGES_STARTUPS
} from "@/modules/corporates/router.js";

import { exportExcel } from "../helpers/exportExcel.js";
import { useUserAuthStore } from "../stores/user-auth-store.js";
const userAuthStore = useUserAuthStore();

import { userErrorHandling } from "@global/hooks/use-error-handling.js";
const { errorManager } = userErrorHandling();

import { pushFilterUrl } from "../hooks/use-filter-url-bind.js";

const inputtext = {
  root: ({ context }) => ({
    class: [
      "m-0 w-full py-3 ps-9 pe-3 text-base",
      "text-black border-1 rounded-lg transition-colors duration-200 appearance-none rounded-lg",
      {
        "focus:outline-none focus:border-grey2": !context.disabled,
        "opacity-60 select-none pointer-events-none cursor-default":
          context.disabled
      }
    ]
  })
};
const visibleRight = ref(false);
const route = useRoute();
const router = useRouter();

const items = ref([]);
const title = ref(null);
const preFiltered = ref(null);

const state = reactive({
  filteredItems: computed(() =>
    uniqueArrayObject(
      items.value.filter((ele) =>
        ele.name?.toLowerCase().includes(state.searchInput.toLowerCase())
      ),
      "name"
    ).sort((a, b) =>
      a.name === b.name
        ? a.startupId - b.startupId
        : a.name.localeCompare(b.name)
    )
  ),
  windowLocation: computed(() => window.location.origin),
  searchInput: ""
});
watchEffect(() => {
  if (!visibleRight.value) {
    state.searchInput = "";
  }
});

onMounted(() => {
  bus.on("open-analytics-startups-list", ({ section, list, filters }) => {
    preFiltered.value = filters;
    title.value = section;
    items.value = uniqueArrayObject(
      list.filter(({ rialto }) => !rialto),
      "startupId"
    );
    visibleRight.value = true;
  });
});

onUnmounted(() => {
  bus.off("open-analytics-startups-list");
});
const isDownloadInProgress = ref(false);
async function download() {
  try {
    isDownloadInProgress.value = true;
    const challengeMap = {};
    items.value.forEach((item) => {
      const { challengeId, startupId } = item;
      if (challengeId) {
        if (challengeMap[challengeId]) {
          challengeMap[challengeId].startupIds.push(startupId);
        } else {
          challengeMap[challengeId] = {
            challengeId,
            startupIds: [startupId]
          };
        }
      }
    });
    const resultArray = Object.values(challengeMap);

    const data = {
      companyId: userAuthStore.state.accountInfo.id,
      opportunityData: resultArray
    };
    await exportExcel(data, `${title.value}-analytics`);
  } catch (error) {
    console.log(error);
    errorManager({
      error,
      functionPath: "exportExcel",
      userErrorMessage: `Cannot export excel file ${title.value}-analytics`
    });
  } finally {
    isDownloadInProgress.value = false;
  }
}

function transformTechnologiesFilter(obj) {
  // Merge _vert and _tech into _tech, separated by "; " if both exist.
  // If only one exists, use it as is for _tech.
  const _tech = [obj._vert, obj._tech].filter(Boolean).join(";");

  // Construct the new object without _vert and with the new _tech
  const { _vert, ...rest } = obj;
  return { ...rest, ...(_tech && { _tech }) };
}

function seeInStartup() {
  if (preFiltered.value) {
    console.log("prefiltered", preFiltered.value);
    const { query } = pushFilterUrl({
      filters: preFiltered.value,
      route
    });
    const newQuery = transformTechnologiesFilter(query);
    console.log(newQuery);
    router.push({
      //TODO add correct parmas
      name: CORPORATE_STARTUPS_ROUTE_NAME,
      params: {
        section: CHALLENGES_STARTUPS
      },
      query: newQuery
    });
    visibleRight.value = false;
  }
}
</script>

<style lang="scss" scoped></style>
