import { defineStore } from "pinia";
import { computed, reactive } from "vue";
import { useAppStateStore } from "@/global/stores/app-state-store.js";
import {
  getApplicationsLikes,
  getAvailableApplicationsTimePeriod
} from "../services/applications-service.js";
import {
  STATE_APPLICATIONS_LIKES,
  STATE_AVAILABLE_APPLICATIONS
} from "@/global/constants/loading-state.js";
import { userErrorHandling } from "@global/hooks/use-error-handling.js";

import { encryptLocalStorage } from "@/plugins/pinia.js";

export const useStartupApplicationsStore = defineStore(
  "startup-applications",
  () => {
    const appStateStore = useAppStateStore();
    const { errorManager } = userErrorHandling();
    const state = reactive({
      applicationsLikes: [],
      availableApplications: 0
    });

    const getters = reactive({
      getApplicationsLikes: computed(() => {
        if (!state.applicationsLikes) {
          return [];
        }
        return state.applicationsLikes;
      }),
      getAvailableApplications: computed(() => {
        if (!state.availableApplications) {
          return [];
        }
        return state.availableApplications;
      })
    });

    const actions = reactive({
      //all application + challenge with opportunities + like + dislike
      setApplicationsLikes: async (startupId) => {
        try {
          appStateStore.actions.setStartProcess(STATE_APPLICATIONS_LIKES);
          if (process.env.NODE_ENV === "development") {
            console.log("🏋️ LOAD LIKES for user: ", startupId);
          }
          const applicationsLikes =
            (await getApplicationsLikes(startupId)) || [];
          state.applicationsLikes = applicationsLikes;
        } catch (error) {
          appStateStore.actions.setError(STATE_APPLICATIONS_LIKES, true);
          errorManager({
            error: error,
            functionPath: "setApplicationsLikes",
            userErrorMessage: "Unable to load applications likes"
          });
        } finally {
          appStateStore.actions.setLoading(STATE_APPLICATIONS_LIKES, false);
        }
      },
      setAvailableApplications: async (startupId) => {
        try {
          appStateStore.actions.setStartProcess(STATE_AVAILABLE_APPLICATIONS);
          if (process.env.NODE_ENV === "development") {
            console.log("🏋️ LOAD AVAILABLE APPLICATIONS for user: ", startupId);
          }
          const availableApplications =
            (await getAvailableApplicationsTimePeriod(startupId)) || [];
          state.availableApplications = availableApplications;
        } catch (error) {
          appStateStore.actions.setError(STATE_AVAILABLE_APPLICATIONS, true);
          errorManager({
            error: error,
            functionPath: "setAvailableApplications",
            userErrorMessage: "Unable to load available applications"
          });
        } finally {
          appStateStore.actions.setLoading(STATE_AVAILABLE_APPLICATIONS, false);
        }
      },
      reset: () => {
        state.applicationsLikes = null;
      }
    });

    return {
      state,
      actions,
      getters
    };
  },
  {
    persist: {
      storage: encryptLocalStorage
    }
  }
);
