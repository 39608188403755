<template>
  <Dropdown
    :modelValue="props.modelValue"
    :options="props.options"
    optionLabel="label"
    placeholder=""
    class="w-full"
    @update:modelValue="update"
    :pt="_dropdownChipPT"
  >
    <template #value="slotProps">
      <div v-if="slotProps.value" class="flex items-center">
        <base-vite-icon
          class="me-2"
          :name="slotProps.value.icon"
          :classes="slotProps.value.iconClasses"
        />
        <div>{{ slotProps.value.label }}</div>
      </div>
    </template>
    <template #option="slotProps">
      <div class="flex items-center">
        <div>{{ slotProps.option.label }}</div>
      </div>
    </template>
  </Dropdown>
</template>
<script setup>
import Dropdown from "primevue/dropdown";
const emits = defineEmits(["update:modelValue"]);
const props = defineProps({
  modelValue: {
    type: Object,
    required: true
  },
  options: {
    type: Array,
    required: true
  }
});
function update(e) {
  emits("update:modelValue", e);
}

const _dropdownChipPT = {
  root: {
    class: [
      "mtb-chip border-none w-full inline-flex relative select-none items-center cursor-pointer",
      props.modelValue?.chipClasses
    ]
  },
  //Dropdown title
  input: {
    class: [
      "focus:outline-none focus:shadow-none",
      "text-ellipsis whitespace-nowrap capitalize"
    ]
  },
  trigger: { class: "w-8" },
  //Dropdown menu including filter
  panel: { class: "rounded-2xl shadow-mtb-card" },
  wrapper: {
    class: [
      "pl-6 pr-14 py-6 inline-flex flex-col justify-start items-start items-start gap-5 overflow-auto",
      "bg-white rounded-2xl"
    ]
  },
  item: ({ context }) => {
    return {
      class: [
        "cursor-pointer overflow-hidden relative whitespace-nowrap",
        { "font-normal text-grey2": !context.selected },
        { "font-bold text-black": context.selected }
      ]
    };
  },
  list: {
    class: "list-none m-0 flex flex-col gap-5"
  },
  transition: {
    enterFromClass: "opacity-0 scale-75",
    enterActiveClass:
      "transition-transform transition-opacity duration-150 ease-in",
    leaveActiveClass: "transition-opacity duration-150 ease-linear",
    leaveToClass: "opacity-0"
  }
};
</script>
