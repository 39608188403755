import { auth } from "@plugins/firebase.js";
import mixpanel from "mixpanel-browser";

export function initMixpanelInstance() {
  mixpanel.init("000a5ec3bdc73dab99eacce8dc3be708", {
    debug: process.env.NODE_ENV === "development" ? true : false,
    ignore_dnt: true,
    api_host: "https://proxy-z32ifbd7sa-uc.a.run.app"
  });
  if (process.env.NODE_ENV == "development") {
    console.info("==> mixpanel ready");
  }
}
// //TODO now: use this helper for each FE mixpanel event
export function mixpanelMtbTracking({ eventName, properties }) {
  if (process.env.NODE_ENV !== "development") {
    //for unauth page the uid is not available
    const dataToTrack = {
      ...(properties || {}),
      distinct_id: auth?.currentUser?.uid
    };
    mixpanel.track(eventName, dataToTrack);
  } else {
    console.info(
      `🚚 Tracking event: ${eventName}\nwith properties: `,
      properties
    );
  }
}

export async function mixpanelPeople({ userData, once }) {
  if (!userData) {
    return;
  }
  //TODO add validate here
  if (process.env.NODE_ENV !== "development") {
    /**IF there is no data init it */

    /**START MIXPANEL PEOPLE */
    const properties = {
      $name: userData.FirstName + " " + userData.LastName,
      $email: userData.Email,
      "First Name": userData.FirstName,
      "Last Name": userData.LastName,
      Company: userData.Account.Name,
      "Company Id": userData.AccountId,
      "Company Type": userData.Account?.Type,
      "Company Tier": userData.tier,
      Sponsor: userData?.sponsorship?.sponsorName || "",
      "Sponsor Id": userData?.sponsorship?.sponsorId || "",
      "Sponsor Status": userData?.sponsorship?.active || ""
    };
    if (once) {
      //we want to track only once created, if already exist mantain the previous one
      mixpanel.people.set_once({
        $created: new Date().toISOString(),
        ...properties
      });
    } else {
      //always override information
      mixpanel.people.set(properties);
    }
  } else {
    console.info(`🧑 Set people on mixpanel \nwith properties: `, userData);
  }
}
