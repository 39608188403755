<template>
  <span
    :role="role"
    class="d-inline-flex align-items-center justify-content-center align-middle"
    :class="[...extraClass, ...styles]"
    @click="handleEmit"
    @mouseover="changeIconName('enter')"
    @mouseleave="changeIconName('leave')"
  >
    <slot></slot>
    <svg viewBox="0 0 50 50" :width="width" :height="height">
      <use :href="`${getImg()}#${iconName}`"></use>
    </svg>
  </span>
</template>

<script setup>
import { toRefs, ref, computed } from "vue";
import { iconConfig } from "@/helpers/static/iconConfig.js";
import { ICON_DEFAULT } from "@/helpers/static/constants.js";

/* TODO: document the style for icons - STYLE GUIDE */

const props = defineProps({
  icon: { type: String, default: ICON_DEFAULT.icon, required: true },
  role: { type: String, default: ICON_DEFAULT.role },
  variant: { type: String, default: ICON_DEFAULT.color },
  strokeWidth: { type: [Number, String], default: ICON_DEFAULT.strokeWidth },
  hover: { type: String, default: ICON_DEFAULT.hover },
  scale: { type: [Number, String], default: ICON_DEFAULT.scale },
  stylingIcon: { type: String, default: "" },
  disable: { type: Boolean, default: false }
});
const emit = defineEmits(["click-applied"]);

// Make props reactive
const { scale, icon, variant, hover, strokeWidth, stylingIcon, disable } =
  toRefs(props);

// Const
const iconName = computed(() => {
  return icon.value;
});
const styles = computed(() => {
  let styles = [];
  let color = getIconInfo(variant.value, "variant");
  let iconStrokeWidth = getIconInfo(strokeWidth.value, "strokeWidth");
  let colorClass = "icon-" + color;
  let strokeWidthClass = "icon-stroke-" + iconStrokeWidth;
  let strokeColorClass =
    Number(iconStrokeWidth) > 0 ? `icon-bold-${color}` : "";
  let hoverClass = hoverEffect.length > 0 ? `icon-hover-${hoverEffect}` : "";
  let disableClass = disable.value ? "pe-none opacity-25" : "";
  return [
    ...styles,
    colorClass,
    strokeWidthClass,
    strokeColorClass,
    hoverClass,
    disableClass
  ];
});

const hoverEffect = getIconInfo(hover.value, "hover");
const extraClass = stylingIcon.value.split(" ");

// Variables:

let initialIconSize = ref(Number(scale.value) * 2);
let height = `${initialIconSize.value.toString()}em`;
let width = `${initialIconSize.value.toString()}em`;

// Functions:

function getIconInfo(prop, propName) {
  try {
    let propDefaultVal = ICON_DEFAULT?.[propName];
    let iconConfigVal = iconConfig?.[iconName.value]?.[propName];

    if (prop === propDefaultVal && iconConfigVal) {
      prop = iconConfigVal;
    }
    return prop;
  } catch (e) {
    // console.log(e);
  }
}
const changeIconName = (eventName) => {
  if (!hoverEffect || hoverEffect !== "fill") {
    return;
  }
  if (eventName === "enter" && !iconName.value.includes("fill")) {
    iconName.value = iconName.value + "_fill";
  } else if (eventName === "leave") {
    iconName.value = iconName.value?.replace("_fill", "");
  }
  //styles.value = getIconStyles();
};
const getImg = () => {
  var images = ""; //.context("@assets/mtbIcons/", false, /\.svg$|\.png$/);
  return ""; //images("./" + iconName.value + ".svg");
};
function handleEmit() {
  emit("click-applied");
}
</script>

<style scoped lang="scss">
@import "@/global/scss/_icon.scss";
</style>
