const ENCRYPTION_PREFIX = "ENC_";
const SECRET_KEY = "my_temporary_key"; // A securely stored key, not hard-coded in production
const ENCRYPTION_ALGORITHM = "AES-GCM";
const TEXT_ENCODER = new TextEncoder();
const TEXT_DECODER = new TextDecoder();

async function getKey(secret) {
  const keyMaterial = await crypto.subtle.importKey(
    "raw",
    TEXT_ENCODER.encode(secret),
    { name: "PBKDF2" },
    false,
    ["deriveKey"]
  );

  return crypto.subtle.deriveKey(
    {
      name: "PBKDF2",
      salt: TEXT_ENCODER.encode("some-salt"), // Use a consistent salt
      iterations: 100000,
      hash: "SHA-256"
    },
    keyMaterial,
    { name: ENCRYPTION_ALGORITHM, length: 256 },
    false,
    ["encrypt", "decrypt"]
  );
}

function toBase64Url(base64String) {
  return base64String
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, "");
}

function fromBase64Url(base64UrlString) {
  let base64 = base64UrlString.replace(/-/g, "+").replace(/_/g, "/");
  while (base64.length % 4) {
    base64 += "=";
  }
  return base64;
}

export async function encryptValue(value) {
  const key = await getKey(SECRET_KEY);
  const iv = crypto.getRandomValues(new Uint8Array(12));
  const encrypted = await crypto.subtle.encrypt(
    { name: ENCRYPTION_ALGORITHM, iv },
    key,
    TEXT_ENCODER.encode(value)
  );
  const encryptedArray = new Uint8Array(encrypted);
  const resultArray = new Uint8Array(iv.length + encryptedArray.length);
  resultArray.set(iv);
  resultArray.set(encryptedArray, iv.length);
  const base64String = btoa(String.fromCharCode(...resultArray));
  return ENCRYPTION_PREFIX + toBase64Url(base64String);
}

export async function decryptValue(value) {
  if (!isEncrypted(value)) {
    return value;
  }
  const key = await getKey(SECRET_KEY);
  const base64String = fromBase64Url(value.substring(ENCRYPTION_PREFIX.length));
  const data = atob(base64String);
  const dataArray = Uint8Array.from(data, (c) => c.charCodeAt(0));
  const iv = dataArray.slice(0, 12);
  const encryptedArray = dataArray.slice(12);
  const decrypted = await crypto.subtle.decrypt(
    { name: ENCRYPTION_ALGORITHM, iv },
    key,
    encryptedArray
  );
  return TEXT_DECODER.decode(decrypted);
}

export function isEncrypted(value) {
  return typeof value === "string" && value.startsWith(ENCRYPTION_PREFIX);
}
