<template>
  <div class="flex justify-center flex-col items-center gap-y-4">
    <AutoComplete
      v-model="value"
      :suggestions="items"
      optionLabel="full_address"
      placeholder="Search for a location"
      @complete="search"
      @select="selectItem"
    >
    </AutoComplete>
    <!-- <div v-if="value.lon && value.lat" class="map-container w-full h-[200px]">
      <iframe
        :mousewheel="false"
        :src="`https://www.openstreetmap.org/export/embed.html?bbox=${parseFloat(value.lon) - 0.01},${parseFloat(value.lat) - 0.01},${parseFloat(value.lon) + 0.01},${parseFloat(value.lat) + 0.01}&layer=mapnik&marker=${parseFloat(value.lat)},${parseFloat(value.lon)}`"
        width="100%"
        height="100%"
        style="border: none"
      >
      </iframe>
    </div> -->
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import axios from "axios";

const emit = defineEmits(["update:modelValue"]);
const props = defineProps({
  initialValue: {
    type: String,
    default: null
  }
});
const value = ref(props.initialValue);
const items = ref([]);
const selectedPlace = ref(null);
const mapUrl = ref("");

const search = async (event) => {
  try {
    const response = await axios.get(
      "https://nominatim.openstreetmap.org/search",
      {
        params: {
          city: event.query,
          format: "json",
          "accept-language": "en",
          addressdetails: 1
        }
      }
    );
    //https://nominatim.org/release-docs/latest/api/Output/#geocodejson
    console.log("###==>", response.data);
    items.value = response.data
      .filter((el) => {
        const city =
          el.address.city ||
          el.address.town ||
          el.address.municipality ||
          el.address.village ||
          el.address.province ||
          el.address.district ||
          el.address.local_authority ||
          el.address.county;

        return !!city;
      })
      .map((el) => {
        const city =
          el.address.city ||
          el.address.town ||
          el.address.municipality ||
          el.address.village ||
          el.address.province ||
          el.address.district ||
          el.address.local_authority ||
          el.address.county;

        const state =
          el.address.state ||
          el.address.region ||
          el.address.state_district ||
          el.address.county;

        const country = el.address.country;

        const full_address_parts = [city, state, country].filter(
          (part) => part
        );

        return {
          ...el,
          address: {
            ...el.address,
            city: city,
            state: state,
            country: parseCountries(country)
          },
          full_address: full_address_parts.join(", ")
        };
      });
  } catch (error) {
    console.error("Error:", error);
    items.value = [];
  }
};
const parseCountries = (country) => {
  if (country === "South Korea") {
    return "Korea, South";
  }
  if (country === "North Korea") {
    return "Korea, North";
  }

  return country;
};
const selectItem = (event) => {
  const place = items.value.find((item) => item.name === event.value);
  if (place) {
    selectedPlace.value = place;
    mapUrl.value = `https://www.openstreetmap.org/export/embed.html?bbox=${place.lon - 0.01},${place.lat - 0.01},${place.lon + 0.01},${place.lat + 0.01}&layer=mapnik&marker=${place.lat},${place.lon}`;
  }
};
watch(
  () => value.value,
  async (newVal, oldVal) => {
    if (newVal === oldVal) {
      return;
    }
    if (newVal?.full_address) {
      emit("update:modelValue", {
        city: newVal?.address?.city,
        state: newVal?.address?.state,
        country: newVal?.address?.country,
        lat: newVal?.lat,
        lon: newVal?.lon
      });
    } else {
      emit("update:modelValue", null);
    }
  },
  { immediate: true }
);
</script>

<style>
.map-container {
  pointer-events: none;
  filter: grayscale(1);
}
</style>
