import { createRouter, createWebHistory } from "vue-router";
// import firebase from "firebase/compat/app";
// import { visistedPagesMixpaneltracking } from "@services/MixpanelService";
// import store from "@/store";
// import * as routerHelper from "./routerHelper";
// import { COMMON_UNAUTH_ROUTES } from "./commonUnauthRoutes";
import { GLOBAL_ROUTES } from "@/global/router.js";
import { MTBER_ROUTES } from "@/modules/mtbers/router.js";
import { CORPORATE_MOUDULE_ROUTES } from "@/modules/corporates/router.js";
import { STARTUP_MODULE_ROUTES } from "@/modules/startups/router.js";
import { INSTITUTION_MOUDULE_ROUTES } from "@/modules/institutions/router.js";
import { mixpanelMtbTracking } from "./mixpanel";
// import { CORPORATES_DEDICATED_ROUTES } from "./corporateDedicatedRoutes";
// import { INSTITUTIONS_DEDICATED_ROUTES } from "./institutionDedicatedRoutes";
// import { STARTUPS_DEDICATED_ROUTES } from "./startupDedicatedRoutes";

import debounce from "lodash/debounce";
export const routes = [
  // {
  //   path: "/c",
  //   name: "c",
  //   component: () => import("@views/CreateNewUser"),
  //   meta: {
  //     navbar: false
  //   }
  // },
  // {
  //   path: "/account-request",
  //   name: "AccountRequest",
  //   component: () => import("@views/AccountRequest"),
  //   meta: {
  //     navbar: false
  //   }
  // },

  ...MTBER_ROUTES,
  ...CORPORATE_MOUDULE_ROUTES,
  ...STARTUP_MODULE_ROUTES,
  ...INSTITUTION_MOUDULE_ROUTES,
  ...GLOBAL_ROUTES
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from) {
    if (to.name === from.name) {
      return;
    }
    return {
      top: 0,
      behavior: "smooth"
    };
  }
});

router.afterEach((to, from) => {
  if (to.name !== from.name) {
    setTimeout(() => {
      mixpanelMtbTracking({
        eventName: "Visited Page",
        properties: {
          visited_page_name: to.name,
          visited_page_path: to.path
        }
      });
    }, 500);
  }
});

export default router;
