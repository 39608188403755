<template>
  <div class="relative">
    <AutoComplete
      v-if="props.editable"
      v-model="searchValue"
      :optionLabel="optionLabel"
      :suggestions="suggestions"
      :class="[
        { 'p-invalid border-alert': errorMessage },
        {
          'search-placeholder': !searchValue || searchValue.length === 0
        }
      ]"
      :disabled="disabled"
      loadingIcon
      :placeholder="props.placeholder"
      aria-describedby="dd-error"
      @complete="search"
      @item-select="addTag"
      @blur="setTouched(true)"
    >
    </AutoComplete>
    <base-tag-input
      class="my-4"
      :addedTags="inputValue"
      :disabled="false"
      :inlineEditable="false"
      :editable="props.editable"
      :placeholder="props.placeholder"
      @tag-changed="removeTag"
    />

    <small
      v-if="isInvalid && !disabled"
      class="p-error absolute bottom-[-20px] text-alert left-0 text-base flex items-center"
      id="dd-error"
    >
      <base-vite-icon name="close" classes="w-3.5 h-3.5 stroke-2" />{{
        errorMessage
      }}</small
    >
  </div>
</template>
<script setup>
import { useField } from "vee-validate";
import { ref, watchEffect, computed } from "vue";
const emits = defineEmits(["addTag", "removeTag", "search"]);
const props = defineProps({
  disabled: {
    type: Boolean,
    default: false
  },
  multiple: {
    type: Boolean,
    default: false
  },
  name: {
    type: String,
    required: true
  },
  editable: {
    type: Boolean,
    default: false
  },
  suggestions: {
    type: Array,
    default: () => []
  },
  optionLabel: {
    type: String,
    default: "AccountName"
  },
  placeholder: {
    type: String,
    default: ""
  },
  tags: {
    type: Object,
    default: () => {}
  }
});
const searchValue = ref("");
const {
  value: inputValue,
  errorMessage,
  meta,
  setTouched
} = useField(props.name, undefined, {
  initialValue: props.tags,
  validateOnMount: false
});
const isInvalid = computed(() => {
  return errorMessage.value && meta.touched;
});
function removeDuplicates(array) {
  let seen = new Set();
  return array.filter((obj) => {
    const serialized = JSON.stringify(obj);
    if (!seen.has(serialized)) {
      seen.add(serialized);
      return true;
    }

    return false;
  });
}
watchEffect(() => {
  inputValue.value = props.tags ? removeDuplicates(props.tags) : null;
});
function search(e) {
  emits("search", e);
}
function addTag(e) {
  searchValue.value = "";
  emits("addTag", e.value);
}
function removeTag(e) {
  emits("removeTag", e);
}
</script>
