import { database, functionsEU } from "@plugins/firebase.js";
import {
  collectionGroup,
  deleteDoc,
  doc,
  getDocs,
  query,
  updateDoc,
  where
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { default as constants } from "@share/envConstant.js";
import validate from "validate.js";

import { default as classes } from "@share/errorClasses.js";

export async function deleteSponsorship(data) {
  try {
    //validation
    const validationRules = {
      data: {
        presence: { allowEmpty: false },
        type: "object"
      },
      "data.institutionId": {
        presence: { allowEmpty: false },
        type: "string"
      },
      "data.sponsorshipId": {
        presence: { allowEmpty: false },
        type: "string"
      }
    };
    const validationErrors = validate({ data }, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }

    const documentRef = doc(
      database,
      "institutions",
      data.institutionId,
      "sponsorships",
      data.sponsorshipId
    );
    await deleteDoc(documentRef);
  } catch (error) {
    console.error(error);
  }
}
export async function createSponsorship(data) {
  try {
    //validation
    const validationRules = {
      data: {
        presence: { allowEmpty: false },
        type: "object"
      },
      "data.startupIdsList": {
        presence: { allowEmpty: false },
        type: "array"
      },
      "data.institutionName": {
        presence: { allowEmpty: false },
        type: "string"
      },
      "data.institutionId": {
        presence: { allowEmpty: false },
        type: "string"
      }
    };
    const validationErrors = validate({ data }, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }

    const httpsCallableFunction = httpsCallable(
      functionsEU,
      "institutionSponsorship-createSponsorShip"
    );

    const payload = {
      startupIdArr: data.startupIdsList,
      institution: {
        id: data.institutionId,
        name: data.institutionName
      }
    };
    return await httpsCallableFunction(payload);
  } catch (error) {
    throw error;
  }
}
export async function updateSponsorship(newObject, accountId, type) {
  //add validate eventually use the cleanup in database folder
  try {
    //validation
    const validationRules = {
      newObject: {
        presence: { allowEmpty: false },
        type: "object"
      },
      accountId: {
        presence: { allowEmpty: false },
        type: "string"
      },
      type: {
        presence: { allowEmpty: false },
        type: "string",
        inclusion: {
          within: ["corporate", "startup"]
        }
      }
    };
    const validationErrors = validate(
      { newObject, accountId, type },
      validationRules
    );
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }
    let queryRef = "";
    if (type == "startup") {
      queryRef = collectionGroup(database, "sponsorships");
    } else if (type == "corporate") {
      queryRef = collectionGroup(database, "corporate_sponsorship");
    }
    const q = query(queryRef, where("companyId", "==", accountId));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach(async (snapshot) => {
      const docRef = snapshot.ref;
      await updateDoc(docRef, newObject);
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
}
export async function getCompanySponsorShip(type, accountId) {
  try {
    //validation
    const validationRules = {
      type: {
        presence: { allowEmpty: false },
        type: "string"
      },
      accountId: { presence: { allowEmpty: false }, type: "string" }
    };
    const validationErrors = validate({ type, accountId }, validationRules);
    if (validationErrors) {
      throw new classes.Api400Error(JSON.stringify(validationErrors));
    }
    const httpsCallableFunction = httpsCallable(
      functionsEU,
      "getCorporatesList-getCompanySponsorShip"
    );

    const payload = {
      companyId: accountId,
      companyType: type
    };
    const response = await httpsCallableFunction(payload);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getStartupSponsored(accountId) {
  try {
    const querySponsoredStartups = collectionGroup(database, "sponsorships");
    const querySnapshotStartup = await getDocs(querySponsoredStartups);

    let excludeAccounts = []; // Accounts to exclude from the results

    if (!accountId) {
      // Filter out ECOSYSTEM_TEST_ACCOUNTS if accountId is not provided
      excludeAccounts = constants.ECOSYSTEM_TEST_ACCOUNTS;
    } else if (
      accountId &&
      !constants.ECOSYSTEM_DEMO_TEST_ACCOUNTS.includes(accountId)
    ) {
      // Filter out ECOSYSTEM_DEMO_TEST_ACCOUNTS if accountId is not a demo or test account
      excludeAccounts = constants.ECOSYSTEM_DEMO_TEST_ACCOUNTS;
    } else if (constants.ECOSYSTEM_DEMO_ACCOUNTS.includes(accountId)) {
      excludeAccounts = constants.ECOSYSTEM_TEST_ACCOUNTS;
    }
    // Note: If accountId is included in ECOSYSTEM_TEST_ACCOUNTS, don't filter

    return querySnapshotStartup.docs
      .map((doc) => doc.data())
      .filter((doc) => {
        // If excludeAccounts is empty, or both sponsorId and companyId are not in excludeAccounts, include the doc
        return (
          excludeAccounts.length === 0 ||
          (!excludeAccounts.includes(doc.sponsorId) &&
            !excludeAccounts.includes(doc.companyId))
        );
      });
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function getCorporateSponsored(accountId) {
  try {
    const querySponsoredCorporates = collectionGroup(
      database,
      "corporate_sponsorship"
    );

    const querySnapshotCorporate = await getDocs(querySponsoredCorporates);

    let excludeAccounts = []; // Accounts to exclude from the results

    if (!accountId) {
      // Filter out ECOSYSTEM_TEST_ACCOUNTS if accountId is not provided
      excludeAccounts = constants.ECOSYSTEM_TEST_ACCOUNTS;
    } else if (
      accountId &&
      !constants.ECOSYSTEM_DEMO_TEST_ACCOUNTS.includes(accountId)
    ) {
      // Filter out ECOSYSTEM_DEMO_TEST_ACCOUNTS if accountId is not a demo or test account
      excludeAccounts = constants.ECOSYSTEM_DEMO_TEST_ACCOUNTS;
    } else if (constants.ECOSYSTEM_DEMO_ACCOUNTS.includes(accountId)) {
      excludeAccounts = constants.ECOSYSTEM_TEST_ACCOUNTS;
    }
    // Note: If accountId is included in ECOSYSTEM_TEST_ACCOUNTS, don't filter

    return querySnapshotCorporate.docs
      .map((doc) => {
        return doc.data();
      })
      .filter((doc) => {
        // If excludeAccounts is empty, or both sponsorId and companyId are not in excludeAccounts, include the doc
        return (
          excludeAccounts.length === 0 ||
          (!excludeAccounts.includes(doc.sponsorId) &&
            !excludeAccounts.includes(doc.companyId))
        );
      });
  } catch (error) {
    console.error(error);
    throw error;
  }
}
