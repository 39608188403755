<template>
  <div
    :class="[
      props.classes,
      'w-full flex items-center',
      props.isRow ? 'mtb-card h-[85px] justify-between' : 'justify-center'
    ]"
  >
    <span :class="[props.textClasses, 'text-start me-2']">
      {{ props.message }}</span
    >
  </div>
</template>
<script setup>
const props = defineProps({
  isRow: {
    type: Boolean,
    default: true
  },
  message: {
    type: String,
    required: true
  },
  classes: {
    type: String,
    default: "p-6"
  },
  textClasses: {
    type: String
  }
});
</script>
