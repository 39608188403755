<template>
  <div class="flex flex-col gap-12">
    <span class="text-grey2 text-base">{{ props.bodyMessage }}</span>
    <div v-if="props.opportunities?.length > 0" class="flex flex-col gap-6">
      <div class="flex items-center justify-start gap-6 w-full">
        <div class="flex items-center gap-2">
          <base-logo
            v-for="companyId in props.opportunities"
            :key="companyId"
            classes="w-8 h-8"
            :companyId="companyId"
          />
        </div>
        <base-button
          :text="true"
          label="Evaluate all"
          severity="primary"
          @click="goToEvaluation(props.challengeId)"
          icon="add"
          iconClass="w-4 h-4 stroke-2 stroke-primary"
        />
      </div>

      <span class="text-grey2 text-base"
        >You haven't reviewed all {{ props.opportunities.length }} startup
        suggestions for this challenge. Do you want to take another look before
        closing?</span
      >
    </div>

    <div class="flex justify-between w-full gap-6">
      <base-button
        :label="props.discardButton.label"
        severity="primary"
        outlined
        @click="discard"
      />
      <base-button
        :label="props.confirmButton.label"
        severity="primary"
        @click="confirm"
      />
    </div>
  </div>
</template>

<script setup>
import { inject, onMounted, reactive } from "vue";
import { useUserAuthStore } from "@/global/stores/user-auth-store.js";
import { bus } from "@/main.js";
import router from "@/plugins/router.js";
const dialogRef = inject("dialogRef");
// const emit = defineEmits(["onCancel"]);
const userAuthStore = useUserAuthStore();
const props = reactive({
  bodyMessage: "",
  confirmButton: {
    label: "Confirm",
    action: "confirm-close"
  },
  discardButton: {
    label: "Confirm"
  },
  opportunities: []
});
const confirm = () => {
  bus.emit(props.confirmButton.action);
  dialogRef.value.close("confirm-close");
};
const discard = () => {
  dialogRef.value.close("cancel");
};
const goToEvaluation = (challengeId) => {
  console.log("challengeId", challengeId);
  discard();
  router.push({
    name: "challenge-pipeline-view",
    params: {
      id: challengeId,
      corporateId: userAuthStore.state.accountInfo?.id
    }
  });
};
onMounted(() => {
  props.bodyMessage = dialogRef.value.data.bodyMessage;
  props.confirmButton = dialogRef.value.data.confirmButton;
  props.discardButton = dialogRef.value.data.discardButton;
  props.opportunities = dialogRef.value.data.opportunities;
  props.challengeId = dialogRef.value.data.challengeId;
});
</script>

<style lang="scss" scoped></style>
