import { ref, computed } from "vue";
import { useUserAuthStore } from "@/global/stores/user-auth-store.js";
import { useToast } from "primevue/usetoast";
import IconHome from "@/assets/custom/icon-home.vue";
import IconChallenge from "@/assets/custom/icon-challenge.vue";
import IconStartups from "@/assets/custom/icon-startups.vue";
import IconMeetings from "@/assets/custom/icon-meetings.vue";
import IconAnalytics from "@/assets/custom/icon-analytics.vue";
import IconPipeline from "@/assets/custom/icon-pipeline.vue";
import IconProfile from "@/assets/custom/icon-profile.vue";
import IconEcosystem from "@/assets/custom/icon-ecosystem.vue";
import {
  PARTNER_ANALYTICS_ROUTE_NAME,
  PARTNER_PROFILE_ROUTE_NAME,
  PARTNER_ECOSYSTEM_ROUTE_NAME
} from "@institutions/router.js";
import { GLOBAL_SIGNIN } from "@global/router.js";
import { auth } from "@/plugins/firebase.js";
import { CORPORATE_CHALLENGES_ROUTE_NAME } from "@/modules/corporates/router.js";
import {
  GLOBAL_PARTNERS,
  GLOBAL_CORPORATES,
  GLOBAL_FEATURED
} from "@/global/router.js";
import IconCorporates from "@/assets/custom/icon-corporates.vue";

export function useNavigationGuard(userTypes, allowMtb) {
  const userAuthStore = useUserAuthStore();
  //block navigation based on userTypes, ex corporate cannot access to corporates list, but institution and starup can
  const blockNavigation = computed(() => {
    return (
      userTypes && !userTypes.includes(userAuthStore.state.accountInfo?.type)
    );
  });
  const forceMtbNavigation = computed(() => {
    return allowMtb && userAuthStore.state?.userInfo?.Claims.mtb;
  });
  const globalNavigationBlock = computed(() => {
    return blockNavigation.value && !forceMtbNavigation.value;
  });
  const mtbTeleportTrigger = () => {
    if (
      globalNavigationBlock.value &&
      userAuthStore.state?.userInfo?.Claims.mtb &&
      userAuthStore.state.login
    ) {
      const toast = useToast();
      toast.add({
        severity: "info",
        summary: `This page is private.`,
        detail: `You are now impersonating a ${
          userAuthStore.state.accountInfo?.type
        } account, please change account (${userTypes.join(
          ", "
        )}) first and retry.`,
        life: 10000
      });
    }
  };
  const newNavigation = async () => {
    if (!userAuthStore.state.login) {
      return { name: GLOBAL_SIGNIN };
    } else if (!globalNavigationBlock.value) {
      return null;
    } else {
      return { name: "homepage" };
    }
  };

  return {
    mtbTeleportTrigger,
    newNavigation: newNavigation,
    userId: userAuthStore.state?.accountInfo?.id,
    isLogin: userAuthStore.state.login,
    //TESTING
    blockNavigation,
    forceMtbNavigation,
    globalNavigationBlock
  };
}

export function useNavigationLinks() {
  const corporate = () => {
    return [
      {
        label: "Home",
        icon: IconHome,
        routeName: "homepage"
      },
      {
        label: "Challenges",
        icon: IconChallenge,
        routeName: CORPORATE_CHALLENGES_ROUTE_NAME
      },
      {
        label: "Startups",
        icon: IconStartups,
        routeName: "corporate-startups"
      },
      {
        label: "Meetings",
        icon: IconMeetings,
        routeName: "corporate-meetings"
      },
      {
        label: "Analytics",
        icon: IconAnalytics,
        routeName: "corporate-analytics"
      }
    ];
  };

  const institution = (userId) => {
    return [
      {
        label: "Home",
        icon: IconHome,
        routeName: "homepage"
      },
      {
        label: "My Profile",
        icon: IconProfile,
        routeName: "institution-profile",
        params: {
          id: userId
        }
      },
      {
        label: "My Ecosystem",
        icon: IconEcosystem,
        routeName: PARTNER_ECOSYSTEM_ROUTE_NAME
      },
      {
        label: "Analytics",
        icon: IconAnalytics,
        routeName: PARTNER_ANALYTICS_ROUTE_NAME
      },
      {
        label: "Challenges",
        icon: IconChallenge,
        routeName: "institution-challenges"
      }
    ];
  };

  const startup = (userId) => {
    return [
      {
        label: "Home",
        icon: IconHome,
        routeName: "homepage"
      },
      {
        label: "Pipeline",
        icon: IconPipeline,
        routeName: "startup-pipeline"
      },
      {
        label: "Challenges",
        icon: IconChallenge,
        routeName: "startup-challenges"
      },
      {
        label: "Meetings",
        icon: IconMeetings,
        routeName: "startup-meetings"
      },
      {
        label: "My Profile",
        icon: IconProfile,
        routeName: "startup-profile",
        params: {
          id: userId
        }
      }
    ];
  };
  const unauth = [
    {
      label: "Ecosystem Partners",
      icon: IconEcosystem,
      routeName: GLOBAL_PARTNERS
    },
    {
      label: "Corporates",
      icon: IconCorporates,
      routeName: GLOBAL_CORPORATES
    },
    {
      label: "Featured Lists",
      icon: IconStartups,
      routeName: GLOBAL_FEATURED
    }
  ];
  return {
    startup,
    institution,
    corporate,
    unauth
  };
}
