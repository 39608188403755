import { defineStore } from "pinia";
import { computed, reactive } from "vue";
import { useAppStateStore } from "@/global/stores/app-state-store.js";
import {
  getFavouriteList,
  getStartupsFromFavouriteList
} from "../services/favourites-service.js";
import {
  STATE_FAVOURITE_LIST,
  STATE_ADDITIONAL_INFO_FAVOURITE_LIST
} from "@/global/constants/loading-state.js";
import { userErrorHandling } from "@global/hooks/use-error-handling.js";

import { encryptSessionStorage } from "@/plugins/pinia.js";

export const useCorporateFavouritesStore = defineStore(
  "corporate-favourites",
  () => {
    const appStateStore = useAppStateStore();
    const { errorManager } = userErrorHandling();
    const state = reactive({
      favouriteLists: []
    });

    const getters = reactive({
      getFavouriteLists: computed(() => {
        if (!state.favouriteLists) {
          return [];
        }
        return state.favouriteLists;
      })
    });

    const actions = reactive({
      setFavouriteLists: async (corporateId) => {
        try {
          appStateStore.actions.setStartProcess(STATE_FAVOURITE_LIST);
          if (process.env.NODE_ENV === "development") {
            console.log("🏋️ LOAD FAVOURITES for user: ", corporateId);
          }
          const favouriteLists =
            (await getFavouriteList({
              Account_Id__c: corporateId
            })) || [];
          actions.setAdditionalInfoFavouriteList(favouriteLists);
          state.favouriteLists = favouriteLists;
        } catch (error) {
          appStateStore.actions.setError(STATE_FAVOURITE_LIST, true);
          errorManager({
            error: error,
            functionPath: "setFavouriteLists",
            userErrorMessage: "Unable to load favourite lists"
          });
        } finally {
          appStateStore.actions.setLoading(STATE_FAVOURITE_LIST, false);
        }
      },
      setAdditionalInfoFavouriteList: async (favouriteLists) => {
        try {
          appStateStore.actions.setStartProcess(
            STATE_ADDITIONAL_INFO_FAVOURITE_LIST
          );
          const startupListPromises = favouriteLists.map((list) =>
            getStartupsFromFavouriteList({ Favourite__c: list.Id })
          );
          const startupLists = await Promise.all(startupListPromises);

          // Assuming you want to attach the startupLists to the corresponding favourite list
          // This step depends on how you want to structure your data
          const updatedFavouriteLists = favouriteLists.map((list, index) => ({
            ...list,
            startupList: startupLists[index]
          }));

          state.favouriteLists = updatedFavouriteLists;
        } catch (error) {
          appStateStore.actions.setError(
            STATE_ADDITIONAL_INFO_FAVOURITE_LIST,
            true
          );
          errorManager({
            error: error,
            functionPath: "setAdditionalInfoFavouriteList",
            userErrorMessage: "Unable to load additional info favourite lists"
          });
        } finally {
          appStateStore.actions.setLoading(
            STATE_ADDITIONAL_INFO_FAVOURITE_LIST,
            false
          );
        }
      },
      reset: () => {
        state.favouriteLists = null;
      }
    });

    return {
      state,
      actions,
      getters
    };
  },
  {
    persist: {
      storage: encryptSessionStorage
    }
  }
);
