<template>
  <transition
    appear
    :appear-active-class="
      bounceEffect ? 'bounce-enter-active' : 'fade-enter-active'
    "
  >
    <div v-show="true">
      <slot></slot>
    </div>
  </transition>
</template>
<script>
export default {
  props: {
    bounceEffect: {
      type: Boolean,
      default: true
    }
  }
};
</script>
<style lang="scss" scoped>
.bounce-enter-active {
  animation: bounce-in 1s;
}
.bounce-leave-active {
  animation: bounce-in 1s reverse;
}
.fade-enter-active {
  animation: fade 1s;
}
.fade-leave-active {
  animation: fade 1s reverse;
}
@keyframes bounce-in {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
