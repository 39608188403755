<template>
  <div
    v-if="sponsorBadge"
    class="ms-4"
    style="height: 40px; aspect-ratio: 1 / 1"
  >
    <router-link
      :to="'/partner-profile/' + loadBadge?.sponsorId"
      target="_blank"
    >
      <mtb-tooltip
        :title="
          'This startup is supported by our partner ' +
          sponsorBadge?.sponsorName
        "
      >
        <img
          class="img-fluid"
          :src="sponsorBadge?.src"
          :alt="sponsorBadge?.sponsorName"
        />
      </mtb-tooltip>
    </router-link>
  </div>
</template>

<script setup>
import { computed, ref, watchEffect } from "vue";
import { getCompanySponsorShip } from "../services/sponsorships-service.js";
import { default as support } from "@share/commonSupport.js";
const props = defineProps({
  companyId: {
    type: String,
    default: "",
    validator: (val) => val
  }
});
const sponsor = ref(null);
const sponsorBadge = computed(() => {
  //doesn't show the badhe if there is no sponsor or if the sponsor is inactive
  if (support.isDataFalsyOrEmpty(sponsor.value) || !sponsor.value.active) {
    return null;
  }
  return {
    src: `https://storage.googleapis.com/mtb_logos/original/${sponsor.value.sponsorId}`,
    sponsorName: sponsor.value.sponsorName,
    sponsorId: sponsor.value.sponsorId
  };
});

watchEffect(async () => {
  const userInfo = {
    AccountId: props.companyId,
    Account: {
      //DEBT this syntax is difficult to manage + make the type dynamic
      Type: "startup"
    }
  };
  sponsor.value = await getCompanySponsorShip(
    userInfo.Account.Type,
    userInfo.AccountId
  );
});
</script>

<style></style>
