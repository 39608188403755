import { onMounted, ref, onBeforeMount } from "vue";
import { FilterService } from "primevue/api";
export const useCustomFilterMode = () => {
  const MULTI_ARRAY = ref("MULTI_ARRAY");
  const MULTI_PICKLIST = ref("MULTI_PICKLIST");
  const DATE_IS_BETWEEN = ref("DATE_IS_BETWEEN");
  const YEAR_IS_BETWEEN = ref("YEAR_IS_BETWEEN");
  const EMPLOYEE_RANGE = ref("EMPLOYEE_RANGE");
  const IS_COMPATIBILITY = ref("IS_COMPATIBILITY");
  const IS_APPLIED = ref("IS_APPLIED");

  onBeforeMount(() => {
    FilterService.register(MULTI_ARRAY.value, (value, filter) => {
      const arrayValue = value?.map(({ index }) => index) || [];
      const filters =
        !filter || filter.length === 0
          ? true
          : filter.some((f) => {
              return arrayValue?.includes(f.index);
            });
      return filters;
    });
    //NOTE: search in a string "x;y;c"
    FilterService.register(MULTI_PICKLIST.value, (value, filter) => {
      const filters = filter.every((f) => {
        return value?.indexOf(f.index) > -1;
      });
      return filters;
    });
    FilterService.register(DATE_IS_BETWEEN.value, (value, filter) => {
      if (!filter || filter.length === 0) {
        return true;
      } else {
        const dateValue = new Date(value);
        const startDateObj = new Date(filter[0]);
        const endDateObj = new Date(filter[1]);

        return dateValue >= startDateObj && dateValue <= endDateObj;
      }
    });
    FilterService.register(YEAR_IS_BETWEEN.value, (value, filter) => {
      if (!filter || filter.length === 0) {
        return true;
      } else {
        const numericValue = parseInt(value, 10);

        const filters =
          !filter || filter.length === 0
            ? true
            : filter.some((f) => {
                const [min, max] = f.index;
                return numericValue >= min && numericValue <= max;
              });
        return filters;
      }
    });
    FilterService.register(EMPLOYEE_RANGE.value, (value, filter) => {
      if (!filter || filter.length === 0) {
        return true;
      } else {
        const filters =
          !filter || filter.length === 0
            ? true
            : filter.some((f) => {
                return value == f.name;
              });
        return filters;
      }
    });
    FilterService.register(IS_COMPATIBILITY.value, (value, filter) => {
      if (!filter || filter.length === 0) {
        return true;
      } else {
        const filters =
          !filter || filter.length === 0
            ? true
            : filter.some((f) => {
                return value.name == f.name;
              });
        return filters;
      }
    });
    FilterService.register(IS_APPLIED.value, (value, filter) => {
      if (!filter || filter.length === 0) {
        return true;
      } else {
        const filters =
          !filter || filter.length === 0
            ? true
            : filter.some((f) => {
                return value.index == f.index;
              });
        return filters;
      }
    });
  });

  return {
    MULTI_ARRAY,
    MULTI_PICKLIST,
    DATE_IS_BETWEEN,
    YEAR_IS_BETWEEN,
    EMPLOYEE_RANGE,
    IS_COMPATIBILITY,
    IS_APPLIED
  };
};
