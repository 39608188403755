import { objectsEqual } from "@/helpers/function/util.js";
import { default as mapping } from "@share/mappingFilter.js";
import { default as support } from "@share/commonSupport.js";
import { default as stageMapping } from "@share/stageMappingConfig.js";

export const dealStage = Object.values(
  stageMapping.stageMappingConfig.stageName
)
  .filter((e) => e.section === "evaluation")
  .map((e) => e.value);
export const metStage = Object.values(stageMapping.stageMappingConfig.stageName)
  .filter((e) => e.section === "meeting")
  .map((e) => e.value);
export const mySelectionStage = Object.values(
  stageMapping.stageMappingConfig.stageName
)
  .filter((e) => e.section === "selection")
  .map((e) => e.value);
export const allStages = Object.values(
  stageMapping.stageMappingConfig.stageName
).map((e) => {
  return e.value;
});
export const filterStagesMap = {
  Discovery: allStages,
  Selection: [...mySelectionStage, ...metStage, ...dealStage],
  Meeting: [...metStage, ...dealStage],
  Evaluation: dealStage
};
export const stages = {
  mySelectionStage,
  metStage,
  dealStage
};
export function getMainFunnelColumn({
  dataset,
  stageToSelect = null,
  previuosCountSeries = null
}) {
  const filterDataset =
    dataset.filter((startup) => {
      return stageToSelect
        ? stageToSelect.includes(startup.collection)
        : startup;
    }) || [];

  const list =
    filterDataset.map((startup) => {
      return {
        startupId: startup.startupId,
        rialto: startup.rialto,
        name: startup.name,
        challengeId: startup.challengeId
      };
    }) || [];

  const serie1 = filterDataset.length;
  const serie2 = previuosCountSeries ? previuosCountSeries - serie1 : 0;
  return {
    list,
    serie1,
    serie2
  };
}
export function getMainFunnelData(data) {
  const mainFunnel = {
    series: [],
    list: {
      Discovery: [],
      Selection: [],
      Meeting: [],
      Evaluation: []
    }
  };

  const suggestedStartup = getMainFunnelColumn({
    dataset: data
  });

  const mySelection = getMainFunnelColumn({
    dataset: data,
    stageToSelect: filterStagesMap["Selection"],
    previuosCountSeries: suggestedStartup.serie1
  });
  const met = getMainFunnelColumn({
    dataset: data,
    stageToSelect: filterStagesMap["Meeting"],
    previuosCountSeries: mySelection.serie1
  });
  const deal = getMainFunnelColumn({
    dataset: data,
    stageToSelect: filterStagesMap["Evaluation"],
    previuosCountSeries: met.serie1
  });
  mainFunnel.list["Discovery"] = suggestedStartup.list;
  mainFunnel.list["Selection"] = mySelection.list;
  mainFunnel.list["Meeting"] = met.list;
  mainFunnel.list["Evaluation"] = deal.list;
  mainFunnel.series = [
    {
      data: [
        suggestedStartup.serie1,
        mySelection.serie1,
        met.serie1,
        deal.serie1
      ]
    },
    {
      data: [
        suggestedStartup.serie2,
        mySelection.serie2,
        met.serie2,
        deal.serie2
      ]
    }
  ];
  return mainFunnel;
}

///employee + years
export const employeesRangesMap = {
  "1-10": ["1-10"],
  "11-50": ["11-50"],
  "51-100": ["51-100"],
  "101-250": ["101-250"],
  "251-1000": ["251-500", "501-1000"],
  "1000+": ["1001-5000", "5001-10000", "10001+"]
};
//array broke filter sidebar
export const employeesRangesFullData = [
  { value: employeesRangesMap["1-10"], label: "1-10" },
  { value: employeesRangesMap["11-50"], label: "11-50" },
  { value: employeesRangesMap["51-100"], label: "51-100" },
  { value: employeesRangesMap["101-250"], label: "101-250" },
  { value: employeesRangesMap["251-1000"], label: "251-1000" },
  { value: employeesRangesMap["1000+"], label: "1000+" }
];

function getYearRange(min, max) {
  const todayYear = new Date().getFullYear();
  const firstDate = min ? todayYear - min : todayYear;
  const secondDate = max ? todayYear - max : 0;
  //second value greater than
  //first value smaller than
  return [secondDate, firstDate];
}
//array broke filter sidebar
export const yearsRangesFullData = [
  {
    min: 0,
    max: 2,
    label: "<2",
    value: getYearRange(0, 1)
  },
  {
    min: 2,
    max: 5,
    label: "2-5",
    value: getYearRange(2, 5)
  },
  {
    min: 6,
    max: 10,
    label: "6-10",
    value: getYearRange(6, 10)
  },
  {
    min: 11,
    max: 15,
    label: "11-15",
    value: getYearRange(11, 15)
  },
  {
    min: 16,
    max: null,
    label: ">15",
    value: getYearRange(16, null)
  }
];

export const employeesRanges = [
  "1-10",
  "11-50",
  "51-100",
  "101-250",
  "251-1000",
  "1000+",
  "N.A"
];

export const yearsRanges = ["<2", "2-5", "6-10", "11-15", ">15", "N.A"];
export const specialSortData = {
  //TODO: change it
  StageName: [
    "Bookmarked",
    "Selected",
    "Matched",
    "Met",
    "No Feedback",
    "Not Selected"
  ],
  [mapping.filterKeys.STAGE_NAME]: Object.values(
    stageMapping.stageMappingConfig.stageName
  ).map((e) => {
    return e.label;
  }),
  [mapping.filterKeys.EMPLOYEE]: employeesRangesFullData.map(
    (empl) => empl.label
  ),
  [mapping.filterKeys.YEARS_RANGE]: yearsRangesFullData.map((yrs) => yrs.label)
};
export function spacialSortObject({ object, path, key }) {
  const presetOrder = specialSortData[key];
  var result = [],
    i,
    j;
  //For each element in custom sorting list
  for (i = 0; i < presetOrder.length; i++) {
    //For each element in list
    for (j = 0; j < object.length; j++) {
      const isElementPresent = result.find((element) =>
        objectsEqual(element, object[j])
      );
      //if the current object has the same state of the current custom sort AND the object is not present yet in result list
      //==>push object in result list
      if (
        support.getValueByPath(object[j], path) === presetOrder[i] &&
        !isElementPresent
      ) {
        result.push(object[j]);
      }
    }
  }
  return result;
}
