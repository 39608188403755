import { createApp } from "vue";
import App from "./App.vue";
/**---------------**/
import plugin from "./plugins";
import { createHead } from "@vueuse/head";

/**-- FIRESBASE --**/
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./plugins/firebase.js";
/**---------------**/

/**-- GLOBAL LIBRARIES --**/
import mitt from "mitt";
import { createVfm } from "vue-final-modal";
import Multiselect from "vue-multiselect";
import VueApexCharts from "vue3-apexcharts";
import Popper from "vue3-popper";
import Vue3Toasity, { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
/**-----------------------**/

/**-- STYLES --**/
// import "bootstrap";
import "@/global/scss/main.scss";
import "./plugins/tailwind.scss";

//TODO: to be removed when we don't use bootstrap

/**------------**/

/**-- EXTERNAL SERVICES --**/
import * as Sentry from "@sentry/vue";
/**-----------------------**/

/**-- FORM VALIDATORS --**/
import "./validators";
/**---------------------**/
import { mixpanelMtbTracking } from "@/plugins/mixpanel.js";
import mixpanel from "mixpanel-browser";
export let VueApp = null;

export const bus = mitt();

// ADD modules when firebase is ready
onAuthStateChanged(auth, async (user) => {
  initVueApplication();
  initUser(user);
});

function initUser(user) {
  if (!user) {
    return;
  }
  Sentry.setUser({ email: user.email, id: user.uid });
  // Mixpanel - Identify User
  mixpanel.identify(user.uid);
  // Mixpanel - Track Active Event - TODO ask Giulio, is it necessary?
  mixpanelMtbTracking({ eventName: "Active" });
}
function initVueApplication() {
  if (VueApp) {
    return;
  }
  const head = createHead();
  console.log("@@ head", head);
  VueApp = createApp(App);

  VueApp.use(VueApexCharts)
    .use(
      createVfm({
        key: "$vfm",
        componentName: "VueFinalModal",
        dynamicContainerName: "ModalsContainer"
      })
    )
    .use(Vue3Toasity, {
      // rtl: true,
      autoClose: 3000,
      theme: "colored",
      transition: toast.TRANSITIONS.SLIDE,
      position: toast.POSITION.TOP_RIGHT
    })
    .use(plugin)
    .use(head);

  // window.tours = VueApp.config.globalProperties.$tours;

  //INFO: register components
  //TODO: remove it when we use all primevue components
  VueApp.component("Multiselect", Multiselect);
  VueApp.component("Popper", Popper);
  VueApp.component("Apexcharts", VueApexCharts);

  VueApp.mount("#app");
  VueApp.config.productionTip = false;
  VueApp.config.performance = true;
  VueApp.config.globalProperties.emitter = bus;

  if (process.env.NODE_ENV === "development") {
    VueApp.config.performance = true;
  }
  if (process.env.NODE_ENV !== "development") {
    VueApp.config.silent = true;
    VueApp.config.devtools = false;
    VueApp.config.productionTip = false;
    //TODO: could be useful for sentry?
    //  Vue.config.errorHandler = function (err, vm, info) {
    //   // handle error
    //   // `info` is a Vue-specific error info, e.g. which lifecycle hook
    //   // the error was found in. Only available in 2.2.0+
    // }
  }
}
