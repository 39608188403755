<template>
  <div :class="displayClass" :style="additionalStyle">
    <div
      v-if="isValid"
      :id="id"
      data-bs-toggle="tooltip"
      :data-bs-placement="placement"
      :data-bs-custom-class="customClass"
      data-bs-html="true"
      :title="title"
      class="d-flex"
      :class="customStyle"
      @hover="test"
    >
      <slot></slot>
    </div>
    <div v-else :class="customStyle">
      <slot></slot>
    </div>
  </div>
</template>
<script>
import { Tooltip } from "bootstrap";
export default {
  props: {
    title: { type: String, default: "" },
    id: {
      type: String,
      default: null
    },
    placement: {
      type: String,
      default: "top",
      validator(value) {
        return ["top", "bottom", "right", "left", "auto"].includes(value);
      }
    },
    displayClass: {
      type: String,
      default: "d-inline-block",
      validator(value) {
        return ["d-flex", "d-inline-block", "d-block"].includes(value);
      }
    },
    customClass: {
      type: String,
      default: "tooltip-secondary"
      // validator(value) {
      //   return [
      //     "tooltip-dark",
      //     "tooltip-primary",
      //     "tooltip-secondary",
      //     "tooltip-warning"
      //   ].includes(value);
      // }
    },
    customStyle: { type: String, default: "" },
    isValid: { type: Boolean, default: true },
    additionalStyle: { type: String, default: "" },
    isToggle: { type: Boolean, default: false }
  },
  watch: {
    title(value) {
      if (!this.id) {
        return;
      }
      let tooltip = Tooltip.getInstance(document.getElementById(`${this.id}`)); // Returns a Bootstrap tooltip instance
      if (tooltip?._config) {
        if (this.isToggle) {
          tooltip.hide();
          tooltip._config.title = value;
          setTimeout(() => {
            tooltip.show();
          }, 500);
        } else {
          tooltip._config.title = value;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.tooltip > .tooltip-inner {
  widows: 100px;
}
</style>
